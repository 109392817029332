import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  SET_PERMISSION_TO_FAIL,
  SET_RAW_DATA_DRAWER_CONTENTS,
  TOGGLE_RAW_DATA_DRAWER,
} from './constants';

export interface IUserInterfaceReducer {
  permissionToFail: DbRecordEntityTransform | undefined;
  rawDataDrawerVisible: boolean;
  rawDataDrawerContents: any | null;
}

export const initialState: IUserInterfaceReducer = {
  permissionToFail: undefined,
  rawDataDrawerVisible: false,
  rawDataDrawerContents: null,
};

function reducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_PERMISSION_TO_FAIL:
      return {
        ...state,
        permissionToFail: action.params.permissionToFail,
      };

    case TOGGLE_RAW_DATA_DRAWER: {
      return {
        ...state,
        rawDataDrawerVisible: !state.rawDataDrawerVisible,
      };
    }

    case SET_RAW_DATA_DRAWER_CONTENTS: {
      return {
        ...state,
        rawDataDrawerContents: action.params,
      };
    }

    default:
      return state;
  }
}

export default reducer;
