import { Table } from 'antd';
import React from 'react';

function NetworkDeviceList(params: { data: any }) {
  const { data } = params;

  function getBackgroundColor(value: any) {
    const splitValue = value?.split('dBm');
    const signal = Number(splitValue?.[0]);

    if (signal <= -68 && signal >= -72) {
      return 'orange';
    } else if (signal < -72) {
      return 'red';
    }

    return 'white';
  }

  const columns = [
    {
      title: 'Device Name',
      dataIndex: 'name',
    },
    {
      title: 'Type',
      dataIndex: 'device_type',
    },
    {
      title: 'Connection',
      dataIndex: 'connection_type',
    },
    {
      title: 'Connected To',
      dataIndex: 'source_display_name',
    },
    {
      title: 'Frequency',
      dataIndex: 'connection_frequency',
    },
    {
      title: 'Usage Down',
      dataIndex: 'usage_down_mbps',
    },
    {
      title: 'Usage Up',
      dataIndex: 'usage_up_mbps',
    },
    {
      title: 'Signal',
      dataIndex: 'connectivity_signal',
      render: (value: any, record: any) => (
        <div
          style={{
            //@ts-ignore
            width: '100%' /* Fill the entire cell width */,
            padding: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '45px',
            backgroundColor: getBackgroundColor(value),
            fontWeight: 600,
            color: '#000000',
          }}
        >
          {value}
        </div>
      ),
    },
  ];

  const parsedData = data?.map((elem: any) => ({
    ...elem,
    name: elem.name,
    source_display_name: elem?.source_display_name,
    usage_down_mbps: elem?.usage_down_mbps,
    usage_up_mbps: elem?.usage_up_mbps,
    connectivity_signal: elem?.connectivity_signal,
    connection_frequency: elem?.connection_frequency,
  }));

  return (
    <Table
      size="small"
      columns={columns}
      dataSource={parsedData}
      pagination={false}
    />
  );
}

export default NetworkDeviceList;
