import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.types';
import { Input, Modal, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { cancelAppointmentRequest } from '../../../../../core/appointments/components/store/actions';
import { IAppointmentReducer } from '../../../../../core/appointments/components/store/reducer';
import {
  createRecordsRequest,
  ICreateRecords,
  IUpdateRecordById,
  updateRecordByIdRequest,
} from '../../../../../core/records/store/actions';
import { IRecordAssociationsReducer } from '../../../../../core/recordsAssociations/store/reducer';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '../../../../../core/schemas/store/actions';
import { ISchemaReducer } from '../../../../../core/schemas/store/reducer';
import { IRecordReducer } from '../../../../../core/records/store/reducer';

const { SUPPORT_MODULE } = SchemaModuleTypeEnums;
const { NOTE } = SchemaModuleEntityTypeEnums;

const { TextArea } = Input;

interface Props {
  record: DbRecordEntityTransform;
  schemaReducer: ISchemaReducer;
  recordReducer: IRecordReducer;
  recordAssociationReducer: IRecordAssociationsReducer;
  appointmentReducer: IAppointmentReducer;
  cancelAppointment: any;
  getSchema: any;
  createRecord: (params: ICreateRecords, cb?: any) => void;
  updateRecord: (params: IUpdateRecordById, cb?: any) => void;
  cbFunc?: () => void;
  showModal?: boolean;
}

interface State {
  isLoading: boolean;
  showModal: boolean;
  noteText: string;
}

class WorkOrderInProgressNoteModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
      showModal: false,
      noteText: '',
    };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { showModal } = this.props;
    if (this.state.showModal !== this.props.showModal) {
      //@ts-ignore
      this.setState({ showModal });
    }
  }

  initializeForm() {
    this.setState({
      showModal: true,
    });
  }

  closeForm() {
    this.setState({
      showModal: false,
      isLoading: false,
    });
  }

  handleSubmit() {
    const { record, getSchema, createRecord } = this.props;

    if (this.state.noteText) {
      // create the note
      // update the stage
      getSchema(
        { moduleName: SUPPORT_MODULE, entityName: NOTE },
        (result: SchemaEntity) => {
          this.setState({
            isLoading: true,
          });
          createRecord(
            {
              schema: result,
              createUpdate: [
                {
                  entity: `${SUPPORT_MODULE}:${NOTE}`,
                  properties: {
                    Body: this.state.noteText,
                  },
                  associations: [
                    {
                      entity: String(record.entity),
                      recordId: record?.id,
                    },
                  ],
                },
              ],
            },
            () => {
              this.closeForm();
              if (this.props.cbFunc) {
                this.props.cbFunc();
              }
            },
          );
        },
      );
    }
  }

  onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    console.log('Change:', e.target.value);
    this.setState({
      noteText: e.target.value,
    });
  };

  render() {
    return (
      <>
        <Modal
          title="Add Note"
          visible={this.state.showModal}
          onOk={() => this.handleSubmit()}
          onCancel={() => this.closeForm()}
          confirmLoading={this.state.isLoading}
          okButtonProps={{
            disabled: this.state.noteText?.length < 5,
          }}
        >
          <div style={{ marginBottom: 10 }}>
            <Typography.Text>
              A note is required to leave a Work Order In-progress
            </Typography.Text>
          </div>
          <TextArea value={this.state.noteText} onChange={this.onChange} />
        </Modal>
      </>
    );
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  recordReducer: state.recordReducer,
  recordAssociationReducer: state.recordAssociationReducer,
  appointmentReducer: state.appointmentReducer,
});

const mapDispatch = (dispatch: any) => ({
  getSchema: (params: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(params, cb)),
  createRecord: (params: ICreateRecords, cb: any) =>
    dispatch(createRecordsRequest(params, cb)),
  updateRecord: (params: IUpdateRecordById, cb: any) =>
    dispatch(updateRecordByIdRequest(params, cb)),
  cancelAppointment: (params: any, cb: any) =>
    dispatch(cancelAppointmentRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(WorkOrderInProgressNoteModal);
