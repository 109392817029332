import React, { useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import { getSchemaByModuleAndEntityRequest } from '../../schemas/store/actions';
import { connect } from 'react-redux';
import { getSchemaFromShortListByModuleAndEntity } from '../../../shared/utilities/schemaHelpers';
import { SchemaEntity } from '@d19n/models/dist/schema-manager/schema/schema.entity';
import './styles.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';

type HEX = `#${string}`;

interface Props {
  moduleName: string;
  entityName: string;
  overrideIcon?: string;
  overrideIconColor?: string;
  compact?: boolean;
  getSchemaByEntity: Function;
  schemaReducer: any;
  style?: any;
  iconContainerStyle?: any
}

const defaultIconColor: HEX = '#8f9490';

const ModuleEntityIcon: React.FC<Props> = (props: Props) => {
  const {
    moduleName,
    entityName,
    compact,
    overrideIcon,
    overrideIconColor,
    getSchemaByEntity,
    schemaReducer,
  } = props;

  const [icon, setIcon] = useState<string | undefined>(undefined);
  const [iconColor, setIconColor] = useState<string | undefined>(undefined);

  useEffect(() => {
    // User can override the icon and color, like on schema settings where we show interactive styling
    if (overrideIcon && overrideIconColor) {
      setIcon(overrideIcon);
      setIconColor(overrideIconColor);
    }
    // Otherwise, fetch the schema from shortlist or API and set the icon name and color
    else if (moduleName && entityName && !icon && !iconColor) {
      const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
        schemaReducer.shortList,
        moduleName,
        entityName,
      );

      if (shortlistSchema) {
        setIcon(shortlistSchema.icon);
        setIconColor(shortlistSchema.iconColor);
      } else {
        getSchemaByEntity({ moduleName, entityName }, (res: SchemaEntity) => {
          setIcon(res.icon);
          setIconColor(res.iconColor);
        });
      }
    }
  }, [moduleName, entityName]);

  // On every override update, update the local component state
  useEffect(() => {
    if (overrideIcon) {
      setIcon(overrideIcon);
    }
    if (overrideIconColor) {
      setIconColor(overrideIconColor);
    }
  }, [overrideIcon, overrideIconColor]);

  const renderModuleIcon = () => {
    if (icon) {
      return <i className={`bi bi-${icon}`} style={{color: 'white', ...props.style}}/>;
    } else {
      // Default icon
      return <i className="bi bi-app" style={{ color: 'white', ...props.style }} />;
    }
  };

  return <Tooltip mouseEnterDelay={1} title={entityName}>
      <div
        className={`moduleEntityIcon ${compact ? 'compact' : ''}`}
        style={{
          backgroundColor: compact ? 'white' : iconColor || defaultIconColor,
          color: compact ? iconColor : 'white',
          ...props.iconContainerStyle
        }}
      >
        {renderModuleIcon()}
      </div>
    </Tooltip>
  
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  getSchemaByEntity: (params: any, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(ModuleEntityIcon);