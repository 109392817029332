export const ORDER_NETWORK_DEVICES_SET_IS_LOADING_ORDER_ITEMS =
  'ORDER_NETWORK_DEVICES_SET_IS_LOADING_ORDER_ITEMS';

export const ORDER_NETWORK_DEVICES_SET_ORDER_ITEMS =
  'ORDER_NETWORK_DEVICES_SET_ORDER_ITEMS';
export const SET_NETWORK_DRAWER_VISIBLE = 'SET_NETWORK_DRAWER_VISIBLE';
export const SET_NETWORK_DRAWER_DEVICE_RECORD =
  'SET_NETWORK_DRAWER_DEVICE_RECORD';
export const SET_NETWORK_DRAWER_ORDER_ITEM_RECORD =
  'SET_NETWORK_DRAWER_ORDER_ITEM_RECORD';

export const SET_RUNNING_BULK_ONT_CHECK = 'SET_RUNNING_BULK_ONT_CHECK';
export const SET_RUNNING_BULK_ROUTER_SPEED_TEST =
  'SET_RUNNING_BULK_ROUTER_SPEED_TEST';
export const SET_ADD_NETWORK_DEVICE_MODAL_VISIBLE =
  'SET_ADD_NETWORK_DEVICE_MODAL_VISIBLE';
export const SET_ADD_NETWORK_DEVICE_MODAL_DATA =
  'SET_ADD_NETWORK_DEVICE_MODAL_DATA';
export const SET_RUNNING_BULK_PROVISION = 'SET_RUNNING_BULK_PROVISION';
