import { getProperty } from '@d19n/models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaEntity } from '@d19n/models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.types';
import { SearchQueryType } from '@d19n/models/dist/search/search.query.type';
import {
  Card,
  Col,
  Descriptions,
  Layout,
  List,
  Result,
  Row,
  Spin,
  Typography,
} from 'antd';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  resetRecordsList,
  searchRecordsRequest,
} from '../../../../core/records/store/actions';
import { IRecordReducer } from '../../../../core/records/store/reducer';
import { IRecordAssociationsReducer } from '../../../../core/recordsAssociations/store/reducer';
import { getSchemaByModuleAndEntityRequest } from '../../../../core/schemas/store/actions';
import { ISchemaReducer } from '../../../../core/schemas/store/reducer';
import StageNameTag from '../../../../shared/components/StageNameTag';
import { parseDateToLocalFormat } from '../../../../shared/utilities/dateHelpers';
import {
  getBrowserPath,
  getRecordListFromShortListById,
} from '../../../../shared/utilities/recordHelpers';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../shared/utilities/schemaHelpers';
import { CaretLeftFilled, CaretRightFilled } from '@ant-design/icons';
import {
  ISetPermissionToFail,
  setPermissionToFail,
} from '../../../../core/userInterface/store/actions';
import { Button, Icon, InputGroup, Switch, Tag } from '@blueprintjs/core';

interface Props {
  moduleName: string;
  entityName: string;
  userReducer: any;
  schemaReducer: ISchemaReducer;
  recordReducer: IRecordReducer;
  recordAssociationReducer: IRecordAssociationsReducer;
  searchRecords: any;
  getSchema: (params: any, cb?: any) => void;
  resetRecordReducer: any;
  setPermissionToFail: (params: ISetPermissionToFail) => void;
}

interface State {
  selectedDate: string;
  searchTerms: string;
  schema: SchemaEntity | undefined;
  showCompleted: boolean;
}

const { FIELD_SERVICE_MODULE } = SchemaModuleTypeEnums;
const { WORK_ORDER } = SchemaModuleEntityTypeEnums;

class EngineerWorkOrders extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      searchTerms: '',
      selectedDate: moment().format('YYYY-MM-DD'),
      schema: undefined,
      showCompleted: false,
    };
  }

  componentDidMount() {
    const { setPermissionToFail } = this.props;
    setPermissionToFail({ permissionToFail: undefined });
    this.getSchemas();
  }

  componentDidUpdate(prevProps: Props, prevState: Readonly<State>): void {
    const { schema } = this.state;

    if (prevState.selectedDate != this.state.selectedDate && schema) {
      this.fetchData(schema);
    }

    if (prevState.searchTerms != this.state.searchTerms && schema) {
      this.fetchData(schema);
    }

    if (prevState.showCompleted != this.state.showCompleted && schema) {
      this.fetchData(schema);
    }
  }

  getSchemas = () => {
    const { getSchema, schemaReducer } = this.props;

    const shortListSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      FIELD_SERVICE_MODULE,
      WORK_ORDER,
    );

    if (shortListSchema) {
      this.setState({ schema: shortListSchema });
      this.fetchData(shortListSchema);
    } else {
      getSchema(
        {
          moduleName: FIELD_SERVICE_MODULE,
          entityName: WORK_ORDER,
        },
        (res: SchemaEntity) => {
          this.setState({ schema: res });
          this.fetchData(res);
        },
      );
    }
  };

  componentWillUnmount() {
    const { resetRecordReducer } = this.props;
    resetRecordReducer();
  }

  private fetchData(schema: SchemaEntity) {
    const { searchRecords, userReducer } = this.props;
    const { selectedDate } = this.state;
    const userId = userReducer?.user?.id;

    if (schema) {
      searchRecords({
        schema: schema,
        searchQuery: {
          schemas: schema.id,
          terms: this.state.searchTerms,
          sort: [
            {
              'ServiceAppointment.dbRecords.properties.TimeBlock.keyword': {
                order: 'asc',
              },
            },
          ],
          boolean: {
            must: [
              {
                query_string: {
                  fields: ['ServiceAppointment.dbRecords.properties.Date'],
                  query: selectedDate,
                  lenient: true,
                  default_operator: 'AND',
                },
              },
              {
                query_string: {
                  fields: ['properties.EngineerUserId'],
                  query: userId,
                  lenient: true,
                  default_operator: 'AND',
                },
              },
            ],
            must_not: [
              this.state.showCompleted
                ? null
                : {
                    match: { 'stage.isSuccess': true },
                  },
              // In all cases, do not show Work Orders in stage Fail
              {
                match: { 'stage.key.isFail': true },
              },
            ],
          },
          pageable: {
            size: 200,
          },
        },
      });
    }
  }

  private renderWorkOrderList() {
    const { recordReducer, schemaReducer } = this.props;

    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      SchemaModuleTypeEnums.FIELD_SERVICE_MODULE,
      SchemaModuleEntityTypeEnums.WORK_ORDER,
    );

    if (schema && schema.id) {
      const data = getRecordListFromShortListById(
        recordReducer.list,
        schema.id,
      );

      if (data?.length < 1) {
        return (
          <Result
            status="success"
            title="All work orders are complete!"
            subTitle=""
          />
        );
      }

      return (
        <List
          dataSource={data}
          renderItem={(item, index) => (
            <List.Item key={index}>
              <div>
                <Row align="middle">
                  <Col span={24}>
                    <span style={{ fontSize: '1.5em', fontWeight: 600 }}>
                      {item.recordNumber}
                    </span>
                    {/* VIP Star */}
                    {item.properties?.IsVip && (
                      <Icon
                        icon="star"
                        size={20}
                        style={{ marginBottom: 1, marginLeft: 8 }}
                        color="gold"
                      />
                    )}
                  </Col>
                </Row>
                <Row style={{ marginTop: 5, marginBottom: 8 }}>
                  <Link to={getBrowserPath(item)} component={Typography.Link}>
                    {item.title}
                  </Link>
                </Row>
                <Descriptions size="small">
                  <Descriptions.Item label="Date/Time">
                    <div style={{ display: 'flex' }}>
                      <div style={{ marginRight: 10 }}>
                        {parseDateToLocalFormat(
                          getProperty(
                            item.ServiceAppointment?.dbRecords[0],
                            'Date',
                          ),
                        )}
                      </div>
                      <span>
                        {getProperty(
                          item.ServiceAppointment?.dbRecords[0],
                          'TimeBlock',
                        )}
                      </span>
                    </div>
                  </Descriptions.Item>
                  <Descriptions.Item label="Type">
                    {item.properties.Type ? item.properties.Type : '-'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Stage">
                    {item.stage && item.stage.name ? (
                      <StageNameTag record={item} text={item.stage.name} />
                    ) : (
                      <Tag>None</Tag>
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Engineer">
                    {item?.ownedBy?.fullName}
                  </Descriptions.Item>
                </Descriptions>
              </div>
            </List.Item>
          )}
        />
      );
    }
  }

  render() {
    const { recordReducer } = this.props;
    const { selectedDate } = this.state;

    return (
      <Layout style={{ padding: 15 }}>
        <Row gutter={8} style={{ marginBottom: 15 }}>
          <Col span={12}>
            <Button
              intent="primary"
              large
              fill
              onClick={() =>
                this.setState((prevState) => ({
                  selectedDate: moment(prevState.selectedDate)
                    .subtract(1, 'days')
                    .format('YYYY-MM-DD'),
                }))
              }
            >
              <CaretLeftFilled style={{ marginRight: 10 }} />
              Previous Day
            </Button>
          </Col>
          <Col span={12}>
            <Button
              large
              intent="primary"
              fill
              onClick={() =>
                this.setState((prevState) => ({
                  selectedDate: moment(prevState.selectedDate)
                    .add(1, 'days')
                    .format('YYYY-MM-DD'),
                }))
              }
            >
              Next Day
              <CaretRightFilled style={{ marginLeft: 10 }} />
            </Button>
          </Col>
        </Row>
        <Card
          size="small"
          title={
            <Row>
              <Col span={16}>
                <Typography.Text style={{ fontSize: '1.2em' }}>
                  Work Orders
                </Typography.Text>
                <br />
                <Typography.Text style={{ fontWeight: 400 }}>
                  {moment(selectedDate).format('dddd MMM Do')}
                </Typography.Text>
              </Col>
              {/* Show Complete Switch */}
              <Col span={8} style={{ textAlign: 'right' }}>
                <span style={{ fontWeight: 400 }}>Show Completed</span>
                <br />
                <Switch
                  large
                  checked={this.state.showCompleted}
                  style={{ marginTop: 2 }}
                  onChange={() =>
                    this.setState({ showCompleted: !this.state.showCompleted })
                  }
                />
              </Col>
            </Row>
          }
        >
          <Row justify="space-between">
            {/* Input */}
            <Col span={14}>
              <InputGroup
                placeholder={'Search address'}
                round
                leftIcon="search"
                value={this.state.searchTerms}
                onChange={(e) => this.setState({ searchTerms: e.target.value })}
              />
            </Col>
            <Col span={10} style={{ textAlign: 'right' }}>
              <Button
                text="Reset"
                onClick={() => this.setState({ searchTerms: '' })}
                style={{ marginRight: 5 }}
              />
              <Button
                text="Refresh"
                onClick={() => this.fetchData(this.state.schema!)}
              />
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Spin spinning={recordReducer.isSearching} size="small">
                {this.renderWorkOrderList()}
              </Spin>
            </Col>
          </Row>
        </Card>
      </Layout>
    );
  }
}

const mapState = (state: any) => ({
  userReducer: state.userReducer,
  recordReducer: state.recordReducer,
  recordAssociationReducer: state.recordAssociationReducer,
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  searchRecords: (params: {
    schema: SchemaEntity;
    searchQuery: SearchQueryType;
  }) => dispatch(searchRecordsRequest(params)),
  getSchema: (params: any, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(params, cb)),
  resetRecordReducer: () => dispatch(resetRecordsList()),
  setPermissionToFail: (params: ISetPermissionToFail) =>
    dispatch(setPermissionToFail(params)),
});

export default connect(mapState, mapDispatch)(EngineerWorkOrders);
