import { DeleteOutlined } from '@ant-design/icons';
import { DbRecordAssociationCreateUpdateDto } from '@d19n/models/dist/schema-manager/db/record/association/dto/db.record.association.create.update.dto';
import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaAssociationEntity } from '@d19n/models/dist/schema-manager/schema/association/schema.association.entity';
import { SchemaEntity } from '@d19n/models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.types';
import { Button, Card, Layout, List, Modal, Popconfirm } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { sendConfirmationEmail } from '../../../../core/notifications/email/store/actions';
import { RecordAssociationsReducer } from '../../../../core/records/auditLogs/store/reducer';
import {
  deleteRecordAssociationById,
  getRecordAssociationsRequest,
  IDeleteRecordAssociation,
  IGetRecordAssociations,
  updateOrCreateRecordAssociations,
} from '../../../../core/recordsAssociations/store/actions';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '../../../../core/schemas/store/actions';
import { ISchemaReducer } from '../../../../core/schemas/store/reducer';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../shared/utilities/schemaHelpers';
import CreateOrderOffer from './../Order/CreateOrder/containers/CreateOrderOffer';

type PathParams = {
  url: string;
  recordId: string;
};

type Props = RouteComponentProps<PathParams> & {
  recordAssociationReducer: RecordAssociationsReducer;
  schemaReducer: ISchemaReducer;
  record: DbRecordEntityTransform;
  moduleName: string;
  entityName: string;
  getSchema: (payload: ISchemaByModuleAndEntity, cb?: any) => any;
  getAssociations: (params: IGetRecordAssociations) => void;
  deleteRecordAssociation: (payload: IDeleteRecordAssociation, cb: any) => any;
  createAssociations: (params: any, cb: any) => any;
  sendConfirmation: any;
};

interface State {
  showModal: boolean;
  modalLoading: boolean;
  addOrderItemParams: any | undefined;
  deleteOrderItemMailModalVisible: boolean;
}

class OrderItemView extends React.Component<Props, State> {
  timer: NodeJS.Timeout | undefined;

  constructor(props: Props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    showModal: false,
    modalLoading: false,
    addOrderItemParams: undefined,
    deleteOrderItemMailModalVisible: false,
  });

  componentDidMount() {
    this.timer = undefined;

    if (!this.timer) {
      this.timer = setInterval(() => this.getRecordAssociations(), 5000);
    }
    this.getRecordAssociations();
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  clearTimer() {
    //@ts-ignore
    clearInterval(this.timer);
    this.timer = undefined;
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<{}>,
    snapshot?: any,
  ) {
    if (
      prevProps.record !== this.props.record &&
      !this.props.recordAssociationReducer.isRequesting
    ) {
      this.getRecordAssociations();
    }
  }

  private getRecordAssociations() {
    const {
      getAssociations,
      schemaReducer,
      getSchema,
      moduleName,
      entityName,
      record,
    } = this.props;
    if (record) {
      const schema = getSchemaFromShortListByModuleAndEntity(
        schemaReducer.shortList,
        moduleName,
        entityName,
      );

      if (schema) {
        getAssociations({
          recordId: record.id,
          key: entityName,
          schema,
          entities: [entityName],
        });
      } else {
        getSchema(
          { moduleName, entityName, withAssociations: true },
          (result: SchemaEntity) => {
            getAssociations({
              recordId: record.id,
              key: entityName,
              schema: result,
              entities: [entityName],
            });
          },
        );
      }
    }
  }

  renderOrderItems() {
    const { record, entityName, recordAssociationReducer } = this.props;
    const associationKey = `${record?.id}_${entityName}`;
    const associationObj: any =
      recordAssociationReducer?.shortList?.[associationKey];
    const orderItems = associationObj?.[entityName]?.dbRecords;
    return (
      <>
        <List
          dataSource={orderItems}
          renderItem={(item: DbRecordEntityTransform) =>
            this.renderOrderItem(item)
          }
        />
      </>
    );
  }

  renderOrderItem(item: DbRecordEntityTransform) {
    return (
      <>
        <List.Item>
          <List.Item.Meta
            title={
              <div style={{ display: 'flex' }}>
                <strong>{item.recordNumber}</strong> -{item.title}
                <Popconfirm
                  title="Sure to delete?"
                  onConfirm={() => this.deleteOrderItem(item)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    icon={<DeleteOutlined />}
                    size="small"
                    danger
                    style={{ marginLeft: 'auto' }}
                  ></Button>
                </Popconfirm>
              </div>
            }
            description={
              <div style={{ display: 'flex' }}>
                <div style={{ marginRight: 8 }}>
                  <strong>Unit Price:</strong> {getProperty(item, 'UnitPrice')}
                </div>
                <div style={{ marginRight: 8 }}>
                  <strong>Billing Start:</strong>{' '}
                  {getProperty(item, 'BillingStartDate')}
                </div>
                <div style={{ marginRight: 8 }}>
                  <strong>Next Billing:</strong>{' '}
                  {getProperty(item, 'NextBillingDate')}
                </div>
              </div>
            }
          />
        </List.Item>
      </>
    );
  }

  deleteOrderItem(item: DbRecordEntityTransform) {
    const {
      deleteRecordAssociation,
      record,
      entityName,
      recordAssociationReducer,
    } = this.props;
    const associationKey = `${record?.id}_${entityName}`;
    const associationObj: any =
      recordAssociationReducer?.shortList?.[associationKey];
    deleteRecordAssociation(
      {
        schema: associationObj?.[entityName]?.schema as SchemaEntity,
        schemaAssociation: associationObj?.[entityName]
          ?.schemaAssociation as SchemaAssociationEntity,
        dbRecordAssociationId: item?.dbRecordAssociation?.id as string,
      },
      (res: any) => {
        if (
          !['OrderStageActive', 'OrderStageCancelled'].includes(
            record.stage?.key as string,
          )
        ) {
          this.confirmMailSend();
        }
      },
    );
  }

  selectProducts(params: {
    selectedAddOnProducts: any[];
    selectedBaseProductItems: any[];
    selectedOfferId: string;
  }) {
    this.setState({
      addOrderItemParams: params,
    });
  }

  confirmMailSend() {
    const { sendConfirmation, record } = this.props;
    Modal.confirm({
      title: 'Confirm',
      content: 'Do you want to send new order confirmation to the customer?',
      onOk: () => {
        sendConfirmation(
          `OrderModule/v1.0/orders/${
            record ? record.id : null
          }/email/SENDGRID_ORDER_CONFIRMATION_V2`,
        );
        Modal.destroyAll();
      },
    });
  }

  private constructProductKey(record: DbRecordEntityTransform) {
    return `${record?.id}#${record?.dbRecordAssociation?.relatedAssociationId}`;
  }

  addOrderItems() {
    const {
      createAssociations,
      record,
      recordAssociationReducer,
      sendConfirmation,
      schemaReducer,
      moduleName,
    } = this.props;
    this.setState({ modalLoading: true });
    const associationKey = `${this.state.addOrderItemParams?.selectedOfferId}_Product`;
    const associationObj: any =
      recordAssociationReducer.shortList?.[associationKey];
    const data = associationObj['Product'].dbRecords;

    const body: DbRecordAssociationCreateUpdateDto[] = [];

    for (const product of [
      ...this.state.addOrderItemParams?.selectedBaseProductItems,
      ...this.state.addOrderItemParams?.selectedAddOnProducts,
    ]) {
      const matchingProduct = data.find(
        (elem: DbRecordEntityTransform) =>
          this.constructProductKey(elem) === this.constructProductKey(product),
      );

      if (matchingProduct) {
        body.push({
          entity: `${SchemaModuleTypeEnums.PRODUCT_MODULE}:${SchemaModuleEntityTypeEnums.PRODUCT}`,
          recordId: matchingProduct?.id,
          relatedAssociationId:
            matchingProduct?.dbRecordAssociation?.relatedAssociationId,
          additionalParams: {
            offerId: this.state.addOrderItemParams?.selectedOfferId,
          },
        });
      }
    }

    const orderSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      moduleName,
      'Order',
    );
    const schemaAssociation = orderSchema?.associations?.find(
      (elem) => elem.label === 'Order__OrderItem',
    );

    createAssociations(
      {
        recordId: record.id,
        schema: orderSchema,
        schemaAssociation: schemaAssociation,
        createUpdate: body,
      },
      (res: any) => {
        this.setState(this.getInitialState());
        if (
          !['OrderStageActive', 'OrderStageCancelled'].includes(
            record.stage?.key as string,
          )
        ) {
          this.confirmMailSend();
        }
      },
    );
  }

  render() {
    return (
      <>
        <Modal
          className="add-contact-modal"
          title="Add Product"
          visible={this.state.showModal}
          onCancel={() => this.setState({ showModal: !this.state.showModal })}
          onOk={() => this.addOrderItems()}
          confirmLoading={this.state.modalLoading}
          okText="Submit"
        >
          <CreateOrderOffer
            sendProductsToParent={(params: any) => this.selectProducts(params)}
          />
        </Modal>
        <Layout>
          <Card
            title={
              <div style={{ display: 'flex' }}>
                <strong>Products</strong>
                <Button
                  type="primary"
                  style={{ display: 'flex', marginLeft: 'auto' }}
                  onClick={() =>
                    this.setState({ showModal: !this.state.showModal })
                  }
                >
                  Add Product
                </Button>
              </div>
            }
          >
            {this.renderOrderItems()}
          </Card>
        </Layout>
      </>
    );
  }
}

const mapState = (state: any) => ({
  recordAssociationReducer: state.recordAssociationReducer,
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
  getAssociations: (params: IGetRecordAssociations) =>
    dispatch(getRecordAssociationsRequest(params)),
  deleteRecordAssociation: (payload: IDeleteRecordAssociation, cb: any) =>
    dispatch(deleteRecordAssociationById(payload, cb)),
  createAssociations: (params: any, cb: any) =>
    dispatch(updateOrCreateRecordAssociations(params, cb)),
  sendConfirmation: (payload: any) => dispatch(sendConfirmationEmail(payload)),
});

export default withRouter(connect(mapState, mapDispatch)(OrderItemView));
