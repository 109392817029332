import { SchemaModuleTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.types';
import { Route } from 'react-router-dom';
import RecordDetailView from '../../core/records/components/DetailView';
import { SchemaModuleEntityTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.entity.types';
import OrdersListView from './containers/Order/ListView';
import OrderDetailView from './containers/Order/DetailView';

const { ORDER } = SchemaModuleEntityTypeEnums;
const { ORDER_MODULE } = SchemaModuleTypeEnums;

export const OrderModuleRoutes = [
  <Route path={`/${ORDER_MODULE}/${ORDER}/:recordId`} exact>
    <RecordDetailView moduleName={ORDER_MODULE} entityName={ORDER}>
      <OrderDetailView />
    </RecordDetailView>
  </Route>,
  <Route path={`/${ORDER_MODULE}`} exact>
    <OrdersListView moduleName={ORDER_MODULE} entityName={ORDER} />
  </Route>,
];
