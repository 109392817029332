import { SchemaEntity } from '@d19n/models/dist/schema-manager/schema/schema.entity';
import { SearchQueryType } from '@d19n/models/dist/search/search.query.type';
import { FC, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { searchRecordsRequest } from '../../../../../core/records/store/actions';
import { SchemaModuleEntityTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.types';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../../shared/utilities/schemaHelpers';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '../../../../../core/schemas/store/actions';
import { Button, Col, Modal, Row } from 'antd';
import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import './styles.scss';
import {
  Link,
  RouteComponentProps,
  useLocation,
  withRouter,
} from 'react-router-dom';
import { getBrowserPath } from '../../../../../shared/utilities/recordHelpers';
import { getProperty } from '@d19n/models/dist/schema-manager/helpers/dbRecordHelpers';
import dayjs from 'dayjs';
import { IUserInterfaceReducer } from '../../../../../core/userInterface/store/reducer';

type PathParams = { url: string; recordId: string; match: any };

type PropsType = RouteComponentProps<PathParams> & {
  workOrderId: string;
  schemaReducer: any;
  userReducer: any;
  searchRecords: (
    params: {
      schema: SchemaEntity;
      searchQuery: SearchQueryType;
    },
    cb?: any,
  ) => void;
  getSchema: (params: ISchemaByModuleAndEntity, cb: any) => void;
  onBack: () => void;
  onRecordsSearched?: Function;
  userInterfaceReducer: IUserInterfaceReducer;
};

const { WORK_ORDER } = SchemaModuleEntityTypeEnums;
const { FIELD_SERVICE_MODULE } = SchemaModuleTypeEnums;

function usePrevious<T>(value: T): T {
  const ref: any = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

// This component was made for a specific purpose to prevent working on other WOs
// while one is in progress. It will search for all WOs for a given user (engineer),
// and if there are other WOs in progress, it will show a modal with a message.
const InProgressRedirectModal: FC<PropsType> = (props: PropsType) => {
  const {
    schemaReducer,
    getSchema,
    userReducer,
    searchRecords,
    workOrderId,
    onBack,
    onRecordsSearched,
    userInterfaceReducer,
  } = props;

  const previousRecordId = usePrevious(workOrderId);

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [workOrderSchema, setWorkOrderSchema] = useState<
    SchemaEntity | undefined
  >(undefined);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [inProgressWO, setInProgressWO] = useState<
    DbRecordEntityTransform | undefined
  >(undefined);

  // On Component mount -> fetch Work Order Schema
  useEffect(() => {
    const shortListSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      FIELD_SERVICE_MODULE,
      WORK_ORDER,
    );
    if (shortListSchema) {
      setWorkOrderSchema(shortListSchema);
    } else {
      getSchema(
        {
          moduleName: FIELD_SERVICE_MODULE,
          entityName: WORK_ORDER,
        },
        (res: SchemaEntity) => {
          if (res) {
            setWorkOrderSchema(res);
          }
        },
      );
    }
  }, []);

  // If the previous record id is not the same as the current one, search the WOs again.
  useEffect(() => {
    if (
      previousRecordId &&
      workOrderId !== previousRecordId &&
      workOrderSchema
    ) {
      searchWorkOrders();
    }
  }, [workOrderId]);

  // When Work Order Schema is available, do a search for all WOs for the current user
  useEffect(() => {
    if (workOrderSchema && userReducer) {
      searchWorkOrders();
    }
  }, [workOrderSchema]);

  const searchWorkOrders = () => {
    const userId = userReducer.user?.id;
    const modalOverride = params.get('overrideRedirectionModal');

    if (workOrderSchema) {
      searchRecords(
        {
          schema: workOrderSchema,
          searchQuery: {
            schemas: workOrderSchema.id,
            terms: '',
            boolean: {
              must: [
                {
                  query_string: {
                    fields: ['properties.EngineerUserId'],
                    query: userId,
                    lenient: true,
                    default_operator: 'AND',
                  },
                },
                {
                  terms: {
                    'stage.key.keyword': [
                      'WorkOrderStageInProgress',
                      'WorkOrderStageEnRoute',
                    ],
                  },
                },
              ],
            },
            pageable: {
              size: 500,
            },
          },
        },
        (res: any) => {
          onRecordsSearched && onRecordsSearched(); // Records searched callback
          const results: DbRecordEntityTransform[] = res.data?.data;
          // If there are any WOs in progress, and it's not the current WO, show the modal
          if (results && results.length > 0) {
            let otherInProgressWOs =
              results.filter((wo: DbRecordEntityTransform) => {
                return wo.id !== workOrderId;
              }) || [];

            // Filter out WOs with PermissionToFailDate that is not today
            otherInProgressWOs = otherInProgressWOs.filter(
              (wo: DbRecordEntityTransform) => {
                const permissionToFailDate = getProperty(
                  wo,
                  'PermissionToFailDate',
                );
                if (
                  permissionToFailDate &&
                  permissionToFailDate !== dayjs().format('YYYY-MM-DD')
                ) {
                  return false;
                } else {
                  return true;
                }
              },
            );

            if (otherInProgressWOs.length > 0) {
              console.log(
                '%cDebug -> Theres a WO in progress...',
                'color:orange',
                otherInProgressWOs[0],
              );
              const inProgressWO = otherInProgressWOs[0];
              setInProgressWO(inProgressWO);

              // We need to check if the modal override exists.
              if (!modalOverride || modalOverride !== 'true') {
                setIsModalOpen(true);
              }
            }
          }
        },
      );
    }
  };

  const goBack = () => {
    if (onBack) {
      onBack();
    }
    setIsModalOpen(false);
  };

  // Hide WO In progress modal if the PTF is still waiting for approval. Otherwise, just show it.
  const shouldHideWOInProgressDialog = () => {
    const PTF = userInterfaceReducer.permissionToFail;

    if (PTF) {
      const PTFStatus = getProperty(PTF, 'ApprovalStatus');
      if (PTFStatus === 'APPROVED' || PTFStatus === 'REJECTED') {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  return (
    <Modal
      title="Important Notice"
      closable={false}
      centered
      open={isModalOpen && !shouldHideWOInProgressDialog()}
      className="InProgressModal"
    >
      <Row>
        <Col span={24}>
          <span style={{ fontSize: '1.2em' }}>
            {inProgressWO?.recordNumber || 'One Work Order'} is in progress
          </span>
        </Col>
        <Col span={24} style={{ marginTop: 20 }}>
          You must complete this work order before you can start your next job.
        </Col>
        <Col span={24} style={{ marginTop: 30 }}>
          <Button
            size="large"
            style={{ width: '100%' }}
            type="primary"
            ghost
            onClick={() => goBack()}
          >
            Go back to List
          </Button>
        </Col>
        <Col span={24} style={{ marginTop: 20 }}>
          <Link
            to={`${getBrowserPath(
              inProgressWO!,
            )}?overrideRedirectionModal=true`}
          >
            <Button
              size="large"
              style={{ width: '100%' }}
              type="primary"
              onClick={() => goBack()}
            >
              Complete the Work Order
            </Button>
          </Link>
        </Col>
      </Row>
    </Modal>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  userReducer: state.userReducer,
  userInterfaceReducer: state.userInterfaceReducer,
});

const mapDispatch = (dispatch: any) => ({
  searchRecords: (
    params: {
      schema: SchemaEntity;
      searchQuery: SearchQueryType;
    },
    cb: any,
  ) => dispatch(searchRecordsRequest(params, cb)),
  getSchema: (params: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(params, cb)),
});

export default withRouter(
  connect(mapState, mapDispatch)(InProgressRedirectModal),
);
