import { PlusOutlined } from '@ant-design/icons';
import { Col, Modal, Row, Upload, UploadFile, UploadProps, Image } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  beforeUploadWithoutWatermark,
  getBase64,
} from '../../../../../core/records/components/Files/helpers';
import './styles.scss';
import { Dialog } from '@blueprintjs/core';
import { getHostName } from '../../../../../shared/http/helpers';
import { isMobile } from 'react-device-detect';
import { deleteRecordByIdRequest } from '../../../../../core/records/store/actions';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../../shared/utilities/schemaHelpers';
import {
  ISchemaByModuleAndEntity,
  getSchemaByModuleAndEntityRequest,
} from '../../../../../core/schemas/store/actions';
import { httpGet } from '../../../../../shared/http/requests';
import { RcFile } from 'antd/es/upload';
import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaModuleTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.types';
import { SchemaModuleEntityTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaEntity } from '@d19n/models/dist/schema-manager/schema/schema.entity';
import { getProperty } from '@d19n/models/dist/schema-manager/helpers/dbRecordHelpers';
interface Props {
  schemaReducer: any;
  viewMode?: boolean;
  fileIds?: string[];
  parentNote?: DbRecordEntityTransform;
  onFileAdded?: (id: string) => void;
  onFileRemoved?: (id: string) => void;
  deleteRecord: (payload: any, cb?: any) => void;
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) => void;
  isEditing?: boolean;
}

const { SCHEMA_MODULE } = SchemaModuleTypeEnums;
const { FILE } = SchemaModuleEntityTypeEnums;

const NoteAttachments: React.FC<Props> = (props: Props) => {
  const {
    schemaReducer,
    viewMode,
    onFileAdded,
    onFileRemoved,
    deleteRecord,
    getSchema,
    fileIds,
    parentNote,
  } = props;
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileSchema, setFileSchema] = useState<SchemaEntity | undefined>(
    undefined,
  );
  const [loadingInitialFiles, setLoadingInitialFiles] =
    useState<boolean>(false);
  const [imagePreviewVisible, setImagePreviewVisible] =
    useState<boolean>(false);

  useEffect(() => {
    const shortListSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      SCHEMA_MODULE,
      FILE,
    );
    if (shortListSchema) {
      setFileSchema(shortListSchema);
    } else {
      getSchema(
        {
          moduleName: SCHEMA_MODULE,
          entityName: FILE,
        },
        (schema: SchemaEntity) => {
          setFileSchema(schema);
        },
      );
    }
  }, []);

  // Get files by ids on component mount
  useEffect(() => {
    if (fileIds && fileIds?.length > 0 && parentNote) {
      getFilesByIds(fileIds);
    }
  }, []);

  // Reset file list when new note editor clears attached fileIds
  useEffect(() => {
    if (!parentNote && fileIds && fileIds?.length === 0) {
      setFileList([]);
    }
  }, [fileIds, parentNote]);

  const getFilesByIds = (fileIds: string[]) => {
    if (fileIds && fileIds.length > 0) {
      setLoadingInitialFiles(true);
      httpGet(`SchemaModule/v1.0/db/File/many?ids=${fileIds.join(',')}`).then(
        (response: any) => {
          setLoadingInitialFiles(false);

          const newFileList = response.data?.data?.map(
            (file: DbRecordEntityTransform) => {
              return {
                name: file.title,
                status: 'done',
                url: getProperty(file, 'Url'),
                thumbUrl: getProperty(file, 'Url'),
                percent: 100,
                uid: file.id,
                id: file.id,
                type: getProperty(file, 'Mimetype'),
              };
            },
          );
          setFileList(newFileList);
        },
      );
    }
  };

  const handleChange: UploadProps['onChange'] = (info: any) => {
    setFileList(info.fileList);
    if (info.file?.status === 'done') {
      const fileDbRecord: DbRecordEntityTransform = info.file.response.data;
      if (onFileAdded) {
        onFileAdded(fileDbRecord?.id);
      }
    }
  };

  const handlePreview = async (file: UploadFile) => {
    if (
      file?.type &&
      ['image/jpeg', 'image/png', 'image/gif'].includes(file.type)
    ) {
      setPreviewImage('');
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as RcFile);
      }
      setPreviewImage(file.url || file.preview || '');
      setImagePreviewVisible(true);
    } else {
      setPreviewImage('');
      // openDrawer({
      //   recordId: file?.uid,
      //   moduleName: SCHEMA_MODULE,
      //   entityName: FILE,
      // });
    }
  };

  // We need to show confirmation when user removes the file, because this
  // file record then needs to be removed from the existing associations
  const onGalleryFileRemove = async (file: any): Promise<boolean> => {
    const { confirm } = Modal;

    return new Promise((resolve, reject) => {
      confirm({
        title: 'Removing file',
        content: 'Removing this file form the form will permanently delete it.',
        onOk: () => {
          onFileRemoved && onFileRemoved(file.id || file.response?.data?.id);
          resolve(true);
          deleteRecord({
            schema: fileSchema,
            recordId: file.id || file.response?.data?.id,
          });
        },
        onCancel: () => {
          reject(true);
        },
      });
    });
  };

  const uploadButton = () => {
    return (
      <button
        style={{ border: 0, background: 'none', cursor: 'pointer' }}
        type="button"
      >
        <PlusOutlined style={{ opacity: 0.7, cursor: 'pointer' }} />
        <div style={{ marginTop: 8, cursor: 'pointer' }}>
          <span style={{ opacity: 0.7, cursor: 'pointer', fontSize: '11px' }}>
            Add Files
          </span>
        </div>
      </button>
    );
  };

  const UploadURL = () => {
    if (parentNote) {
      return `${getHostName()}/SchemaModule/v1.0/s3/files/${
        parentNote.entity
      }/${parentNote?.id}/upload`;
    } else {
      return `${getHostName()}/SchemaModule/v1.0/s3/files/upload`;
    }
  };

  return (
    <Row className={`NoteAttachments ${viewMode ? 'viewMode' : ''}`}>
      <Col span={24} style={{ marginTop: 10 }} />
      <Col span={24}>
        <Upload
          action={UploadURL()}
          headers={{ Authorization: 'Bearer ' + localStorage.getItem(`token`) }}
          name="file"
          data={{
            device: isMobile ? 'MOBILE' : 'DESKTOP',
            applicationVersion: process.env.REACT_APP_VERSION || '',
          }}
          listType="picture"
          fileList={fileList}
          onPreview={handlePreview}
          onChange={handleChange}
          onRemove={onGalleryFileRemove}
          multiple
          beforeUpload={(file: any) =>
            beforeUploadWithoutWatermark(file) as any
          }
          disabled={viewMode || !fileSchema}
        >
          {uploadButton()}
        </Upload>
        <Image
          alt={parentNote?.id}
          width={0}
          height={0}
          style={{ display: 'none', border: 'none' }}
          src={previewImage}
          preview={{
            visible: imagePreviewVisible,
            src: previewImage,
            onVisibleChange: (value) => {
              setImagePreviewVisible(value);
            },
          }}
        />
        <Dialog></Dialog>
      </Col>
    </Row>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});
const mapDispatch = (dispatch: any) => ({
  deleteRecord: (payload: any, cb: any) =>
    dispatch(deleteRecordByIdRequest(payload, cb)),
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
});

export default connect(mapState, mapDispatch)(NoteAttachments);
