import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import throttle from 'lodash/throttle';
import { createLogger } from 'redux-logger';
import { loadState, saveState } from './localStorage';
import monitorReducerEnhancer from '../enhancers/monitorReducer';
import authCheckMiddleware from '../middleware/authCheck';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

export default function configureStore() {


  const isInDevMode = ['development', 'staging'].includes(process.env.NODE_ENV);
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [ authCheckMiddleware, sagaMiddleware];

  // Add redux-logger on development and staging environments
  if (isInDevMode) {
    const logger = createLogger({
      collapsed: (logEntry) => !logEntry.error,
    });
    middlewares.push(logger);
  }

  const middlewareEnhancer = applyMiddleware(...middlewares);
  const enhancers = [middlewareEnhancer, monitorReducerEnhancer];
  const composedEnhancers = compose(...enhancers);
  const persistedState = loadState();
  const store = createStore(rootReducer, persistedState, composedEnhancers);

  // if (process.env.NODE_ENV === 'development') {
    // console.log(store.getState());
  // }

  store.subscribe(
    throttle(() => {
      saveState({
        schemaReducer: store.getState().schemaReducer,
        userReducer: store.getState().userReducer,
        identityReducer: store.getState().identityReducer,
        recordTableReducer: store.getState().recordTableReducer,
        queryBuilderReducer: store.getState().queryBuilderReducer,
        navigationReducer: store.getState().navigationReducer,
        recordReducer: store.getState().recordReducer,
        userInterfaceReducer: store.getState().userInterfaceReducer
      });
    }, 1000),
  );

  sagaMiddleware.run(rootSaga);

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./rootReducer', () => store.replaceReducer(rootReducer));
  }

  return store;
}
