import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import React from 'react';
import { IUserInterfaceReducer } from '../../../../core/userInterface/store/reducer';
import { getProperty } from '@d19n/models/dist/schema-manager/helpers/dbRecordHelpers';
import {
  IGetRecordById,
  getRecordByIdRequest,
} from '../../../../core/records/store/actions';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../shared/utilities/schemaHelpers';
import { SchemaModuleEntityTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.types';
import { SchemaEntity } from '@d19n/models/dist/schema-manager/schema/schema.entity';
import {
  ISchemaByModuleAndEntity,
  getSchemaByModuleAndEntityRequest,
} from '../../../../core/schemas/store/actions';
import { connect } from 'react-redux';

const { WORK_ORDER } = SchemaModuleEntityTypeEnums;
const { FIELD_SERVICE_MODULE } = SchemaModuleTypeEnums;

interface Props {
  record: DbRecordEntityTransform;
  userInterfaceReducer: IUserInterfaceReducer;
  schemaReducer: any;
  getRecordById: (payload: IGetRecordById, cb?: any) => void;
  getSchema: (params: ISchemaByModuleAndEntity, cb: any) => void;
}

class PermissionToFailUpdateMonitor extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  fetchWORecord = () => {
    const { schemaReducer, record, getRecordById, getSchema } = this.props;

    const getRecord = (schema: SchemaEntity) => {
      getRecordById({ schema: schema, recordId: record?.id });
    };

    const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer,
      FIELD_SERVICE_MODULE,
      WORK_ORDER,
    );
    if (shortlistSchema) {
      getRecord(shortlistSchema);
    } else {
      getSchema(
        { moduleName: FIELD_SERVICE_MODULE, entityName: WORK_ORDER },
        (res: any) => {
          getRecord(res);
        },
      );
    }
  };

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { userInterfaceReducer } = this.props;

    const previousPTF = prevProps.userInterfaceReducer.permissionToFail;
    const currentPTF = userInterfaceReducer.permissionToFail;

    if (
      previousPTF &&
      getProperty(previousPTF, 'ApprovalStatus') === null &&
      getProperty(currentPTF, 'ApprovalStatus') !== null
    ) {
      this.fetchWORecord();
    }
  }

  render() {
    return <></>;
  }
}

const mapState = (state: any) => ({
  userInterfaceReducer: state.userInterfaceReducer,
  schemaReducer: state.schemaReducer,
});
const mapDispatch = (dispatch: any) => ({
  getRecordById: (payload: IGetRecordById, cb: any) =>
    dispatch(getRecordByIdRequest(payload, cb)),
  getSchema: (params: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(PermissionToFailUpdateMonitor);
