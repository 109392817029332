import dayjs from 'dayjs';
import { INTERNAL_NOTE_GROUP_NAME, TReaction } from './types';
import { SchemaModuleEntityTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.entity.types';
import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { OrganizationUserGroupEntity } from '@d19n/models/dist/identity/organization/user/group/organization.user.group.entity';

const { NOTE } = SchemaModuleEntityTypeEnums;

export const isUserAuthorOfNote = (
  note: DbRecordEntityTransform,
  userReducer: any,
) => {
  if (note) {
    const createdById = note.createdBy?.id;
    const currentUserId = userReducer.user?.id;
    if (createdById && currentUserId && createdById === currentUserId) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const isNoteOlderThanSevenDays = (note: DbRecordEntityTransform) => {
  return dayjs().diff(dayjs(note.createdAt), 'day') > 7;
};

export const emptyNoteBody = [{ type: 'paragraph', children: [{ text: '' }] }];

export const sortNotesAndNestedNoteReplies = (
  notes: DbRecordEntityTransform[],
): DbRecordEntityTransform[] => {
  if (notes.length > 0) {
    let sortedNotes = notes.sort(
      (a: DbRecordEntityTransform, b: DbRecordEntityTransform) =>
        dayjs(a.createdAt).isBefore(dayjs(b.createdAt)) ? 1 : -1,
    );

    return (sortedNotes = sortedNotes.map((note: DbRecordEntityTransform) => {
      if (note[NOTE]?.dbRecords) {
        note[NOTE].dbRecords = note[NOTE].dbRecords.sort(
          (a: DbRecordEntityTransform, b: DbRecordEntityTransform) =>
            dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? 1 : -1,
        );
        return note;
      } else {
        return note;
      }
    }));
  } else {
    return [];
  }
};

export const removeUserReactionFromNote = (
  allReactions: TReaction[],
  userId: string,
) => {
  if (allReactions) {
    return allReactions.filter(
      (reaction: TReaction) => reaction.userId !== userId,
    );
  } else {
    return [];
  }
};

export const removeReactionOnNote = (
  allReactions: TReaction[],
  userId: string,
) => {
  if (allReactions) {
    return allReactions.filter(
      (reaction: TReaction) => reaction.userId !== userId,
    );
  } else {
    return [];
  }
};

export const updateReactionsOnNote = (
  allReactions: [],
  newReactionId: string,
  userId: string,
): TReaction[] => {
  // There are existing reactions on the record
  if (allReactions) {
    let newReactions: TReaction[] = Object.assign([], allReactions);
    const userReaction = newReactions.find(
      (reaction: TReaction) => reaction.userId === userId,
    );

    // There is already a reaction from this user
    if (userReaction) {
      newReactions = newReactions.filter(
        (reaction: TReaction) => reaction.userId !== userId,
      );
      newReactions.push({
        emojiId: newReactionId,
        userId: userId,
        date: dayjs().toISOString(),
      });
      return newReactions;
    }
    // First reaction by this user, add it to the list
    else {
      newReactions.push({
        emojiId: newReactionId,
        userId: userId,
        date: dayjs().toISOString(),
      });
      return newReactions;
    }
  } else {
    // No reactions yet on the record, create a new array with the new reaction
    return [
      {
        emojiId: newReactionId,
        userId: userId,
        date: dayjs().toISOString(),
      },
    ];
  }
};

export const isUserMemberOfInternalNoteGroup = (userReducer: any): boolean => {
  const groups = userReducer?.user?.groups || [];
  return groups.some(
    (group: OrganizationUserGroupEntity) =>
      group.name === INTERNAL_NOTE_GROUP_NAME,
  );
};

export const isRecordInInternalNoteGroup = (
  record: DbRecordEntityTransform,
): boolean => {
  const groups = record.groups || [];
  return groups.some(
    (group: OrganizationUserGroupEntity) =>
      group.name === INTERNAL_NOTE_GROUP_NAME,
  );
};
