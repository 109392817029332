import { Col, Row } from 'antd';
import { FC, useEffect, useState } from 'react';
import AssociationSimpleList from '../../../../../../core/recordsAssociations/AssociationSimpleList';
import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { v4 as uuidv4 } from 'uuid';
import CoreForm from '../../../../../../core/records/components/Forms/CoreForm';
import { SchemaEntity } from '@d19n/models/dist/schema-manager/schema/schema.entity';
import {
  ISchemaByModuleAndEntity,
  getSchemaByModuleAndEntityRequest,
} from '../../../../../../core/schemas/store/actions';
import { connect } from 'react-redux';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../../../shared/utilities/schemaHelpers';
import { SchemaModuleTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.types';
import { initializeRecordForm } from '../../../../../../core/records/components/Forms/store/actions';
import { Button, Section } from '@blueprintjs/core';

interface Props {
  record: DbRecordEntityTransform;
  getSchema: (params: ISchemaByModuleAndEntity, cb: any) => void;
  schemaReducer: any;
  initializeForm: (params: any) => void;
  recordFormReducer: any;
  onFetch?: Function;
}

const uuid = uuidv4();

const { FIELD_SERVICE_MODULE } = SchemaModuleTypeEnums;

const PermissionToFailCard: FC<Props> = (props: Props) => {
  const {
    record,
    getSchema,
    schemaReducer,
    initializeForm,
    recordFormReducer,
  } = props;
  const [schema, setSchema] = useState<SchemaEntity | undefined>(undefined);

  const initializePermissionToFailForm = () => {
    if (schema) {
      initializeForm({
        formUUID: uuid,
        title: 'New Permission to fail',
        showFormModal: true,
        isBatchCreateReq: true,
        hideRecordFormFields: true,
        isCreateReq: true,
        selected: null,
        schema: schema,
        sections: [{ name: schema.name, schema: schema }],
        modified: [
          {
            schemaId: schema?.id,
            associations: [
              {
                recordId: record?.id,
              },
            ],
          },
        ],
      });
    }
  };

  //   Fetch schema on component mount
  useEffect(() => {
    const shortListSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      FIELD_SERVICE_MODULE,
      'PermissionToFail',
    );
    if (shortListSchema) {
      setSchema(shortListSchema);
    } else {
      getSchema(
        {
          moduleName: FIELD_SERVICE_MODULE,
          entityName: 'PermissionToFail',
        },
        (res: any) => setSchema(res),
      );
    }
  }, []);

  // Fetch records on component mount

  return (
    <Col span={24} style={{ padding: 0 }}>
      <Section title="Permission to Fail">
        <Row style={{ padding: 15 }}>
          <Col span={24}>
            <AssociationSimpleList
              hideQuickView
              hideHeader
              parentRecord={record}
              associationEntityName={'PermissionToFail'}
              associationModuleName={'FieldServiceModule'}
              recordColumns={['recordNumber', 'Description']}
            />
          </Col>
          {/* <Col span={24}>
            <Button
              intent="primary"
              style={{ width: '100%', marginTop: 20, borderRadius: 2 }}
              large
              fill
              onClick={() => initializePermissionToFailForm()}
            >
              Create new
            </Button>
          </Col> */}
        </Row>
      </Section>

      <CoreForm
        type="MODAL"
        formUUID={uuid}
        onSubmitEvent={(params: { event: string; res: any }) => {
          // Callback function after the record was created
        }}
      />
    </Col>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  recordFormReducer: state.recordFormReducer,
});

const mapDispatch = (dispatch: any) => ({
  getSchema: (params: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(params, cb)),
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
});

export default connect(mapState, mapDispatch)(PermissionToFailCard);
