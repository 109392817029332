import { FC, useContext } from 'react';
import { getONTsFromOrderItem, getRoutersFromOrderItem } from '../helpers';
import { Col, Row } from 'antd';
import ActivatedONT from '../ONT/ActivatedONT';
import DeactivatedONT from '../ONT/DeactivatedONT';
import DeactivatedRouter from '../Router/DeactivatedRouter';
import ActivatedRouter from '../Router/ActivatedRouter';
import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
interface INetworkBaseProductProps {
  record: DbRecordEntityTransform; // OrderItem
  onUpdated?: () => void;
  staticIpRecord?: DbRecordEntityTransform;
}

export const NetworkBaseProduct: FC<INetworkBaseProductProps> = (props) => {
  const { record, onUpdated, staticIpRecord } = props;

  const ONTs = getONTsFromOrderItem(record);
  const Routers = getRoutersFromOrderItem(record);

  return (
    <Row>
      {/* Associated ONTs */}
      {ONTs.length > 0 ? (
        <Col span={24}>
          <ActivatedONT
            ONTRecord={ONTs[0]}
            OIRecord={record}
            onUpdated={onUpdated}
          />
        </Col>
      ) : (
        <Col span={24}>
          <DeactivatedONT orderItem={record} onUpdated={onUpdated} />
        </Col>
      )}

      {/* Associated Routers */}
      {Routers.length > 0 ? (
        <Col span={24} style={{ marginTop: 12 }}>
          <ActivatedRouter
            routerRecord={Routers[0]}
            orderItem={record}
            onUpdated={onUpdated}
            staticIpRecord={staticIpRecord}
          />
        </Col>
      ) : (
        <Col span={24} style={{ marginTop: 12 }}>
          <DeactivatedRouter orderItem={record} onUpdated={onUpdated} />
        </Col>
      )}
    </Row>
  );
};
