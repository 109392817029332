import { Callout } from '@blueprintjs/core';
import { Col, Row } from 'antd';
import React from 'react';
import './styles.scss';

interface Props {
  children: any;
}

const DisabledNetworkPanelOverlay: React.FC<Props> = (props: Props) => {
  const { children } = props;
  return (
    <>
      <div className="disabledNetworkPanelOverlayMessage">
        <Row style={{ width: '100%' }} justify="center">
          <Col style={{ zIndex: 9999 }}>
            <span style={{ fontSize: '1.2em' }}>
              <Callout
                className="disabledNetworkPanelCallout"
                title="Update Record Stage"
                intent="primary"
                style={{ background: '#eaf1fa' }}
              >
                Move Work Order to In Progress in order to access devices.
              </Callout>
            </span>
          </Col>
        </Row>
      </div>
      <div
        className="disabledNetworkPanelOverlayBackground"
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    </>
  );
};
export default DisabledNetworkPanelOverlay;
