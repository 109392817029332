import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import React from 'react';
import { connect } from 'react-redux';
import { IRecordReducer } from '../../../../../../../core/records/store/reducer';
import {
  getRecordAssociationsRequest,
  IGetRecordAssociations,
} from '../../../../../../../core/recordsAssociations/store/actions';
import { ISchemaReducer } from '../../../../../../../core/schemas/store/reducer';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../../../../shared/utilities/schemaHelpers';
import { Divider, Select, Table } from 'antd';
import { SchemaEntity } from '@d19n/models/dist/schema-manager/schema/schema.entity';
import {
  ISearchRecords,
  searchRecordsRequest,
} from '../../../../../../../core/records/store/actions';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '../../../../../../../core/schemas/store/actions';
import { IRecordAssociationsReducer } from '../../../../../../../core/recordsAssociations/store/reducer';

interface Props {
  recordReducer: IRecordReducer;
  schemaReducer: ISchemaReducer;
  getAssociations: any;
  getSchema: any;
  searchRecords: any;
  recordAssociationReducer: IRecordAssociationsReducer;
  sendProductsToParent: any;
}

interface State {
  selectedOffer: any | undefined;
  offerArray: any;
  productsList: any;
  selectedContractType: any;
  selectedRowKeys: any;
  selectedBaseProductRowKeys: any;
  selectedBaseProducts: any;
  selectedAddOnProducts: any;
}

const { Option } = Select;

const moduleName = 'ProductModule';
const entityName = 'Offer';

class CreateOrderOffer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    selectedOffer: undefined,
    offerArray: [],
    productsList: [],
    selectedContractType: undefined,
    selectedRowKeys: [],
    selectedBaseProductRowKeys: [],
    selectedBaseProducts: [],
    selectedAddOnProducts: [],
  });

  componentDidMount() {
    this.loadLists();
  }

  loadLists() {
    const { getSchema, searchRecords, recordReducer, schemaReducer } =
      this.props;
    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      moduleName,
      entityName,
    );
    // get schema by module and entity and save it to the local state
    getSchema(
      {
        moduleName: moduleName,
        entityName: entityName,
        withAssociations: true,
      },
      (result: SchemaEntity) => {
        searchRecords({
          schema: result,
          searchQuery: {
            terms: '*',
            schemas: result.id,
            pageable: {
              size: 150,
            },
            sort: [
              {
                createdAt: {
                  order: 'desc',
                },
              },
            ],
          },
        });
      },
    );
    if (schema) {
      const data = recordReducer.list[schema.id];
      data?.map((elem: DbRecordEntityTransform) => {
        elem.key = elem.id;
      });
      this.setState({
        offerArray: data,
      });
    }
  }

  private optionSelected(val: any) {
    const { schemaReducer, getAssociations, sendProductsToParent } = this.props;
    this.setState({
      selectedOffer: val,
      productsList: [],
      selectedContractType: undefined,
      selectedRowKeys: [],
      selectedBaseProductRowKeys: [],
      selectedBaseProducts: [],
      selectedAddOnProducts: [],
    });
    setTimeout(() => {
      sendProductsToParent({
        selectedAddOnProducts: this.state.selectedAddOnProducts,
        selectedBaseProductItems: this.state.selectedBaseProducts,
        offerId: val,
      });
    });
    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      moduleName,
      entityName,
    );
    if (schema) {
      getAssociations({
        recordId: val,
        key: 'Product',
        schema: schema,
        entities: ['Product'],
      });
    }
  }

  private renderPriceBookOptions() {
    const { schemaReducer } = this.props;
    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      moduleName,
      entityName,
    );
    if (schema) {
      if (this.state.offerArray) {
        return this.state.offerArray.map((elem: DbRecordEntityTransform) => (
          // @ts-ignore
          <Option key={elem?.id?.toString()} value={elem.id}>
            {elem.title}
          </Option>
        ));
      } else {
        return;
      }
    }
  }

  addRemoveItem = async (items: DbRecordEntityTransform[]) => {
    const { sendProductsToParent } = this.props;
    this.setState({
      selectedAddOnProducts: items,
    });
    setTimeout(() => {
      sendProductsToParent({
        selectedAddOnProducts: this.state.selectedAddOnProducts,
        selectedBaseProductItems: this.state.selectedBaseProducts,
        offerId: this.state.selectedOffer,
      });
    });
  };

  addRemoveBaseItem(item: DbRecordEntityTransform[]) {
    const { sendProductsToParent } = this.props;
    this.setState({
      selectedBaseProducts: item,
    });
    setTimeout(() => {
      sendProductsToParent({
        selectedAddOnProducts: this.state.selectedAddOnProducts,
        selectedBaseProductItems: this.state.selectedBaseProducts,
        offerId: this.state.selectedOffer,
      });
    });
  }

  renderBaseProductList() {
    const { recordAssociationReducer } = this.props;
    const columns = [
      {
        title: 'Title',
        key: 'title',
        dataIndex: 'title',
      },
      {
        title: 'Category',
        dataIndex: 'Category',
        key: 'Category',
        render: (text: any, record: any) => <>{record.properties.Category}</>,
      },
      {
        title: 'Price',
        dataIndex: 'Price',
        key: 'Price',
        render: (text: any, record: any) => <>{record.properties?.UnitPrice}</>,
      },
    ];
    return (
      <Table
        rowSelection={{
          type: 'radio',
          onChange: (
            selectedRowKeys: React.Key[],
            selectedRows: DbRecordEntityTransform[],
          ) => {
            this.addRemoveBaseItem(selectedRows);
            this.setState({ selectedBaseProductRowKeys: selectedRowKeys });
          },
          preserveSelectedRowKeys: true,
          selectedRowKeys: this.state.selectedBaseProductRowKeys,
          onSelect: (record: DbRecordEntityTransform) =>
            this.onBaseProductSelect(record),
        }}
        loading={recordAssociationReducer?.isSearching}
        scroll={{ y: 'calc(100vh - 315px)' }}
        style={{ minHeight: '100%', width: '100%' }}
        size="small"
        dataSource={this.state.productsList?.filter(
          (elem: any) => elem.properties.Type === 'BASE_PRODUCT',
        )}
        columns={columns}
      ></Table>
    );
  }

  setSelectedRowKeys(selectedRowKeys: any) {
    this.setState({
      selectedRowKeys: selectedRowKeys,
    });
  }

  contractTypeSelect(val: any) {
    const { recordAssociationReducer } = this.props;
    const associationKey = `${this.state.selectedOffer}_Product`;
    const associationObj: any =
      recordAssociationReducer.shortList[associationKey];
    let productsList = associationObj?.Product?.dbRecords.filter(
      (elem: any) => elem.properties.ContractType === val,
    );
    productsList.map((elem: any) => {
      elem.key = elem.id;
    });
    this.setState({
      productsList: productsList,
      selectedContractType: val,
    });
  }

  renderContractTypeOptions() {
    const { recordAssociationReducer } = this.props;
    const associationKey = `${this.state.selectedOffer}_Product`;
    const associationObj: any =
      recordAssociationReducer.shortList[associationKey];

    if (associationObj) {
      return this.getUniqueValues(
        associationObj?.Product?.dbRecords,
        'ContractType',
      ).map((elem: any) => (
        // @ts-ignore
        <Option key={elem} value={elem}>
          {elem}
        </Option>
      ));
    } else {
      return;
    }
  }

  getUniqueValues(array: any, key: any) {
    var result = new Set();
    array?.forEach(function (item: any) {
      if (item.properties.hasOwnProperty(key)) {
        result.add(item.properties[key]);
      }
    });
    return Array.from(result);
  }

  renderAddOnProductList() {
    const { recordAssociationReducer } = this.props;
    const associationKey = `${this.state.selectedOffer}_Product`;
    const associationObj: any =
      recordAssociationReducer.shortList[associationKey];
    const productsList = associationObj?.Product?.dbRecords;
    productsList?.map((el: any) => {
      el.key = el.id;
    });
    const columns = [
      {
        title: 'Title',
        key: 'title',
        dataIndex: 'title',
      },
      {
        title: 'Category',
        dataIndex: 'Category',
        key: 'Category',
        render: (text: any, record: any) => <>{record.properties.Category}</>,
      },
      {
        title: 'Price',
        dataIndex: 'Price',
        key: 'Price',
        render: (text: any, record: any) => <>{record.properties?.UnitPrice}</>,
      },
    ];
    return (
      <Table
        rowSelection={{
          type: 'checkbox',
          onChange: (
            selectedRowKeys: React.Key[],
            selectedRows: DbRecordEntityTransform[],
          ) => {
            this.addRemoveItem(selectedRows);
            this.setSelectedRowKeys(selectedRowKeys);
          },
          preserveSelectedRowKeys: true,
          selectedRowKeys: this.state.selectedRowKeys,
        }}
        loading={recordAssociationReducer?.isSearching}
        scroll={{ y: 'calc(100vh - 315px)' }}
        style={{ minHeight: '100%', width: '100%' }}
        size="small"
        dataSource={productsList?.filter(
          (elem: any) => elem.properties.Type === 'ADD_ON_PRODUCT',
        )}
        columns={columns}
      ></Table>
    );
  }

  onBaseProductSelect(record: DbRecordEntityTransform) {
    this.setState({
      selectedRowKeys: [],
      selectedAddOnProducts: [],
    });
  }

  render() {
    const { recordReducer } = this.props;
    return (
      <div style={{ marginTop: '.5rem' }}>
        <Select
          loading={recordReducer.isSearching}
          style={{ width: '100%' }}
          defaultValue={[]}
          placeholder="Select Offer"
          onSelect={(val: any) => this.optionSelected(val)}
          onClick={(e) => this.loadLists()}
        >
          {this.renderPriceBookOptions()}
        </Select>
        <Select
          style={{ width: '100%', marginTop: '1rem' }}
          placeholder="Select Contract Type"
          onSelect={(val: any) => this.contractTypeSelect(val)}
          disabled={this.state.selectedOffer === undefined}
          value={this.state.selectedContractType || undefined}
        >
          {this.renderContractTypeOptions()}
        </Select>
        <Divider />
        {this.renderAddOnProductList()}
        {this.renderBaseProductList()}
      </div>
    );
  }
}

const mapState = (state: any) => ({
  recordReducer: state.recordReducer,
  schemaReducer: state.schemaReducer,
  recordAssociationReducer: state.recordAssociationReducer,
});

const mapDispatch = (dispatch: any) => ({
  searchRecords: (params: ISearchRecords) =>
    dispatch(searchRecordsRequest(params)),
  getAssociations: (params: IGetRecordAssociations) =>
    dispatch(getRecordAssociationsRequest(params)),
  getSchema: (params: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(params, cb)),
});

// @ts-ignore
export default connect(mapState, mapDispatch)(CreateOrderOffer);
