import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaActionEntity } from '@d19n/models/dist/schema-manager/schema/action/schema.action.entity';
import { SchemaEntity } from '@d19n/models/dist/schema-manager/schema/schema.entity';
import { SchemaTypeEntity } from '@d19n/models/dist/schema-manager/schema/types/schema.type.entity';
import { isSchemaActionPropertyFilterMatching } from './helpers';

export const getSchemaActionsForPageHeader = (
  allSchemaActions: SchemaActionEntity[],
  sourceRecord: DbRecordEntityTransform,
  sourceSchema: SchemaEntity,
): SchemaActionEntity[] => {
  let schemaActions: SchemaActionEntity[] = Object.assign(allSchemaActions);

  // 1. Keep actions where userAction is set to true
  schemaActions = schemaActions.filter(
    (action: SchemaActionEntity) => action.userAction,
  );

  // 2. Keep actions that either have schemaTypeId that is matching the current record, or don't have type specified at all.
  schemaActions = schemaActions.filter((action: SchemaActionEntity) => {
    const sourceRecordSchemaTypeName = sourceRecord?.type;
    const sourceRecordSchemaType = sourceSchema.types.find(
      (type: SchemaTypeEntity) => type.name === sourceRecordSchemaTypeName,
    );
    if (
      action.schemaTypeId &&
      action.schemaTypeId !== sourceRecordSchemaType?.id
    ) {
      return false;
    } else {
      return action;
    }
  });

  // 3. Keep actions that heave stages[] property, and it's containing the record
  schemaActions = schemaActions.filter((action: SchemaActionEntity) => {
    if (
      action.stages?.length! > 0 &&
      !action.stages?.includes(sourceRecord?.stage?.key)
    ) {
      return false;
    } else {
      return action;
    }
  });

  // 4. Check for propertyFilters and filter out actions that are not matching the record properties
  schemaActions = schemaActions.filter((action: SchemaActionEntity) => {
    const propertyFilters = action.definition?.propertyFilters;
    if (propertyFilters && propertyFilters?.length! > 0) {
      return isSchemaActionPropertyFilterMatching(
        sourceRecord.properties,
        propertyFilters,
      );
    } else {
      return action;
    }
  });

  return schemaActions;
};

export const getSchemaActionsForCoreFormUpdate = (
  allSchemaActions: SchemaActionEntity[],
  sourceRecord: DbRecordEntityTransform,
  sourceSchema: SchemaEntity,
): SchemaActionEntity[] => {
  let schemaActions: SchemaActionEntity[] = Object.assign(allSchemaActions);

  // 1. Keep action that are matching schemaId
  schemaActions = schemaActions?.filter(
    (action: SchemaActionEntity) => action.schemaId === sourceSchema.id,
  );

  // 2. Keep actions intended for record update
  schemaActions = schemaActions.filter(
    (action: SchemaActionEntity) => action.isUpdate === true,
  );

  // 3. Keep actions intended for default form
  schemaActions = schemaActions.filter(
    (action: SchemaActionEntity) => action.defaultForm === true,
  );

  // 4. Keep actions that either have schemaTypeId that is matching the current record, or don't have type specified at all
  schemaActions = schemaActions.filter((action: SchemaActionEntity) => {
    const sourceRecordSchemaType = sourceSchema.types.find(
      (type: SchemaTypeEntity) => type.name === sourceRecord?.type,
    );
    if (
      action.schemaTypeId &&
      action.schemaTypeId !== sourceRecordSchemaType?.id
    ) {
      return false;
    } else {
      return action;
    }
  });

  // 5. Keep actions that have stages[] property, and it's containing the record stage
  schemaActions = schemaActions.filter((action: SchemaActionEntity) => {
    if (
      action.stages?.length! > 0 &&
      !action.stages?.includes(sourceRecord?.stage?.key)
    ) {
      return false;
    } else {
      return action;
    }
  });

  // 6. Check for propertyFilters and filter out actions that are not matching the record properties
  schemaActions = schemaActions.filter((action: SchemaActionEntity) => {
    const propertyFilters = action.definition?.propertyFilters;
    if (propertyFilters && propertyFilters?.length! > 0) {
      return isSchemaActionPropertyFilterMatching(
        sourceRecord.properties,
        propertyFilters,
      );
    } else {
      return action;
    }
  });

  return schemaActions;
};

export const getSchemaActionsForCoreFormCreate = (
  allSchemaActions: SchemaActionEntity[],
  recordType: string | undefined,
  sourceSchema: SchemaEntity,
): SchemaActionEntity[] => {
  let schemaActions: SchemaActionEntity[] = Object.assign(allSchemaActions);

  // 1. Keep action that are matching schemaId
  schemaActions = schemaActions?.filter(
    (action: SchemaActionEntity) => action.schemaId === sourceSchema.id,
  );

  // 2. Keep actions intended for record create
  schemaActions = schemaActions.filter(
    (action: SchemaActionEntity) => action.isCreate === true,
  );

  // 3. Keep actions intended for default form
  schemaActions = schemaActions.filter(
    (action: SchemaActionEntity) => action.defaultForm === true,
  );

  // 4. Keep actions that either have schemaTypeId that is matching the current record, or don't have type specified at all
  schemaActions = schemaActions.filter((action: SchemaActionEntity) => {
    const sourceRecordSchemaType = sourceSchema.types.find(
      (type: SchemaTypeEntity) => type.name === recordType,
    );
    if (
      action.schemaTypeId &&
      action.schemaTypeId !== sourceRecordSchemaType?.id
    ) {
      return false;
    } else {
      return action;
    }
  });

  return schemaActions;
};

export const getSchemaActionsForStageChangeFlow = (
  allSchemaActions: SchemaActionEntity[],
  sourceRecord: DbRecordEntityTransform,
  sourceSchema: SchemaEntity,
  targetStage: string,
): SchemaActionEntity[] => {
  const getSchemaTypeIdByName = (
    typeName: string | undefined,
    schema: SchemaEntity,
  ) => {
    const type = schema.types?.find(
      (type: SchemaTypeEntity) => type.name === typeName,
    );
    return type ? type.id : '';
  };

  let schemaActions: SchemaActionEntity[] = Object.assign(allSchemaActions);

  // 1. Keep actions that are step flows
  schemaActions = schemaActions.filter(
    (action: SchemaActionEntity) => action.isStepFlow,
  );

  // 2. Filter out actions that are not for this record / schema
  schemaActions = schemaActions.filter(
    (action: SchemaActionEntity) => action.schemaId === sourceRecord.schemaId,
  );

  // 3. Filter out actions that are not intended for this source record stage
  schemaActions = schemaActions.filter((action: SchemaActionEntity) => {
    if (
      action.stages?.length! > 0 &&
      !action.stages?.includes(sourceRecord.stage?.key)
    ) {
      return false;
    } else {
      return action;
    }
  });

  // 4. Filter out actions that are not intended for this targeted stage update
  schemaActions = schemaActions.filter((action: SchemaActionEntity) =>
    action.targetStages?.includes(targetStage),
  );

  // 5. Filter out actions that are not intended for this record type, but leave actions
  //    that don't have type specified at all
  schemaActions = schemaActions.filter(
    (action: SchemaActionEntity) =>
      action.schemaTypeId === null ||
      action.schemaTypeId ===
        getSchemaTypeIdByName(sourceRecord.type, sourceSchema),
  );

  // 6. Check for propertyFilters and filter out actions that are not matching the record properties
  schemaActions = schemaActions.filter((action: SchemaActionEntity) => {
    const propertyFilters = action.definition?.propertyFilters;
    if (propertyFilters && propertyFilters?.length! > 0) {
      return isSchemaActionPropertyFilterMatching(
        sourceRecord.properties,
        propertyFilters,
      );
    } else {
      return action;
    }
  });

  return schemaActions;
};

export const getSchemaActionsForSchemaActionFlows = (
  allSchemaActions: SchemaActionEntity[],
  sourceRecord: DbRecordEntityTransform,
  sourceSchema: SchemaEntity,
): SchemaActionEntity[] => {
  let schemaActions: SchemaActionEntity[] = Object.assign(allSchemaActions);

  // 1. Keep actions where userAction is set to true
  schemaActions = schemaActions.filter(
    (action: SchemaActionEntity) => action.userAction,
  );

  // 2. Keep actions that are step flows
  schemaActions = schemaActions.filter(
    (action: SchemaActionEntity) => action.isStepFlow,
  );

  // 3. Filter out actions that are not for this record / schema
  schemaActions = schemaActions.filter(
    (action: SchemaActionEntity) => action.schemaId === sourceRecord.schemaId,
  );

  // 4. Keep actions that either have schemaTypeId that is matching the current record, or don't have type specified at all
  schemaActions = schemaActions.filter((action: SchemaActionEntity) => {
    const sourceRecordSchemaType = sourceSchema.types.find(
      (type: SchemaTypeEntity) => type.name === sourceRecord.type,
    );
    if (
      action.schemaTypeId &&
      action.schemaTypeId !== sourceRecordSchemaType?.id
    ) {
      return false;
    } else {
      return action;
    }
  });

  // 5. Keep actions that have stages[] property, and it's containing the record stage
  schemaActions = schemaActions.filter((action: SchemaActionEntity) => {
    if (
      action.stages?.length! > 0 &&
      !action.stages?.includes(sourceRecord?.stage?.key)
    ) {
      return false;
    } else {
      return action;
    }
  });

  // 6. Check for propertyFilters and filter out actions that are not matching the record properties
  schemaActions = schemaActions.filter((action: SchemaActionEntity) => {
    const propertyFilters = action.definition?.propertyFilters;
    if (propertyFilters && propertyFilters?.length! > 0) {
      return isSchemaActionPropertyFilterMatching(
        sourceRecord.properties,
        propertyFilters,
      );
    } else {
      return action;
    }
  });

  return schemaActions;
};
