import axios from 'axios';
import { getHostName } from './helpers';

const urlMapper = (path: string) => {
  // override url here to user local service
  // if (path.startsWith('IdentityModule/v1.0')) return `http://localhost:10100/${path}`;
  return `${getHostName()}/${path}`;
};

const buildUrl = (path: string) => urlMapper(path);

export function httpGet<T>(path: string, params?: any) {
  const token = localStorage.getItem(`token`);
  if (!token) {
    return Promise.reject({
      statusCode: 401,
      message: `no access token provided for path ${path}`,
      response: { data: { statusCode: 401 } },
    });
  }
  return axios({
    method: 'get',
    timeout: 60 * 1000,
    params: params,
    url: buildUrl(path),
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
}

export function httpPost<T>(path: string, body: T) {
  const token = localStorage.getItem(`token`);

  // Chech for all API requests but the login
  // allow these routes that do not require a token
  const excludedRoutes =
    path.indexOf('login') === -1 ||
    path.indexOf('mfa') === -1 ||
    path.indexOf('forgot-password') === -1 ||
    path.indexOf('reset-password') === -1 ||
    path.indexOf('complete-registration') === -1 ||
    path.indexOf('register') === -1;

  if (!token && !excludedRoutes) {
    return Promise.reject({
      statusCode: 401,
      message: `no access token provided for path ${path}`,
      response: { data: { statusCode: 401 } },
    });
  }

  return axios({
    method: 'post',
    timeout: 60 * 1000,
    url: buildUrl(path),
    headers: {
      Authorization: 'Bearer ' + token,
    },
    data: body,
  });
}

export function httpPut<T>(path: string, body: T) {
  const token = localStorage.getItem(`token`);
  if (!token) {
    return Promise.reject({
      statusCode: 401,
      message: `no access token provided for path ${path}`,
      response: { data: { statusCode: 401 } },
    });
  }
  return axios({
    method: 'put',
    timeout: 60 * 1000,
    url: buildUrl(path),
    headers: {
      Authorization: 'Bearer ' + token,
    },
    data: body,
  });
}

export function httpPatch<T>(path: string, body: T) {
  const token = localStorage.getItem(`token`);
  if (!token) {
    return Promise.reject({
      statusCode: 401,
      message: `no access token provided for path ${path}`,
      response: { data: { statusCode: 401 } },
    });
  }
  return axios({
    method: 'patch',
    timeout: 60 * 1000,
    url: buildUrl(path),
    headers: {
      Authorization: 'Bearer ' + token,
    },
    data: body,
  });
}

export function httpDelete<T>(path: string, body?: T) {
  const token = localStorage.getItem(`token`);
  if (!token) {
    return Promise.reject({
      statusCode: 401,
      message: `no access token provided for path ${path}`,
      response: { data: { statusCode: 401 } },
    });
  }
  return axios({
    method: 'delete',
    timeout: 60 * 1000,
    url: buildUrl(path),
    headers: {
      Authorization: 'Bearer ' + token,
    },
    data: body,
  });
}

export function httpFileUpload<T>(path: string, body: T) {
  const token = localStorage.getItem(`token`);
  if (!token) {
    return Promise.reject({
      statusCode: 401,
      message: `no access token provided for path ${path}`,
      response: { data: { statusCode: 401 } },
    });
  }
  return axios({
    method: 'post',
    timeout: 60 * 1000,
    url: buildUrl(path),
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + token,
    },
    data: body,
  });
}
