import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  IGetRecordAssociations,
  getRecordAssociationsRequest,
} from '../../../../../core/recordsAssociations/store/actions';
import {
  ISchemaByModuleAndEntity,
  getSchemaByModuleAndEntityRequest,
} from '../../../../../core/schemas/store/actions';
import { SchemaEntity } from '@d19n/models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.types';
import { SchemaModuleEntityTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.entity.types';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../../shared/utilities/schemaHelpers';
import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  Dialog,
  DialogBody,
  DialogFooter,
  ProgressBar,
} from '@blueprintjs/core';
import {
  ISetPermissionToFail,
  setPermissionToFail,
} from '../../../../../core/userInterface/store/actions';
import { IUserInterfaceReducer } from '../../../../../core/userInterface/store/reducer';
import { getProperty } from '@d19n/models/dist/schema-manager/helpers/dbRecordHelpers';
import { Col, Row } from 'antd';
import {
  IGetRecordById,
  getRecordByIdRequest,
} from '../../../../../core/records/store/actions';

interface Props {
  record: DbRecordEntityTransform;
  getAssociations: (params: IGetRecordAssociations, cb: any) => void;
  getSchema: (params: ISchemaByModuleAndEntity, cb?: any) => void;
  setPermissionToFail: (params: ISetPermissionToFail) => void;
  userInterfaceReducer: IUserInterfaceReducer;
  getRecordById: (payload: IGetRecordById, cb: any) => void;
  recordFormReducer: any;
  schemaReducer: any;
}

let timer: NodeJS.Timeout | undefined = undefined;
const { FIELD_SERVICE_MODULE } = SchemaModuleTypeEnums;
const { WORK_ORDER } = SchemaModuleEntityTypeEnums;
const PERMISSION_TO_FAIL = 'PermissionToFail';

const PermissionToFailApprovalNotification: React.FC<Props> = (
  props: Props,
) => {
  const {
    schemaReducer,
    getAssociations,
    getSchema,
    record,
    setPermissionToFail,
    userInterfaceReducer,
    getRecordById,
    recordFormReducer,
  } = props;
  const [schema, setSchema] = useState<SchemaEntity | undefined>(undefined);
  const [isLoadingPTFs, setIsLoadingPTFs] = useState<boolean>(false);

  function usePrevious<T>(value: T): T {
    const ref: any = useRef<T>();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }

  const previousFormModalState = usePrevious(recordFormReducer.showFormModal);

  // Configure polling timer
  const startTimer = () => {
    timer = setInterval(() => {
      if (!document.hidden) {
        fetchPermissionsToFail();
      }
    }, 3000);
  };
  const clearTimer = () => {
    clearInterval(timer);
    timer = undefined;
  };

  // Start timer on component mount, clear timer on unmount
  useEffect(() => {
    // startTimer();
  }, []);
  useEffect(() => {
    return () => {
      clearTimer();
    };
  }, []);

  // Fetch schema on component mount
  useEffect(() => {
    const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      FIELD_SERVICE_MODULE,
      WORK_ORDER,
    );

    if (shortlistSchema) {
      setSchema(shortlistSchema);
    } else {
      getSchema(
        {
          moduleName: FIELD_SERVICE_MODULE,
          entityName: WORK_ORDER,
        },
        (res: any) => {
          setSchema(res);
        },
      );
    }
  }, []);

  // When schema is available, fetch permissions
  useEffect(() => {
    if (schema && record) {
      startTimer();
    }
  }, [schema]);

  const fetchPermissionsToFail = () => {
    if (schema && record) {
      setIsLoadingPTFs(true);
      getAssociations(
        {
          recordId: record.id,
          key: WORK_ORDER,
          schema: schema,
          entities: [PERMISSION_TO_FAIL],
        },
        (res: any) => {
          const results = res.results?.[PERMISSION_TO_FAIL]?.dbRecords || [];
          setIsLoadingPTFs(false);
          if (results && results.length > 0) {
            const PTF = results[0];
            setPermissionToFail({ permissionToFail: PTF });
          } else {
            setPermissionToFail({ permissionToFail: undefined });
          }
        },
      );
    }
  };

  // When the Form modal closes, fetch the PTFs immediately
  useEffect(() => {
    if (
      recordFormReducer.showFormModal === false &&
      previousFormModalState === true
    ) {
      fetchPermissionsToFail();
    }
  }, [recordFormReducer.showFormModal]);

  const shouldShowDialog = () => {
    const PTF = userInterfaceReducer.permissionToFail;
    if (PTF && getProperty(PTF, 'ApprovalStatus') === 'APPROVED') {
      return false;
    } else if (PTF && getProperty(PTF, 'ApprovalStatus') === 'REJECTED') {
      return false;
    } else if (PTF) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Dialog isOpen={shouldShowDialog()} style={{ width: '90%' }}>
      <DialogBody>
        <Row>
          <Col span={24}>
            <h3>Permission to Fail</h3>
          </Col>
          <Col span={24} style={{ marginTop: 15 }}>
            <p>
              You have a pending Permission to fail request.
              <strong> Please wait for the confirmation.</strong>
            </p>
            <p>
              We are now checking for the Approval every 5 seconds. This window
              will close once your request is approved.
            </p>
          </Col>
        </Row>
      </DialogBody>
      <DialogFooter>
        <Row>
          <Col span={24} style={{ marginBottom: 5 }}>
            <p>Waiting for Approval</p>
            <ProgressBar intent="primary" />
          </Col>
        </Row>
      </DialogFooter>
    </Dialog>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  userInterfaceReducer: state.userInterfaceReducer,
  recordFormReducer: state.recordFormReducer,
});

const mapDispatch = (dispatch: any) => ({
  getAssociations: (params: IGetRecordAssociations, cb: any) =>
    dispatch(getRecordAssociationsRequest(params, cb)),
  getSchema: (params: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(params, cb)),
  setPermissionToFail: (params: ISetPermissionToFail) =>
    dispatch(setPermissionToFail(params)),
  getRecordById: (payload: IGetRecordById, cb: any) =>
    dispatch(getRecordByIdRequest(payload, cb)),
});

export default connect(
  mapState,
  mapDispatch,
)(PermissionToFailApprovalNotification);
