import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

import { Col, Row, Tabs } from 'antd';
import moment from 'moment';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import './styles.scss';

import { Link } from 'react-router-dom';
import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { ISchemaReducer } from '../../../../../core/schemas/store/reducer';
import { IRecordReducer } from '../../../../../core/records/store/reducer';
import { IRecordAssociationsReducer } from '../../../../../core/recordsAssociations/store/reducer';
import {
  IGetRecordAssociationWithNestedEntities,
  getRecordAssociationWithNestedEntitiesRequest,
} from '../../../../../core/recordsAssociations/store/actions';
import {
  IAddRecordToShortList,
  IGetRecordById,
  addRecordToShortList,
  getRecordByIdRequest,
  updateRecordByIdRequest,
} from '../../../../../core/records/store/actions';
import { RelationTypeEnum } from '@d19n/models/dist/schema-manager/db/record/association/types/db.record.association.constants';
import { getSchemaFromShortListBySchemaId } from '../../../../../shared/utilities/schemaHelpers';
import { getProperty } from '@d19n/models/dist/schema-manager/helpers/dbRecordHelpers';
import { hasRole } from '../../../../../shared/permissions/rbacRules';
import ModuleEntityIcon from '../../../../../core/theme/ModuleEntityIcon';
import { getBrowserPath } from '../../../../../shared/utilities/recordHelpers';

interface Props {
  userReducer: any;
  title?: string;
  expandable?: any;
  moduleName: string;
  entityName: string;
  nestedEntityName?: string;
  ignoreRelatedAssociationIdForNested?: boolean;
  record: DbRecordEntityTransform;
  schemaReducer: ISchemaReducer;
  recordReducer: IRecordReducer;
  recordAssociationReducer: IRecordAssociationsReducer;
  updateRecord: any;
  getAssociationWithNestedEntities: (
    params: IGetRecordAssociationWithNestedEntities,
  ) => {};
  shortListRecord: (params: IAddRecordToShortList) => {};
  getRecordById: (payload: IGetRecordById, cb: any) => {};
  hidden?: string[];
  filters?: string[];
  isCreateHidden?: boolean;
  customActionOverride?: boolean;
  disableRelatedProductEdit?: boolean;
  thumbnailSize?: number; // 8, 12, 24
  hideViewOptions?: boolean;
  relationType?: RelationTypeEnum;
  sortRecords?: (
    records: DbRecordEntityTransform[],
  ) => DbRecordEntityTransform[];
  isEditDisabled?: boolean;
  location?: 'sidebar' | 'association';
  disableUpload?: boolean;
  displayType?: 'list' | 'thumbnails';
  onNewWebhookReceived?: (record: DbRecordEntityTransform) => void;
  tabAutoSelect: boolean;
  setTabAutoSelect: (tabAutoSelect: boolean) => void;
}

enum WebhookType {
  SERVICE_PROVISION = 'SERVICE_PROVISION',
  SERVICE_DEPROVISION = 'SERVICE_DEPROVISION',
  SERVICE_CHECK = 'SERVICE_CHECK',
  DEVICE_REBOOT = 'DEVICE_REBOOT',
}

export enum CheckResult {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
}

class NetworkWebhookResults extends React.Component<Props> {
  timer: NodeJS.Timeout | undefined;

  state = {
    lastResults: [],
    watchChanges: false,
    activeTab: undefined,
  };

  componentDidMount() {
    this.timer = undefined;

    if (!this.timer) {
      this.timer = setInterval(() => this.getRecordAssociations(), 5000);
    }

    this.getRecordAssociations();
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (
      prevProps.record?.id !== this.props.record?.id &&
      !this.props.recordAssociationReducer.isRequesting
    ) {
      this.getRecordAssociations();
    }
  }

  clearTimer() {
    clearInterval(this.timer);
    this.timer = undefined;
  }

  getRecordAssociations = () => {
    const {
      getAssociationWithNestedEntities,
      schemaReducer,
      entityName,
      nestedEntityName,
      record,
      filters,
    } = this.props;

    if (record) {
      // request relations from module that hosts record entity
      const recordSchema = getSchemaFromShortListBySchemaId(
        schemaReducer.shortList,
        record.schemaId,
      );

      if (recordSchema && recordSchema.associations) {
        // request to /one-relation to load nested entity
        getAssociationWithNestedEntities({
          recordId: record?.id,
          key: filters ? `${entityName}_${filters}` : entityName,
          schema: recordSchema,
          entity: entityName,
          nestedEntities: nestedEntityName ? [nestedEntityName] : [],
          filters,
          // ODN-2224 load links if there are lookup fields
          withLinks: false,
        });
      }
    }
  };

  getActivityRecords() {
    const { record, entityName, filters, recordAssociationReducer } =
      this.props;

    const associationKey = filters
      ? `${record?.id}_${entityName}_${filters}`
      : `${record?.id}_${entityName}`;
    const associationObj: any =
      recordAssociationReducer.shortList[associationKey];

    if (!associationObj) {
      return null;
    }

    const newActivityRecords = associationObj[entityName]?.dbRecords?.filter(
      (record: DbRecordEntityTransform) =>
        getProperty(record, 'Process') === 'NETWORK_WEBHOOK',
    );

    if (
      this.state.watchChanges &&
      this.props.onNewWebhookReceived &&
      this.state.lastResults &&
      newActivityRecords &&
      newActivityRecords.length > this.state.lastResults.length
    ) {
      newActivityRecords
        .slice(0, newActivityRecords.length - this.state.lastResults.length)
        .forEach((record: DbRecordEntityTransform) =>
          this.props.onNewWebhookReceived!(record),
        );

      this.setState({
        ...this.state,
        lastResults: newActivityRecords,
        activeTab:
          this.props.tabAutoSelect && newActivityRecords.length > 0
            ? newActivityRecords[0].id
            : undefined,
      });
    }

    if (!this.state.watchChanges && newActivityRecords) {
      this.setState({
        ...this.state,
        lastResults: newActivityRecords,
        watchChanges: true,
      });
    }

    return newActivityRecords;
  }

  renderCheckSegment(
    name: string,
    check: {
      result: CheckResult;
      warnings: {
        message: string;
        solution?: string[]; //legacy
        simpleSolution: string[];
        semiTechnicalSolution: string[];
        technicalSolution: string[];
      }[];
      errors: {
        message: string;
        solution?: string[]; //legacy
        simpleSolution: string[];
        semiTechnicalSolution: string[];
        technicalSolution: string[];
      }[];
    },
    userReducer: any,
  ) {
    return (
      <li className="webhook-result-check-list" key={name}>
        {check.result === CheckResult.SUCCESS ? (
          <CheckCircleOutlined className="webhook-result-icon-success" />
        ) : check.result === CheckResult.WARNING ? (
          <InfoCircleOutlined className="webhook-result-icon-warning" />
        ) : (
          <CloseCircleOutlined className="webhook-result-icon-error" />
        )}{' '}
        {name}
        <br />
        {check.errors && check.errors.length > 0 && (
          <div className="webhook-result-check-errors">
            <strong>Errors:</strong>

            <ul>
              {check.errors.map((error, index) => (
                <li key={index}>
                  <p className="webhook-result-check-error">{error.message}</p>

                  <p className="webhook-result-check-solution">
                    <strong>Possible Solutions:</strong>
                    <br />
                    {error.solution
                      ? error.simpleSolution
                        ? error.simpleSolution
                        : error.solution
                      : (hasRole(userReducer, 'NetworkSolutionsTechnical')
                          ? error.technicalSolution
                          : hasRole(
                              userReducer,
                              'NetworkSolutionsSemiTechnical',
                            )
                          ? error.semiTechnicalSolution
                          : error.simpleSolution
                        ).map((line, index) => (
                          <div
                            className="webhook-result-check-solution-line"
                            key={index}
                          >
                            {line}
                          </div>
                        ))}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        )}
        {check.warnings && check.warnings.length > 0 && (
          <div className="webhook-result-check-errors">
            <strong>Warnings:</strong>

            <ul>
              {check.warnings.map((warning, index) => (
                <li key={index}>
                  <p className="webhook-result-check-error">
                    {warning.message}
                  </p>

                  <p className="webhook-result-check-solution">
                    <strong>Possible Solution: </strong>
                    <br />
                    {warning.solution
                      ? warning.simpleSolution
                        ? warning.simpleSolution
                        : warning.solution
                      : (hasRole(userReducer, 'NetworkSolutionsTechnical')
                          ? warning.technicalSolution
                          : hasRole(
                              userReducer,
                              'NetworkSolutionsSemiTechnical',
                            )
                          ? warning.semiTechnicalSolution
                          : warning.simpleSolution
                        ).map((line, index) => (
                          <div
                            className="webhook-result-check-solution-line"
                            key={index}
                          >
                            {line}
                          </div>
                        ))}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        )}
      </li>
    );
  }

  render() {
    const { record } = this.props;
    const activityRecords = this.getActivityRecords();

    if (!activityRecords || activityRecords?.length === 0) {
      return (
        <div className="ont-view-controls">
          The details is only supported for Omni managed devices.
        </div>
      );
    }

    return (
      <Row className="webhook-results-wrapper">
        {/* Order Item */}
        <Col span={24} style={{ marginBottom: 20 }}>
          <div
            style={{
              borderRadius: 10,
              background: '#f5f5f5',
              border: '1px solid #e2e2e2',
              padding: '15px 15px',
            }}
          >
            <Row align="bottom" justify="space-between">
              {/* Icon + Title */}
              <Col>
                <Row align="middle">
                  <Col>
                    <ModuleEntityIcon
                      moduleName="OrderModule"
                      entityName="OrderItem"
                      iconContainerStyle={{ padding: '8px 9px' }}
                    />
                  </Col>
                  <Col>
                    <span style={{ fontSize: '0.9em', opacity: 0.3 }}>
                      Order Item
                    </span>
                    <br />
                    <span style={{ fontWeight: 600 }}>
                      {record?.title || '-'}
                    </span>
                  </Col>
                </Row>
              </Col>

              <Col>
                <Link to={getBrowserPath(record)} target="_blank">
                  <span>{record?.recordNumber}</span>
                </Link>
              </Col>
            </Row>
          </div>
        </Col>
        {/* Rest */}
        <Col span={24}>
          <Tabs
            defaultActiveKey="0"
            tabPosition="left"
            style={{ height: '79vh' }}
            activeKey={this.state.activeTab}
            onChange={(key) => {
              this.setState({
                ...this.state,
                activeTab: key,
              });

              this.props.setTabAutoSelect(false);
            }}
          >
            {activityRecords?.map((record: DbRecordEntityTransform) => {
              const description = getProperty(record, 'Description');
              const data = getProperty(record, 'JSONData');

              return (
                <Tabs.TabPane
                  style={{ height: '79vh', overflowY: 'auto' }}
                  tab={
                    <>
                      {description}
                      <br />
                      <small>
                        {moment(record.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                      </small>
                    </>
                  }
                  key={record.id}
                >
                  {data ? (
                    data['webhookType'] === WebhookType.SERVICE_CHECK ? (
                      <>
                        {data['success'] ? (
                          <>
                            <ul className="webhook-result-check-list">
                              {this.renderCheckSegment(
                                'OLT Health',
                                data['results']['oltCheck'],
                                this.props.userReducer,
                              )}
                              {this.renderCheckSegment(
                                'ONT Health',
                                data['results']['onuCheck'],
                                this.props.userReducer,
                              )}
                              {this.renderCheckSegment(
                                'Service Health',
                                data['results']['serviceCheck'],
                                this.props.userReducer,
                              )}
                            </ul>
                          </>
                        ) : (
                          <>
                            <p>
                              <CloseCircleOutlined className="webhook-result-icon-error" />{' '}
                              Service Check Failed
                            </p>
                            {data['message'] && (
                              <p>
                                <strong>Error: </strong> {data['message']}
                              </p>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {data['success'] ? (
                          <>
                            <CheckCircleOutlined className="webhook-result-icon-success" />{' '}
                            {data['message']}
                          </>
                        ) : (
                          <>
                            <p>
                              <CloseCircleOutlined className="webhook-result-icon-error" />{' '}
                              {data['message']}
                            </p>
                          </>
                        )}
                      </>
                    )
                  ) : (
                    <div className="webhook-results-loader-wrapper">
                      <div className="webhook-results-loader">
                        <LoadingOutlined spin style={{ fontSize: 24 }} />
                        <br />
                        <p>Waiting for results</p>
                      </div>
                    </div>
                  )}
                </Tabs.TabPane>
              );
            })}
          </Tabs>
        </Col>
      </Row>
    );
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  recordReducer: state.recordReducer,
  recordAssociationReducer: state.recordAssociationReducer,
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
  shortListRecord: (params: IAddRecordToShortList) =>
    dispatch(addRecordToShortList(params)),
  getRecordById: (payload: IGetRecordById, cb: any) =>
    dispatch(getRecordByIdRequest(payload, cb)),
  updateRecord: (params: any, cb: any) =>
    dispatch(updateRecordByIdRequest(params, cb)),
  getAssociationWithNestedEntities: (
    params: IGetRecordAssociationWithNestedEntities,
  ) => dispatch(getRecordAssociationWithNestedEntitiesRequest(params)),
});

export default connect(mapState, mapDispatch)(NetworkWebhookResults);
