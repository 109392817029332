import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  ORDER_NETWORK_DEVICES_SET_IS_LOADING_ORDER_ITEMS,
  ORDER_NETWORK_DEVICES_SET_ORDER_ITEMS,
  SET_ADD_NETWORK_DEVICE_MODAL_DATA,
  SET_ADD_NETWORK_DEVICE_MODAL_VISIBLE,
  SET_NETWORK_DRAWER_DEVICE_RECORD,
  SET_NETWORK_DRAWER_ORDER_ITEM_RECORD,
  SET_NETWORK_DRAWER_VISIBLE,
  SET_RUNNING_BULK_ONT_CHECK,
  SET_RUNNING_BULK_PROVISION,
  SET_RUNNING_BULK_ROUTER_SPEED_TEST,
} from './constants';

export interface IOrderNetworkDevicesReducer {
  isLoadingOrderItems: boolean;
  orderItems: DbRecordEntityTransform[];
  networkDrawerVisible: boolean;
  networkDrawerDeviceRecord: DbRecordEntityTransform | undefined;
  networkDrawerOrderItemRecord: DbRecordEntityTransform | undefined;
  runningBulkONTCheck: boolean;
  runningBulkRouterCheck: boolean;
  addDeviceModalVisible: boolean;
  addDeviceModalData: any;
  runningBulkProvision: boolean;
}

export const OrderNetworkDevicesInitialState: IOrderNetworkDevicesReducer = {
  isLoadingOrderItems: false,
  orderItems: [],
  networkDrawerVisible: false,
  networkDrawerDeviceRecord: undefined,
  networkDrawerOrderItemRecord: undefined,
  runningBulkONTCheck: false,
  runningBulkRouterCheck: false,
  addDeviceModalVisible: false,
  addDeviceModalData: undefined,
  runningBulkProvision: false,
};

export function orderNetworkDevicesReducer(
  state: IOrderNetworkDevicesReducer,
  action: { type: string; payload: any },
) {
  switch (action.type) {
    case ORDER_NETWORK_DEVICES_SET_IS_LOADING_ORDER_ITEMS:
      return {
        ...state,
        isLoadingOrderItems: action.payload,
      };

    case ORDER_NETWORK_DEVICES_SET_ORDER_ITEMS:
      return {
        ...state,
        orderItems: action.payload,
      };

    case SET_NETWORK_DRAWER_VISIBLE:
      return {
        ...state,
        networkDrawerVisible: action.payload,
      };

    case SET_NETWORK_DRAWER_DEVICE_RECORD:
      return {
        ...state,
        networkDrawerDeviceRecord: action.payload,
      };

    case SET_NETWORK_DRAWER_ORDER_ITEM_RECORD:
      return {
        ...state,
        networkDrawerOrderItemRecord: action.payload,
      };

    case SET_RUNNING_BULK_ONT_CHECK:
      return {
        ...state,
        runningBulkONTCheck: action.payload,
      };

    case SET_RUNNING_BULK_ROUTER_SPEED_TEST:
      return {
        ...state,
        runningBulkRouterCheck: action.payload,
      };

    case SET_ADD_NETWORK_DEVICE_MODAL_VISIBLE:
      return {
        ...state,
        addDeviceModalVisible: action.payload,
      };

    case SET_ADD_NETWORK_DEVICE_MODAL_DATA:
      return {
        ...state,
        addDeviceModalData: action.payload,
      };

    case SET_RUNNING_BULK_PROVISION:
      return {
        ...state,
        runningBulkProvision: action.payload,
      };

    default:
      return state;
  }
}
