import {
    Button,
    Dialog,
    DialogBody,
    DialogFooter,
    IconName,
    NonIdealState,
    Section,
} from '@blueprintjs/core';
import {OrganizationEntity} from '@d19n/models/dist/identity/organization/organization.entity';
import {DbRecordEntityTransform} from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {getProperty} from '@d19n/models/dist/schema-manager/helpers/dbRecordHelpers';
import {SchemaActionEntity} from '@d19n/models/dist/schema-manager/schema/action/schema.action.entity';
import {SchemaAssociationEntity} from '@d19n/models/dist/schema-manager/schema/association/schema.association.entity';
import {SchemaColumnEntity} from '@d19n/models/dist/schema-manager/schema/column/schema.column.entity';
import {SchemaEntity} from '@d19n/models/dist/schema-manager/schema/schema.entity';
import {SchemaModuleTypeEnums} from '@d19n/models/dist/schema-manager/schema/types/schema.module.types';
import {SchemaTypeEntity} from '@d19n/models/dist/schema-manager/schema/types/schema.type.entity';
import {
    Alert,
    Col,
    Divider,
    Empty,
    Form,
    Input,
    Progress,
    Row,
    Select,
} from 'antd';
import {FormInstance} from 'antd/lib/form';
import React from 'react';
import {isMobile} from 'react-device-detect';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {
    httpGet,
    httpPost,
    httpPut,
} from '../../../../../shared/http/requests';
import {
    hasAnyRoles,
    hasPermissions,
    isSystemAdmin,
} from '../../../../../shared/permissions/rbacRules';
import {
    getSchemaActionsForCoreFormCreate,
    getSchemaActionsForCoreFormUpdate,
} from '../../../../../shared/SchemaActions/schemaActionFilters';
import {errorNotification} from '../../../../../shared/system/notifications/store/reducers';
import {checkRecordIsLocked} from '../../../../../shared/utilities/recordHelpers';
import {
    IUpdateRelatedRecordAssociation,
    updateRecordAssociationRequest,
} from '../../../../recordsAssociations/store/actions';
import {DB_RECORD_ASSOCIATIONS_UPDATE_REQUEST} from '../../../../recordsAssociations/store/constants';
import {getSingleSchemaAction} from '../../../../schemas/store/actions';
import {
    bulkUpdateRecordsRequest,
    createRecordsRequest,
    IBulkUpdateRecords,
    ICreateRecords,
    ISearchRecords,
    IUpdateRecordById,
    searchRecordsRequest,
    updateRecordByIdRequest,
} from '../../../store/actions';
import {
    BULK_UPDATE_DB_RECORDS_REQUEST,
    CREATE_DB_RECORD_REQUEST,
    UPDATE_DB_RECORD_BY_ID_REQUEST,
} from '../../../store/constants';
import {IRecordReducer} from '../../../store/reducer';
import {TableReducer} from '../../DynamicTable/store/reducer';
import renderFormField, {FormField, InputChangeParams} from '../FormFields';
import {
    constructSchemaActionAuxElement,
    constructSchemaActionFormField,
    getSchemaActionFormFieldDefaultValue,
    getSchemaActionVersion,
    schemaActionHasProgressBar,
    shouldSchemaActionShowRecordNumberField,
    shouldSchemaActionShowRecordTypeField,
    shouldSchemaActionShowTitleField,
} from '../helpers';
import {
    closeRecordForm,
    closeSecondaryRecordForm,
    updateFormInput,
    updateRecordFormState,
} from '../store/actions';
import {IFormReducer} from '../store/reducer';
import {SchemaActionFormField} from '../types';

const {Option} = Select;
const {SCHEMA_MODULE} = SchemaModuleTypeEnums;

type TError = {
    title: string;
    message: string;
    icon?: IconName;
};

interface FormSectionEntity {
    id?: string | null;
    organization?: OrganizationEntity;
    schema?: SchemaEntity;
    schemaColumns?: SchemaColumnEntity[];
    name?: string;
    description?: string;
    position?: number;
    columns?: number;
    associations?: {
        recordId: string;
        title: string;
        recordNumber: string;
        schemaAssociationId: string;
    }[];
}

export interface Props {
    bulkUpdateRecords: (params: IBulkUpdateRecords, cb?: any) => void;
    closeForm: any;
    closeSecondaryForm: (params: { formUUID: string }) => void;
    createRecord: (params: ICreateRecords, cb?: any) => void;
    customTopElement?: JSX.Element;
    formDirection?: 'vertical' | 'horizontal';
    formUUID: string;
    getSchemaAction: (params: { schemaActionId: string }, cb: any) => void;
    hasColumnMappings?: boolean;
    hiddenFormFields?: Array<string>;
    hideCancelButton?: boolean;
    hideStageFormField?: boolean;
    initializeCb?: any;
    isBatchCreate?: boolean;
    isCreateRecord?: boolean;
    isNextDisabled?: any;
    isSecondaryForm?: boolean;
    notifyError: any;
    onCancelEvent?: Function;
    onCloseEvent?: Function;
    onSubmitEvent?: Function;
    record?: DbRecordEntityTransform;
    recordFormReducer: IFormReducer;
    recordReducer: IRecordReducer;
    recordTableReducer: TableReducer;
    relatedEntityName?: string;
    searchRecords: Function;
    showFormActions?: boolean;
    showOnlyRequired?: boolean;
    submitButtonTitle?: string;
    type: 'MODAL' | 'EMBEDDED';
    updateFormProperties: any;
    updateFormState: any;
    updateRecord: (params: IUpdateRecordById, cb?: any) => void;
    updateRecordAssociation: (
        params: IUpdateRelatedRecordAssociation,
        cb: any,
    ) => void;
    userReducer: any;
}

interface State {
    isCreatingOrUpdating: boolean;
    SchemaAction: SchemaActionEntity | undefined;
    formColumns: number | undefined;
    isLoadingSchemaAction: boolean;
    customRecordNumber: string;
    error: TError | undefined;
    isFormLoaded: boolean;
    formFields: any[];
    isSettingInitialValues: boolean;
}

class CoreForm extends React.Component<Props, State> {
    formRef = React.createRef<FormInstance>();
    prevFormRef: any = null;

    constructor(props: Props) {
        super(props);

        this.state = {
            isCreatingOrUpdating: false,
            SchemaAction: undefined,
            formColumns: undefined,
            isLoadingSchemaAction: false,
            error: undefined,
            customRecordNumber: '',
            isFormLoaded: false,
            formFields: [],
            isSettingInitialValues: false,
        };
    }

    getFormReducer = (): IFormReducer => {
        const {recordFormReducer, formUUID, isSecondaryForm} = this.props;
        return isSecondaryForm
            ? recordFormReducer[formUUID as keyof IFormReducer]
            : recordFormReducer;
    };

    componentDidMount = () => {
        const recordFormReducer: IFormReducer = this.getFormReducer();

        if (this.props.initializeCb) {
            this.props.initializeCb();
        }

        // Initialize embedded forms on component mount
        if (
            this.props.type === 'EMBEDDED' &&
            this.checkIfCurrentFormIsInReducer()
        ) {
            if (recordFormReducer?.schemaActionId) {
                this.fetchSchemaActionById();
            } else {
                this.fetchDefaultSchemaAction();
            }
        }
    };

    checkIfCurrentFormIsInReducer = (): boolean => {
        const {formUUID} = this.props;
        const recordFormReducer: IFormReducer = this.getFormReducer();
        return recordFormReducer?.formUUID === formUUID;
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
        // Form is opened
        const {isSecondaryForm, formUUID} = this.props;

        const previousFormReducer = isSecondaryForm
            ? prevProps.recordFormReducer[formUUID as keyof IFormReducer]
            : prevProps.recordFormReducer;

        const currentFormReducer = isSecondaryForm
            ? this.props.recordFormReducer[formUUID as keyof IFormReducer]
            : this.props.recordFormReducer;

        if (
            !previousFormReducer?.showFormModal &&
            currentFormReducer?.showFormModal &&
            this.checkIfCurrentFormIsInReducer()
        ) {
            this.setState({error: undefined});

            // Schema action already set in the form reducer?
            if (currentFormReducer?.schemaActionId) {
                this.fetchSchemaActionById();
            }
            // If not, fetch all and filter by schemaId, type and isCreate/isUpdate
            else {
                this.fetchDefaultSchemaAction();
            }
        }

        // Schema action is set in the state, get all fields with default values and set to form reducer
        if (prevState.SchemaAction !== this.state.SchemaAction) {
            this.setInitialValuesFromSchemaAction();
        }

        // When form stays mounted but the schema action changes, reset the schema action in the state
        if (
            previousFormReducer?.schemaActionId !== currentFormReducer?.schemaActionId
        ) {
            this.setState({SchemaAction: undefined});
        }
    }

    componentWillUnmount() {
        const {isSecondaryForm, closeSecondaryForm} = this.props;
        if (isSecondaryForm) {
            closeSecondaryForm({formUUID: this.props.formUUID});
        } else {
            this.props.closeForm();
        }
    }

    fetchSchemaActionById = () => {
        const {schemaActionId} = this.props.recordFormReducer;
        this.setState({isLoadingSchemaAction: true});

        if (
            schemaActionId &&
            !this.state.isLoadingSchemaAction &&
            !this.state.SchemaAction
        ) {
            httpGet(`SchemaModule/v1.0/schemas-actions/${schemaActionId}`)
                .then((res) => {
                    this.setState({
                        SchemaAction: res.data.data,
                        isLoadingSchemaAction: false,
                    });

                    console.log(
                        '%cdebug: Fetched predefined schema action',
                        'color:limegreen',
                        res.data.data,
                    );
                })
                .catch((err) => {
                    this.setState({
                        isLoadingSchemaAction: false,
                    });
                    console.error(
                        'Error loading Schema Action for the outcome form.',
                        err,
                    );
                });
        }
    };

    fetchDefaultSchemaAction = () => {
        const {userReducer} = this.props;

        httpGet(`SchemaModule/v1.0/schemas-actions`)
            .then((res: any) => {
                this.setState({error: undefined});
                const recordFormReducer = this.getFormReducer();
                const {schema, isCreateReq} = recordFormReducer;
                const recordType =
                    recordFormReducer.recordType || recordFormReducer?.selected?.type;
                let filteredActions: SchemaActionEntity[];

                // Create Form
                if (isCreateReq) {
                    filteredActions = getSchemaActionsForCoreFormCreate(
                        res.data?.data,
                        recordType,
                        schema!,
                    );
                }
                // Update Form
                else {
                    filteredActions = getSchemaActionsForCoreFormUpdate(
                        res.data?.data,
                        recordFormReducer?.selected!,
                        schema!,
                    );
                }

                // There are schema types but type is not yet selected
                if (schema?.types?.length! > 0 && !recordType && isCreateReq) {
                    this.setState({
                        error: {
                            title: 'Record Type not selected',
                            message: 'Please select Record Type to proceed.',
                        },
                        SchemaAction: undefined,
                    });
                }

                // When all filtering is done and still no schema action found, set the error message, or set the schema action to the first one.
                if (!filteredActions.length) {
                    this.setState({
                        SchemaAction: undefined,
                        error: {
                            title: 'Form not found',
                            icon: 'search',
                            message: `Could not find ${
                                isCreateReq ? 'CREATE' : 'UPDATE'
                            } schema action for ${schema?.entityName} entity and ${
                                recordType || 'undefined'
                            } record type. ${
                                !isSystemAdmin(userReducer)
                                    ? 'Please contact your Administrator in order to gain access to this feature.'
                                    : 'Create a new schema action in order to gain access to this feature.'
                            }`,
                        },
                    });
                }
                // Schema actions found that are matching the criteria
                else {
                    const permissions: string[] =
                        filteredActions[0]?.permissions?.map((perm: any) => perm.name) ||
                        [];

                    // Check if action has permissions and user has them
                    if (
                        permissions.length > 0 &&
                        !hasPermissions(userReducer, permissions)
                    ) {
                        this.setState({
                            error: {
                                title: 'Permission denied',
                                icon: 'lock',
                                message: `You don't have permissions to access this form. Please contact your Administrator.`,
                            },
                            SchemaAction: undefined,
                        });
                    }
                    // If everything is okay - set the schema action to the state
                    else {
                        console.log(
                            '%cdebug: Default form schema action selected',
                            'color:limegreen',
                            filteredActions,
                        );
                        this.setState({
                            SchemaAction: filteredActions[0],
                        });
                    }
                }

                this.setState({
                    isLoadingSchemaAction: false,
                });
            })
            .catch((err) => {
                this.setState({
                    isLoadingSchemaAction: false,
                    SchemaAction: undefined,
                });
                console.error('Error loading Schema Action for the outcome form.', err);
            });
    };

    handleInputChange = (params: InputChangeParams) => {
        const {isSecondaryForm, updateFormProperties, isCreateRecord, formUUID} =
            this.props;
        const recordFormReducer: IFormReducer = this.getFormReducer();
        const {selected, additionalInputChangeHandler, isBulkUpdateReq} =
            recordFormReducer;

        // add contact email address to the lead
        const leadFormFields = recordFormReducer.modified?.find(
            (elem: any) => elem.entity === 'CrmModule:Lead',
        );
        const contactFormFields = recordFormReducer.modified?.find(
            (elem: any) => elem.entity === 'CrmModule:Contact',
        );

        // ODN-1792 change null or '' value to undefined to prevent the bulk field clearing
        if (isBulkUpdateReq) {
            if (!params.value) {
                params.value = undefined;
            }
        }

        // Add contact email address to the lead form
        if (!!leadFormFields && !!contactFormFields) {
            if (params.id === `${contactFormFields.schemaId}_EmailAddress`) {
                updateFormProperties({
                    formUUID: isSecondaryForm ? this.props.formUUID : undefined,
                    targetId: `${leadFormFields.schemaId}_EmailAddress`,
                    entity: leadFormFields.entity,
                    targetValue: params.value,
                    record: selected,
                });
            }
        }

        // exec additional injected handler
        if (additionalInputChangeHandler) {
            additionalInputChangeHandler(this.props, params);
        }

        if (isCreateRecord) {
            setTimeout(async () => await this.handleFormValidation(), 10);
        }

        updateFormProperties({
            formUUID: isSecondaryForm ? formUUID : undefined,
            targetId: params.id,
            entity: params.entity,
            targetValue: params.value,
            record: selected,
            association: params.association,
        });
    };

    handleFormValidation = async () => {
        const {isNextDisabled} = this.props;
        try {
            await this.formRef.current?.validateFields();
            const formErrors = this.formRef.current
                ? this.formRef.current.getFieldsError()
                : [];
            const hasErrors =
                formErrors.filter(({errors}) => errors.length).length > 0;
            if (isNextDisabled) {
                if (!hasErrors) {
                    isNextDisabled(false);
                } else {
                    isNextDisabled(true);
                }
            }
        } catch (e: any) {
            if (isNextDisabled) {
                isNextDisabled(true);
            }
            console.error(e);
        }
    };

    calculateProgressBar = () => {
        const {SchemaAction} = this.state;
        const recordFormReducer: IFormReducer = this.getFormReducer();
        const {schema} = recordFormReducer;

        let totalFormFields: any[] = [];

        if (getSchemaActionVersion(SchemaAction) === 1) {
            totalFormFields = SchemaAction?.definition?.formFields;
        } else {
            SchemaAction?.definition?.formDefinition.forEach((section: any) => {
                totalFormFields = totalFormFields.concat(section.formFields);
            });
        }

        let filledFields: number = 0;
        let visibleFieldsInTheForm: string[] = [];

        // Get all visible fields in the form
        totalFormFields.forEach((field: any) => {
            const col = schema?.columns?.find(
                (col: SchemaColumnEntity) => col.name === field.name,
            );

            if (col && field.type === 'formField') {
                const SchemaActionFormField: any = constructSchemaActionFormField({
                    SchemaAction: this.state.SchemaAction,
                    fieldName: field.name,
                    Form: this.formRef?.current!,
                    schemaColumns: schema?.columns || [],
                });

                if (SchemaActionFormField && SchemaActionFormField?.visible !== false) {
                    visibleFieldsInTheForm.push(SchemaActionFormField.name);
                }
            }
        });

        if (this.formRef?.current) {
            visibleFieldsInTheForm.forEach((field: any) => {
                if (this.props.recordFormReducer.modified.length > 0) {
                    this.props.recordFormReducer.modified.forEach((modified: any) => {
                        if (modified?.properties && modified?.properties[field]) {
                            filledFields++;
                        }
                    });
                }
            });

            return Math.round((filledFields / visibleFieldsInTheForm.length) * 100);
        }
    };

    handleCancel = () => {
        const {
            closeForm,
            isSecondaryForm,
            closeSecondaryForm,
            onCancelEvent,
            onCloseEvent,
        } = this.props;
        onCancelEvent && onCancelEvent();
        onCloseEvent && onCloseEvent();
        this.setState({SchemaAction: undefined, customRecordNumber: ''});

        if (isSecondaryForm) {
            closeSecondaryForm({formUUID: this.props.formUUID});
        } else {
            closeForm();
        }
    };

    closeTheForm = () => {
        const {isSecondaryForm, closeSecondaryForm, closeForm, onCloseEvent} =
            this.props;
        onCloseEvent && onCloseEvent();
        this.setState({SchemaAction: undefined, customRecordNumber: ''});

        if (isSecondaryForm) {
            closeSecondaryForm({formUUID: this.props.formUUID});
        } else {
            closeForm();
        }
    };

    handleSubmit = async () => {
        const {
            createRecord,
            updateRecord,
            bulkUpdateRecords,
            updateRecordAssociation,
            notifyError,
            recordReducer,
            recordTableReducer,
            onSubmitEvent,
            userReducer,
        } = this.props;

        const recordFormReducer: IFormReducer = this.getFormReducer();
        const {selected, schema, isUpdateReq, isCreateReq, isBulkUpdateReq} =
            recordFormReducer;
        const onSubmitUrl = this.state.SchemaAction?.definition?.onSubmitUrl;

        // Prevent editing if record is locked
        if (
            selected &&
            checkRecordIsLocked(selected) &&
            !hasAnyRoles(userReducer, 'system.admin', 'BillingModuleAdmin')
        ) {
            notifyError({message: 'the record is locked'});
            this.handleCancel();
            return;
        }

        try {
            if (!!this.formRef.current) {
                // Field Validation
                await this.formRef.current.validateFields();
                if (recordFormReducer.modified.length < 1) {
                    return notifyError({
                        message: 'no modified form values',
                        validation: null,
                        data: null,
                    });
                } else {
                    this.setState({isCreatingOrUpdating: true});

                    // 1. Custom URL
                    if (onSubmitUrl) {
                        let URL = onSubmitUrl.url;

                        let modified: any[] = Object.assign(recordFormReducer.modified);
                        if (modified.length > 0) {
                            modified = modified.map((modified: any) => ({
                                ...modified,
                                type: recordFormReducer.recordType,
                            }));
                        }
                        if (this.state.customRecordNumber) {
                            modified[0].recordNumber = this.state.customRecordNumber;
                        }
                        if (this.state.SchemaAction) {
                            modified[0].schemaActionId = this.state.SchemaAction?.id;
                        }

                        // Replace source record id if asked in schema configuration
                        if (URL && URL.includes('{source_record_id}')) {
                            URL = URL.replace('{source_record_id}', selected?.id);
                        }

                        if (onSubmitUrl.method === 'post') {
                            httpPost(URL, {
                                schema: recordFormReducer.schema,
                                createUpdate: [...recordFormReducer.payload, ...modified],
                            })
                                .then(() => {
                                    if (this.props.type === 'EMBEDDED') {
                                        this.closeTheForm();
                                    } else {
                                        this.handleCancel();
                                    }
                                })
                                .catch(() => {
                                    this.setState({isCreatingOrUpdating: false});
                                    notifyError({
                                        message:
                                            'There was an error submitting the form with Custom URL',
                                    });
                                });
                        } else if (onSubmitUrl.method === 'put') {
                            httpPut(URL, {
                                schema: recordFormReducer.schema,
                                createUpdate: [...recordFormReducer.payload, ...modified],
                            })
                                .then(() => {
                                    if (this.props.type === 'EMBEDDED') {
                                        this.closeTheForm();
                                    } else {
                                        this.handleCancel();
                                    }
                                })
                                .catch(() => {
                                    this.setState({isCreatingOrUpdating: false});
                                    notifyError({
                                        message:
                                            'There was an error submitting the form with Custom URL',
                                    });
                                });
                        }
                    }
                    // 2. Update Request
                    else if (isUpdateReq && selected) {
                        // Update with Column Mappings
                        if (
                            this.props.hasColumnMappings &&
                            selected.dbRecordAssociation?.id &&
                            recordFormReducer.relatedEntityName
                        ) {
                            return updateRecordAssociation(
                                {
                                    relatedEntityName: recordFormReducer.relatedEntityName,
                                    parentSchema: schema!,
                                    recordId: selected.id,
                                    dbRecordAssociationId: selected.dbRecordAssociation.id,
                                    createUpdate: recordFormReducer.modified[0] as any,
                                },
                                (res: DbRecordEntityTransform) => {
                                    this.setState({isCreatingOrUpdating: false});
                                    if (res && onSubmitEvent) {
                                        onSubmitEvent({
                                            event: DB_RECORD_ASSOCIATIONS_UPDATE_REQUEST,
                                            results: res,
                                        });
                                        if (this.props.type === 'EMBEDDED') {
                                            this.closeTheForm();
                                        } else {
                                            this.handleCancel();
                                        }
                                    }
                                },
                            );
                        }
                        // Update without Column Mappings
                        else {
                            return updateRecord(
                                {
                                    schema: schema!,
                                    recordId: selected.id,
                                    createUpdate: recordFormReducer.modified[0],
                                },
                                (res: DbRecordEntityTransform) => {
                                    this.setState({isCreatingOrUpdating: false});
                                    if (res && onSubmitEvent) {
                                        onSubmitEvent({
                                            event: UPDATE_DB_RECORD_BY_ID_REQUEST,
                                            results: res,
                                        });
                                        if (this.props.type === 'EMBEDDED') {
                                            this.closeTheForm();
                                        } else {
                                            this.handleCancel();
                                        }
                                    }
                                },
                            );
                        }
                    }
                    // 3. Create Request
                    else if (isCreateReq && recordFormReducer.schema) {
                        let modified: any[] = Object.assign(recordFormReducer.modified);
                        if (modified.length > 0) {
                            modified = modified.map((modified: any) => ({
                                ...modified,
                                type: recordFormReducer.recordType,
                            }));
                        }
                        if (this.state.customRecordNumber) {
                            modified[0].recordNumber = this.state.customRecordNumber;
                        }
                        if (this.state.SchemaAction) {
                            modified[0].schemaActionId = this.state.SchemaAction?.id;
                        }
                        return createRecord(
                            {
                                schema: recordFormReducer.schema,
                                createUpdate: [...recordFormReducer.payload, ...modified],
                            },
                            (res: DbRecordEntityTransform) => {
                                this.setState({isCreatingOrUpdating: false});
                                if (res && onSubmitEvent) {
                                    onSubmitEvent({
                                        event: CREATE_DB_RECORD_REQUEST,
                                        results: res,
                                    });
                                    if (this.props.type === 'EMBEDDED') {
                                        this.closeTheForm();
                                    } else {
                                        this.handleCancel();
                                    }
                                }
                            },
                        );
                    }
                    // 4. Bulk Update Request
                    else if (isBulkUpdateReq && schema) {
                        this.setState({isCreatingOrUpdating: true});
                        const bulkUpdateParams: IBulkUpdateRecords = {
                            schema: schema!,
                            createUpdate: recordFormReducer.modified[0],
                        };
                        // ODN-1988 bulk update selected records
                        if (recordTableReducer?.selectedItems?.length > 0) {
                            bulkUpdateParams.recordIds = recordTableReducer.selectedItems;
                        }
                        // bulk update records in current search
                        else {
                            const searchQuery = recordReducer.searchQuery[schema!.id];
                            const isNotEmptyQuery =
                                !!searchQuery?.terms ||
                                !!searchQuery?.boolean?.must?.length ||
                                !!searchQuery?.boolean?.must_not?.length ||
                                !!searchQuery?.boolean?.should?.length ||
                                !!searchQuery?.boolean?.filter?.length;
                            if (isNotEmptyQuery) {
                                bulkUpdateParams.searchQuery = {
                                    schemas: schema.id,
                                    fields: searchQuery?.fields,
                                    terms: searchQuery?.terms,
                                    boolean: searchQuery?.boolean,
                                    sort: searchQuery?.sort,
                                    pageable: {
                                        page: 1,
                                    },
                                };
                            }
                        }
                        if (bulkUpdateParams?.searchQuery || bulkUpdateParams?.recordIds) {
                            bulkUpdateRecords(bulkUpdateParams, (res: any) => {
                                this.setState({isCreatingOrUpdating: false});
                                if (res && onSubmitEvent) {
                                    onSubmitEvent({
                                        event: BULK_UPDATE_DB_RECORDS_REQUEST,
                                        results: res,
                                    });
                                    if (this.props.type === 'EMBEDDED') {
                                        this.closeTheForm();
                                    } else {
                                        this.handleCancel();
                                    }
                                }
                            });
                        } else {
                            this.setState({isCreatingOrUpdating: false});
                            notifyError({
                                message:
                                    'searchQuery or selectedItems are not defined, bulk update is not allowed',
                            });
                            if (this.props.type === 'EMBEDDED') {
                                this.closeTheForm();
                            } else {
                                this.handleCancel();
                            }
                        }
                    }
                }
            }
        } catch (e: any) {
            this.setState({isCreatingOrUpdating: false});
            console.error('Debug: Form error', e);

            const formErrors = this.formRef.current
                ? this.formRef.current.getFieldsError()
                : [];
            const errors = formErrors.filter(({errors}) => errors.length);
            if (errors && errors.length > 0) {
                notifyError({
                    message: `'${errors[0]['errors'][0]}'`,
                    validation: errors[0]['errors'],
                    data: null,
                });
            }
        }
    };

    // If any schema action field has a default value, preset the value to the form reducer
    setInitialValuesFromSchemaAction = () => {
        const {SchemaAction} = this.state;
        const {isSecondaryForm} = this.props;
        const recordFormReducer: IFormReducer = this.getFormReducer();
        const {schema} = recordFormReducer;

        // Update loading state so that the form re-renders every
        // time we set the initial values into fields. This is
        // crucial for schema actions to properly parse rules.
        this.setState({isSettingInitialValues: true});

        if (this.state.SchemaAction && schema) {
            // Gather all form fields
            const definition = SchemaAction?.definition;
            let formFields: any = [];
            const version = getSchemaActionVersion(SchemaAction);
            if (version === 1) {
                formFields = definition?.formFields;
            } else {
                definition?.formDefinition?.forEach((section: any) => {
                    formFields = formFields.concat(section.formFields);
                });
            }

            // Some schema actions can specify a sourceRecord property as a default value.
            // The Syntax is {source_record_title} or {source_record_recordNumber} or for
            // a property it would be {source_record_properties_propertyName}. Other use cases
            // for a default value can be just a string or a number.

            // Iterate over form fields, and set default values to form reducer
            formFields?.forEach((field: any) => {
                if (field.defaultValue) {
                    const defaultValue = getSchemaActionFormFieldDefaultValue(
                        field,
                        recordFormReducer.selected ||
                        recordFormReducer.selectedFlowRecord ||
                        null,
                    );

                    this.handleInputChange({
                        formUUID: isSecondaryForm ? this.props.formUUID : undefined,
                        id: `${schema.id}#${field.name}`,
                        value: defaultValue,
                        entity: `${schema?.moduleName}:${schema?.entityName}`,
                    });
                }
            });
        }

        this.setState({isSettingInitialValues: false});
    };

    // Render form fields for each Schema action form section
    renderSchemaActionFormSection = (formSection: any) => {
        const recordFormReducer: IFormReducer = this.getFormReducer();
        const {disabledFields, customValidations, selected, selectedFlowRecord} =
            recordFormReducer;
        const schema = recordFormReducer.schema;
        const definition = this.state.SchemaAction?.definition;
        const version = getSchemaActionVersion(this.state.SchemaAction!);

        // V1 and V2 have formFields structured differently
        let formFields: any[];

        if (version === 1) {
            formFields = definition?.formFields?.filter(
                (field: any) => field.sectionId === formSection.id,
            );
        } else {
            formFields = formSection?.formFields;
        }

        formFields = formFields.sort((a: any, b: any) => a.order - b.order);

        if (schema && formFields && definition) {
            // Remove form fields / columns that don't match the source record type. This means, we should
            // get the schema type, then scan over form fields and see if the corresponding column matches
            // the schema type. If it does, then we should include it in the form fields.

            // If Action has schemaTypeId
            const schemaActionTypeId: string | undefined =
                this.state.SchemaAction?.schemaTypeId;
            const filteredColumns = schema?.columns?.filter(
                (col: SchemaColumnEntity) => {
                    return col.schemaTypeId === schemaActionTypeId || !col.schemaTypeId;
                },
            );
            formFields = formFields.filter((formField: any) => {
                const col = filteredColumns?.find(
                    (col: SchemaColumnEntity) => col.name === formField.name,
                );
                if (col && formField.type === 'formField') {
                    return true;
                } else if (formField.type !== 'formField') {
                    return true;
                }
            });

            // Iterate through form fields
            return formFields.map((formField: SchemaActionFormField) => {
                // 1. Regular form fields with corresponding schema Column
                if (formField.type === 'formField') {
                    const col = filteredColumns?.find(
                        (col: SchemaColumnEntity) => col.name === formField.name,
                    );

                    if (col) {
                        const linkedSchemaAssociation = schema?.associations?.find(
                            (s: SchemaAssociationEntity) =>
                                s.id === col.linkedSchemaAssociationId,
                        );
                        const linkedSchemaTypesConstraint =
                            linkedSchemaAssociation?.schemaTypesConstraints?.find(
                                (c: any) => c.id === col.linkedSchemaTypesConstraintId,
                            );

                        const SchemaActionFormField: any = constructSchemaActionFormField({
                            SchemaAction: this.state.SchemaAction,
                            fieldName: formField.name,
                            Form: this.formRef?.current!,
                            schemaColumns: filteredColumns,
                        });

                        let initialValue: any = undefined;

                        // a) Form Field has a default value set in schema actions
                        if (formField?.defaultValue) {
                            initialValue = getSchemaActionFormFieldDefaultValue(
                                formField,
                                selected || selectedFlowRecord || null,
                            );
                        }
                        // b) No default value in schema actions, but it's an update form, so pre fill the form
                        else if (!formField?.defaultValue && selected) {
                            initialValue = getProperty(selected, formField.name);
                        }

                        // Hidden means that form will not render the field, therefore won't be validated
                        let isHidden = false;
                        if (SchemaActionFormField?.hidden === true) {
                            isHidden = true;
                        }

                        const field: FormField = {
                            id: col?.id ? col?.id.toString() : col?.name,
                            schemaId: schema?.id.toString() || undefined,
                            entity:
                                schema && schema?.moduleName
                                    ? `${schema?.moduleName}:${schema.entityName}`
                                    : undefined,
                            type: col?.type,
                            isHidden,
                            name: col?.name,
                            label: col?.label || col?.name,
                            description: col?.description,
                            defaultValue: initialValue,
                            initialValue: initialValue,
                            options: SchemaActionFormField?.options || col?.options,
                            validators: col?.validators,
                            customValidation: customValidations
                                ? customValidations[col?.name]
                                : undefined,
                            isDisabled: disabledFields
                                ? disabledFields.includes(col?.name)
                                : false,
                            handleInputChange: this.handleInputChange,
                            linkedSchemaAssociation,
                            linkedSchemaTypesConstraint,
                            selected: recordFormReducer.selected || undefined,
                            overrideRequired: SchemaActionFormField?.required,
                            form: this.formRef?.current!,
                            customFilterValue: SchemaActionFormField?.customFilterValue,
                            customFilterEntity: SchemaActionFormField?.customFilterEntity,
                        };

                        return (
                            !isHidden && (
                                <Col
                                    key={formField.name}
                                    style={{
                                        display: SchemaActionFormField?.alwaysInvisible
                                            ? 'none'
                                            : 'block',
                                    }}
                                    span={formSection.columns ? 24 / formSection.columns : 24}
                                >
                                    {SchemaActionFormField?.divided && (
                                        <Divider
                                            key="divider"
                                            style={{marginTop: 0, marginBottom: 10}}
                                        />
                                    )}
                                    {renderFormField(field)}
                                </Col>
                            )
                        );
                    } else {
                        return (
                            <Col span={formSection.columns ? 24 / formSection.columns : 24}>
                                <Alert
                                    style={{padding: 8, marginBottom: 15}}
                                    type="error"
                                    description={
                                        <span>
                      No Schema Column found for formField{' '}
                                            <b>{formField.name}</b>
                    </span>
                                    }
                                ></Alert>
                            </Col>
                        );
                    }
                }

                // 2. Non-form fields e.g. alerts, charts, etc.
                else {
                    const SchemaActionFormField: any = constructSchemaActionFormField({
                        SchemaAction: this.state.SchemaAction,
                        fieldName: formField.name,
                        Form: this.formRef?.current!,
                        schemaColumns: filteredColumns,
                    });
                    return (
                        SchemaActionFormField?.visible !== false && (
                            <Col span={formSection.columns ? 24 / formSection.columns : 24}>
                                {SchemaActionFormField?.divided && (
                                    <Divider style={{marginTop: 0, marginBottom: 10}}/>
                                )}
                                {constructSchemaActionAuxElement(formField, this.formRef?.current!)}
                            </Col>
                        )
                    );
                }
            });
        }
    };

    // Get all sections in Schema Actions and construct the form
    constructSchemaActionFormLayout = (schemaAction: SchemaActionEntity) => {
        const version: number = getSchemaActionVersion(schemaAction);
        const definition: any = schemaAction?.definition;

        // Sections config differ in V1 and V2
        let Sections: any = undefined;
        if (version === 1) {
            Sections = definition?.formSections;
        } else {
            Sections = definition?.formDefinition;
        }

        if (Sections?.length! > 0) {
            return Sections.sort(
                (secA: any, secB: any) => secA.order - secB.order,
            ).map((FormSection: any) => {
                let SectionName =
                    version === 1 ? FormSection.name : FormSection.sectionName;

                return Sections.length > 1 ? (
                    <Col span={24} style={{marginBottom: 15}} key={FormSection.name}>
                        <Section
                            key={FormSection.name}
                            collapsible
                            title={SectionName || 'Unnamed Section'}
                            collapseProps={{defaultIsOpen: !FormSection.closedByDefault}}
                        >
                            <Row gutter={12} style={{padding: 15}} key={FormSection.name}>
                                {this.renderSchemaActionFormSection(FormSection)}
                            </Row>
                        </Section>
                    </Col>
                ) : (
                    <Col span={24}>
                        <Row gutter={12}>
                            {this.renderSchemaActionFormSection(FormSection)}
                        </Row>
                    </Col>
                );
            });
        } else {
            return (
                <Col span={24} style={{paddingTop: 40}}>
                    <Empty description="No sections defined in this Action. Check your Action definition."/>
                </Col>
            );
        }
    };

    renderTitleField(section: FormSectionEntity) {
        const recordFormReducer: IFormReducer = this.getFormReducer();
        const {selected, isCreateReq, isBulkUpdateReq} = recordFormReducer;

        if (section.schema?.hasTitle && !isBulkUpdateReq) {
            return (
                <Form.Item
                    key="title"
                    name="title"
                    label="Record title"
                    labelCol={{span: 24}}
                    initialValue={selected ? selected.title : ''}
                    rules={[{required: isCreateReq && section.schema?.isTitleUnique}]}
                >
                    <Input
                        type="text"
                        defaultValue={selected ? selected.title : ''}
                        placeholder="add record title"
                        onChange={(e) =>
                            this.handleInputChange({
                                id: `${section.schema?.id}#title`,
                                entity: 'Record',
                                value: e.target.value,
                            })
                        }
                    />
                </Form.Item>
            );
        }
    }

    renderSchemaTypeField(section: FormSectionEntity) {
        const {isSecondaryForm} = this.props;
        const recordFormReducer: IFormReducer = this.getFormReducer();
        const {
            schema,
            selected,
            recordType,
            isCloning,
            isBulkUpdateReq,
            hideRecordTypeField,
        } = recordFormReducer;

        const hasTypes = schema?.types?.length! > 0;
        const hasDefaultType = schema?.types?.some(
            (schema: SchemaTypeEntity) => schema.name === 'DEFAULT',
        );

        const getInitialValueForSchemaType = () => {
            if (selected?.type) {
                return selected?.type;
            } else if (recordType) {
                return recordType;
            } else if (hasDefaultType) {
                return 'DEFAULT';
            } else {
                return '';
            }
        };

        if (schema && hasTypes && !isBulkUpdateReq && !hideRecordTypeField) {
            return (
                <Form.Item
                    key="recordType"
                    name="recordType"
                    label="Record Type"
                    labelCol={{span: 24}}
                    initialValue={getInitialValueForSchemaType()}
                    rules={[{required: true}]}
                >
                    <Select
                        key={'recordType'}
                        disabled={!!selected?.type && !isCloning}
                        defaultValue={getInitialValueForSchemaType()}
                        style={{width: '100%'}}
                        onChange={(val) => {
                            this.fetchDefaultSchemaAction();
                            this.handleInputChange({
                                formUUID: isSecondaryForm ? this.props.formUUID : undefined,
                                id: `${section.schema?.id}#recordType`,
                                entity: 'Record',
                                value: val,
                            });
                        }}
                    >
                        <Option
                            key={selected ? selected.type : 1}
                            value={selected ? selected.type : ''}
                        >
                            {selected ? selected.type : 'Select Type'}
                        </Option>
                        {schema.types &&
                            schema.types.map((elem: SchemaTypeEntity) => (
                                <Option key={elem?.id?.toString()} value={elem.name}>
                                    {elem.name}
                                </Option>
                            ))}
                    </Select>
                </Form.Item>
            );
        }
    }

    renderRecordNumberField = (definition: any) => {
        const {record, recordFormReducer} = this.props;
        const {isBulkUpdateReq, isUpdateReq} = recordFormReducer;

        if (definition && !isBulkUpdateReq && !isUpdateReq) {
            return (
                <Form.Item
                    key="recordNumber"
                    name="recordNumber"
                    label="Record number"
                    labelCol={{span: 24}}
                    initialValue={record ? record.number : ''}
                    rules={[{required: true}]}
                >
                    <Input
                        type="text"
                        defaultValue={record ? record.recordNumber : ''}
                        placeholder="Add custom record number"
                        onChange={(e) =>
                            this.setState({customRecordNumber: e.target.value})
                        }
                    />
                </Form.Item>
            );
        } else {
            return <></>;
        }
    };

    renderSelectInputForAssociations(section: any) {
        return (
            section.associations && (
                <Form.Item
                    key="related"
                    name="related"
                    label="related"
                    labelCol={{span: 24}}
                    rules={[{required: false}]}
                >
                    <Select
                        mode="multiple"
                        style={{width: '100%'}}
                        defaultValue={section.associations.map(
                            (elem: any) => elem.title || elem.recordNumber,
                        )}
                        placeholder="Please select"
                    >
                        {section.associations.map((elem: any) => (
                            <Option key={elem.recordId} value={elem.recordId}>
                                {elem.title || elem.recordNumber}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            )
        );
    }

    shouldShowTopFormDivider = () => {
        const {recordFormReducer} = this.props;
        const {schema, isBulkUpdateReq} = recordFormReducer;
        const {hideStageFormField} = this.props;
        if (!isBulkUpdateReq) {
            if (
                schema?.hasTitle ||
                schema?.types?.length! > 0 ||
                !hideStageFormField
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    renderRecordTitleField(schema: SchemaEntity | undefined) {
        const {record, recordFormReducer} = this.props;
        const {isCreateReq, isBulkUpdateReq} = recordFormReducer;

        if (schema?.hasTitle && !isBulkUpdateReq) {
            return (
                <Form.Item
                    key="title"
                    name="title"
                    label="Record title"
                    labelCol={{span: 24}}
                    initialValue={record ? record.title : ''}
                    rules={[{required: isCreateReq && schema?.isTitleRequired}]}
                >
                    <Input
                        disabled={!isCreateReq}
                        type="text"
                        defaultValue={record ? record.title : ''}
                        placeholder="Add record title"
                        onChange={(e) =>
                            this.handleInputChange({
                                id: `${schema?.id}#title`,
                                entity: 'Record',
                                value: e.target.value,
                            })
                        }
                    />
                </Form.Item>
            );
        } else {
            return <></>;
        }
    }

    renderFormBody = () => {
        const recordFormReducer: IFormReducer = this.getFormReducer();
        const schema = recordFormReducer?.schema;
        const {showFormActions, type, userReducer} = this.props;
        const {SchemaAction} = this.state;

        return (
            <Form
                style={{
                    minHeight: 300,
                    maxHeight: 500,
                    width: '100%',
                    padding: '0 0 20px 0',
                }}
                ref={this.formRef}
                key={this.state.SchemaAction?.id}
                name={this.state.SchemaAction?.name}
                className="dynamic-form"
                autoComplete="off"
                initialValues={{remember: true}}
            >
                {/* Record Title */}
                {shouldSchemaActionShowTitleField(this.state.SchemaAction!) &&
                    schema &&
                    this.renderTitleField({schema: schema})}

                {/* Record Type */}
                {shouldSchemaActionShowRecordTypeField(
                        this.state.SchemaAction!,
                        schema!,
                    ) &&
                    schema &&
                    this.renderSchemaTypeField({schema: schema})}

                {/* Record Number */}
                {shouldSchemaActionShowRecordNumberField(this.state.SchemaAction!) &&
                    this.renderRecordNumberField(this.state.SchemaAction?.definition)}

                {/* Take Schema Action definition and construct sections with form fields */}
                {SchemaAction &&
                    SchemaAction.definition &&
                    schema &&
                    this.formRef.current && (
                        <Row gutter={16}>
                            {this.constructSchemaActionFormLayout(SchemaAction)}
                        </Row>
                    )}

                {/* Error Message */}
                {this.state.error && (
                    <div style={{padding: isMobile ? 10 : '50px 0'}}>
                        <NonIdealState
                            icon={this.state.error?.icon || 'error'}
                            title={this.state.error?.title || 'Error'}
                            action={
                                isSystemAdmin(userReducer) &&
                                this.state.error?.icon !== 'lock' && (
                                    <Link to={`/${SCHEMA_MODULE}/Schema/${schema?.id}#Actions`}>
                                        <Button
                                            intent="primary"
                                            text="Create Action"
                                            style={{marginTop: 10}}
                                        />
                                    </Link>
                                )
                            }
                            description={this.state.error?.message || 'An error occurred'}
                        />
                    </div>
                )}

                {/* Footer for EMBEDDED forms */}
                {showFormActions && type === 'EMBEDDED' && (
                    <Row style={{marginTop: 10, marginRight: 10, marginBottom: 5}}>
                        <Divider style={{marginTop: 0, marginBottom: 12}}/>
                        <Col span={24} style={{textAlign: 'right'}}>
                            <Button
                                disabled={this.state.isCreatingOrUpdating}
                                onClick={this.handleCancel}
                                style={{
                                    marginRight: 10,
                                    display: this.props.hideCancelButton
                                        ? 'none'
                                        : 'inline-block',
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={this.state.isCreatingOrUpdating || !SchemaAction}
                                loading={this.state.isCreatingOrUpdating}
                                intent="primary"
                                onClick={this.handleSubmit}
                            >
                                {this.props.submitButtonTitle || 'Submit'}
                            </Button>
                        </Col>
                    </Row>
                )}
            </Form>
        );
    };

    render() {
        const {formUUID, type, userReducer} = this.props;
        const recordFormReducer: IFormReducer = this.getFormReducer();
        const schema = recordFormReducer?.schema || undefined;

        ///// MODAL ////////////////////////////////////////////////////////////////
        return type === 'MODAL' ? (
            <Dialog
                onClose={this.handleCancel}
                canEscapeKeyClose={false}
                canOutsideClickClose={false}
                style={{width: isMobile ? '95%' : '50%'}}
                isOpen={
                    recordFormReducer.showFormModal &&
                    recordFormReducer.formUUID === formUUID
                }
                title={recordFormReducer.title}
                usePortal={true}
            >
                <DialogBody useOverflowScrollContainer>
                    {this.renderFormBody()}
                </DialogBody>
                <DialogFooter
                    actions={
                        <>
                            <Button
                                text="Cancel"
                                disabled={this.state.isCreatingOrUpdating}
                                onClick={this.handleCancel}
                            />
                            <Button
                                text="OK"
                                intent="primary"
                                onClick={this.handleSubmit}
                                disabled={
                                    this.state.isCreatingOrUpdating ||
                                    this.state.isLoadingSchemaAction ||
                                    !!this.state.error
                                }
                                loading={this.state.isCreatingOrUpdating}
                            />
                        </>
                    }
                >
                    {/* Progress bar */}
                    {schemaActionHasProgressBar(this.state.SchemaAction) &&
                        !this.props.recordFormReducer.isUpdateReq && (
                            <Row style={{marginRight: 5}}>
                                <Col span={24} style={{marginTop: 10}}>
                                    <Progress
                                        percent={this.calculateProgressBar()}
                                        style={{width: '90%'}}
                                    />
                                </Col>
                            </Row>
                        )}

                    {this.state.SchemaAction && isSystemAdmin(userReducer) && (
                        <Link
                            to={`/${SCHEMA_MODULE}/SchemaAction/${schema?.id}/${this.state.SchemaAction?.id}`}
                            target="_blank"
                        >
                            {this.state.SchemaAction?.name}
                        </Link>
                    )}
                </DialogFooter>
            </Dialog>
        ) : (
            ////// EMBEDDED ////////////////////////////////////////////////////////////////
            <div>{this.renderFormBody()}</div>
        );
    }
}

const mapState = (state: any) => ({
    userReducer: state.userReducer,
    recordReducer: state.recordReducer,
    recordTableReducer: state.recordTableReducer,
    recordFormReducer: state.recordFormReducer,
});

const mapDispatch = (dispatch: any) => ({
    getSchemaAction: (params: { schemaActionId: string }, cb: any) =>
        dispatch(getSingleSchemaAction(params, cb)),
    closeForm: () => dispatch(closeRecordForm()),
    closeSecondaryForm: (params: { formUUID: string }) =>
        dispatch(closeSecondaryRecordForm(params)),
    updateFormProperties: (value: any) => dispatch(updateFormInput(value)),
    createRecord: (params: ICreateRecords, cb: any) =>
        dispatch(createRecordsRequest(params, cb)),
    updateRecord: (params: IUpdateRecordById, cb: any) =>
        dispatch(updateRecordByIdRequest(params, cb)),
    updateRecordAssociation: (params: IUpdateRelatedRecordAssociation, cb: any) =>
        dispatch(updateRecordAssociationRequest(params, cb)),
    bulkUpdateRecords: (params: IBulkUpdateRecords, cb?: any) =>
        dispatch(bulkUpdateRecordsRequest(params, cb)),
    notifyError: (params: any) => dispatch(errorNotification(params)),
    updateFormState: (params: IFormReducer) =>
        dispatch(updateRecordFormState(params)),
    searchRecords: (params: ISearchRecords, cb: any) =>
        dispatch(searchRecordsRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(CoreForm);
