import { Card, Col, Layout, Row, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { sendConfirmationEmail } from '../../../../../core/notifications/email/store/actions';
import FileUploaderDragAndDrop from '../../../../../core/records/components/Files/FileUploaderDragAndDrop';
import { IRecordReducer } from '../../../../../core/records/store/reducer';
import AssociationCardList from '../../../../../core/recordsAssociations/AssociationCardList';
import AssociationDataTable from '../../../../../core/recordsAssociations/AssociationDataTable/DataTable';
import { ISchemaReducer } from '../../../../../core/schemas/store/reducer';
import StageNameTag from '../../../../../shared/components/StageNameTag';
import { getRecordFromShortListById } from '../../../../../shared/utilities/recordHelpers';
import { getSchemaFromShortListBySchemaId } from '../../../../../shared/utilities/schemaHelpers';
import NoteFeed_LEGACY from '../../../../../core/records/components/NoteFeed_LEGACY';
import NoteFeed from '../../../../../core/records/components/NoteFeed';

type PathParams = {
  url: string;
  recordId: string;
};

type PropsType = RouteComponentProps<PathParams> & {
  recordReducer: IRecordReducer;
  schemaReducer: ISchemaReducer;
  sendConfirmation: any;
  match: any;
};

class WorkOrderSurveyDetailView extends React.Component<PropsType> {
  render() {
    const { recordReducer, schemaReducer, match } = this.props;
    const record = getRecordFromShortListById(
      recordReducer.shortList,
      match.params.recordId,
    );

    const schema = getSchemaFromShortListBySchemaId(
      schemaReducer.shortList,
      record?.schemaId,
    );

    return (
      <Layout className="record-detail-view">
        <Row gutter={12} className="record-main-content-row">
          <Col
            span={24}
            style={{ marginBottom: 8, background: '#fff', padding: 12 }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Link to={'/FieldServiceModule/WorkOrder/Survey'}>
                Back to list
              </Link>
              <div>
                <Typography.Title level={4} style={{ marginBottom: 4 }}>
                  # {record?.recordNumber}
                </Typography.Title>
                <StageNameTag record={record} text={record?.stage?.name} />
              </div>
            </div>
          </Col>

          <Row gutter={16}>
            <Col span={24}>
              {/* <WorkOrderSurveyStageButtons record={record} /> */}
            </Col>
            <Col span={24} style={{ marginBottom: 16 }}>
              {/* Address */}
              <AssociationCardList
                record={record}
                parentSchema={schema!}
                moduleName="CrmModule"
                entityName="Address"
                layout="vertical"
                showRecordTitle
                propKeys={[
                  'SalesStatus',
                  'BuildStatus',
                  'TargetReleaseDate',
                  'UDPRN',
                  'ExPolygonId',
                  'L2PolygonId',
                  'L4PolygonId',
                ]}
              />
            </Col>

            {/* File Upload */}
            <Col span={24} style={{ marginBottom: 16 }}>
              <Card title="Upload">
                <FileUploaderDragAndDrop record={record} />
                <AssociationDataTable
                  title="Files"
                  record={record}
                  moduleName="SchemaModule"
                  entityName="File"
                />
              </Card>
            </Col>

            {/* Notes */}
            <Col span={24} style={{ marginBottom: 30 }}>
              <Card title="Notes">
                <NoteFeed record={record} />
              </Card>
            </Col>
          </Row>
        </Row>
      </Layout>
    );
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  recordReducer: state.recordReducer,
});

const mapDispatch = (dispatch: any) => ({
  sendConfirmation: (payload: any) => dispatch(sendConfirmationEmail(payload)),
});

export default withRouter(
  connect(mapState, mapDispatch)(WorkOrderSurveyDetailView),
);
