import {ArrowLeftOutlined} from '@ant-design/icons';
import {DbRecordEntityTransform} from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {Card, Col, Divider, Layout, PageHeader, Row, Spin, Typography,} from 'antd';
import {FormInstance} from 'antd/lib/form';
import React from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {IRecordReducer} from '../../core/records/store/reducer';
import {IRecordAssociationsReducer} from '../../core/recordsAssociations/store/reducer';
import history from '../../shared/utilities/browserHisory';
import {getRecordFromShortListById, getRecordRelatedFromShortListById,} from '../utilities/recordHelpers';
import './styles.scss';
import WorkOrderInProgressNoteModal
  from '../../containers/FieldServiceModule/containers/WorkOrder/WorkOrderInProgressNoteModal';
import {SchemaModuleEntityTypeEnums} from '@d19n/models/dist/schema-manager/schema/types/schema.module.entity.types';
import {SchemaModuleTypeEnums} from '@d19n/models/dist/schema-manager/schema/types/schema.module.types';
import InProgressRedirectModal from '../../containers/FieldServiceModule/containers/WorkOrder/InProgressRedirectModal';
import {IUserInterfaceReducer} from '../../core/userInterface/store/reducer';
import {ISetPermissionToFail, setPermissionToFail,} from '../../core/userInterface/store/actions';
import {Tag} from '@blueprintjs/core';
import PermissionToFailApprovalNotification
  from '../../containers/FieldServiceModule/containers/WorkOrder/PermissionToFailApprovalNotification';
import PermissionToFailUpdateMonitor
  from '../../containers/FieldServiceModule/containers/PermissionToFailUpdateMonitor';
import ModuleEntityIcon from '../../core/theme/ModuleEntityIcon';
import RecordStagePipeline from '../RecordStagePipeline';
import NoteFeed from '../../core/records/components/NoteFeed';
import {IFormReducer} from "../../core/records/components/Forms/store/reducer";

type PathParams = {
  url: string;
  recordId: string;
};

type PropsType = RouteComponentProps<PathParams> & {
  recordReducer: IRecordReducer;
  recordAssociationReducer: IRecordAssociationsReducer;
  recordFormReducer: IFormReducer;
  match?: any;
  hasColumnMappings?: boolean;
  visibleProperties?: string[];
  backUrl: string;
  userInterfaceReducer: IUserInterfaceReducer;
};

interface State {
  showModal: boolean;
  actionButton: string;
  isLoading: boolean;
  isSearchingRecords: boolean;
  remediationStageKey: string | undefined;
}

const { WORK_ORDER, ORDER } = SchemaModuleEntityTypeEnums;
const { ORDER_MODULE, FIELD_SERVICE_MODULE } = SchemaModuleTypeEnums;

class PlainRecordDetailView extends React.Component<PropsType, State> {
  constructor(props: any) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    actionButton: 'Edit',
    isLoading: false,
    showModal: false,
    isSearchingRecords: true,
    remediationStageKey: undefined,
  });

  formRef = React.createRef<FormInstance>();

  getRecord = () => {
    let record: DbRecordEntityTransform;
    const {
      recordReducer,
      match,
      hasColumnMappings,
      recordAssociationReducer,
    } = this.props;

    if (hasColumnMappings) {
      record = getRecordRelatedFromShortListById(
        recordAssociationReducer.shortList,
        match.params.dbRecordAssociationId,
        match.params.recordId,
      );
    } else {
      record = getRecordFromShortListById(
        recordReducer.shortList,
        match.params.recordId,
      );
    }

    return record;
  };

  renderBackButtonAction() {
    const { backUrl, userInterfaceReducer } = this.props;
    const record = this.getRecord();

    if (
      record &&
      ![
        'WorkOrderStageScheduled',
        'WorkOrderStageDone',
        'WorkOrderStageCancelled',
        'WorkOrderRemediationRequired',
        'WorkOrderStageSurveyComplete',
      ].includes(String(record?.stage?.key)) &&
      record?.entity === 'FieldServiceModule:WorkOrder' &&
      !userInterfaceReducer.permissionToFail
    ) {
      this.setState({
        showModal: true,
      });
    } else {
      history.push(backUrl);
    }
  }

  onRecordsSearched = () => {
    this.setState({
      isSearchingRecords: false,
    });
  };

  render() {
    const { recordReducer, backUrl } = this.props;
    const { Text } = Typography;
    const record = this.getRecord();

    return (
      <>
        {/* Page Header */}
        <PageHeader
          className="page-header"
          ghost={false}
          title={<></>}
          backIcon={
            <div style={{ display: 'flex' }}>
              <ArrowLeftOutlined style={{ marginRight: 10 }} />
              Back
            </div>
          }
          onBack={() => this.renderBackButtonAction()}
        >
          <Row>
            <Col span={24}>
              <Divider style={{ marginTop: 0, marginBottom: '10px' }} />
            </Col>
          </Row>

          {/* Record Title */}
          <Row
            style={{
              textAlign: 'left',
              fontSize: 13,
            }}
            align="middle"
          >
            <Col span={3}>
              <ModuleEntityIcon
                moduleName={record?.entity?.split(':')[0] || ''}
                entityName={record?.entity?.split(':')[1] || ''}
                iconContainerStyle={{ fontSize: '1.2em', padding: '3px 8px' }}
              />
            </Col>
            <Col span={19} style={{ paddingLeft: 5 }}>
              <Spin spinning={recordReducer.isRequesting}>
                <span style={{ fontWeight: 'bold' }}>
                  {record ? record.title : '-'}
                </span>
                <br />
                <div style={{ opacity: 0.5 }}>
                  <Text>
                    {record ? record?.entity?.split(':')[1] : 'Unknown'}
                  </Text>{' '}
                  <Text>#{record?.recordNumber}</Text>
                </div>
              </Spin>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Divider style={{ marginTop: 8, marginBottom: 10 }} />
            </Col>
            <Col span={24} style={{ fontSize: 13 }}>
              <Row>
                <Col span={12}>
                  <span style={{ fontWeight: 500 }}>Type</span>
                </Col>
                <Col span={12}>
                  <span style={{ fontWeight: 500 }}>Stage</span>
                </Col>
              </Row>
              <Row style={{ marginTop: 5 }}>
                <Col span={12}>
                  <Tag minimal>{record?.type}</Tag>
                </Col>
                <Col span={12}>
                  <Tag minimal intent="primary">
                    {record?.stage?.name}
                  </Tag>
                </Col>
              </Row>
            </Col>
          </Row>
        </PageHeader>

        <Layout>
          {/* WO In progress Modal */}
          {this.state.showModal && (
            <WorkOrderInProgressNoteModal
              record={record}
              showModal={true}
              cbFunc={() => {
                return history.push(backUrl);
              }}
            />
          )}

          {/* Pipeline */}
          {record?.stage && <RecordStagePipeline record={record} />}

          {/* Show Notes on Orders, include associated Work Order Notes */}
          {record?.entity === `${ORDER_MODULE}:${ORDER}` && (
            <Row style={{ background: 'white' }}>
              <Col span={24}>
                <Card title="Notes">
                  <NoteFeed record={record!} />
                </Card>
              </Col>
            </Row>
          )}

          {/* Show an In Progress WO Modal redirection for Work Orders. */}
          {record?.entity === `${FIELD_SERVICE_MODULE}:${WORK_ORDER}` &&
            record && (
              <InProgressRedirectModal
                workOrderId={record.id}
                onBack={() => history.push(backUrl)}
                onRecordsSearched={this.onRecordsSearched}
              />
            )}

          {record?.entity === `${FIELD_SERVICE_MODULE}:${WORK_ORDER}` &&
            record && (
              <>
                <PermissionToFailApprovalNotification record={record} />
                <PermissionToFailUpdateMonitor record={record} />
              </>
            )}
        </Layout>
      </>
    );
  }
}

const mapState = (state: any) => ({
  recordReducer: state.recordReducer,
  userInterfaceReducer: state.userInterfaceReducer,
  recordAssociationReducer: state.recordAssociationReducer,
});

const mapDispatch = (dispatch: any) => ({
  setPermissionToFail: (params: ISetPermissionToFail) =>
    dispatch(setPermissionToFail(params)),
});

export default withRouter(
  connect(mapState, mapDispatch)(PlainRecordDetailView),
);
