import { ClockCircleOutlined } from '@ant-design/icons';
import { DbRecordAssociationRecordsTransform } from '@d19n/models/dist/schema-manager/db/record/association/transform/db.record.association.records.transform';
import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  getAllRelations,
  getFirstRelation,
  getProperty,
} from '@d19n/models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaEntity } from '@d19n/models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.types';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Drawer,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
} from 'antd';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { httpPost } from '../../../../shared/http/requests';
import {
  canUserUpdateRecord,
  isSystemAdmin,
} from '../../../../shared/permissions/rbacRules';
import {
  displayMessage,
  goCardlessErrorMessage,
} from '../../../../shared/system/messages/store/reducers';
import { parseDateToLocalFormat } from '../../../../shared/utilities/dateHelpers';
import { getRecordLink } from '../../../../shared/utilities/recordHelpers';
import {
  getSchemaFromShortListByModuleAndEntity,
  getSchemaFromShortListBySchemaId,
} from '../../../../shared/utilities/schemaHelpers';
import {
  deleteRecordByIdRequest,
  getRecordByIdRequest,
  IDeleteRecordById,
  IGetRecordById,
  IRecordLookup,
  lookupRecords,
} from '../../../records/store/actions';
import { IRecordReducer } from '../../../records/store/reducer';
import {
  getRecordAssociationsRequest,
  IGetRecordAssociations,
} from '../../../recordsAssociations/store/actions';
import { IRecordAssociationsReducer } from '../../../recordsAssociations/store/reducer';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '../../../schemas/store/actions';
import { ISchemaReducer } from '../../../schemas/store/reducer';
import {
  createAppointmentRequest,
  creteWorkOrderAndAppointmentRequest,
  ICreateServiceAppointment,
  initailizeCancelAppointmentModal,
  loadTimeSlotsRequest,
  setScheduleId,
} from '../store/actions';
import { ISetScheduleId } from '../store/reducer';

import './styles.scss';

interface Props {
  record: DbRecordEntityTransform;
  relation?: DbRecordAssociationRecordsTransform;
  hidden?: string[];
  userReducer: any;
  recordReducer: IRecordReducer;
  recordAssociationReducer: IRecordAssociationsReducer;
  schemaReducer: ISchemaReducer;
  getTimeSlots: any;
  createAppointment: any;
  appointmentReducer: any;
  deleteRecord: any;
  getAssociations: any;
  getRecordById: any;
  alertMessage: any;
  goCardlessErrorMessage: any;
  initializeCancelAppointment: any;
  creteWorkOrderAndAppointment: any;
  lookup: any;
  setScheduleId: Function;
  getSchema: Function;
  showAsButton?: boolean;
}

interface State {
  visible: boolean;
  start: string;
  paymentMethodFormVisible: boolean;
  isLoading: boolean;
  accountNumber: string;
  branchCode: string;
  contactId: string | undefined;
  isLoadingSchedules: boolean;
  schedules: DbRecordEntityTransform[];
  currentServiceAppointment: DbRecordEntityTransform | undefined;
}

const { CRM_MODULE } = SchemaModuleTypeEnums;

const { SERVICE_APPOINTMENT, PAYMENT_METHOD, CONTACT, ADDRESS } =
  SchemaModuleEntityTypeEnums;

const validPaymentMethod = [
  'ACTIVE',
  'SUBMITTED',
  'REINSTATED',
  'CREATED',
  'PENDING_SUBMISSION',
  'CUSTOMER_APPROVAL_GRANTED',
];

class BookingModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      visible: false,
      start: moment().format('YYYY-MM-DD'),
      paymentMethodFormVisible: false,
      isLoading: false,
      accountNumber: '',
      branchCode: '',
      contactId: '',
      isLoadingSchedules: false,
      schedules: [],
      currentServiceAppointment: undefined,
    };
    this.handleCloseDrawer = this.handleCloseDrawer.bind(this);
  }

  componentDidMount() {
    this.loadSchemas();
    this.getAssociatedServiceAppointment();
  }

  // Get associated SA and set it to the state
  getAssociatedServiceAppointment = () => {
    const { recordAssociationReducer, record } = this.props;

    const associationKey = `${record?.id}_${SERVICE_APPOINTMENT}`;
    const associationObj: any =
      recordAssociationReducer.shortList[associationKey];
    const serviceAppointment =
      associationObj?.[SERVICE_APPOINTMENT].dbRecords[0];

    if (serviceAppointment) {
      this.setState({ currentServiceAppointment: serviceAppointment });
    }
  };

  loadSchemas = () => {
    const { getSchema } = this.props;

    // Load Service appointment schema and fetch appointment configs
    // getSchema({
    //   moduleName: SchemaModuleTypeEnums.FIELD_SERVICE_MODULE,
    //   entityName: SchemaModuleEntityTypeEnums.SERVICE_APPOINTMENT_CONFIG
    // }, (response: SchemaEntity) => {
    //   //this.fetchServiceAppointmentConfig(response)
    // })

    getSchema({
      moduleName: SchemaModuleTypeEnums.CRM_MODULE,
      entityName: SchemaModuleEntityTypeEnums.CONTACT,
    });

    getSchema({
      moduleName: SchemaModuleTypeEnums.FIELD_SERVICE_MODULE,
      entityName: SchemaModuleEntityTypeEnums.SERVICE_APPOINTMENT_CONFIG,
    });
  };

  private initializeModal() {
    const {
      record,
      getTimeSlots,
      getAssociations,
      schemaReducer,
      alertMessage,
      userReducer,
    } = this.props;

    const addressLink = getRecordLink(record, `${CRM_MODULE}:${ADDRESS}`);

    let contactSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      CRM_MODULE,
      CONTACT,
    );

    let exPolygonId = undefined;
    let scheduleId = undefined;

    if (this.state.currentServiceAppointment) {
      exPolygonId = getProperty(
        this.state.currentServiceAppointment,
        'ExPolygonId',
      );
      scheduleId = getProperty(
        this.state.currentServiceAppointment,
        'ScheduleId',
      );
    }

    getTimeSlots({
      start: moment().format('YYYY-MM-DD'),
      end: moment().add(7, 'days').format('YYYY-MM-DD'),
      type: getProperty(record, 'Type'),
      addressId: addressLink ? addressLink.id : undefined,
      exPolygonId: getProperty(
        this.state.currentServiceAppointment,
        'ExPolygonId',
      ),
      scheduleId: scheduleId,
    });

    if (isSystemAdmin(userReducer)) {
      // if user isSystemAdmin allow him to add appointments without mandate check
      this.setState({
        visible: true,
      });
    } else {
      const contactLink = record?.links?.find(
        (elem) => elem.entity === 'CrmModule:Contact',
      );

      if (contactLink) {
        this.setState({
          contactId: contactLink?.id,
        });
        getAssociations(
          {
            recordId: contactLink?.id,
            key: PAYMENT_METHOD,
            schema: contactSchema,
            entities: [PAYMENT_METHOD],
          },
          (res: any) => {
            // check if contact has any mandates associated
            if (getFirstRelation(res.results, PAYMENT_METHOD)) {
              // check if contact in WO has valid mandate status
              if (
                this.checkPaymentMethodStatus(
                  getAllRelations(res.results, PAYMENT_METHOD),
                )
              ) {
                this.setState({
                  visible: true,
                });
              } else {
                // if contact does not have any valid mandate display error message
                alertMessage({
                  body: `Valid Payment Method is missing`,
                  type: 'error',
                });
              }
            } else {
              // if contact has no mandates associated display add mandate form
              this.setState({
                paymentMethodFormVisible: true,
              });
            }
          },
        );
      }
    }
  }

  private checkPaymentMethodStatus(
    dbRecords: DbRecordEntityTransform[] | undefined,
  ) {
    if (
      dbRecords?.find((elem: DbRecordEntityTransform) =>
        validPaymentMethod.includes(elem.properties.Status),
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  private handleDateChange(start: string) {
    const { record, getTimeSlots } = this.props;

    this.setState({
      start,
    });

    let exPolygonId = undefined;
    let scheduleId = undefined;

    if (this.state.currentServiceAppointment) {
      exPolygonId = getProperty(
        this.state.currentServiceAppointment,
        'ExPolygonId',
      );
      scheduleId = getProperty(
        this.state.currentServiceAppointment,
        'ScheduleId',
      );
    }

    const addressLink = getRecordLink(record, `${CRM_MODULE}:${ADDRESS}`);
    getTimeSlots({
      start: moment(start).format('YYYY-MM-DD'),
      end: moment(start).add(7, 'days').format('YYYY-MM-DD'),
      type: getProperty(record, 'Type'),
      addressId: addressLink ? addressLink.id : undefined,
      exPolygonId: exPolygonId,
      scheduleId: scheduleId,
    });
  }

  private handleOk(apt: {
    Date: string;
    AM: boolean;
    PM: boolean;
    Config: DbRecordEntityTransform;
  }) {
    const {
      recordAssociationReducer,
      record,
      initializeCancelAppointment,
      relation,
      setScheduleId,
    } = this.props;

    setScheduleId({ id: apt?.Config?.id });

    // Appointment already exists
    if (record && record.id) {
      const associationKey = `${record?.id}_${SERVICE_APPOINTMENT}`;
      const associationObj: any =
        recordAssociationReducer.shortList[associationKey];

      if (associationObj?.[SERVICE_APPOINTMENT].dbRecords) {
        // handle appointments with records
        this.handleCloseDrawer();
        initializeCancelAppointment({
          cancelModalVisible: true,
          cancelRelatedRecord:
            associationObj?.[SERVICE_APPOINTMENT].dbRecords[0],
          reScheduleServiceAppointment: true,
          saveData: {},
          newAppointmentData: apt,
          schemaType: 'SA_RESCHEDULE',
        });
      }
      // No previous appointment, create new one
      else {
        this.createNewAppointment(apt);
      }
    }
  }

  private createNewAppointment(apt: {
    Date: string;
    AM: boolean;
    PM: boolean;
    Config: DbRecordEntityTransform;
  }) {
    const {
      record,
      createAppointment,
      appointmentReducer,
      creteWorkOrderAndAppointment,
    } = this.props;

    if (record) {
      if (appointmentReducer.appointmentModalVisible) {
        creteWorkOrderAndAppointment(
          {
            orderId: record.id,
            createUpdate: {
              Date: apt.Date,
              scheduleId: apt.Config?.id,
              TimeBlock: apt.AM ? 'AM' : 'PM',
              Type: 'INSTALL',
              skipCustomerNotification: true,
            },
          },
          () => {
            this.handleCloseDrawer();
          },
        );
      } else {
        createAppointment(
          {
            workOrderId: record.id,
            createUpdate: {
              scheduleId: apt.Config?.id,
              Date: apt.Date,
              TimeBlock: apt.AM ? 'AM' : 'PM',
            },
          },
          () => {
            this.handleCloseDrawer();
            this.getRecordAssociations();
            // fetch new associations
          },
        );
      }
    }
  }

  private getRecordAssociations() {
    const { getAssociations, record, schemaReducer, getRecordById } =
      this.props;

    if (record) {
      const schema = getSchemaFromShortListBySchemaId(
        schemaReducer.shortList,
        record.schemaId,
      );

      if (schema) {
        getRecordById({ schema, recordId: record.id });
        getAssociations({
          recordId: record.id,
          key: SERVICE_APPOINTMENT,
          schema,
          entities: [SERVICE_APPOINTMENT],
        });
      }
    }

    return <div>data fetched</div>;
  }

  private handleCloseDrawer() {
    this.setState({
      visible: false,
    });
  }

  /**
   * D19-541 - Fetch appointments that match the current date in availableFrom/availableTo time span
   */
  // private fetchServiceAppointmentConfig(SACSchema: SchemaEntity) {
  //   const { FIELD_SERVICE_MODULE } = SchemaModuleTypeEnums;
  //   const { SERVICE_APPOINTMENT_CONFIG } = SchemaModuleEntityTypeEnums;
  //   const { lookup, schemaReducer, record, getRecordById, getSchema } = this.props;

  //   this.setState({ isLoadingSchedules: true });
  //   const addressSchema = SACSchema

  //   // Look through Work order associations, find the Address and fetch the record.
  //   // We need this in order to filter out schedules, because we only need schedules
  //   // that have the same exPolygonId as the Address
  //   const addressAssociation = record?.links?.find(
  //     (assoc: any) => assoc.entity == `${CRM_MODULE}:${ADDRESS}`,
  //   );

  //   getSchema({
  //     moduleName: SchemaModuleTypeEnums.CRM_MODULE,
  //     entityName: SchemaModuleEntityTypeEnums.ADDRESS
  //   }, (addressSchema: SchemaEntity) => {

  //     if (addressSchema) {
  //       getRecordById(
  //         { schema: addressSchema, recordId: addressAssociation?.id },
  //         (addressRecord: any) => {
  //           lookup(
  //             {
  //               schema: addressSchema,
  //               query: {
  //                 entity: `${FIELD_SERVICE_MODULE}:${SERVICE_APPOINTMENT_CONFIG}`,
  //                 type: record?.type,
  //                 properties: [
  //                   {
  //                     columnName: 'ExPolygonId',
  //                     operator: 'VECTOR',
  //                     value: getProperty(addressRecord, 'ExPolygonId'),
  //                   },
  //                 ],
  //               },
  //             },
  //             (res: DbRecordEntityTransform[]) => {
  //               // Get all schedules that are available before current date and last
  //               // either indefinite or after the current date. Filter schedules to
  //               // include the same exPolygonId as the Address record that we fetched.
  //               // Also, return only appointments that match the record type "INSTALL" | "SERVICE"
  //               const filteredResults = res?.filter(
  //                 (appointment: DbRecordEntityTransform) => {
  //                   const today = moment();
  //                   const availableFrom = getProperty(
  //                     appointment,
  //                     'AvailableFrom',
  //                   );
  //                   const availableTo = getProperty(appointment, 'AvailableTo');
  //                   if (
  //                     (moment(availableFrom, 'YYYY-MM-DD').isBefore(today) ||
  //                       moment(availableFrom, 'YYYY-MM-DD').isSame(today)) &&
  //                     (!availableTo || moment(availableTo).isBefore(today))
  //                   ) {
  //                     return appointment;
  //                   }
  //                 },
  //               );

  //               // Set the state
  //               this.setState({
  //                 isLoadingSchedules: false,
  //                 schedules: filteredResults,
  //                 selectedSchedule: filteredResults?.find(
  //                   (elem: DbRecordEntityTransform) =>
  //                     getProperty(elem, 'IsDefault') === 'true',
  //                 ),
  //               });
  //             },
  //           );
  //         },
  //       );
  //     } else {
  //       this.setState({
  //         isLoadingSchedules: false,
  //         schedules: [],
  //       });
  //     }
  //   })

  // }

  handleSelectedSchedule = (e: any) => {
    const { setScheduleId } = this.props;

    if (e !== 'None' && this.state.schedules?.length > 0) {
      const foundItem = this.state.schedules.find(
        (appointment: DbRecordEntityTransform) => appointment.id == e,
      );

      // If there is an appointment with the id from the select box
      if (foundItem) {
        setScheduleId({ id: foundItem.id });
        this.setState(
          {
            currentServiceAppointment: foundItem,
          },
          () => {
            this.initializeModal();
          },
        );
      } else {
        setScheduleId({ id: undefined });
        this.setState(
          {
            currentServiceAppointment: undefined,
          },
          () => {
            this.initializeModal();
          },
        );
      }
    } else {
      setScheduleId({ id: undefined });
      this.setState(
        {
          currentServiceAppointment: undefined,
        },
        () => {
          this.initializeModal();
        },
      );
    }
  };

  private renderListTitle() {
    const { recordAssociationReducer } = this.props;
    if (
      !!recordAssociationReducer.selected &&
      recordAssociationReducer.selected.schema
    ) {
      return `Book ${recordAssociationReducer.selected.schema.entityName}`;
    } else {
      return 'Book Appointment';
    }
  }

  private renderConfirmBookingText(
    date: string | undefined,
    timeBlock: string,
  ) {
    const { recordAssociationReducer } = this.props;

    if (recordAssociationReducer.selected) {
      if (recordAssociationReducer.selected.dbRecords.length > 0) {
        return `This will cancel the current appointment and reserve a new appointment on ${date} for an ${timeBlock} time block`;
      } else {
        return `confirm ${timeBlock} booking`;
      }
    } else {
      return `confirm ${timeBlock} booking`;
    }
  }

  private renderAppointments() {
    const { appointmentReducer } = this.props;
    if (!!appointmentReducer.list) {
      return appointmentReducer.list.map(
        (elem: {
          Date: string;
          AM: boolean;
          PM: boolean;
          Config: DbRecordEntityTransform;
        }) => (
          <Card
            style={{
              opacity: elem.AM || elem.PM ? 1 : '0.4',
              border: elem.AM || elem.PM ? '' : 'none',
            }}
            title={
              <Row>
                <Col span={24} style={{ textAlign: 'center' }}>
                  {parseDateToLocalFormat(elem.Date)}
                </Col>
              </Row>
            }
            bordered={false}
            className="calendar-day-card"
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Popconfirm
                title={this.renderConfirmBookingText(
                  parseDateToLocalFormat(elem.Date),
                  'AM',
                )}
                onConfirm={() =>
                  this.handleOk({
                    Date: elem.Date,
                    AM: true,
                    PM: false,
                    Config: elem.Config,
                  })
                }
                okText="Yes"
                cancelText="No"
                disabled={!elem.AM}
              >
                <Button
                  disabled={!elem.AM}
                  icon={<ClockCircleOutlined />}
                  className="time-am"
                >
                  AM
                </Button>
              </Popconfirm>
              <Popconfirm
                title={this.renderConfirmBookingText(
                  parseDateToLocalFormat(elem.Date),
                  'PM',
                )}
                onConfirm={() =>
                  this.handleOk({
                    Date: elem.Date,
                    AM: false,
                    PM: true,
                    Config: elem.Config,
                  })
                }
                okText="Yes"
                cancelText="No"
                disabled={!elem.PM}
              >
                <Button
                  disabled={!elem.PM}
                  icon={<ClockCircleOutlined />}
                  className="time-pm"
                >
                  PM
                </Button>
              </Popconfirm>
            </div>
          </Card>
        ),
      );
    }
  }

  submitPaymentMethodForm = async () => {
    const { alertMessage, getTimeSlots, record, goCardlessErrorMessage } =
      this.props;

    this.setState({
      isLoading: true,
    });

    await httpPost(
      `BillingModule/v1.0/contact/${this.state.contactId}/payment-methods`,
      {
        identityName: 'GOCARDLESS',
        bankDetails: {
          accountNumber: this.state.accountNumber,
          branchCode: this.state.branchCode,
        },
        authorizedDirectDebit: true,
      },
    )
      .then(({ data }) => {
        this.setState({
          paymentMethodFormVisible: false,
          isLoading: false,
          visible: true,
        });

        let exPolygonId = undefined;
        let scheduleId = undefined;

        if (this.state.currentServiceAppointment) {
          exPolygonId = getProperty(
            this.state.currentServiceAppointment,
            'ExPolygonId',
          );
          scheduleId = this.state.currentServiceAppointment?.id;
        }

        getTimeSlots({
          start: moment().format('YYYY-MM-DD'),
          end: moment().add(7, 'days').format('YYYY-MM-DD'),
          type: getProperty(record, 'Type'),
          exPolygonId: exPolygonId,
          scheduleId: scheduleId,
        });

        if (data.data) {
          if (moment().utc().isAfter(data.data.createdAt)) {
            alertMessage({
              body: `nothing to do the customers mandate is ${getProperty(
                data.data,
                'Status',
              )}`,
              type: 'success',
            });
          } else {
            alertMessage({
              body: 'A new mandate was created',
              type: 'success',
            });
          }
        }
      })
      .catch((err) => goCardlessErrorMessage(err));
  };

  render() {
    const { schemaReducer, userReducer, record, appointmentReducer } =
      this.props;
    const schema = getSchemaFromShortListBySchemaId(
      schemaReducer.shortList,
      record?.schemaId,
    );

    return (
      <div>
        <Modal
          title="Add Mandate"
          visible={this.state.paymentMethodFormVisible}
          onOk={() => this.submitPaymentMethodForm()}
          onCancel={() => {
            this.setState({
              paymentMethodFormVisible: false,
            });
          }}
          confirmLoading={this.state.isLoading}
        >
          <Space direction="vertical" style={{ width: '100%' }}>
            <Input.Password
              autoComplete="new-password"
              placeholder="bank account #"
              onChange={(e) => this.setState({ accountNumber: e.target.value })}
            />
            <Input.Password
              autoComplete="new-password"
              placeholder="sort code"
              onChange={(e) => this.setState({ branchCode: e.target.value })}
            />
          </Space>
        </Modal>
        <Button
          disabled={!canUserUpdateRecord(userReducer, schema)}
          type={this.props.showAsButton ? 'primary' : 'text'}
          onClick={() => this.initializeModal()}
        >
          Re-schedule
        </Button>
        <Drawer
          title={this.renderListTitle()}
          visible={this.state.visible}
          onClose={() => this.handleCloseDrawer()}
          width={365}
        >
          <Spin
            spinning={appointmentReducer.isSearching}
            tip="Finding Appointments..."
          >
            <Spin
              spinning={appointmentReducer.isCreating}
              tip="Saving Appointment..."
            >
              <div>
                {/* Select Schedule */}
                {/* <Row style={{ marginBottom: 15 }}> */}
                {/* <Col span={24} style={{ marginBottom: 5 }}>
                    <span style={{ fontWeight: 500 }}>Schedule</span>
                  </Col> */}
                {/* <Col span={24}>
                    {
                      <Select
                        style={{ width: '100%' }}
                        disabled={this.state.isLoadingSchedules}
                        loading={this.state.isLoadingSchedules}
                        defaultValue={this.state.currentServiceAppointment?.id}
                        value={this.state.currentServiceAppointment?.id}
                        onChange={(e: any) => this.handleSelectedSchedule(e)}
                      >
                        {this.state.schedules?.map(
                          (appointment: DbRecordEntityTransform) => {
                            return (
                              <Select.Option
                                key={appointment.title}
                                value={appointment.id}
                              >
                                {appointment.title}
                              </Select.Option>
                            );
                          },
                        )}
                      </Select>
                    }
                  </Col> */}
                {/* </Row> */}

                {/* Select Date */}
                <Row style={{ marginBottom: 15 }}>
                  <Col span={24} style={{ marginBottom: 5 }}>
                    <span style={{ fontWeight: 500 }}>Date</span>
                  </Col>
                  <Col span={24}>
                    <DatePicker
                      inputReadOnly
                      style={{ width: '100%' }}
                      defaultValue={moment(this.state.start, 'YYYY-MM-DD')}
                      format={'YYYY-MM-DD'}
                      onChange={(date) =>
                        this.handleDateChange(moment(date).format('YYYY-MM-DD'))
                      }
                    />
                  </Col>
                </Row>
              </div>
              {this.renderAppointments()}
            </Spin>
          </Spin>
        </Drawer>
      </div>
    );
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  userReducer: state.userReducer,
  recordReducer: state.recordReducer,
  recordAssociationReducer: state.recordAssociationReducer,
  appointmentReducer: state.appointmentReducer,
});

const mapDispatch = (dispatch: any) => ({
  lookup: (params: IRecordLookup, cb: () => {}) =>
    dispatch(lookupRecords(params, cb)),
  getRecordById: (payload: IGetRecordById, cb: any) =>
    dispatch(getRecordByIdRequest(payload, cb)),
  deleteRecord: (payload: IDeleteRecordById, cb: any) =>
    dispatch(deleteRecordByIdRequest(payload, cb)),
  getTimeSlots: (params: any) => dispatch(loadTimeSlotsRequest(params)),
  createAppointment: (params: ICreateServiceAppointment, cb: () => {}) =>
    dispatch(createAppointmentRequest(params, cb)),
  getAssociations: (params: IGetRecordAssociations, cb: any) =>
    dispatch(getRecordAssociationsRequest(params, cb)),
  alertMessage: (params: { body: string; type: string }) =>
    dispatch(displayMessage(params)),
  goCardlessErrorMessage: (params: any) =>
    dispatch(goCardlessErrorMessage(params)),
  initializeCancelAppointment: (params: any) =>
    dispatch(initailizeCancelAppointmentModal(params)),
  creteWorkOrderAndAppointment: (params: any, cb: () => {}) =>
    dispatch(creteWorkOrderAndAppointmentRequest(params, cb)),
  setScheduleId: (params: ISetScheduleId) => dispatch(setScheduleId(params)),
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
});

export default connect(mapState, mapDispatch)(BookingModal);
