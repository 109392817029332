import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaColumnEntity } from '@d19n/models/dist/schema-manager/schema/column/schema.column.entity';
import { SchemaEntity } from '@d19n/models/dist/schema-manager/schema/schema.entity';
import { FC } from 'react';
import { Col, Divider, Row } from 'antd';
import { CheckSquareTwoTone, CloseSquareTwoTone } from '@ant-design/icons';
import dayjs from 'dayjs';
import './styles.scss';
import { isMobile } from 'react-device-detect';
import FileFormField from '../../../../core/records/components/Files/FileFormField';
interface Props {
  schema: SchemaEntity;
  record: DbRecordEntityTransform;
}

const supportedSchemaColumnTypes = [
  'TEXT',
  'FILE_MULTIPLE',
  'FILE_SINGLE',
  'BOOLEAN',
  'DATE',
  'EMAIL',
  'SIGNATURE',
];

export const OutcomeFormBody: FC<Props> = (props: Props) => {
  const { schema, record } = props;

  // Depending on schema column type, render form field body
  const renderFieldBody = (type: string | undefined, value: string) => {
    switch (type) {
      case 'FILE_MULTIPLE':
        return value?.length > 0 ? (
          <FileFormField
            readOnly
            thumbnailSize={isMobile ? 'large' : 'medium'}
            withoutBorder
            mode={type}
            fileIds={value ? value.split(',') : []}
            parentSchemaId={''}
          />
        ) : (
          <span className="disabledText">No Files to show.</span>
        );
      case 'FILE_SINGLE':
        return value && value?.length > 0 ? (
          <FileFormField
            readOnly
            thumbnailSize={isMobile ? 'large' : 'medium'}
            withoutBorder
            mode={type}
            fileIds={value ? value.split(',') : []}
            parentSchemaId={''}
          />
        ) : (
          <span className="disabledText">No Files to show.</span>
        );
      case 'TEXT':
        return <span style={{ paddingLeft: 10 }}>{value}</span>;
      case 'SIGNATURE':
        return value && value?.length > 0 ? (
          <FileFormField
            thumbnailSize={isMobile ? 'large' : 'medium'}
            readOnly
            mode={'FILE_SINGLE'}
            withoutBorder
            fileIds={value ? value.split(',') : []}
            parentSchemaId={''}
          />
        ) : (
          <span className="disabledText">No Signature to show.</span>
        );
      case 'EMAIL':
        return <span style={{ paddingLeft: 10 }}>{value}</span>;
      case 'DATE':
        return (
          <span style={{ paddingLeft: 10 }}>
            {dayjs(value).format('DD/MM/YYYY')}
          </span>
        );
      case 'BOOLEAN':
        return value === 'true' ? (
          <CheckSquareTwoTone
            twoToneColor="#52c41a"
            style={{ fontSize: '1.3em', paddingLeft: 10 }}
          />
        ) : (
          <CloseSquareTwoTone
            twoToneColor="#eb2f96"
            style={{ fontSize: '1.3em', paddingLeft: 10 }}
          />
        );
      default:
        return <></>;
    }
  };

  const sortColumns = (a: SchemaColumnEntity, b: SchemaColumnEntity) => {
    if (a.position && b.position) {
      return a.position - b.position;
    } else {
      return 0;
    }
  };

  let allSchemaColumns = schema?.columns;
  allSchemaColumns = allSchemaColumns?.sort(sortColumns);

  return (
    <Row style={{ padding: '10px 5px' }}>
      {record && schema ? (
        // New ways
        allSchemaColumns?.map((schemaColumn: SchemaColumnEntity) => {
          const fieldName = schemaColumn.name;
          const fieldValue = getProperty(record, fieldName);
          return (
            <Col span={24} style={{ marginBottom: 20 }}>
              <Row>
                <Col span={24} style={{ marginBottom: 10 }}>
                  <Divider
                    orientation="left"
                    orientationMargin={0}
                    style={{ marginTop: 10, marginBottom: 5 }}
                  >
                    {fieldName}
                  </Divider>
                </Col>
                {supportedSchemaColumnTypes.includes(schemaColumn!.type) ? (
                  <Col span={24}>
                    {renderFieldBody(schemaColumn?.type, fieldValue)}
                  </Col>
                ) : (
                  <Col span={24} style={{ opacity: 0.2 }}>
                    <span>
                      Schema column type {schemaColumn?.type} is not supported.
                    </span>
                  </Col>
                )}
              </Row>
            </Col>
          );
        })
      ) : (
        <></>
      )}
    </Row>
  );
};
