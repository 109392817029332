import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/models/dist/schema-manager/schema/schema.entity';
import React from 'react';
import { connect } from 'react-redux';
import { sendConfirmationEmail } from '../../../../../../core/notifications/email/store/actions';
import { IRecordReducer } from '../../../../../../core/records/store/reducer';
import AssociationCardList from '../../../../../../core/recordsAssociations/AssociationCardList';
import {
  getRecordAssociationsRequest,
  IGetRecordAssociations,
} from '../../../../../../core/recordsAssociations/store/actions';
import { IRecordAssociationsReducer } from '../../../../../../core/recordsAssociations/store/reducer';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '../../../../../../core/schemas/store/actions';
import { ISchemaReducer } from '../../../../../../core/schemas/store/reducer';
import SipwiseCustomerContactSetupModal from '../../SipwiseCustomerContactSetupModal';
import {
  showCustomerDeviceOnt,
  showVoiceTab,
} from '../component-rendering-conditions';
import NetworkPanel from '../../../NetworkPanel';

interface Props {
  record: DbRecordEntityTransform;
  recordReducer: IRecordReducer;
  schemaReducer: ISchemaReducer;
  parentSchema: SchemaEntity;
  recordAssociationReducer: IRecordAssociationsReducer;
  sendConfirmation: any;
  getSchema: any;
  getAssociations: any;
  type: string;
}

const moduleName = 'ServiceModule';
const entityName = 'CustomerDeviceOnt';

class OrderItemOntView extends React.Component<Props> {
  componentDidMount() {
    const { getSchema } = this.props;
    getSchema({ moduleName, entityName });
  }

  render() {
    const { record, parentSchema, type } = this.props;

    return (
      <div className="ont-view-wrapper">
        {showCustomerDeviceOnt(record) && (
          <AssociationCardList
            formEnabled
            record={record}
            parentSchema={parentSchema!}
            moduleName={moduleName}
            entityName={entityName}
            layout="horizontal"
            showRecordTitle
            propKeys={['Model', 'SerialNumber']}
          />
        )}
        {showVoiceTab(record) && (
          <>
            <p>
              If the customer is porting their phone number,
              <br /> create a phone porting entry first.
            </p>
            <div style={{ display: 'flex', marginBottom: 24 }}>
              <div style={{ marginRight: 12 }}>
                <SipwiseCustomerContactSetupModal record={record} />
              </div>
            </div>
          </>
        )}
        <NetworkPanel record={record} type={type} />
      </div>
    );
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  recordReducer: state.recordReducer,
  recordAssociationReducer: state.recordAssociationReducer,
});

const mapDispatch = (dispatch: any) => ({
  sendConfirmation: (payload: any) => dispatch(sendConfirmationEmail(payload)),
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
  getAssociations: (params: IGetRecordAssociations, cb: any) =>
    dispatch(getRecordAssociationsRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(OrderItemOntView);
