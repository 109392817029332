import React from 'react';

interface Props {
  size?: 'default' | 'small' | 'large';
  monospace?: boolean;
  increaseLineHeight?: boolean;
  disabled?: boolean;
  strong?: boolean;
  stronger?: boolean;
  style?: any;
  ellipsis?: boolean;
  children: any;
}

const Typography: React.FC<Props> = (props: Props) => {
  const { size, children, monospace, disabled, strong, style, ellipsis, stronger } = props;

  const getFontSize = () => {
    switch (size) {
      case 'small':
        return 'bp5-text-small';
      case 'large':
        return 'bp5-text-large';
      default:
        return 'bp5-ui-text';
    }
  };

  const isMonoSpace = () => {
    if (monospace) {
      return 'bp5-monospace-text';
    }
  };

  const isDisabled = () => {
    if (disabled) {
      return 'bp5-text-disabled';
    }
  };

  const isStrong = () => {
    if (strong) {
      return 'bp5-text-strong';
    }
  };

  const isStronger = () => {
    if (stronger) {
      return 'bp5-text-stronger';
    }
  };

  const isEllipsis = () => {
    if (ellipsis) {
      return 'bp5-text-overflow-ellipsis';
    }
  };

  return (
    <span
      style={style}
      className={`${getFontSize()} ${isMonoSpace()} ${isDisabled()} ${isStrong()} ${isEllipsis()} ${isStronger()}`}
    >
      {children}
    </span>
  );
};
export default Typography;
