import { Button, Col, Divider, Input, Row, Select } from 'antd';
import { FC, useContext, useState } from 'react';
import { OrderNetworkDevicesContext } from '..';
import {
  SET_ADD_NETWORK_DEVICE_MODAL_DATA,
  SET_ADD_NETWORK_DEVICE_MODAL_VISIBLE,
} from '../store/constants';
import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaModuleTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.types';
import { SchemaModuleEntityTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.entity.types';
import { httpPost } from '../../../../../shared/http/requests';
import ModuleEntityIcon from '../../../../../core/theme/ModuleEntityIcon';

interface Props {
  orderItem: DbRecordEntityTransform;
  onUpdated?: () => void;
}

const { SERVICE_MODULE } = SchemaModuleTypeEnums;
const { CUSTOMER_DEVICE_ROUTER } = SchemaModuleEntityTypeEnums;

const DeactivatedRouter: FC<Props> = (props: Props) => {
  const { orderItem, onUpdated } = props;
  const [serialNumber, setSerialNumber] = useState<string>('');
  const [WiFiSSID, setWiFiSSID] = useState<string>('');
  const [WiFiPassword, setWiFiPassword] = useState<string>('');
  const [isCreatingRecord, setIsCreatingRecord] = useState<boolean>(false);
  const [selectedModel, setSelectedModel] = useState<
    'EERO' | 'LINKSYS' | undefined
  >(undefined);

  const { state, dispatch } = useContext(OrderNetworkDevicesContext);
  const handleSerialNumberChange = (e: any) => {
    setSerialNumber(e.target.value);
  };

  const activateONT = () => {
    setIsCreatingRecord(true);

    httpPost(`ServiceModule/v2.0/network/router/device/${orderItem.id}/link`, {
      entity: `${SERVICE_MODULE}:${CUSTOMER_DEVICE_ROUTER}`,
      properties: {
        SerialNumber: serialNumber,
        Model: selectedModel,
        WifiPassword: WiFiPassword,
        WifiSSID: WiFiSSID,
      },
    })
      .then((res: any) => {
        setIsCreatingRecord(false);
        const response = res.data?.data;
        if (response?.conflict) {
          dispatch({
            type: SET_ADD_NETWORK_DEVICE_MODAL_VISIBLE,
            payload: true,
          });
          dispatch({
            type: SET_ADD_NETWORK_DEVICE_MODAL_DATA,
            payload: response,
          });
        } else {
          onUpdated && onUpdated();
        }
      })
      .catch((err: any) => {
        setIsCreatingRecord(false);
      });
  };

  const handleWiFiSSIDCHange = (e: any) => {
    setWiFiSSID(e.target.value);
  };

  const handleWiFiPasswordHange = (e: any) => {
    setWiFiPassword(e.target.value);
  };

  return (
    <div
      style={{
        borderRadius: 2,
        background: '#f5f5f5',
        border: '1px solid #e2e2e2',
        padding: '15px 15px',
      }}
    >
      <Row justify="space-between" align="middle">
        <Col>
          <ModuleEntityIcon
            moduleName={SERVICE_MODULE}
            entityName={CUSTOMER_DEVICE_ROUTER}
          />
          <span style={{ fontWeight: 600 }}>Router Device</span>
        </Col>
        <Col>
          <Button
            type="primary"
            style={{ marginRight: 10 }}
            disabled={
              isCreatingRecord ||
              (!serialNumber && !selectedModel && !WiFiSSID && !WiFiPassword)
            }
            size="small"
            ghost
            onClick={() => {
              setSerialNumber('');
              setSelectedModel(undefined);
              setWiFiSSID('');
              setWiFiPassword('');
            }}
          >
            Clear
          </Button>
          <Button
            icon={<i className="bi bi-power" />}
            type="primary"
            size="small"
            onClick={activateONT}
            disabled={
              serialNumber.length === 0 ||
              WiFiSSID?.length! === 0 ||
              WiFiPassword?.length! === 0 ||
              !selectedModel ||
              isCreatingRecord
            }
          >
            Add
          </Button>
        </Col>
      </Row>
      <Row justify="space-between" gutter={12}>
        <Col xs={24}>
          <Divider
            style={{ marginTop: 15, marginBottom: 15, borderColor: '#e2e2e2' }}
          />
        </Col>
        {/* Serial Number */}
        <Col xs={24} md={12}>
          <Input
            placeholder="Enter Serial No."
            allowClear
            value={serialNumber}
            onChange={handleSerialNumberChange}
          />
        </Col>
        {/* Router Model */}
        <Col xs={24} md={12}>
          <Select
            allowClear
            style={{ width: '100%' }}
            placeholder="Select Model"
            onClear={() => setSelectedModel(undefined)}
            value={selectedModel}
            onSelect={setSelectedModel}
          >
            <Select.Option value="EERO">Eero</Select.Option>
            <Select.Option value="LINKSYS">Linksys</Select.Option>
            <Select.Option value="ASUS">Asus</Select.Option>
            <Select.Option value="ARRIS">Arris</Select.Option>
          </Select>
        </Col>

        {/* Wifi SSID */}
        <Col xs={24} md={12} style={{ marginTop: 10 }}>
          <Input
            placeholder="Enter WiFi SSID"
            allowClear
            value={WiFiSSID}
            onChange={handleWiFiSSIDCHange}
          />
        </Col>

        {/* Wifi Password */}
        <Col xs={24} md={12} style={{ marginTop: 10 }}>
          <Input
            placeholder="Enter WiFi Password"
            allowClear
            value={WiFiPassword}
            onChange={handleWiFiPasswordHange}
          />
        </Col>
      </Row>
    </div>
  );
};

export default DeactivatedRouter;
