import { Col, Row } from 'antd';
import React from 'react';
import VoiceActivateModal from '../VoiceActivateModal';
import SipwiseCustomerContactSetupModal from '../SipwiseCustomerContactSetupModal';

export const VoiceActivateForm = (record: any) => {
  return (
    <>
      <Row>
        <Col span={24}>
          <ol>
            <li style={{ marginBottom: '10px', marginTop: '10px' }}>
              If the customer is porting their phone number, create a phone
              porting entry first. Otherwise skip to step 2.
            </li>
            <li style={{ marginBottom: '10px' }}>Setup Sipwise profile</li>
            <li style={{ marginBottom: '10px' }}>
              Activate voice on the network
            </li>
          </ol>
        </Col>
      </Row>
      <Row style={{ margin: '10px' }}>
        <Col span={12} style={{ textAlign: 'left' }}>
          <SipwiseCustomerContactSetupModal record={record.targetRecord} />
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <VoiceActivateModal record={record.targetRecord} />
        </Col>
      </Row>
    </>
  );
};
