import { useMemo, useReducer } from 'react';
import { httpGet, httpPost } from '../../../../../../shared/http/requests';

type MFAAction<T extends any> = {
  type: 'set-status' | 'set-response' | 'set-error';
  payload?: T;
};

type MFAStatus =
  | 'idle'
  | 'generating'
  | 'generated'
  | 'user-setup-completed'
  | 'validating'
  | 'valid'
  | 'failed';

export type MFASelectors = ReturnType<typeof useMFA>['selectors'];

export type MFAState = {
  status: MFAStatus;
  mfaSettings?: {
    secret: string;
    url: string;
    backupCode: string;
  };
  error?: any;
};

const initialState: MFAState = {
  status: 'idle',
};

const mfaReducer = (state: MFAState, action: MFAAction<any>): MFAState => {
  switch (action.type) {
    case 'set-status':
      return {
        ...state,
        status: action.payload,
      };
    case 'set-response':
      return {
        ...state,
        status: 'generated',
        mfaSettings: action.payload,
      };
    case 'set-error':
      return {
        ...state,
        status: 'failed',
        error: action.payload,
      };
    default:
      return state;
  }
};

export const useMFA = () => {
  const [state, dispatch] = useReducer(mfaReducer, initialState);

  const service = useMemo(
    () => ({
      generateSecret: async () => {
        if (state.mfaSettings?.secret) return;
        dispatch({ type: 'set-status', payload: 'generating' });
        try {
          const result = await httpGet('IdentityModule/v1.0/users/mfa').then(
            (response) => response.data.data,
          );
          dispatch({
            type: 'set-response',
            payload: {
              ...result,
            },
          });
        } catch (e) {
          console.log('[DebugX] generateSecret error', e);
          dispatch({ type: 'set-error', payload: e });
        }
      },

      userSetupCompleted: () => {
        dispatch({ type: 'set-status', payload: 'user-setup-completed' });
      },

      verifyPIN: async (pin: string) => {
        dispatch({ type: 'set-status', payload: 'validating' });
        try {
          const result = await httpPost('IdentityModule/v1.0/users/mfa', {
            pin,
          })
            .then((response) => Boolean(response.data.data))
            .catch((err) => {
              throw new Error(err.response?.data?.message ?? err.message);
            });
          dispatch({ type: 'set-status', payload: 'valid' });
          return result;
        } catch (e) {
          dispatch({ type: 'set-error', payload: e });
          return false;
        }
      },
    }),
    [dispatch],
  );

  const selectors = useMemo(
    () => ({
      isLoading: () => ['generating', 'validating'].indexOf(state.status) > -1,
      hasError: () => state.status === 'failed',
      getMFAData: () => state.mfaSettings,
    }),
    [state],
  );

  return { state, service, selectors };
};
