import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Menu, MenuItem, Tooltip } from '@blueprintjs/core';
import { Badge, Col, Row, Skeleton } from 'antd';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import RecordStageChangeDialog from '../SchemaActions/RecordStageChangeDialog';
import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { IRecordReducer } from '../../core/records/store/reducer';
import { ISchemaReducer } from '../../core/schemas/store/reducer';
import { PipelineEntity } from '@d19n/models/dist/schema-manager/pipeline/pipeline.entity';
import { getSchemaFromShortListBySchemaId } from '../utilities/schemaHelpers';
import { SchemaEntity } from '@d19n/models/dist/schema-manager/schema/schema.entity';
import { httpGet } from '../http/requests';
import { PipelineStageEntity } from '@d19n/models/dist/schema-manager/pipeline/stage/pipeline.stage.entity';
import {
  IPipelineByStageId,
  getPipelineByStageIdRequest,
} from '../../core/pipelines/store/actions';
import { IGetSchemaById } from '@d19n/models/dist/rabbitmq/rabbitmq.interfaces';
import { getSchemaByIdRequest } from '../../core/schemas/store/actions';
import './styles.scss';

interface Props {
  record: DbRecordEntityTransform;
  recordReducer: IRecordReducer;
  schemaReducer: ISchemaReducer;
  className?: string;
  getPipeline: any;
  stageKey?: string;
  onSuccess?: Function;
  flat?: boolean;
  small?: boolean;
  getSchemaById: Function;
}

interface State {
  pipeline: PipelineEntity | undefined;
  hoveredStage: string | undefined;
  stageKey: string | undefined;
}

class RecordStagePipeline extends React.Component<Props, State> {
  private pipelineContainerRef = React.createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);

    this.state = {
      pipeline: undefined,
      hoveredStage: undefined,
      stageKey: undefined,
    };
  }

  componentDidMount(): void {
    this.fetchSchemas();
  }

  private fetchSchemas() {
    const { record, schemaReducer, getSchemaById } = this.props;
    if (record) {
      const shortlistSchema = getSchemaFromShortListBySchemaId(
        schemaReducer.shortList,
        record.schemaId,
      );

      if (shortlistSchema) {
        this.fetchData(shortlistSchema);
      } else {
        getSchemaById({ schemaId: record?.schemaId }, (res: SchemaEntity) => {
          if (res) {
            this.fetchData(res);
          }
        });
      }
    }
  }

  // Fetch pipeline
  private fetchData = (schema: SchemaEntity) => {
    const { record, getPipeline } = this.props;

    if (schema && record && record?.stage) {
      // New pipeline fetch
      httpGet(
        `SchemaModule/v1.0/pipelines/bymodule/${schema.moduleName}/${
          schema.entityName
        }${record.type ? `?schemaType=${record.type}` : '?schemaType='}`,
      ).then((res: any) => {
        const pipelines = res.data?.data || [];
        if (pipelines.length > 0) {
          this.setState({ pipeline: pipelines[0] });
        }
      });
    }
  };

  private getPipelineState(elem: PipelineStageEntity) {
    const { record } = this.props;
    if (record && record.stage?.id === elem?.id && !elem.isFail) {
      return 'active';
    } else if (record && record.stage?.id === elem?.id && elem.isFail) {
      return 'failed';
    } else if (
      record &&
      record.stage &&
      record.stage.position > elem.position
    ) {
      return 'complete';
    } else {
      return 'inactive';
    }
  }

  private sortColumns(
    stage1: PipelineStageEntity,
    stage2: PipelineStageEntity,
  ) {
    if (stage1.position && stage2.position) {
      return stage1.position - stage2.position;
    } else {
      return 0;
    }
  }

  // Render Badge or Icon in the mobile Pipeline Select component
  renderBadgeOrIconInSelectBox = (elem: any) => {
    const status = this.getPipelineState(elem);

    switch (status) {
      case 'active':
        return <Badge color="blue" className="compactPipelineBadge" />;
      case 'failed':
        return <CloseOutlined style={{ color: 'red', marginRight: 5 }} />;
      case 'complete':
        return <CheckOutlined style={{ color: 'green', marginRight: 5 }} />;
      case 'inactive':
        return <Badge color="grey" className="compactPipelineBadge" />;
      default:
        return <Badge color="grey" className="compactPipelineBadge" />;
    }
  };

  renderStageIntent = (stage: PipelineStageEntity) => {
    if (stage.isFail) {
      return 'danger';
    } else if (stage.isSuccess) {
      return 'success';
    } else {
      return 'primary';
    }
  };

  // Stage Change Actions - get all stages from a pipeline, filter out stages if
  // the current stage is not allowed to move to that stage, and remove the
  // stage where the record is currently at
  pipelineStageActions = (mode: 'MENU' | 'BUTTON'): JSX.Element[] => {
    const { record } = this.props;
    const { pipeline } = this.state;

    if (pipeline && pipeline?.stages?.length! > 0) {
      const currentStage = pipeline?.stages?.find(
        (elem: PipelineStageEntity) => elem.key === record?.stage?.key,
      );

      return (
        currentStage?.allowedNextStages?.map(
          (elem: PipelineStageEntity, index: number) => {
            return mode === 'MENU' ? (
              <MenuItem
                key={index}
                intent={this.renderStageIntent(elem)}
                text={elem.name}
                onClick={() => this.setState({ stageKey: elem.key })}
              />
            ) : (
              <Button
                fill
                key={index}
                text={elem.name}
                intent={this.renderStageIntent(elem)}
                onClick={() => this.setState({ stageKey: elem.key })}
                style={{
                  marginBottom:
                    index === currentStage?.allowedNextStages?.length! - 1
                      ? 0
                      : 10,
                }}
              />
            );
          },
        ) || []
      );
    } else {
      return [];
    }
  };

  render() {
    const { small = false, record } = this.props;
    const { pipeline } = this.state;

    return (
      <>
        <RecordStageChangeDialog
          openDialog={this.state.stageKey !== undefined}
          record={record}
          targetStage={this.state.stageKey}
          onClose={() => {
            this.setState({ stageKey: undefined });
          }}
          onConfirm={() => {
            this.setState({ stageKey: undefined });
          }}
        />

        {/* Pipeline Loader */}
        {!pipeline && !isMobile ? (
          <Row style={{ height: 32, maxHeight: 32, padding: 0, margin: 0 }}>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Skeleton
                paragraph={{ rows: 1 }}
                style={{ marginTop: -12 }}
                active
              />
            </Col>
          </Row>
        ) : (
          <></>
        )}

        {pipeline && (
          <Row className={`PipelineContainer ${this.props.flat ? 'Flat' : ''}`}>
            <Col
              xs={24}
              sm={21}
              style={{
                backgroundColor: 'white',
                borderRadius: 8,
                padding: this.props.flat ? 0 : 7,
              }}
            >
              {/* DESKTOP Pipeline */}
              <div
                className="nav-progress"
                ref={this.pipelineContainerRef}
                style={{ display: isMobile || small ? 'none' : 'block' }}
              >
                {pipeline && pipeline?.stages ? (
                  pipeline.stages
                    .sort(
                      (
                        stage1: PipelineStageEntity,
                        stage2: PipelineStageEntity,
                      ) => this.sortColumns(stage1, stage2),
                    )
                    .map((elem: PipelineStageEntity, index: number) => (
                      <div
                        key={index}
                        onMouseEnter={() =>
                          this.setState({ hoveredStage: elem.name })
                        }
                        onMouseLeave={() =>
                          this.setState({ hoveredStage: undefined })
                        }
                        className={`${this.getPipelineState(elem)} 
                      ${pipeline?.stages?.length! > 5 ? 'hoverable' : ''}`}
                        style={{
                          opacity: this.pipelineContainerRef?.current
                            ?.clientWidth
                            ? 1
                            : 0,
                          width:
                            this.pipelineContainerRef?.current?.clientWidth! /
                            pipeline?.stages?.length!,
                          paddingLeft:
                            (isMobile ||
                              small ||
                              pipeline?.stages?.length! > 5) &&
                            this.getPipelineState(elem) !== 'active' &&
                            this.getPipelineState(elem) !== 'inactive'
                              ? 10
                              : 30,
                        }}
                      >
                        {(isMobile || small || pipeline?.stages?.length! > 5) &&
                        this.getPipelineState(elem) !== 'active' &&
                        this.state.hoveredStage !== elem.name &&
                        this.getPipelineState(elem) !== 'inactive' ? (
                          <CheckOutlined style={{ marginLeft: 20 }} />
                        ) : (
                          <div
                            className="stageTitle"
                            style={{
                              fontSize:
                                pipeline?.stages?.length! > 5 ? '0.9em' : '1em',
                            }}
                          >
                            {elem?.name?.length < 12 ||
                            this.state.hoveredStage === elem.name
                              ? elem?.name
                              : '...'}
                          </div>
                        )}
                        {pipeline?.stages?.length &&
                        index < pipeline?.stages?.length ? (
                          <div className="arrow-wrapper">
                            <div className="arrow-cover">
                              <div className="arrow"></div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    ))
                ) : (
                  <></>
                )}
              </div>

              {/* MOBILE Pipeline */}
              <div
                style={{
                  display: (isMobile || small) && pipeline ? 'block' : 'none',
                  padding: 8,
                }}
              >
                <Row>
                  <Col span={24}>
                    <span style={{ fontWeight: 500 }}>Record Stage</span>
                  </Col>
                  <Col span={24} style={{ marginTop: 15 }}>
                    <Tooltip
                      content="This is the current stage"
                      position="top"
                      fill
                    >
                      <Button
                        text={record.stage?.name}
                        fill
                        style={{
                          marginBottom:
                            this.pipelineStageActions('BUTTON').length === 0
                              ? 0
                              : 10,
                        }}
                        disabled
                      />
                    </Tooltip>
                    {this.pipelineStageActions('BUTTON')}
                  </Col>
                </Row>
              </div>
            </Col>

            <Col
              xs={24}
              sm={3}
              style={{ display: isMobile || small ? 'none' : 'block' }}
            >
              <Menu
                style={{
                  margin: '2px',
                }}
              >
                <MenuItem
                  disabled={
                    !this.state.pipeline ||
                    this.pipelineStageActions('MENU').length === 0
                  }
                  intent="primary"
                  roleStructure="listoption"
                  text="Change Stage"
                  children={this.pipelineStageActions('MENU')}
                />
              </Menu>
            </Col>
          </Row>
        )}
      </>
    );
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  recordReducer: state.recordReducer,
});

const mapDispatch = (dispatch: any) => ({
  getPipeline: (params: IPipelineByStageId, cb: any) =>
    dispatch(getPipelineByStageIdRequest(params, cb)),
  getSchemaById: (payload: IGetSchemaById, cb: any) =>
    dispatch(getSchemaByIdRequest(payload, cb)),
});

export default connect(mapState, mapDispatch)(RecordStagePipeline);
