import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {isUserTokenExpired} from "../../../core/identity/helpers";
import {logoutRequest, updateUserRolesAndPermissionsRequest} from "../../../core/identity/store/actions";

interface Props {
  history: any;
  logout: () => void;
  updateUserRolesAndPermissions: () => void;
}

let timer: NodeJS.Timeout | undefined = undefined;
const interval = 30000; // 30 seconds

const UserSync: React.FC<Props> = (props: Props) => {
  const { updateUserRolesAndPermissions, logout } = props;
  const userToken = localStorage.getItem(`token`);
  const location = props.history.location.pathname;

  // Configure timer
  const startTimer = () => {
    timer = setInterval(() => {
      if (userToken && isUserTokenExpired()) {
        logout();
      } else if (userToken && !isUserTokenExpired() && !document.hidden) {
        updateUserRolesAndPermissions();
      } else if (!userToken) {
        clearTimer();
        logout();
      }
    }, interval);
  };
  const clearTimer = () => {
    clearInterval(timer);
    timer = undefined;
  };

  // Start timer on component mount, stop on unmount
  useEffect(() => {
    if (userToken) {
      startTimer();
    } else {
      clearTimer();
    }
  }, [userToken]);
  useEffect(() => {
    return () => {
      clearTimer();
    };
  }, []);

  // On each location update check if the user is authenticated and fetch the user object.
  useEffect(() => {
    if (userToken) {
      clearTimer();
      startTimer();
      refreshUserData();
    }
  }, [location]);

  //  D19-3253 - Migrated from App.tsx. Not sure what this was trying to achieve, but
  //             it seems to be a resolved with this component's effect hooks.
  //
  //   if (!userReducer.user && !isUserTokenExpired() && userToken) {
  //     this.props.updateUserRolesAndPermissions();
  //   }

  const refreshUserData = () => {
    updateUserRolesAndPermissions();
  };

  return <></>;
};

const mapState = (state: any) => ({});
const mapDispatch = (dispatch: any) => ({
  updateUserRolesAndPermissions: () => dispatch(updateUserRolesAndPermissionsRequest()),
  logout: () => dispatch(logoutRequest()),
});

export default withRouter(connect(mapState, mapDispatch)(UserSync));
