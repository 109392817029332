import {
  NOTE_FEED_ADD_NOTE,
  NOTE_FEED_ADD_NOTES,
  NOTE_FEED_ADD_REPLY,
  NOTE_FEED_ADD_TASK,
  NOTE_FEED_ADD_USERS,
  NOTE_FEED_DELETE_NOTE,
  NOTE_FEED_UPDATE_NOTE,
  NOTE_FEED_UPDATE_REACTION,
} from './constants';
import { sortNotesAndNestedNoteReplies } from '../helpers';
import { SUPPORT_TASK } from '../types';
import { SchemaModuleEntityTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.entity.types';
import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';

const { NOTE } = SchemaModuleEntityTypeEnums;

export interface INoteFeedReducer {
  notes: DbRecordEntityTransform[];
  users: { name: string; id: string }[];
}

export const noteFeedReducerInitialState: INoteFeedReducer = {
  notes: [],
  users: [],
};

export function noteFeedReducer(
  state: INoteFeedReducer,
  action: { type: string; payload: any },
) {
  switch (action.type) {
    // Unused
    case NOTE_FEED_ADD_USERS:
      return {
        ...state,
        users: action.payload,
      };

    // Add batch of notes to state
    case NOTE_FEED_ADD_NOTES:
      const sortedNotes = sortNotesAndNestedNoteReplies(action.payload);
      return {
        ...state,
        notes: sortedNotes,
      };

    // Add single note to state
    case NOTE_FEED_ADD_NOTE:
      const newNote = action.payload;
      const newNotes = Object.assign([], state.notes);
      newNotes.unshift(newNote);
      return {
        ...state,
        notes: newNotes,
      };

    // Update note contents
    case NOTE_FEED_UPDATE_NOTE:
      const noteId = action.payload.noteId;
      const noteContents = action.payload.noteContents;
      const noteGroups = action.payload.groups;

      let newNotesAfterUpdate: DbRecordEntityTransform[] = Object.assign(
        [],
        state.notes,
      );
      newNotesAfterUpdate = newNotesAfterUpdate.map(
        (note: DbRecordEntityTransform) => {
          if (note.id === noteId) {
            note.properties.JSONContent = noteContents;
            note.groups = noteGroups;
          }
          if (note[NOTE]?.dbRecords?.length > 0) {
            note[NOTE].dbRecords.forEach((reply: DbRecordEntityTransform) => {
              if (reply.id === noteId) {
                reply.properties.JSONContent = noteContents;
              }
            });
          }
          return note;
        },
      );

      return {
        ...state,
        notes: newNotesAfterUpdate,
      };

    // Delete note
    case NOTE_FEED_DELETE_NOTE:
      const noteIdToDelete = action.payload.noteId;

      let newNotesAfterDelete: DbRecordEntityTransform[] = Object.assign(
        [],
        state.notes,
      );
      newNotesAfterDelete = newNotesAfterDelete.filter(
        (note: DbRecordEntityTransform) => {
          if (note.id === noteIdToDelete) {
            return false;
          }
          if (note[NOTE]?.dbRecords?.length > 0) {
            note[NOTE].dbRecords = note[NOTE].dbRecords.filter(
              (reply: DbRecordEntityTransform) => reply.id !== noteIdToDelete,
            );
          }
          return true;
        },
      );
      return {
        ...state,
        notes: newNotesAfterDelete,
      };

    // Add reply to parent note
    case NOTE_FEED_ADD_REPLY:
      const newReply = action.payload.newReply;
      const parentNodeId = action.payload.parentNoteId;
      let newNotesAfterReply: DbRecordEntityTransform[] = Object.assign(
        [],
        state.notes,
      );
      newNotesAfterReply = newNotesAfterReply.map(
        (note: DbRecordEntityTransform) => {
          if (note.id === parentNodeId) {
            if (note[NOTE]?.dbRecords) {
              note[NOTE].dbRecords.unshift(newReply);
            } else {
              note[NOTE] = {};
              note[NOTE].dbRecords = [newReply];
            }
          }
          return note;
        },
      );
      newNotesAfterReply = sortNotesAndNestedNoteReplies(newNotesAfterReply);

      return {
        ...state,
        notes: newNotesAfterReply,
      };

    // Add task to note
    case NOTE_FEED_ADD_TASK:
      const newTask = action.payload.newTask;
      const noteIdForTask = action.payload.noteId;

      let newNotesAfterTask: DbRecordEntityTransform[] = Object.assign(
        [],
        state.notes,
      );
      newNotesAfterTask = newNotesAfterTask.map(
        (note: DbRecordEntityTransform) => {
          if (note.id === noteIdForTask) {
            if (note[SUPPORT_TASK]?.dbRecords) {
              note[SUPPORT_TASK]?.dbRecords?.unshift(newTask);
            } else {
              note[SUPPORT_TASK] = {};
              note[SUPPORT_TASK].dbRecords = [newTask];
            }
          }
          return note;
        },
      );

      return {
        ...state,
        notes: newNotesAfterTask,
      };

    // Add reaction to note
    case NOTE_FEED_UPDATE_REACTION:
      const reactions = action.payload.reactions;

      let newNotesAfterReaction: DbRecordEntityTransform[] = Object.assign(
        [],
        state.notes,
      );

      newNotesAfterReaction = newNotesAfterReaction.map(
        (note: DbRecordEntityTransform) => {
          if (note.id === action.payload.noteId) {
            note.properties.Reactions = reactions;
          }
          if (note[NOTE]?.dbRecords) {
            note[NOTE].dbRecords.forEach((reply: DbRecordEntityTransform) => {
              if (reply.id === action.payload.noteId) {
                reply.properties.Reactions = reactions;
              }
            });
          }
          return note;
        },
      );

      return {
        ...state,
        notes: newNotesAfterReaction,
      };

    default:
      return state;
  }
}
