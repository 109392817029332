import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import React from 'react';
import { connect } from 'react-redux';
import { sendConfirmationEmail } from '../../../../../core/notifications/email/store/actions';
import { IRecordReducer } from '../../../../../core/records/store/reducer';
import {
  deleteRecordAssociationById,
  getRecordAssociationsRequest,
  IDeleteRecordAssociation,
  IGetRecordAssociations,
} from '../../../../../core/recordsAssociations/store/actions';
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { IRecordAssociationsReducer } from '../../../../../core/recordsAssociations/store/reducer';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '../../../../../core/schemas/store/actions';
import { ISchemaReducer } from '../../../../../core/schemas/store/reducer';
import { Button, Card, Col, Descriptions, Empty, Modal, Row, Spin } from 'antd';
import { getProperty } from '@d19n/models/dist/schema-manager/helpers/dbRecordHelpers';
import './index.scss';
import { SchemaEntity } from '@d19n/models/dist/schema-manager/schema/schema.entity';
import { v4 as uuidv4 } from 'uuid';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../../shared/utilities/schemaHelpers';
import { initializeRecordForm } from '../../../../../core/records/components/Forms/store/actions';
import { deleteRecordByIdRequest } from '../../../../../core/records/store/actions';
import SpeedTest from '../../Service/SpeedTest';
import CoreForm from '../../../../../core/records/components/Forms/CoreForm';

interface Props {
  initializeForm: any;
  record: DbRecordEntityTransform;
  parentSchema: SchemaEntity;
  recordReducer: IRecordReducer;
  schemaReducer: ISchemaReducer;
  recordAssociationReducer: IRecordAssociationsReducer;
  sendConfirmation: any;
  getSchema: any;
  getAssociations: any;
  deleteRecordAssociation: any;
  deleteRecord: any;
}

interface State {
  confirmDeleteRecordAssociation: boolean;
  router: DbRecordEntityTransform | null;
  isLoading: boolean;
  isRefreshing: boolean;
}

const uuid = uuidv4();
const moduleName = 'ServiceModule';
const entityName = 'CustomerDeviceRouter';
const interval = 3000;

class AddRouterDevice extends React.Component<Props, State> {
  timer: NodeJS.Timeout | undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      confirmDeleteRecordAssociation: false,
      router: null,
      isLoading: true,
      isRefreshing: false,
    };
  }

  componentDidMount() {
    const { getSchema } = this.props;
    this.startTimer();
    this.getRecordAssociations();

    getSchema({ moduleName, entityName });
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  startTimer() {
    this.timer = setInterval(() => {
      if (!document.hidden) {
        this.getRecordAssociations();
      }
    }, interval);
  }

  clearTimer() {
    clearInterval(this.timer);
    this.timer = undefined;
  }

  deleteRecord() {
    const { schemaReducer, deleteRecord } = this.props;

    const routerSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      moduleName,
      entityName,
    );

    if (this.state.router && routerSchema) {
      deleteRecord(
        {
          schema: routerSchema,
          recordId: this.state.router ? this.state.router.id : null,
        },
        () => {
          this.setState({ confirmDeleteRecordAssociation: false });
          this.getRecordAssociations();
          this.setState({ router: null });
        },
      );
    }
  }

  initializeCreateForm() {
    const { record, schemaReducer, initializeForm } = this.props;

    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      moduleName,
      entityName,
    );

    if (schema && record) {
      initializeForm({
        showFormModal: true,
        formUUID: uuid,
        isCreateReq: true,
        schema,
        selected: null,
        sections: [
          {
            name: schema.name,
            schema: schema,
            associations: [{ recordId: record?.id, title: record?.title }],
          },
        ],
        modified: [
          {
            schemaId: schema?.id,
            associations: [
              {
                recordId: record?.id,
              },
            ],
          },
        ],
      });
    }
  }

  initializeUpdateForm(recordToUpdate: DbRecordEntityTransform) {
    const { schemaReducer, initializeForm } = this.props;
    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      moduleName,
      entityName,
    );

    if (schema) {
      initializeForm({
        showFormModal: true,
        formUUID: uuid,
        isUpdateReq: true,
        schema: schema,
        selected: recordToUpdate,
        sections: [{ name: schema.name, schema: schema }],
      });
    }
  }

  private getRecordAssociations() {
    const { getAssociations, parentSchema, record } = this.props;
    if (record) {
      this.setState({ isRefreshing: true });
      getAssociations(
        {
          recordId: record.id,
          key: entityName,
          schema: parentSchema,
          entities: [entityName],
        },
        (res: any) => {
          this.setState({ isLoading: false });
          this.setState({ isRefreshing: false });
        },
      );
    }
  }

  renderListOfRouters(Routers: Array<any>) {
    if (this.state.isLoading) {
      return (
        <Row style={{ marginBottom: '20px' }}>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Spin size="large" />
          </Col>
        </Row>
      );
    } else {
      if (Routers && Routers.length > 0) {
        return Routers.map((Router: DbRecordEntityTransform) => (
          <Card className="routerDeviceCard">
            <Row style={{ padding: '5px' }}>
              <Col span={24}>
                <Descriptions bordered size="small">
                  <Descriptions.Item label="Model">
                    {getProperty(Router, 'Model')}
                  </Descriptions.Item>
                  <Descriptions.Item label="Serial No.">
                    {getProperty(Router, 'SerialNumber')}
                  </Descriptions.Item>
                  <Descriptions.Item label="SSID.">
                    {getProperty(Router, 'WifiSSID')}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
            <Row style={{ padding: '5px' }}>
              {getProperty(Router, 'Model') === 'EERO' && (
                <Col span={24} style={{ marginTop: 10 }}>
                  <SpeedTest record={Router} />
                </Col>
              )}

              <Col span={24} style={{ marginTop: 10 }}>
                <Button
                  style={{ width: '100%' }}
                  icon={<SettingOutlined />}
                  type="primary"
                  ghost
                  onClick={() => {
                    this.initializeUpdateForm(Router);
                  }}
                >
                  Settings
                </Button>
              </Col>
              <Col span={24} style={{ marginTop: 10 }}>
                <Button
                  icon={<DeleteOutlined />}
                  danger
                  ghost
                  style={{ width: '100%' }}
                  onClick={() =>
                    this.setState({
                      confirmDeleteRecordAssociation: true,
                      router: Router,
                    })
                  }
                >
                  Remove
                </Button>
              </Col>
            </Row>
          </Card>
        ));
      } else {
        return (
          <Empty
            style={{ marginBottom: '20px' }}
            imageStyle={{ height: 60 }}
            description={<span>No Router Devices</span>}
          />
        );
      }
    }
  }

  getListOfRelatedRecordsByEntity(
    record: DbRecordEntityTransform,
    entityName: string,
  ) {
    const { recordAssociationReducer } = this.props;
    const associationKey = `${record?.id}_${entityName}`;
    const associationObj: any =
      recordAssociationReducer.shortList[associationKey];
    if (
      associationObj &&
      associationObj[entityName] &&
      associationObj[entityName].dbRecords
    ) {
      return associationObj[entityName].dbRecords;
    } else {
      return undefined;
    }
  }

  render() {
    const { record, recordAssociationReducer, schemaReducer } = this.props;

    return (
      <>
        <Modal
          title="Remove Router"
          visible={this.state.confirmDeleteRecordAssociation}
          confirmLoading={recordAssociationReducer.isDeleting}
          onOk={() => {
            this.deleteRecord();
          }}
          onCancel={() =>
            this.setState({ confirmDeleteRecordAssociation: false })
          }
          okText="Confirm"
          cancelText="Cancel"
        >
          <ExclamationCircleOutlined
            style={{ fontSize: '24px', color: '#ffa940' }}
          />
          <p>Are you sure you want to delete this Router?</p>
        </Modal>

        <CoreForm
          type="MODAL"
          formUUID={uuid}
          onSubmitEvent={() => this.getRecordAssociations()}
        />
        <div style={{ padding: '5px' }}>
          {this.renderListOfRouters(
            this.getListOfRelatedRecordsByEntity(
              record,
              'CustomerDeviceRouter',
            ),
          )}
          <Row>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Button
                size="large"
                type="primary"
                style={{ width: '95%' }}
                disabled={this.state.isLoading}
                onClick={() => this.initializeCreateForm()}
              >
                Add New Device
              </Button>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  recordReducer: state.recordReducer,
  recordAssociationReducer: state.recordAssociationReducer,
  recordFormReducer: state.recordFormReducer,
});

const mapDispatch = (dispatch: any) => ({
  sendConfirmation: (payload: any) => dispatch(sendConfirmationEmail(payload)),
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
  getAssociations: (params: IGetRecordAssociations, cb: any) =>
    dispatch(getRecordAssociationsRequest(params, cb)),
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  deleteRecord: (payload: any, cb: any) =>
    dispatch(deleteRecordByIdRequest(payload, cb)),
  deleteRecordAssociation: (payload: IDeleteRecordAssociation, cb: any) =>
    dispatch(deleteRecordAssociationById(payload, cb)),
});

export default connect(mapState, mapDispatch)(AddRouterDevice);
