import { Col, Empty, Row, Spin } from 'antd';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Button, Section } from '@blueprintjs/core';
import { isMobile } from 'react-device-detect';
import { getAllSchemaActionsForMultipleSchemaIds } from './api';
import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/models/dist/schema-manager/schema/schema.entity';
import { getAllSchemaAssociationSchemas } from '../../../../shared/utilities/recordHelpers';
import {getOdinSchemaById, schemaHasType} from '../../../../shared/utilities/schemaHelpers';
import { SchemaAssociationSchemaTypesConstraintEntity } from '@d19n/models/dist/schema-manager/schema/association/constraint/schema.association.schema.types.constraint.entity';
import { SchemaAssociationEntity } from '@d19n/models/dist/schema-manager/schema/association/schema.association.entity';
import { SchemaActionEntity } from '@d19n/models/dist/schema-manager/schema/action/schema.action.entity';
import ModuleEntityIcon from '../../../theme/ModuleEntityIcon';
import CoreForm from '../Forms/CoreForm';
import { initializeRecordForm } from '../Forms/store/actions';
import { getSchemaActionVersion } from '../Forms/helpers';

interface Props {
  parentRecord: DbRecordEntityTransform;
  parentSchema: SchemaEntity;
  initializeForm: Function;
  columns?: number;
}

const uuid = uuidv4();

const OutcomeFormLauncher: FC<Props> = (props: Props) => {
  const { parentSchema, parentRecord, initializeForm, columns } = props;
  const [schemasWithOutcomeForms, setSchemasWithOutcomeForms] = useState<
    SchemaEntity[]
  >([]);
  const [schemaActions, setSchemaActions] = useState<any[]>([]);
  const [isLoadingSchemaActions, setIsLoadingSchemaActions] =
    useState<boolean>(false);

  // When parent schema is available, get all schemas with outcome forms
  useEffect(() => {
    if (parentSchema) {
      const parentRecordType = parentRecord?.type;
      const relatedSchemas = getAllSchemaAssociationSchemas(
        parentSchema?.associations,
        [],
      );
      const outcomeSchemas: SchemaEntity[] = relatedSchemas?.filter(
        (relatedSchema: SchemaEntity) =>
          schemaHasType(relatedSchema, 'OUTCOME_FORM'),
      );

      if (outcomeSchemas.length > 0) {
        let filteredOutcomeSchemas = Object.assign(outcomeSchemas);

        // Exclude schemas with incompatible type constraints, leave schemas that have no type constraints defined.
        if (parentSchema && parentRecordType) {
          const associations = parentSchema.associations;

          associations.map((assoc: SchemaAssociationEntity) => {
            if (assoc.schemaTypesConstraints.length > 0) {
              const constraintTypes = assoc.schemaTypesConstraints.map(
                (constraint: SchemaAssociationSchemaTypesConstraintEntity) => {
                  return constraint.parentSchemaType?.name;
                },
              );

              if (!constraintTypes.includes(parentRecordType)) {
                filteredOutcomeSchemas = filteredOutcomeSchemas.filter(
                  (schema: SchemaEntity) => schema.id !== assoc.childSchemaId,
                );
              }
            }
          });
        }

        setSchemasWithOutcomeForms(filteredOutcomeSchemas);
      }
    }
  }, [parentSchema]);

  // Get all schema actions once schemas with outcome forms are extracted
  useEffect(() => {
    if (schemasWithOutcomeForms.length > 0) {
      const schemaIds = schemasWithOutcomeForms.map((schema: any) => schema.id);
      setIsLoadingSchemaActions(true);
      getAllSchemaActionsForMultipleSchemaIds(schemaIds).then((res: any) => {
        setSchemaActions(res);
        setIsLoadingSchemaActions(false);
      });
    }
  }, [schemasWithOutcomeForms]);

  const initializeOutcomeForm = async (schemaAction: SchemaActionEntity) => {
    const schema = schemasWithOutcomeForms.find(
      (schema: SchemaEntity) => schema.id === schemaAction.schemaId,
    );

    // Pre-fetch parent schema for the file form fields, they need this and
    // shouldn't be fetched on fileFormField component mount
    const parentSchema = await getOdinSchemaById(schema?.id!);

    initializeForm({
      formUUID: uuid,
      title: `Create ${schemaAction.name}`,
      showFormModal: true,
      isCreateReq: true,
      schema: schema!,
      selected: null,
      recordType: 'OUTCOME_FORM',
      sections: [{ name: schema?.name, schema: schema }],
      schemaActionId: schemaAction?.id,
      modified: [
        {
          schemaId: schema!.id,
          associations: [
            {
              entity: parentRecord.entity,
              recordId: parentRecord.id,
            },
          ],
        },
      ],
    });
  };

  const truncate = (str: any) => {
    return str?.length > 30 ? str.substring(0, 33) + '...' : str;
  };

  const renderFormActions = () => {
    const getColumns = () => {
      if (columns && !isMobile) {
        return 24 / columns;
      } else {
        return 24;
      }
    };
    // Remove schema actions that are not CREATE type
    const filteredSchemaActions = schemaActions?.filter(
      (schemaAction: any) => schemaAction.isCreate,
    );

    return filteredSchemaActions.map((schemaAction: any, i: number) => {
      const version = getSchemaActionVersion(schemaAction);
      const Definition = schemaAction.definition;

      let actionLabel: string = '';

      if (version === 1 && Definition?.actionLabel) {
        actionLabel = Definition?.actionLabel;
      } else if (version === 2 && Definition?.settings?.actionLabel) {
        actionLabel = Definition?.settings?.actionLabel;
      } else {
        actionLabel = schemaAction.name;
      }

      return (
        <Col span={getColumns()} style={{ padding: 5 }}>
          <Button
            onClick={() => initializeOutcomeForm(schemaAction)}
            text={actionLabel}
            fill
          />
        </Col>
      );
    });
  };

  return (
    <Section
      style={{ marginBottom: 10 }}
      icon={
        <ModuleEntityIcon
          iconContainerStyle={{
            padding: '5px 6px',
            fontSize: '1em',
            margin: 0,
          }}
          style={{ padding: 0 }}
          overrideIcon="ui-checks-grid"
          overrideIconColor="#1981f5"
          moduleName=""
          entityName=""
        />
      }
      title="Outcome Forms"
    >
      <Row style={{ padding: 10 }}>
        {/* Loader */}
        {isLoadingSchemaActions && (
          <Col span={24} style={{ padding: 15, textAlign: 'center' }}>
            <Spin style={{ marginBottom: 10 }} />
            <br />
            <span>Loading...</span>
          </Col>
        )}

        {/* Show All Actions */}
        {schemaActions.length > 0 &&
          !isLoadingSchemaActions &&
          renderFormActions()}

        {/*  No Outcome Forms */}
        {schemaActions.length === 0 && !isLoadingSchemaActions && (
          <Col span={24} style={{ padding: 10 }}>
            <Empty description="There are no OUTCOME_FORM schemas." />
          </Col>
        )}
      </Row>
      <CoreForm
        type="MODAL"
        formUUID={uuid}
        onSubmitEvent={(params: { event: string; res: any }) => {
          // Callback function after the record was created
        }}
      />
    </Section>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
});

export default connect(mapState, mapDispatch)(OutcomeFormLauncher);
