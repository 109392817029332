import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  SET_PERMISSION_TO_FAIL,
  SET_RAW_DATA_DRAWER_CONTENTS,
  TOGGLE_RAW_DATA_DRAWER,
} from './constants';

export interface ISetPermissionToFail {
  permissionToFail: DbRecordEntityTransform | undefined;
}

export function setPermissionToFail(params: ISetPermissionToFail) {
  return {
    type: SET_PERMISSION_TO_FAIL,
    params,
  };
}

// Raw Data
export function toggleRawDataDrawer() {
  return {
    type: TOGGLE_RAW_DATA_DRAWER,
  };
}

export function setRawDataDrawerContents(params: string) {
  return {
    type: SET_RAW_DATA_DRAWER_CONTENTS,
    params,
  };
}
