import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { GET_ACTIVE_OFFER_REQUEST, UPDATE_ORDER_WORKFLOW } from './constants';

export interface IOrderWorkflowParams {
  addressRecord?: DbRecordEntityTransform | undefined;
  createCPP?: boolean;
  cppModalVisible?: boolean;
  AreaCode?: string | undefined;
  CountryCode?: string | undefined;
  SubscriberNumber?: string | undefined;
  AuthorizedLOA?: boolean;
}

export function updateOrderWorkflow(params: IOrderWorkflowParams) {
  return {
    type: UPDATE_ORDER_WORKFLOW,
    params,
  };
}

export function getActiveOfferRequest() {
  return {
    type: GET_ACTIVE_OFFER_REQUEST,
  };
}
