export const SET_DB_RECORD_ASSOCIATION_SEARCH_QUERY =
  'SET_DB_RECORD_ASSOCIATION_SEARCH_QUERY';

export const SEARCH_DB_RECORD_ASSOCIATIONS_REQUEST =
  'SEARCH_DB_RECORD_ASSOCIATIONS_REQUEST';
export const SEARCH_DB_RECORD_ASSOCIATIONS_SUCCESS =
  'SEARCH_DB_RECORD_ASSOCIATIONS_SUCCESS';
export const SEARCH_DB_RECORD_ASSOCIATIONS_ERROR =
  'SEARCH_DB_RECORD_ASSOCIATIONS_ERROR';

export const GET_DB_RECORD_ASSOCIATIONS_REQUEST =
  'GET_DB_RECORD_ASSOCIATIONS_REQUEST';
export const GET_DB_RECORD_ASSOCIATIONS_SUCCESS =
  'GET_DB_RECORD_ASSOCIATIONS_SUCCESS';
export const GET_DB_RECORD_ASSOCIATIONS_ERROR =
  'GET_DB_RECORD_ASSOCIATIONS_ERROR';

export const GET_DB_RECORD_ASSOCIATION_WITH_NESTED_ENTITIES_REQUEST =
  'GET_DB_RECORD_ASSOCIATION_WITH_NESTED_ENTITIES_REQUEST';
export const GET_DB_RECORD_ASSOCIATION_WITH_NESTED_ENTITIES_SUCCESS =
  'GET_DB_RECORD_ASSOCIATION_WITH_NESTED_ENTITIES_SUCCESS';
export const GET_DB_RECORD_ASSOCIATION_WITH_NESTED_ENTITIES_ERROR =
  'GET_DB_RECORGET_DB_RECORD_ASSOCIATION_WITH_NESTED_ENTITIES_ERRORD_ASSOCIATIONS_WITH_NESTED_ERROR';

export const DB_RECORD_ASSOCIATIONS_CREATE_REQUEST =
  'DB_RECORD_ASSOCIATIONS_CREATE_REQUEST';
export const DB_RECORD_ASSOCIATIONS_CREATE_SUCCESS =
  'DB_RECORD_ASSOCIATIONS_CREATE_SUCCESS';
export const DB_RECORD_ASSOCIATIONS_CREATE_ERROR =
  'DB_RECORD_ASSOCIATIONS_CREATE_ERROR';

export const DELETE_DB_RECORD_ASSOCIATION_BY_ID_REQUEST =
  'DELETE_DB_RECORD_ASSOCIATION_BY_ID_REQUEST';
export const DELETE_DB_RECORD_ASSOCIATION_BY_ID_SUCCESS =
  'DELETE_DB_RECORD_ASSOCIATION_BY_ID_SUCCESS';
export const DELETE_DB_RECORD_ASSOCIATION_BY_ID_ERROR =
  'DELETE_DB_RECORD_ASSOCIATION_BY_ID_ERROR';

export const DB_RECORD_ASSOCIATIONS_UPDATE_REQUEST =
  'DB_RECORD_ASSOCIATIONS_UPDATE_REQUEST';
export const DB_RECORD_ASSOCIATIONS_UPDATE_SUCCESS =
  'DB_RECORD_ASSOCIATIONS_UPDATE_SUCCESS';
export const DB_RECORD_ASSOCIATIONS_UPDATE_ERROR =
  'DB_RECORD_ASSOCIATIONS_UPDATE_ERROR';

export const GET_DB_RECORD_ASSOCIATION_BY_ID_REQUEST =
  'GET_DB_RECORD_ASSOCIATION_BY_ID_REQUEST';
export const GET_DB_RECORD_ASSOCIATION_BY_ID_SUCCESS =
  'GET_DB_RECORD_ASSOCIATION_BY_ID_SUCCESS';
export const GET_DB_RECORD_ASSOCIATION_BY_ID_ERROR =
  'GET_DB_RECORD_ASSOCIATION_BY_ID_ERROR';

export const SET_SELECTED_DB_RECORD_ASSOCIATION_FROM_SHORTLIST =
  'SET_SELECTED_DB_RECORD_ASSOCIATION_FROM_SHORTLIST';

export const DB_RECORD_ASSOCIATIONS_ADD_TO_SELECTED =
  'DB_RECORD_ASSOCIATIONS_ADD_TO_SELECTED';

export const EXPORT_DB_RECORD_ASSOCIATION_RECORDS_REQUEST =
  'EXPORT_DB_RECORD_ASSOCIATION_RECORDS_REQUEST';
export const EXPORT_DB_RECORD_ASSOCIATION_RECORDS_SUCCESS =
  'EXPORT_DB_RECORD_ASSOCIATION_RECORDS_SUCCESS';
export const EXPORT_DB_RECORD_ASSOCIATION_RECORDS_ERROR =
  'EXPORT_DB_RECORD_ASSOCIATION_RECORDS_ERROR';

export const DELETE_DB_RECORD_ASSOCIATION_BY_MODULE_AND_ID_REQUEST =
  'DELETE_DB_RECORD_ASSOCIATION_BY_MODULE_AND_ID_REQUEST';
export const DELETE_DB_RECORD_ASSOCIATION_BY_MODULE_AND_ID_SUCCESS =
  'DELETE_DB_RECORD_ASSOCIATION_BY_MODULE_AND_ID_SUCCESS';
export const DELETE_DB_RECORD_ASSOCIATION_BY_MODULE_AND_ID_ERROR =
  'DELETE_DB_RECORD_ASSOCIATION_BY_MODULE_AND_ID_ERROR';
