import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/models/dist/schema-manager/schema/schema.entity';
import { Component } from 'react';

interface Props {
  trigger: SchemaEntity | DbRecordEntityTransform; // Pass schema or object record
  onChange: any;
}

class RecordUpdateHook extends Component<Props> {
  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { onChange, trigger } = this.props;

    if (prevProps.trigger?.id !== trigger?.id) {
      onChange();
    }
  }

  render() {
    return <></>;
  }
}

export default RecordUpdateHook;
