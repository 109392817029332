import {
  CaretDownFilled,
  CheckCircleTwoTone,
  CheckOutlined,
  ExclamationCircleTwoTone,
  SyncOutlined,
} from '@ant-design/icons';

import {
  Alert,
  Button,
  Col,
  Divider,
  Dropdown,
  MenuProps,
  Row,
  Space,
  Tag,
} from 'antd';

import { FC, useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { OrderNetworkDevicesContext } from '..';
import { DateTime } from 'luxon';
import {
  SET_NETWORK_DRAWER_DEVICE_RECORD,
  SET_NETWORK_DRAWER_ORDER_ITEM_RECORD,
  SET_NETWORK_DRAWER_VISIBLE,
} from '../store/constants';
import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  deleteRecordAssociationById,
  IDeleteRecordAssociation,
} from '../../../../../core/recordsAssociations/store/actions';
import { SchemaModuleTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.types';
import { SchemaModuleEntityTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.entity.types';
import { httpGet, httpPost } from '../../../../../shared/http/requests';
import { getProperty } from '@d19n/models/dist/schema-manager/helpers/dbRecordHelpers';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../../shared/utilities/schemaHelpers';
import ModuleEntityIcon from '../../../../../core/theme/ModuleEntityIcon';
import CoreForm from '../../../../../core/records/components/Forms/CoreForm';
import { displayMessage } from '../../../../../shared/system/messages/store/reducers';
import { initializeRecordForm } from '../../../../../core/records/components/Forms/store/actions';
import DeviceListSection from '../shared/DeviceListSection';
import RemoveONTDeviceFlow from "../RemoveONTDeviceFlow";

interface Props {
  ONTRecord: DbRecordEntityTransform; // ONT Device
  OIRecord: DbRecordEntityTransform; // OrderItem
  alertMessage: (params: { body: string; type: string }) => void;
  schemaReducer: any;
  initializeForm: Function;
  onUpdated?: () => void;
  deleteAssociation: (params: IDeleteRecordAssociation, cb?: any) => void;
}

const uuid = uuidv4();
let timer: NodeJS.Timeout | undefined = undefined;
const interval = 4000;

const { SERVICE_MODULE, ORDER_MODULE } = SchemaModuleTypeEnums;
const { CUSTOMER_DEVICE_ONT, ORDER_ITEM } = SchemaModuleEntityTypeEnums;

enum SupportedMethods {
  PROVISION,
  DEPROVISION,
  CHECK,
  RESTART,
}

type TServiceStatus = {
  color: string;
  text: 'NOT PROVISIONED' | 'PROVISIONED' | 'CHECKING';
  enabledMethods: number[];
  locked: boolean;
};

const ActivatedONT: FC<Props> = (props: Props) => {
  const {
    ONTRecord,
    OIRecord,
    alertMessage,
    schemaReducer,
    initializeForm,
    onUpdated,
    deleteAssociation,
  } = props;
  const [isGettingServiceStatus, setIsGettingServiceStatus] =
    useState<boolean>(false);
  const [isGettingDataStatus, setIsGettingDataStatus] =
    useState<boolean>(false);
  const [isDeprovisioning, setIsDeprovisioning] = useState<boolean>(false);
  const [isProvisioning, setIsProvisioning] = useState<boolean>(false);
  const [isCheckingStatus, setIsCheckingStatus] = useState<boolean>(false);
  const [isRestartingONT, setIsRestartingONT] = useState<boolean>(false);
  const [serviceStatus, setServiceStatus] = useState<
    TServiceStatus | undefined
  >(undefined);
  const [dataStatus, setDataStatus] = useState<TServiceStatus | undefined>(
    undefined,
  );
  const [isONTRemovalFlowVisible, setIsONTRemovalFlowVisible] = useState<boolean>(false);

  const [error, setError] = useState<any>(undefined);
  const { state, dispatch } = useContext(OrderNetworkDevicesContext);

  // Configure polling timer
  const startTimer = () => {
    timer = setInterval(() => {
      if (!document.hidden && OIRecord?.id) {
        getONTServiceStatus();
        getONTDataStatus();
      }
    }, interval);
  };
  const clearTimer = () => {
    clearInterval(timer);
    timer = undefined;
  };

  // Start timer on component mount, clear timer on unmount
  useEffect(() => {
    startTimer();
  }, []);
  useEffect(() => {
    return () => {
      clearTimer();
    };
  }, []);

  // On Component mount, fetch the Service / Data status
  useEffect(() => {
    getONTServiceStatus();
    getONTDataStatus();
  }, []);

  // When the ONT record changes, check if the last action indicates a status change
  useEffect(() => {
    const lastAction = getProperty(ONTRecord, 'LastAction');

    if (isCheckingStatus && lastAction !== 'CHECK_DATA_REQUEST') {
      setIsCheckingStatus(false);
    }

    if (isProvisioning && lastAction !== 'ACTIVATE_DATA_REQUEST') {
      setIsProvisioning(false);
    }

    if (isDeprovisioning && lastAction !== 'DEACTIVATE_DATA_REQUEST') {
      setIsDeprovisioning(false);
    }

    if (isDeprovisioning && lastAction !== 'RESTART_ONT_REQUEST') {
      setIsRestartingONT(false);
    }
  }, [ONTRecord]);

  const getONTServiceStatus = () => {
    setIsGettingServiceStatus(true);

    const type = 'data';
    httpGet(`ServiceModule/v2.0/network/onu/${type}/${OIRecord.id}/state`)
      .then((res: any) => {
        if (res.data?.data) {
          setServiceStatus(res.data.data);
          setIsGettingServiceStatus(false);
        }
      })
      .catch((err: any) => {
        setServiceStatus(undefined);
        setIsGettingServiceStatus(false);
      });
  };

  const getONTDataStatus = () => {
    setIsGettingDataStatus(true);

    httpGet(`ServiceModule/v2.0/network/onu/device/${OIRecord.id}/state`)
      .then((res: any) => {
        if (res.data?.data) {
          setDataStatus(res.data.data);
          setIsGettingDataStatus(false);
        }
      })
      .catch((err: any) => {
        setDataStatus(undefined);
        setIsGettingDataStatus(false);
      });
  };

  const provisionONT = () => {
    const type = 'data';
    setError(undefined);
    setIsProvisioning(true);
    httpPost(
      `ServiceModule/v2.0/network/onu/${type}/${OIRecord.id}/activate`,
      {},
    )
      .then((res: any) => {
        alertMessage({
          body: `Device is being provisioned, this may take some time.`,
          type: 'success',
        });
        getONTDataStatus();
        getONTServiceStatus();
      })
      .catch((err: any) => {
        setIsProvisioning(false);
        const error = err.response ? err.response.data : undefined;
        setError(error?.message || 'Could not provision device.');
        console.log('debug: provision err', err);
      });
  };

  const restartONT = () => {
    const type = 'data';
    setError(undefined);
    setIsRestartingONT(true);
    httpPost(
      `ServiceModule/v2.0/network/onu/${type}/${OIRecord.id}/restart`,
      {},
    )
      .then((res: any) => {
        alertMessage({
          body: `Device is being restarted, this may take some time.`,
          type: 'success',
        });
        getONTDataStatus();
        getONTServiceStatus();
      })
      .catch((err: any) => {
        setIsRestartingONT(false);
        const error = err.response ? err.response.data : undefined;
        setError(error?.message || 'Could not restert device.');
        console.log('debug: rebooting err', err);
      });
  };

  const checkONTStatus = () => {
    setIsCheckingStatus(true);
    setError(undefined);
    const type = 'data';
    httpPost(`ServiceModule/v2.0/network/onu/${type}/${OIRecord.id}/check`, {})
      .then((res: any) => {
        alertMessage({
          body: `Device is being checked, this can take some time.`,
          type: 'success',
        });
      })
      .catch((err) => {
        setIsCheckingStatus(false);
        const error = err.response ? err.response.data : undefined;
        setError(error?.message || 'Could not check device status.');
        console.log(err);
      });
  };

  const deprovisionONT = () => {
    const type = 'data';
    setError(undefined);
    setIsDeprovisioning(true);
    httpPost(
      `ServiceModule/v2.0/network/onu/${type}/${OIRecord.id}/deactivate`,
      {},
    )
      .then((res: any) => {
        alertMessage({
          body: `Device is being deprovisioned, this may take some time.`,
          type: 'success',
        });
        getONTDataStatus();
        getONTServiceStatus();
      })
      .catch((err: any) => {
        setIsDeprovisioning(false);
        const error = err.response ? err.response.data : undefined;
        setError(error?.message || 'Could not deprovision device.');
      });
  };

  const isMethodEnabled = (method: SupportedMethods) => {
    return (
      (serviceStatus && serviceStatus?.enabledMethods?.includes(method)) ||
      (dataStatus && dataStatus?.enabledMethods?.includes(method))
    );
  };

  const areActionsDisabled = () => {
    return (
      getProperty(ONTRecord, 'LastAction') === 'CHECK_DATA_REQUEST' ||
      getProperty(ONTRecord, 'LastAction') === 'DEACTIVATE_DATA_REQUEST' ||
      getProperty(ONTRecord, 'LastAction') === 'ACTIVATE_DATA_REQUEST' ||
      getProperty(ONTRecord, 'LastAction') === 'RESTART_DATA_REQUEST'
    );
  };

  const isManageLoading = () => {
    return (
      getProperty(ONTRecord, 'LastAction') === 'DEACTIVATE_DATA_REQUEST' ||
      getProperty(ONTRecord, 'LastAction') === 'ACTIVATE_DATA_REQUEST' ||
      getProperty(ONTRecord, 'LastAction') === 'RESTART_DATA_REQUEST'
    );
  };

  const initializeUpdateForm = () => {
    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      SERVICE_MODULE,
      CUSTOMER_DEVICE_ONT,
    );
    if (schema) {
      initializeForm({
        formUUID: uuid,
        title: `Update ${schema.description}`,
        selected: ONTRecord,
        showFormModal: true,
        isCreateReq: false,
        isUpdateReq: true,
        schema: schema,
        sections: [{ name: schema.name, schema: schema }],
      });
    }
  };

  const openNetworkDrawer = () => {
    dispatch({ type: SET_NETWORK_DRAWER_VISIBLE, payload: true });
    dispatch({ type: SET_NETWORK_DRAWER_DEVICE_RECORD, payload: ONTRecord });
    dispatch({ type: SET_NETWORK_DRAWER_ORDER_ITEM_RECORD, payload: OIRecord });
  };

  const removeONTFromOrderItem = () => {
    setIsONTRemovalFlowVisible(true);
    // const OISchema = getSchemaFromShortListByModuleAndEntity(
    //   schemaReducer.shortList,
    //   ORDER_MODULE,
    //   ORDER_ITEM,
    // );
    // const ONTSchema = getSchemaFromShortListByModuleAndEntity(
    //   schemaReducer.shortList,
    //   SERVICE_MODULE,
    //   CUSTOMER_DEVICE_ONT,
    // );
    //
    // if (OISchema && ONTSchema) {
    //   const schemaAssociation = OISchema?.associations.find(
    //     (assoc: any) =>
    //       assoc.childSchemaId === ONTSchema.id ||
    //       assoc.parentSchemaId === ONTSchema.id,
    //   );
    //
    //   if (schemaAssociation) {
    //     deleteAssociation(
    //       {
    //         schema: OISchema,
    //         schemaAssociation: schemaAssociation,
    //         dbRecordAssociationId: ONTRecord?.dbRecordAssociation?.id || '',
    //       },
    //       () => {
    //         onUpdated && onUpdated();
    //       },
    //     );
    //   }
    // }
  };

  const items: MenuProps['items'] = [
    {
      key: '0',
      label: 'Edit',
      onClick: initializeUpdateForm,
      icon: <i className="bi bi-pencil-square" />,
    },
    {
      key: '1',
      label: 'Restart',
      onClick: restartONT,
      icon: <i className="bi bi-arrow-clockwise" />,
    },
    {
      key: '2',
      label: 'Provision',
      disabled: !isMethodEnabled(SupportedMethods.PROVISION),
      onClick: provisionONT,
      icon: <i className="bi bi-box-arrow-right" />,
    },
    {
      key: '3',
      label: 'Deprovision',
      disabled: !isMethodEnabled(SupportedMethods.DEPROVISION),
      onClick: deprovisionONT,
      icon: <i className="bi bi-box-arrow-left" />,
    },
    {
      key: '4',
      label: 'Remove',
      danger: true,
      onClick: removeONTFromOrderItem,
      icon: <i className="bi bi-trash3" />,
    },
  ];

  const isLastCheckStatusDateOlderThanOneDay = (
    record: DbRecordEntityTransform,
  ) => {
    // Create a Luxon DateTime object for the current date and time
    const currentDate = DateTime.now();
    // Subtract 1 day from the current date
    const oneDayAgo = currentDate.minus({ days: 1 });
    // Create a Luxon DateTime object for the date you want to compare
    const dateToCompare = DateTime.fromISO(
      getProperty(ONTRecord, 'LastCheckStatusDate'),
    ); // Replace with your target date
    // Check if dateToCompare is 1 day or more in the past
    return dateToCompare < oneDayAgo;
  };

  const getOverallStatus = () => {
    if (serviceStatus?.color === 'red' || dataStatus?.color === 'red') {
      return <ExclamationCircleTwoTone twoToneColor={'red'} />;
    }

    if (serviceStatus?.color !== 'gray' && dataStatus?.color !== 'gray') {
      return <CheckCircleTwoTone twoToneColor={'#52c41a'} />;
    }
  };

  const ontDetailsSection = () => {
    let tags = [<Tag>STATE UNKNOWN</Tag>];

    const operStatus = getProperty(ONTRecord, 'OperStatus');
    const adminStatus = getProperty(ONTRecord, 'AdminStatus');
    const uptime = getProperty(ONTRecord, 'Uptime');
    const bipErrors = getProperty(ONTRecord, 'BipErrors');
    const estimatedRxPower = getProperty(ONTRecord, 'EstimatedRxPower');
    const rxPower = getProperty(ONTRecord, 'RxPower');
    const serial = getProperty(ONTRecord, 'SerialNumber');
    const model = getProperty(ONTRecord, 'Model');
    const negotiatedSpeed = getProperty(ONTRecord, 'NegotiatedSpeed');

    if (dataStatus || serviceStatus) {
      tags = [];

      if (dataStatus) {
        tags.push(
          <Tag
            icon={
              [
                'CHECKING',
                'REBOOTING',
                'PROVISIONING',
                'DEPROVISIONING',
              ].includes(dataStatus.text) ? (
                <SyncOutlined spin />
              ) : undefined
            }
            color={dataStatus.color}
          >
            {dataStatus.color === 'green' ? <CheckOutlined /> : <></>} DEVICE{' '}
            {dataStatus.text}
          </Tag>,
        );
      }

      if (serviceStatus) {
        tags.push(
          <Tag
            icon={
              [
                'CHECKING',
                'REBOOTING',
                'PROVISIONING',
                'DEPROVISIONING',
              ].includes(serviceStatus.text) ? (
                <SyncOutlined spin />
              ) : undefined
            }
            color={serviceStatus.color}
          >
            {serviceStatus.color === 'green' ? <CheckOutlined /> : <></>}{' '}
            SERVICE {serviceStatus.text}
          </Tag>,
        );
      }
    }

    const details = [
      {
        label: 'Serial #',
        value: serial,
      },
      {
        label: 'Estimated Rx Power',
        value: estimatedRxPower ? `${estimatedRxPower} dBm` : undefined,
      },
      {
        label: 'Rx Power',
        value: rxPower ? `${rxPower} dBm` : undefined,
      },
      {
        label: 'Operating Status',
        value: operStatus,
        validValues: ['up'],
      },
      {
        label: 'Admin Status',
        value: adminStatus,
        validValues: ['up'],
      },
      {
        label: 'Uptime',
        value: uptime,
      },
      {
        label: 'Bip Errors',
        value: bipErrors,
        validValues: ['0'],
      },
      {
        label: 'Model',
        value: model,
      },
      {
        label: 'Negotiated Speed',
        value: `${negotiatedSpeed} Gb/s`,
      },
    ];

    return (
      <DeviceListSection name={'Provision'} tags={tags} details={details} />
    );
  };

  const metaSection = () => {
    const lastAction = getProperty(ONTRecord, 'LastAction');
    const lastActionDate = getProperty(ONTRecord, 'LastActionDate');
    const lastCheckDate = getProperty(ONTRecord, 'LastCheckStatusDate');

    const details = [
      {
        label: 'Last Action',
        value:
          lastAction && lastActionDate
            ? `${lastAction} at ${DateTime.fromISO(lastActionDate).toFormat(
                'd/M/yyyy h:mm a',
              )}`
            : undefined,
      },
      {
        label: 'Last Check Status at',
        value: lastCheckDate
          ? DateTime.fromISO(lastActionDate).toFormat('d/M/yyyy h:mm a')
          : undefined,
      },
    ];

    return <DeviceListSection details={details} columns={1} />;
  };

  return (
    <div
      style={{
        borderRadius: 2,
        background: '#f5f5f5',
        border: '1px solid #e2e2e2',
        padding: '15px 15px',
      }}
    >

      <RemoveONTDeviceFlow
          isVisible={isONTRemovalFlowVisible}
          record={ONTRecord!}
          onClose={() => setIsONTRemovalFlowVisible(false)}
          onSuccess={() => {
            setIsONTRemovalFlowVisible(false);
            onUpdated && onUpdated();
          }}
      />

      <Row justify="space-between" align="middle">
        <Col>
          <ModuleEntityIcon
            moduleName={SERVICE_MODULE}
            entityName={CUSTOMER_DEVICE_ONT}
          />
          <div
            style={{
              position: 'absolute',
              left: 18,
              bottom: -12,
              fontSize: 20,
            }}
          >
            {getOverallStatus()}
          </div>
          <span style={{ fontWeight: 600 }}>ONT Device</span>
        </Col>
        <Col style={{ marginTop: isMobile ? 15 : 0 }}>
          {/* Dropdown Button - Manage */}
          <Dropdown menu={{ items }} trigger={['click']}>
            <Button
              type="primary"
              size="small"
              ghost
              style={{ marginRight: 10 }}
              loading={
                isManageLoading() ||
                isProvisioning ||
                isDeprovisioning ||
                isRestartingONT
              }
              disabled={
                areActionsDisabled() ||
                isCheckingStatus ||
                isProvisioning ||
                isDeprovisioning ||
                isRestartingONT ||
                state.runningBulkONTCheck
              }
            >
              <Space>
                Manage
                <CaretDownFilled />
              </Space>
            </Button>
          </Dropdown>

          {/* Button Details */}
          <Button
            onClick={openNetworkDrawer}
            size="small"
            style={{ marginRight: 10 }}
            type="primary"
            ghost
            disabled={areActionsDisabled()}
          >
            Show Details
          </Button>

          {/* Button - Run Check */}
          <Button
            icon={<i className="bi bi-check2-circle" />}
            type="primary"
            size="small"
            onClick={checkONTStatus}
            disabled={
              areActionsDisabled() ||
              isCheckingStatus ||
              isProvisioning ||
              isDeprovisioning ||
              isRestartingONT ||
              state.runningBulkONTCheck
            }
            loading={
              getProperty(ONTRecord, 'LastAction') === 'CHECK_DATA_REQUEST' ||
              isCheckingStatus ||
              state.runningBulkONTCheck
            }
          >
            Run Check
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Divider
            style={{ marginTop: 15, marginBottom: 10, borderColor: '#e2e2e2' }}
          />
        </Col>
        <Col xs={24} md={24}>
          {/* Notifications */}
          {isLastCheckStatusDateOlderThanOneDay(ONTRecord) && (
            <Alert
              style={{ marginBottom: 10 }}
              type={'warning'}
              message={
                'The last check status date is older than 1 day. Please run a check to update the status.'
              }
            />
          )}

          {getProperty(ONTRecord, 'LastAction')?.indexOf('ERROR') > -1 && (
            <Alert
              style={{ marginBottom: 10 }}
              type={'error'}
              message={`The last action performed had an error: ${getProperty(
                ONTRecord,
                'LastAction',
              )} at ${DateTime.fromISO(
                getProperty(ONTRecord, 'LastActionDate'),
              ).toFormat('M/d/yyyy h:mm a ZZZZ')}`}
            />
          )}

          {ontDetailsSection()}

          <Divider style={{ marginTop: 10, marginBottom: 15 }} />

          {metaSection()}
        </Col>
      </Row>

      {/* Error Message */}
      {error && (
        <Row style={{ marginTop: 15 }}>
          <Col span={24}>
            <Alert
              showIcon
              type="error"
              message={error}
              closable
              onClose={() => setError(undefined)}
            />
          </Col>
        </Row>
      )}

      {/* Form */}
      <CoreForm
        type="MODAL"
        formUUID={uuid}
        onSubmitEvent={(params: { event: string; res: any }) => {
          onUpdated && onUpdated();
        }}
      />
    </div>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any, ownProps: any) => ({
  alertMessage: (params: { body: string; type: string }) =>
    dispatch(displayMessage(params)),
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  deleteAssociation: (params: IDeleteRecordAssociation, cb: () => {}) =>
    dispatch(deleteRecordAssociationById(params, cb)),
});

export default connect(mapState, mapDispatch)(ActivatedONT);
