import moment from "moment";

export const isUserTokenExpired = () => {
    const tokenExpiresAt = localStorage.getItem(`tokenExpiresAt`);
    const isAfter = moment(moment().add(10, 'minutes').toISOString()).isAfter(tokenExpiresAt);
    const userToken = localStorage.getItem(`token`);
    if (isAfter || !tokenExpiresAt || !userToken) {
        localStorage.removeItem(`token`);
        localStorage.removeItem(`tokenExpiresAt`);
        return true;
    }

    return false;
};