import { Table } from 'antd';
import { DateTime } from 'luxon';
import React from 'react';

function MeshNodeList(params: { data: any }) {
  const { data } = params;

  const formatDate = (date: string) => {
    if (date) {
      return DateTime.fromISO(date).toFormat('M/d/yyyy h:mm a ZZZZ');
    } else {
      return '-';
    }
  };

  const columns = [
    {
      title: 'Location',
      dataIndex: 'location',
    },
    {
      title: 'Gateway',
      dataIndex: 'gateway',
      render: (text: any) => String(text),
    },
    {
      title: 'Serial',
      dataIndex: 'serial',
    },
    {
      title: 'IP Address',
      dataIndex: 'ip_address',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Mesh Bars',
      dataIndex: 'mesh_quality_bars',
    },
    {
      title: 'Wired',
      dataIndex: 'wired',
      render: (text: any) => (text ? String(text) : '-'),
    },
    {
      title: 'Last Reboot',
      dataIndex: 'last_reboot',
      render: (text: any) => formatDate(text),
    },
    {
      title: 'Heart Beat',
      dataIndex: 'heartbeat_ok',
      render: (text: any) => (String(text) === 'true' ? 'OK' : 'Not OK'),
    },
  ];

  return <Table size="small" columns={columns} dataSource={data} />;
}

export default MeshNodeList;
