import { SchemaColumnEntity } from '@d19n/models/dist/schema-manager/schema/column/schema.column.entity';
import { SchemaEntity } from '@d19n/models/dist/schema-manager/schema/schema.entity';

export const copyTextToClipboard = (
  text: any,
  message: string,
  alertMessage: Function,
) => {
  navigator.clipboard.writeText(text);
  alertMessage({ body: message, type: 'success' });
};

/**
 * Get all JSON columns from schema
 * @param schema
 */
export const getJSONColumnsFromSchema = (
  schema: SchemaEntity | undefined,
): SchemaColumnEntity[] => {
  if (schema && schema.columns?.length > 0) {
    return schema.columns?.filter(
      (column: SchemaColumnEntity) => column.type === 'JSON',
    );
  } else {
    return [];
  }
};
