import {DbRecordEntityTransform} from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {getProperty} from '@d19n/models/dist/schema-manager/helpers/dbRecordHelpers';
import {SchemaEntity} from '@d19n/models/dist/schema-manager/schema/schema.entity';
import {SchemaModuleEntityTypeEnums} from '@d19n/models/dist/schema-manager/schema/types/schema.module.entity.types';
import {SchemaModuleTypeEnums} from '@d19n/models/dist/schema-manager/schema/types/schema.module.types';
import {Col, Modal, Row, Upload, UploadFile, Image} from 'antd';
import {RcFile, UploadProps} from 'antd/lib/upload';
import {FC, useEffect, useState} from 'react';
import {isMobile} from 'react-device-detect';
import {connect} from 'react-redux';
import {getHostName} from '../../../../../shared/http/helpers';
import {httpGet} from '../../../../../shared/http/requests';
import {
    getOdinSchemaByEntity,
    getOdinSchemaById,
    getSchemaFromShortListByModuleAndEntity,
    getSchemaFromShortListBySchemaId,
} from '../../../../../shared/utilities/schemaHelpers';
import {
    getSchemaByModuleAndEntityRequest,
    ISchemaByModuleAndEntity,
} from '../../../../schemas/store/actions';
import {
    DeleteSchemaAssociation,
    deleteSchemaAssociationsRequest,
} from '../../../../schemasAssociations/store/actions';
import {deleteRecordByIdRequest} from '../../../store/actions';
import {beforeUpload, getBase64} from '../helpers';
import './styles.scss';
import {Icon} from '@blueprintjs/core';
import {displayMessage} from '../../../../../shared/system/messages/store/reducers';

const {SCHEMA_MODULE} = SchemaModuleTypeEnums;
const {FILE} = SchemaModuleEntityTypeEnums;

const MAX_SIMULTANEOUS_UPLOADS = 10;
const MAX_TOTAL_UPLOADS = 50;

export type TFileFormMode = 'FILE_MULTIPLE' | 'FILE_SINGLE';

interface Props {
    mode: TFileFormMode;
    fileIds?: string[];
    readOnly?: boolean;
    deleteAssociation: Function;
    getSchema: Function;
    deleteRecord: Function;
    schemaReducer: any;
    onUpdate?: Function; // On every file add/remove we return complete list of file ids
    parentSchemaId: string | undefined;
    onDelete?: Function;
    thumbnailSize?: 'small' | 'medium' | 'large';
    fullWidth?: boolean;
    withoutBorder?: boolean;
    alertMessage: (params: { body: string; type: string }) => void;
}

const FileFormField: FC<Props> = (props: Props) => {
    const {
        readOnly,
        onUpdate,
        fileIds,
        mode,
        parentSchemaId,
        getSchema,
        schemaReducer,
        deleteRecord,
        thumbnailSize,
        withoutBorder,
        fullWidth,
        alertMessage,
    } = props;
    const [parentSchema, setParentSchema] = useState<SchemaEntity | undefined>(
        undefined,
    );
    const [fileSchema, setFileSchema] = useState<SchemaEntity | undefined>(
        undefined,
    );
    const [previewVisible, setPreviewVisible] = useState<boolean>(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [loadingInitialFiles, setLoadingInitialFiles] =
        useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const [files, setFiles] = useState<UploadFile[]>([]);

    const uploadButton = () => (
        <div className={`uploadButton ${error ? 'error' : ''}`}>
            <Icon icon="upload" color={error ? '#CC4246' : '#2D72D2'}/>
            <div
                style={{
                    marginTop: thumbnailSize === 'small' ? 2 : 8,
                    fontWeight: 500,
                }}
            >
                {mode === 'FILE_MULTIPLE' ? 'Upload Files' : 'Upload File'}
            </div>
            {mode === 'FILE_MULTIPLE' ? (
                <span style={{fontSize: 10, opacity: 0.8}}>
          (Max {MAX_TOTAL_UPLOADS} files)
        </span>
            ) : (
                <span style={{fontSize: 10, opacity: 0.8}}>(Max 1 file)</span>
            )}
        </div>
    );

    // Fetch file schema on component mount
    useEffect(() => {
        if (!fileSchema) {
            const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
                schemaReducer.shortList,
                SCHEMA_MODULE,
                FILE,
            );

            if (shortlistSchema) {
                setFileSchema(shortlistSchema);
            } else {
                getSchema(
                    {moduleName: SCHEMA_MODULE, entityName: FILE},
                    (res: SchemaEntity) => {
                        setFileSchema(res);
                    },
                );
            }
        }
    }, []);

    // Fetch file schema on component mount
    useEffect(() => {
        if (!fileSchema) {
            getFileSchema()
        }
    }, [])
    const getFileSchema = async () => {
        const schema = await getOdinSchemaByEntity(SCHEMA_MODULE, FILE);
        if (schema) {
            setFileSchema(schema);
        }
    }

    // Fetch parent schema once it's ID comes into the component
    useEffect(() => {
        if (parentSchemaId && !parentSchema) {
            fetchParentSchema(parentSchemaId)
        }
    }, [parentSchemaId]);
    const fetchParentSchema = async (parentSchemaId: string) => {
        const schema = await getOdinSchemaById(parentSchemaId);
        if (schema) {
            setParentSchema(schema);
        }
    }

    // Get files by ids on component mount
    useEffect(() => {
        if (fileIds && fileIds?.length > 0) {
            getFilesByIds(fileIds);
        }
    }, []);

    // Update parent component with list of file ids every file list change (upload/remove)
    useEffect(() => {
        if (onUpdate) {
            const fileIds = files.map(
                (file: any) => file.id || file.response?.data?.id,
            );
            onUpdate(fileIds);
        }
    }, [files]);

    const getFilesByIds = (fileIds: string[]) => {
        if (fileIds && fileIds.length > 0) {
            setLoadingInitialFiles(true);
            httpGet(`SchemaModule/v1.0/db/File/many?ids=${fileIds.join(',')}`).then(
                (response: any) => {
                    setLoadingInitialFiles(false);

                    const newFileList = response.data?.data?.map(
                        (file: DbRecordEntityTransform) => {
                            return {
                                name: file.title,
                                status: 'done',
                                url: getProperty(file, 'Url'),
                                thumbUrl: getProperty(file, 'Url'),
                                percent: 100,
                                uid: file.id,
                                id: file.id,
                                type: getProperty(file, 'MimeType'),
                            };
                        },
                    );
                    setFiles(newFileList);
                },
            );
        }
    };

    const handlePreview = async (file: UploadFile) => {
        setPreviewImage('');
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }
        setPreviewImage(file.url || file.preview || '');
        setPreviewVisible(true);
        setPreviewTitle(
            file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1),
        );
    };

    const handleChange: UploadProps['onChange'] = (info: any) => {
        setFiles(info.fileList);
        if (info.file?.status === 'done') {
            const fileDbRecord: DbRecordEntityTransform = info.file.response.data;
        }
    };

    // We need to show confirmation when user removes the file, because this
    // file record then needs to be removed from the existing associations
    const onGalleryFileRemove = async (file: any): Promise<boolean> => {
        const {confirm} = Modal;
        return new Promise((resolve, reject) => {
            confirm({
                title: 'Removing file',
                content: 'Removing this file form the form will permanently delete it.',
                onOk: () => {
                    props.onDelete && props.onDelete(file.id || file.response?.data?.id);
                    resolve(true);
                    deleteRecord({
                        schema: fileSchema,
                        recordId: file.id || file.response?.data?.id,
                    });
                    setError(false);
                },
                onCancel: () => {
                    reject(true);
                },
            });
        });
    };

    const shouldShowUploadButton = () => {
        if (readOnly) {
            return false;
        } else if (mode === 'FILE_SINGLE' && files.length === 1) {
            return false;
        } else if (mode === 'FILE_SINGLE' && files.length > 1) {
            return false;
        } else {
            return true;
        }
    };

    return (
        <Row>
            <Col span={isMobile || fullWidth ? 24 : 12}>
                {readOnly ? (
                    <>
                        {files?.map((file: any) => (
                            <Image src={file.url} width={'100%'} height={'auto'}/>
                        ))}
                    </>
                ) : (
                    <>
                        <Upload
                            className={`upload-list-inline fileFormFieldUpload ${
                                readOnly || (mode === 'FILE_SINGLE' && files.length > 0)
                                    ? 'readOnly'
                                    : ''
                            }${thumbnailSize === 'medium' ? ' mediumThumbnail' : ''}${
                                thumbnailSize === 'large' ? ' largeThumbnail' : ''
                            }${withoutBorder ? ' withoutBorder' : ''} ${
                                thumbnailSize === 'small' ? ' smallThumbnail' : ''
                            }`}
                            multiple={mode === 'FILE_MULTIPLE'}
                            name={'file'}
                            fileList={files}
                            maxCount={mode === 'FILE_SINGLE' ? 1 : MAX_TOTAL_UPLOADS} // Max uploads in multiple file mode
                            disabled={readOnly || loadingInitialFiles}
                            action={`${getHostName()}/SchemaModule/v1.0/s3/files/upload`}
                            headers={{
                                Authorization: 'Bearer ' + localStorage.getItem(`token`),
                            }}
                            data={{
                                device: isMobile ? 'MOBILE' : 'DESKTOP',
                                applicationVersion: process.env.REACT_APP_VERSION || '',
                            }}
                            onChange={handleChange}
                            onPreview={handlePreview}
                            onRemove={onGalleryFileRemove}
                            beforeUpload={(file: any, fileList: any[]) => {
                                // In multiple file mode, allow max uploads per batch
                                if (
                                    mode === 'FILE_MULTIPLE' &&
                                    fileList.length > MAX_SIMULTANEOUS_UPLOADS
                                ) {
                                    alertMessage({
                                        body: `You can upload maximum ${MAX_SIMULTANEOUS_UPLOADS} files at a time.`,
                                        type: 'error',
                                    });
                                    setError(true);
                                    return Upload.LIST_IGNORE;
                                }
                                // Watch for total cap on uploads
                                else if (
                                    mode === 'FILE_MULTIPLE' &&
                                    (files.length > MAX_TOTAL_UPLOADS ||
                                        fileList.length + files.length > MAX_TOTAL_UPLOADS)
                                ) {
                                    alertMessage({
                                        body: `You can upload maximum ${MAX_SIMULTANEOUS_UPLOADS} files per form field.`,
                                        type: 'error',
                                    });
                                    setError(true);
                                    return Upload.LIST_IGNORE;
                                }

                                // Else, just upload
                                else {
                                    setError(false);
                                    beforeUpload(file) as any;
                                }
                            }}
                            listType="picture"
                        >
                            {shouldShowUploadButton() ? uploadButton() : <></>}
                        </Upload>
                        <Image
                            width={200}
                            style={{display: 'none', border: 'none'}}
                            src={previewImage}
                            preview={{
                                visible: previewVisible,
                                src: previewImage,
                                onVisibleChange: (value) => {
                                    setPreviewVisible(value);
                                },
                            }}
                        />
                    </>
                )}
            </Col>
        </Row>
    );
};

const mapState = (state: any) => ({
    schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
    getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
        dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
    deleteAssociation: (params: DeleteSchemaAssociation) =>
        dispatch(deleteSchemaAssociationsRequest(params)),
    deleteRecord: (payload: any, cb: any) =>
        dispatch(deleteRecordByIdRequest(payload, cb)),
    alertMessage: (params: { body: string; type: string }) =>
        dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(FileFormField);
