import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const currentVersion = process.env.REACT_APP_VERSION;

type Props = RouteComponentProps & {
  history: any;
  navigationReducer: any;
};

class CacheBuster extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
    };
  }

  /* Compare two SemVer identifiers. */
  semverGreaterThan = (versionA: any, versionB: any) => {
    const versionsA = versionA.split(/\./g);
    const versionsB = versionB.split(/\./g);

    while (versionsA.length || versionsB.length) {
      const a = Number(versionsA.shift());
      const b = Number(versionsB.shift());
      if (a === b) continue;
      return a > b || isNaN(b);
    }
    return false;
  };

  refreshCacheAndReload = async () => {
    if (caches) {
      const names = await caches.keys();
      await Promise.all(names.map((name) => caches.delete(name))).then(() => {
        window.location.reload();
      });
    }
  };

  compareCacheVersion = () => {
    if (currentVersion)
      fetch('/meta.json', { cache: 'no-cache' })
        .then((response) => response.json())
        .then((meta) => {
          const latestVersion = meta.version;
          const shouldForceRefresh = this.semverGreaterThan(
            latestVersion,
            currentVersion,
          );
          if (shouldForceRefresh) {
            console.log(
              `%c[CACHE] There is a new app version ${latestVersion}, refreshing current version ${currentVersion}.`,
              'color:red',
            );
            this.setState({ loading: false, isLatestVersion: false });
            this.refreshCacheAndReload();
          } else {
            console.log(
              `%c[CACHE] App already at latest version ${latestVersion}.`,
              'color:limegreen',
            );
            this.setState({ loading: false, isLatestVersion: true });
          }
        })
        .catch(function (error) {
          console.log(
            `%c[CACHE] Error fetching latest app version.`,
            'color:red',
            error,
          );
        });
  };

  /* Compare cached versions on route change. */
  componentDidUpdate(prevProps: Readonly<Props>) {
    if (
      prevProps.navigationReducer.previousPage !==
      this.props.navigationReducer.previousPage
    )
      this.compareCacheVersion();
  }

  componentDidMount() {
    this.compareCacheVersion();
  }

  render() {
    return <></>;
  }
}

const mapState = (state: any) => ({
  navigationReducer: state.navigationReducer,
});

export default withRouter(connect(mapState)(CacheBuster));
