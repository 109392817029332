import { SchemaColumnOptionEntity } from '@d19n/models/dist/schema-manager/schema/column/option/schema.column.option.entity';
import { SchemaEntity } from '@d19n/models/dist/schema-manager/schema/schema.entity';
import { TableHeaderColumn } from '../../core/records/components/DynamicTable/helpers/configureColumns';
import { SchemaTypeEntity } from '@d19n/models/dist/schema-manager/schema/types/schema.type.entity';
import {getSchemaByIdRequest, getSchemaByModuleAndEntityRequest} from "../../core/schemas/store/actions";
import {store} from "../../index";
import {DbRecordEntityTransform} from "@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform";
import {getModuleAndEntityNameFromRecord} from "./recordHelpers";


// Get schema by schema ID
//
// This method should return shortlist schema if found, otherwise it should return
// schema from API. If schema is found in shortlist, fetch the schema from API anyway,
// to update the shortlist in the schema reducer.
export const getOdinSchemaById = async (schemaId: string): Promise<SchemaEntity> => {
  const state = store.getState();
  const schemaReducer = state.schemaReducer;

  return new Promise((resolve, reject) => {
    const shortlistSchema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, schemaId);

    const getSchema = () => {
      store.dispatch(
          getSchemaByIdRequest({ schemaId: schemaId }, (response: SchemaEntity) => {
            if (response) {
              resolve(response);
            } else {
              reject('Schema not found');
            }
          }),
      );
    };

    if (shortlistSchema) {
      resolve(shortlistSchema);
    } else {
      getSchema();
    }
  });
};

// Get schema by record
//
// This method should return shortlist schema if found, otherwise it should return
// schema from API. If schema is found in shortlist, fetch the schema from API anyway,
// to update the shortlist in the schema reducer.
export const getOdinSchemaByRecord = async (
    record: DbRecordEntityTransform,
): Promise<SchemaEntity> => {
  const state = store.getState();
  const schemaReducer = state.schemaReducer;

  return new Promise((resolve, reject) => {
    const { moduleName, entityName } = getModuleAndEntityNameFromRecord(record);
    const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
        schemaReducer.shortList,
        moduleName,
        entityName,
    );

    const getSchema = () => {
      store.dispatch(
          getSchemaByModuleAndEntityRequest(
              { moduleName: moduleName, entityName: entityName },
              (response: SchemaEntity) => {
                if (response) {
                  resolve(response);
                } else {
                  reject('Schema not found');
                }
              },
          ),
      );
    };

    if (shortlistSchema) {
      resolve(shortlistSchema);
    } else {
      getSchema();
    }
  });
};

// Get schema by module and entity name
//
// This method should return shortlist schema if found, otherwise it should return
// schema from API. If schema is found in shortlist, fetch the schema from API anyway,
// to update the shortlist in the schema reducer.
export const getOdinSchemaByEntity = async (
    moduleName: string,
    entityName: string,
): Promise<SchemaEntity> => {
  const state = store.getState();
  const schemaReducer = state.schemaReducer;

  return new Promise((resolve, reject) => {
    const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
        schemaReducer.shortList,
        moduleName,
        entityName,
    );

    const getSchema = () => {
      store.dispatch(
          getSchemaByModuleAndEntityRequest(
              { moduleName: moduleName, entityName: entityName },
              (response: SchemaEntity) => {
                if (response) {
                  resolve(response);
                } else {
                  reject('Schema not found');
                }
              },
          ),
      );
    };

    if (shortlistSchema) {
      resolve(shortlistSchema);
    } else {
      getSchema();
    }
  });
};


// Check if schema has type with name
export const schemaHasType = (schema: SchemaEntity, typeName: string) => {
  if (schema && schema.types?.length! > 0 && typeName) {
    return schema.types?.find(
      (schemaType: SchemaTypeEntity) => schemaType.name === typeName,
    );
  } else {
    return false;
  }
};

export const sortByName = (
  a: SchemaColumnOptionEntity,
  b: SchemaColumnOptionEntity,
) => {
  return Number(a.label) - Number(b.label);
};

export const getSchemaFromShortListByModuleAndEntity = (
  shortList: { [key: string]: SchemaEntity },
  moduleName: string | undefined,
  entityName: string | undefined,
): SchemaEntity | undefined => {
  const keys = Object.keys(shortList);
  for (const key of keys) {
    const schema = shortList[key];
    if (
      schema?.moduleName === moduleName &&
      schema?.entityName === entityName
    ) {
      return schema;
    }
  }
};

export const getSchemaFromShortListBySchemaId = (
  shortList: { [key: string]: SchemaEntity },
  schemaId: string | null | undefined,
): SchemaEntity | undefined => {
  return schemaId ? shortList[schemaId] : undefined;
};

export const sortByPosition = (
  a: SchemaColumnOptionEntity,
  b: SchemaColumnOptionEntity,
) => {
  return Number(a.position) - Number(b.position);
};

/**
 *  formats schema columns into elastic search index mappings
 * @param schema
 */
export const getElasticSearchKeysFromSchemaColumn = (
  schema: SchemaEntity,
  schemaTypeId?: string,
): TableHeaderColumn[] => {
  return schema?.columns
    ?.filter(
      (col) =>
        col.isVisibleInTables &&
        (col.schemaTypeId === schemaTypeId || !col.schemaTypeId),
    )
    .map((col) => ({
      title: col.label ? col.label : '',
      dataIndex: `properties.${col.name}`,
      columnType: col.type,
      position: col.position,
      isTitleColumn: col.isTitleColumn ? col.isTitleColumn : false,
    }));
};

/**
 * Sort schema column options
 *
 * Supports: strings and numbers
 * @param a
 * @param b
 */
export const sortOptions = (
  a: SchemaColumnOptionEntity,
  b: SchemaColumnOptionEntity,
) => {
  if (!isNaN(Number(a.value))) {
    return Number(a.value) - Number(b.value);
  } else {
    return a.value.localeCompare(b.value);
  }
};
