import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/models/dist/schema-manager/schema/schema.entity';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getAllSchemaAssociationSchemas } from '../../../../shared/utilities/recordHelpers';
import { schemaHasType } from '../../../../shared/utilities/schemaHelpers';
import { SchemaAssociationEntity } from '@d19n/models/dist/schema-manager/schema/association/schema.association.entity';
import { SchemaAssociationSchemaTypesConstraintEntity } from '@d19n/models/dist/schema-manager/schema/association/constraint/schema.association.schema.types.constraint.entity';
import { httpGet } from '../../../../shared/http/requests';
import { Col, Row, Table } from 'antd';
import { Button } from '@blueprintjs/core';
import { initializeRecordForm } from '../Forms/store/actions';
import { v4 as uuidv4 } from 'uuid';
import CoreForm from '../Forms/CoreForm';
interface Props {
  schemaReducer: any;
  parentRecord: DbRecordEntityTransform;
  parentSchema: SchemaEntity;
  initializeForm: Function;
}

const uuid = uuidv4();

const OutcomeFormList: React.FC<Props> = (props: Props) => {
  const { schemaReducer, parentRecord, parentSchema, initializeForm } = props;
  const [schemasWithOutcomeForms, setSchemasWithOutcomeForms] = useState<
    SchemaEntity[]
  >([]);
  const [outcomeFormRecords, setOutcomeFormRecords] = useState<
    DbRecordEntityTransform[]
  >([]);

  // When parent schema is available, get all schemas with outcome forms
  useEffect(() => {
    if (parentSchema) {
      const parentRecordType = parentRecord?.type;
      const relatedSchemas = getAllSchemaAssociationSchemas(
        parentSchema?.associations,
        [],
      );
      const outcomeSchemas: SchemaEntity[] = relatedSchemas?.filter(
        (relatedSchema: SchemaEntity) =>
          schemaHasType(relatedSchema, 'OUTCOME_FORM'),
      );

      if (outcomeSchemas.length > 0) {
        let filteredOutcomeSchemas = Object.assign(outcomeSchemas);

        // Exclude schemas with incompatible type constraints, leave schemas that have no type constraints defined.
        if (parentSchema && parentRecordType) {
          const associations = parentSchema.associations;
          associations.map((assoc: SchemaAssociationEntity) => {
            if (assoc.schemaTypesConstraints.length > 0) {
              assoc.schemaTypesConstraints.map(
                (constraint: SchemaAssociationSchemaTypesConstraintEntity) => {
                  if (constraint.parentSchemaType?.name !== parentRecordType) {
                    filteredOutcomeSchemas = filteredOutcomeSchemas.filter(
                      (schema: SchemaEntity) =>
                        schema.id !== assoc.childSchemaId,
                    );
                  }
                },
              );
            }
          });
        }

        setSchemasWithOutcomeForms(filteredOutcomeSchemas);
      }
    }
  }, [parentSchema]);

  // When schemas with outcome forms are available, get all outcome form records
  useEffect(() => {
    if (schemasWithOutcomeForms.length > 0) {
      getAllOutcomeForms();
    }
  }, [schemasWithOutcomeForms]);

  // Fetch all nested outcome forms for the parent record
  const getAllOutcomeForms = () => {
    if (schemasWithOutcomeForms.length > 0) {
      setOutcomeFormRecords([]);
      const outcomeSchemaEntityNames = schemasWithOutcomeForms.map(
        (schema) => schema.entityName,
      );

      const URL = `${parentSchema.moduleName}/v1.0/db-associations/${
        parentSchema.entityName
      }/${parentRecord.id}/relations?entities=${JSON.stringify(
        outcomeSchemaEntityNames,
      )}&withLinks=false`;

      httpGet(URL).then((res: any) => {
        outcomeSchemaEntityNames.map((entityName: string) => {
          const records = res.data.data[entityName]?.dbRecords;
          if (records) {
            // console.log('debug: All outcome form records', records);
            // append to outcomeFormRecords
            setOutcomeFormRecords((prevRecords: DbRecordEntityTransform[]) => {
              return [...prevRecords, ...records];
            });
          }
        });
      });
    }
  };

  const initializeUpdateForm = (record: DbRecordEntityTransform) => {
    const schema = schemasWithOutcomeForms.find(
      (schema: SchemaEntity) => schema.id === record.schemaId,
    );

    if (schema) {
      initializeForm({
        formUUID: uuid,
        title: `Update ${schema.name}`,
        selected: record,
        showFormModal: true,
        isCreateReq: false,
        isUpdateReq: true,
        schema: schema,
        sections: [
          {
            name: schema.name,
            schema: schema,
          },
        ],
      });
    }
  };

  const columns: any[] = [
    {
      title: 'Form',
      dataIndex: 'recordNumber',
      width: '70%',
      key: 'recordNumber',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '30%',
      align: 'right',
      render: (text: string, record: DbRecordEntityTransform) => {
        return (
          <Button
            small
            intent="primary"
            minimal
            text="Edit"
            icon="annotation"
            onClick={() => initializeUpdateForm(record)}
          />
        );
      },
    },
  ];

  const tableData = outcomeFormRecords.map(
    (record: DbRecordEntityTransform) => {
      return {
        ...record,
        key: record.id,
      };
    },
  );

  return (
    <>
      {outcomeFormRecords.length > 0 ? (
        <Row>
          <Col span={24}>
            <Table size="small" dataSource={tableData} columns={columns} />
          </Col>
        </Row>
      ) : (
        <></>
      )}
      {/* Form */}
      <CoreForm
        type="MODAL"
        formUUID={uuid}
        onSubmitEvent={getAllOutcomeForms}
      />
    </>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});
const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
});

export default connect(mapState, mapDispatch)(OutcomeFormList);
