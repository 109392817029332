import { SchemaEntity } from '@d19n/models/dist/schema-manager/schema/schema.entity';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { IGetSchemaById } from '@d19n/models/dist/rabbitmq/rabbitmq.interfaces';
import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';

import { EyeOutlined } from '@ant-design/icons';
import { QGISBuildStatusEnum } from '@d19n/common/dist/com.netomnia/auto-splicing/interfaces/qgis.interfaces';
import { getProperty } from '@d19n/models/dist/schema-manager/helpers/dbRecordHelpers';
import { Button, Col, Row, Spin, Tooltip } from 'antd';
import { isMobile } from 'react-device-detect';
import { getSchemaFromShortListBySchemaId } from '../../../shared/utilities/schemaHelpers';
import {
  IAddRecordToShortList,
  addRecordToShortList,
} from '../../records/store/actions';
import { getSchemaByIdRequest } from '../../schemas/store/actions';
import {
  ISetPermissionToFail,
  setPermissionToFail,
} from '../../userInterface/store/actions';
import {
  IGetRecordAssociations,
  getRecordAssociationsRequest,
} from '../store/actions';

interface Props {
  parentRecord: DbRecordEntityTransform | undefined;
  associationEntityName: string;
  associationModuleName: string;
  associationType?: string;
  recordColumns?: string[]; // Pass up to 2 record columns to show
  schemaReducer: any;
  getAssociations: (params: IGetRecordAssociations, cb?: any) => void;
  getSchemaById: Function;
  shortListRecord: (params: IAddRecordToShortList) => {};
  showAllRecords?: boolean;
  customRowAction?: Function;
  overrideTitle?: string;
  hideHeader?: boolean;
  hideQuickView?: boolean;
  setPermissionToFail: (params: ISetPermissionToFail) => void;
}

let timer: NodeJS.Timeout | undefined = undefined;
const interval = 6000; // 1 second

const AssociationSimpleList: FC<Props> = (props: Props) => {
  const {
    getAssociations,
    getSchemaById,
    parentRecord,
    associationEntityName,
    associationType,
    schemaReducer,
    recordColumns,
    shortListRecord,
    showAllRecords,
    customRowAction,
    overrideTitle,
    hideHeader,
    hideQuickView,
    setPermissionToFail,
  } = props;

  const [schema, setSchema] = useState<SchemaEntity | undefined>(undefined);
  const [associatedRecords, setAssociatedRecords] = useState<
    DbRecordEntityTransform[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isViewingMore, setIsViewingMore] = useState<boolean>(false);

  // On Component mount fetch the schema
  useEffect(() => {
    if (parentRecord) {
      getSchema();
    }
  }, [parentRecord]);

  // When schema is available, fetch the record associations
  useEffect(() => {
    if (schema && parentRecord) {
      getAssociatedRecords();
    }
  }, [schema]);

  // On component mount initialize 3000ms timer for record polling
  const startTimer = () => {
    timer = setInterval(() => {
      if (!document.hidden && parentRecord) {
        getAssociatedRecords();
      }
    }, interval);
  };
  useEffect(() => {
    if (parentRecord) {
      startTimer();
    }
  }, [parentRecord]);

  useEffect(() => {
    if (!timer) {
      const id = setInterval(() => {
        getAssociatedRecords();
      }, 6000);
      timer = id;
    }
  }, []);

  const clearTimer = () => {
    clearInterval(timer);
    timer = undefined;
  };
  // On component unmount remove the timer
  useEffect(() => {
    return () => {
      clearTimer();
    };
  }, []);

  const getSchema = () => {
    const shortListSchema = getSchemaFromShortListBySchemaId(
      schemaReducer.shortList,
      parentRecord?.schemaId,
    );

    if (shortListSchema) {
      setSchema(shortListSchema);
    } else {
      getSchemaById({ schemaId: parentRecord?.schemaId }, (response: any) => {
        setSchema(response);
      });
    }
  };

  const getAssociatedRecords = () => {
    getAssociations(
      {
        recordId: parentRecord?.id,
        key: associationEntityName,
        schema: schema!,
        entities: [associationEntityName],
      },
      (res: any) => {
        setIsLoading(false);

        if (
          res &&
          res.results?.[associationEntityName]?.dbRecords?.length > 0
        ) {
          let filteredRecords: DbRecordEntityTransform[] =
            res.results?.[associationEntityName]?.dbRecords;

          if (associationType) {
            filteredRecords = filteredRecords.filter(
              (rec: DbRecordEntityTransform) => rec.type === associationType,
            );
          }

          setAssociatedRecords(filteredRecords);
        } else {
          setAssociatedRecords([]);
          if (associationEntityName === 'PermissionToFail') {
            setPermissionToFail({ permissionToFail: undefined });
          }
        }
      },
    );
  };

  const listHeader = () => {
    return (
      <Col
        span={24}
        style={{
          marginBottom: 5,
          background: '#f4f4f4',
          padding: '3px 5px',
          fontWeight: 500,
          borderRadius: 3,
        }}
      >
        <span>{`${overrideTitle || associationEntityName} ${
          associationType ? `(${associationType})` : ''
        }`}</span>
      </Col>
    );
  };

  const truncate = (str: any) => {
    return str?.length > 13 ? (
      <Tooltip title={str} mouseEnterDelay={0.7}>
        {str.substring(0, isMobile ? 6 : 13) + '...'}
      </Tooltip>
    ) : (
      str
    );
  };

  const handleQuickViewAction = (record: DbRecordEntityTransform) => {
    if (customRowAction) {
      customRowAction(record);
    } else {
      shortListRecord({
        showPreview: true,
        record: record,
      });
    }
  };

  const renderList = () => {
    if (associatedRecords.length > 0) {
      let filteredRecords: DbRecordEntityTransform[] =
        Object.assign(associatedRecords);

      if (filteredRecords.length > 2 && !showAllRecords && !isViewingMore) {
        filteredRecords = filteredRecords.slice(0, 2);
      }

      return (
        <Col span={24} style={{ marginBottom: 5 }}>
          <Row>
            {!hideHeader && <Col span={24}>{listHeader()}</Col>}
            <Col span={24}>
              {filteredRecords.map((rec: DbRecordEntityTransform) => (
                <Row
                  style={{
                    fontSize: isMobile ? '0.8em' : '0.9em',
                    padding: '4px 4px',
                  }}
                  align="middle"
                  justify="space-between"
                >
                  {recordColumns?.map((column: string) => {
                    if (column === 'BuildStatus') {
                      return (
                        <Col span={5}>
                          {QGISBuildStatusEnum[getProperty(rec, column)] ||
                            'No Build Status'}
                        </Col>
                      );
                    } else if (column === 'recordNumber') {
                      return (
                        <Col style={{ textAlign: 'center' }}>
                          {' '}
                          <Col>
                            <Button
                              style={{ padding: 0 }}
                              type="link"
                              onClick={() => handleQuickViewAction(rec)}
                            >
                              {rec.recordNumber}
                            </Button>
                          </Col>
                        </Col>
                      );
                    } else if (column === 'type') {
                      return (
                        <Col style={{ textAlign: 'center' }}>{rec?.type}</Col>
                      );
                    } else if (column === 'ExternalRef') {
                      return (
                        <Col>
                          <Button
                            style={{ padding: 0 }}
                            type="link"
                            onClick={() => handleQuickViewAction(rec)}
                          ></Button>
                        </Col>
                      );
                    } else {
                      return <Col>{getProperty(rec, column)}</Col>;
                    }
                  })}
                  {/* Show either custom row action or Record Quick View */}
                  {!hideQuickView && (
                    <Col style={{ textAlign: 'right' }} key={`div3-${rec.id}`}>
                      <Button
                        key={'button' + rec.id}
                        size="small"
                        icon={
                          <EyeOutlined
                            onClick={() => handleQuickViewAction(rec)}
                          />
                        }
                      />
                    </Col>
                  )}
                </Row>
              ))}
            </Col>
            {/* View More / Less Button */}
            {!showAllRecords && associatedRecords.length > 2 ? (
              <Col span={24} style={{ textAlign: 'center', marginTop: 10 }}>
                <Button
                  type="link"
                  onClick={() => setIsViewingMore(!isViewingMore)}
                  style={{ padding: 0 }}
                >
                  {isViewingMore ? 'Show Less' : 'Show More'}
                </Button>
              </Col>
            ) : (
              <></>
            )}
            <Col span={24}></Col>
          </Row>
        </Col>
      );
    } else {
      return (
        <>
          {!hideHeader && <Col span={24}>{listHeader()}</Col>}
          <Col
            key={123}
            span={24}
            style={{
              paddingTop: 5,
              paddingBottom: 10,
              textAlign: 'center',
              opacity: 0.3,
            }}
          >
            <span style={{ padding: 0, fontSize: '0.9em' }}>
              No Records found
            </span>
          </Col>
        </>
      );
    }
  };

  return (
    <Row>
      {isLoading ? (
        <>
          {!hideHeader && <Col span={24}>{listHeader()}</Col>}
          <Col span={24} style={{ textAlign: 'center' }}>
            <Spin />
          </Col>
        </>
      ) : (
        renderList()
      )}
    </Row>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  userInterfaceReducer: state.userInterfaceReducer,
});

const mapDispatch = (dispatch: any) => ({
  getAssociations: (params: IGetRecordAssociations, cb?: any) =>
    dispatch(getRecordAssociationsRequest(params, cb)),
  getSchemaById: (payload: IGetSchemaById, cb: any) =>
    dispatch(getSchemaByIdRequest(payload, cb)),
  shortListRecord: (params: IAddRecordToShortList) =>
    dispatch(addRecordToShortList(params)),
  setPermissionToFail: (params: ISetPermissionToFail) =>
    dispatch(setPermissionToFail(params)),
});

export default connect(mapState, mapDispatch)(AssociationSimpleList);
