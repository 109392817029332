import { call, put, takeLatest } from 'redux-saga/effects';
import { httpGet } from '../../../shared/http/requests';
import { ERROR_NOTIFICATION } from '../../../shared/system/notifications/store/reducers';
import { USER_LOGOUT_REQUEST } from '../../identity/store/constants';
import {
  GET_ACTIVE_OFFER_ERROR,
  GET_ACTIVE_OFFER_REQUEST,
  GET_ACTIVE_OFFER_SUCCESS,
} from './constants';

// @ts-ignore
function* getActiveOffer() {
  try {
    // @ts-ignore
    const res = yield call(
      async () =>
        await httpGet(
          'ProductModule/v1.0/cst/Offer/active/RESIDENTIAL?filters=ContractType:CONTRACT,IsDefault:true',
        ),
    );
    yield put({ type: GET_ACTIVE_OFFER_SUCCESS, results: res.data });
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: GET_ACTIVE_OFFER_ERROR, error });
    if (e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error });
    }
  }
}

function* rootSaga() {
  yield takeLatest(GET_ACTIVE_OFFER_REQUEST, getActiveOffer);
}

export default rootSaga;
