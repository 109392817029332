import { TSchemaActionPropertyFilter } from './types';

export const isSchemaActionPropertyFilterMatching = (
  properties: any,
  propertyFilters: TSchemaActionPropertyFilter[],
) => {
  let match: boolean = false;

  // Go through each property filter and create an array of booleans matching the property value
  const propertyFilterMatches = propertyFilters.map((propertyFilter) => {
    const propertyValue = properties[propertyFilter.property];
    if (propertyFilter.operator === 'IN') {
      return propertyFilter.values.includes(propertyValue);
    } else {
      return !propertyFilter.values.includes(propertyValue);
    }
  });

  // Reduce the array of booleans, but treat as AND conditional
  match = propertyFilterMatches.reduce((acc, current) => acc && current, true);

  return match;
};
