
import { Button, Col, Divider, Row, Steps } from 'antd';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import {
  changeStepNumber,
  IStepViewChangeStepNumber,
  IStepViewValidation,
  setStepValidationArray,
} from './store/actions';
import { StepViewReducerState } from './store/reducer';
import './styles.scss'

const { Step } = Steps;

interface Props {
  steps: { name: string; content: ReactElement; entityName?: string }[];
  stepViewReducer: StepViewReducerState;
  onSubmit: any;
  previousDisabled?: boolean;
  onNextActionClick?: any;
  isLookupCreate?: boolean;
  setValidationData: (params: IStepViewValidation[]) => void;
  changeStep: (params: IStepViewChangeStepNumber) => void;
  onPrevActionClick?: any;
  submitDisabled?: boolean;
}

interface State {
  submitLoading: boolean;
  isNextLoading: boolean;
}

class StepView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount(): void {
    this.setInitailStepData(this.props.steps.length);
  }

  setInitailStepData = (n: number) => {
    const { setValidationData, changeStep } = this.props;
    let tempArr: any = [];

    for (let i = 1; i <= n; i++) {
      if (i === 1) {
        tempArr.push({ isNextDisabled: true, isPreviousDisabled: true });
      } else {
        tempArr.push({ isNextDisabled: false, isPreviousDisabled: true });
      }
      if (i === n) {
        setValidationData(tempArr);
        changeStep({ stepNumber: 0 });
      }
    }
  };

  state = {
    submitLoading: false,
    isNextLoading: false,
  };

  next = () => {
    const {
      onNextActionClick,
      isLookupCreate,
      stepViewReducer,
      steps,
      changeStep,
    } = this.props;
    if (isLookupCreate) {
      this.setState({
        isNextLoading: true,
      });
      onNextActionClick(
        {
          step: stepViewReducer.currentStep,
          entityName: steps[stepViewReducer.currentStep].entityName,
        },
        (result: any) => {
          if (result) {
            // move to the next step
            const currentStep = stepViewReducer.currentStep;
            changeStep({ stepNumber: currentStep + 1 });
          }

          this.setState({
            isNextLoading: false,
          });
        },
      );
    } else {
      const currentStep = stepViewReducer.currentStep;
      changeStep({ stepNumber: currentStep + 1 });
    }
  };

  prev = () => {
    const {
      onPrevActionClick,
      isLookupCreate,
      stepViewReducer,
      steps,
      changeStep,
    } = this.props;
    if (isLookupCreate) {
      onPrevActionClick(
        {
          step: stepViewReducer.currentStep,
          entityName: steps[stepViewReducer.currentStep].entityName,
        },
        (result: any) => {
          if (result) {
            // move to the next step
            const currentStep = stepViewReducer.currentStep;
            changeStep({ stepNumber: currentStep - 1 });
          }
        },
      );
    } else {
      const currentStep = stepViewReducer.currentStep;
      changeStep({ stepNumber: currentStep - 1 });
    }
  };

  submitClick() {
    const { onSubmit, stepViewReducer, setValidationData, changeStep } =
      this.props;
    this.setState({
      submitLoading: true,
    });
    onSubmit((cb: any) => {
      if (cb) {
        this.setState({
          submitLoading: false,
        });
        const tempArr = stepViewReducer.stepComponentsData;
        setValidationData(tempArr);
        changeStep({ stepNumber: 1 });
      } else {
        this.setState({
          submitLoading: false,
        });
      }
    });
  }

  render() {
    const { steps, stepViewReducer, previousDisabled, isLookupCreate, submitDisabled } =
      this.props;

    return (
      <div>
        <Row className='stepModalStepsContainer'>

        <Col span={24}>
          <Steps
            size="small"
            current={stepViewReducer.currentStep}
            className="stepModalSteps"
          >
            {steps.map((step: any) => (
              <Step key={step.name} title={step.name} />
            ))}
          </Steps>
          </Col>
          
          <Col span={24} style={{paddingRight:10}}>
            <Divider style={{marginTop:0, marginBottom:15}}/>
          </Col>

          <Col span={24} className='stepModalStepsActions' style={{ textAlign: 'right' }}>
            {stepViewReducer.currentStep < steps.length - 1 && (
              
                <Button
                  className="stepModalStepsButton"
                  type="primary"
                  onClick={() => this.next()}
                  disabled={
                    stepViewReducer.stepComponentsData[stepViewReducer.currentStep]
                      ?.isNextDisabled
                  }
                  // loading={this.state.isNextLoading}
                >
                  Next
                </Button>

            )}
            {stepViewReducer.currentStep === steps.length - 1 && (
              <>
                <Button
                  className="stepModalStepsButton"
                  style={{ marginRight: 10 }}
                  type="primary"
                  ghost
                  onClick={() => {
                    this.setState({ submitLoading: false });
                  }}
                  disabled={!this.state.submitLoading}
                >
                  Cancel
                </Button>

                <Button
                  className="stepModalStepsButton"
                  type="primary"
                  onClick={() => {
                    this.submitClick();
                  }}
                  disabled={
                    stepViewReducer.stepComponentsData[
                      stepViewReducer.currentStep
                    ]?.isNextDisabled || submitDisabled
                  }
                  loading={this.state.submitLoading}
                >
                  Submit
                </Button>
                </>
            )}
          </Col>
        </Row>



        <div className="stepModalStepsContent">
          {steps[stepViewReducer.currentStep]?.content}
        </div>

      </div>




    );
  }
}

const mapState = (state: any) => ({
  stepViewReducer: state.stepViewReducer,
});

const mapDispatch = (dispatch: any) => ({
  setValidationData: (params: IStepViewValidation[]) =>
    dispatch(setStepValidationArray(params)),
  changeStep: (params: IStepViewChangeStepNumber) =>
    dispatch(changeStepNumber(params)),
});

export default connect(mapState, mapDispatch)(StepView);
