import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaModuleEntityTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.entity.types';

const { CUSTOMER_DEVICE_ONT, CUSTOMER_DEVICE_ROUTER } =
  SchemaModuleEntityTypeEnums;

export const getONTsFromOrderItem = (record: DbRecordEntityTransform) => {
  return record[CUSTOMER_DEVICE_ONT]?.dbRecords || [];
};

export const getAtaFromOrderItem = (record: DbRecordEntityTransform) => {
  const records = record['CustomerDeviceAta']?.dbRecords;
  return records ? records[0] : undefined;
};

export const getRoutersFromOrderItem = (record: DbRecordEntityTransform) => {
  return record[CUSTOMER_DEVICE_ROUTER]?.dbRecords || [];
};

export const isOrderItemBaseProduct = (record: DbRecordEntityTransform) => {
  return getProperty(record, 'ProductType') === 'BASE_PRODUCT';
};

export const isOrderItemAddonProduct = (record: DbRecordEntityTransform) => {
  return getProperty(record, 'ProductType') === 'ADD_ON_PRODUCT';
};

export const isOrderItemBroadbandProduct = (
  record: DbRecordEntityTransform,
) => {
  return getProperty(record, 'ProductCategory') === 'BROADBAND';
};

export const isOrderItemVoiceProduct = (record: DbRecordEntityTransform) => {
  return getProperty(record, 'ProductCategory') === 'VOICE';
};

export const isOrderItemStaticIP = (record: DbRecordEntityTransform) => {
  return record.title?.indexOf('Static IP') !== -1;
};

export const isOrderItemBatteryPack = (record: DbRecordEntityTransform) => {
  return record.title?.indexOf('Battery') !== -1;
};
