import React, { useEffect } from 'react';
import { LoadingOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Layout, Row, Spin, Typography } from 'antd';
import { withRouter } from 'react-router-dom';
import { MFASelectors, MFAState, useMFA } from './useMFA';


const { Content } = Layout;
const { Title } = Typography;

interface Props {
  history: any;
}

const antIcon = (
  <LoadingOutlined
    style={{ fontSize: 24, color: '#bddfff', marginRight: '8px' }}
    spin
  />
);

const Step1 = ({ onSubmit }: { onSubmit: () => void }) => {
  return (
    <>
      <p>
        You need to set up 2-factor authentication before continuing to the dashboard.
        This enables us verify it is you who is logging in to your user account,
        keeping your account secure.
      </p>
      <Button
        type="primary"
        size="large"
        className="loginSubmit"
        htmlType="submit"
        onClick={onSubmit}
      >
        Continue
      </Button>
    </>
  )
}

const Step2 = ({ show, status, mfaSettings, onSubmit }: MFAState & { show: "qrcode" | "link", onSubmit: () => void }) => {
  if (status === 'generating' || !mfaSettings) return (
    // Display loading state
    <LoadingOutlined
      style={{ fontSize: 24, color: '#bddfff', marginRight: '8px' }}
      spin
    />
  );

  const type = show === 'qrcode' ? 'QR code' : 'link';

  return (
    <>
      <Title
        level={4}
      >
        1. Use an Authenticator App
      </Title>
      <p>
        Clicking the {type} below will set up your authenticator app. We recommend
        you to use <strong>Google Authenticator</strong> or <strong>Microsoft
        Authenticator</strong> apps.
      </p>
      {show === 'link' && <a href={mfaSettings.url} style={{ display: "block", margin: "auto" }}>Add Secret to Authenticator App</a>}
      <br/>
      <p>
        If the {type} above don't work, enter this secret key manually in your
        Authenticator app: <br/>
        <strong>{formatSecret(mfaSettings.secret)}</strong>
      </p>

      <Button
        type="primary"
        size="large"
        className="loginSubmit"
        htmlType="submit"
        onClick={onSubmit}
      >
        Continue
      </Button>
    </>
  );
}

const Step3 = ({ onSubmit, state, selectors }: { state: MFAState, selectors: MFASelectors, onSubmit: (data: any) => void }) => {
  const hasError = selectors.hasError();
  const isLoading = state.status === 'validating';

  return (
    <>
      <Title
        level={4}
      >
        2. Confirm your Setup
      </Title>
      <p>Enter the 6-digit code generated by the authenticator app in the field below:</p>
      <Form
        name="user-login"
        className="login-form"
        initialValues={{ pin: '' }}
        onFinish={onSubmit}
      >
        <Form.Item
          name="pin"
          rules={[
            { required: true, message: 'Please input your PIN' },
          ]}
          help={hasError ? state.error.message : ''}
          validateStatus={hasError ? 'error' : undefined}
        >
          <Input
            autoComplete="true"
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="text"
            placeholder="Authenticator PIN"
            size="large"
            disabled={isLoading}
          />
        </Form.Item>
        <Form.Item shouldUpdate={true}>
          <div
            style={{
              justifyContent: 'center',
              display: 'flex',
              marginBottom: 0,
            }}
          >
            <Button
              type="primary"
              size="large"
              className="loginSubmit"
              htmlType="submit"
              disabled={state.status === 'validating'}
            >
              {isLoading ? (
                <>
                  <Spin indicator={antIcon} /> Verifying...
                </>
              ) : (
                'Verify'
              )}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  )
}

const Step4 = ({ backupCode, onClick }: { backupCode: string, onClick: () => void }) => {
  return (
    <>
      <p>Thanks you for setting up a second authentication factor.</p>
      <p>
        In case you lose access to your mobile phone, take note of the
        following backup code: <br/>
        <strong>{backupCode}</strong>
      </p>
      <Button
          type="primary"
          size="large"
          className="loginSubmit"
          htmlType="submit"
          onClick={onClick}
      >
        Continue
      </Button>
  </>
  )
}

const formatSecret = (secret: string) => secret.replaceAll(/(.{4})/g, '$1 ');

const MFASettings = ({ history }: Props) => {
  const { state, service: mfaService, selectors } = useMFA();
  // const [form] = Form.useForm();
  // const hasError = selectors.hasError();
  // const mfaSettings = selectors.getMFAData();
  // const isLoading = state.status === 'generating';

  let component = null;
  if (state.status === 'idle')
    component = <Step1 onSubmit={() => mfaService.generateSecret() }/>
  if (state.status === 'generating' || state.status === 'generated')
    component = <Step2 {...state} show="link" onSubmit={() => mfaService.userSetupCompleted() }/>;
  if (state.status === 'user-setup-completed' || state.status === 'validating' || state.status === 'failed')
    component = <Step3 state={state} selectors={selectors} onSubmit={({ pin }: { pin: string }) => {
      mfaService.verifyPIN(pin);
    }} />;
  if (state.status === 'valid' && state.mfaSettings)
    component = <Step4 backupCode={state.mfaSettings.backupCode} onClick={() => history.push('/')} />

  return (
    <Layout style={{ paddingTop: '100px' }}>
      <Content>
        <Row className="login-form-row">
          <Col
            xs={{ span: 20, offset: 2 }}
            md={{ span: 12, offset: 6 }}
            xxl={{ span: 8, offset: 8 }}
          >
            <div className="login-container" style={{ textAlign: 'center' }}>
              <Title
                level={3}
                style={{ marginBottom: '30px' }}
              >
                Set up 2-factor authentication
              </Title>
              {component}
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default withRouter(MFASettings);
