import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Col, Divider, Input, Layout, List, Row, Spin } from 'antd';
import Text from 'antd/es/typography/Text';
import React, { createRef } from 'react';
import { connect } from 'react-redux';
import {
  ISearchRecords,
  searchRecordsRequest,
} from '../../../../../core/records/store/actions';
import { IRecordReducer } from '../../../../../core/records/store/reducer';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '../../../../../core/schemas/store/actions';
import { ISchemaReducer } from '../../../../../core/schemas/store/reducer';
import { getRecordListFromShortListById } from '../../../../../shared/utilities/recordHelpers';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../../shared/utilities/schemaHelpers';
import {
  getDefaultFields,
  setSearchQuery,
  setSortQuery,
} from '../../../../../shared/utilities/searchHelpers';
import history from '../../../../../shared/utilities/browserHisory';
import { SchemaModuleTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.types';
import { SchemaModuleEntityTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.entity.types';
import StageNameTag from '../../../../../shared/components/StageNameTag';
import { getProperty } from '@d19n/models/dist/schema-manager/helpers/dbRecordHelpers';

const { ORDER_MODULE } = SchemaModuleTypeEnums;
const { ORDER } = SchemaModuleEntityTypeEnums;

const { Search } = Input;

interface Props {
  moduleName: string;
  entityName: string;
  schemaReducer: ISchemaReducer;
  recordReducer: IRecordReducer;
  identityReducer: any;
  searchRecords: any;
  getSchema: any;
}

class OrdersListView extends React.Component<Props> {
  // @ts-ignore
  private searchInputRef = createRef<Input>();

  componentDidMount(): void {
    this.loadSchema();

    /* If search box contains search query when the component is mounted -> search for it */
    if (
      this.searchInputRef.current &&
      this.searchInputRef?.current?.props?.value
    ) {
      const searchQuery: string = String(
        this.searchInputRef?.current?.props?.value,
      ).trim();

      if (searchQuery.length > 0) {
        this.searchRecordOnChange(searchQuery);
      } else {
        this.searchRecordOnChange('');
      }
    } else {
      this.searchRecordOnChange('');
    }
  }

  loadSchema() {
    const { getSchema } = this.props;
    // get schema by module and entity and save it to redux
    getSchema({
      moduleName: ORDER_MODULE,
      entityName: ORDER,
      withAssociations: true,
    });
  }

  searchRecordOnChange(keyword: string) {
    const {
      schemaReducer,
      recordReducer,
      moduleName,
      entityName,
      searchRecords,
    } = this.props;
    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      moduleName,
      entityName,
    );

    if (schema) {
      searchRecords({
        schema: schema,
        searchQuery: {
          terms: keyword,
          fields: getDefaultFields(moduleName, entityName),
          schemas: schema.id,
          sort: setSortQuery(
            schemaReducer,
            recordReducer,
            moduleName,
            entityName,
          ),
        },
      });
    }
  }

  handleSelectedOrder(item: any) {
    history.push(`/${ORDER_MODULE}/${ORDER}/${item.id}`);
  }

  render() {
    const { recordReducer, schemaReducer, moduleName, entityName } = this.props;

    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      moduleName,
      entityName,
    );

    return (
      <Layout className="premise-list-view" style={{ padding: '10px' }}>
        <Search
          className="search-input"
          placeholder="search records"
          ref={this.searchInputRef}
          value={setSearchQuery(
            schemaReducer,
            recordReducer,
            moduleName,
            entityName,
          )}
          onChange={(e) => this.searchRecordOnChange(e.target.value)}
        />
        <Spin spinning={recordReducer.isCreating}>
          <List
            bordered
            className="list"
            loading={recordReducer.isSearching}
            style={{ padding: '15px' }}
            dataSource={
              schema
                ? getRecordListFromShortListById(recordReducer.list, schema.id)
                : []
            }
            renderItem={(item: DbRecordEntityTransform) => (
              <>
                <Row>
                  <Col
                    style={{
                      textAlign: 'left',
                      paddingTop: '2px',
                      cursor: 'pointer',
                    }}
                  >
                    <Text style={{ color: '#1890ff' }}>
                      <span
                        onClick={() => this.handleSelectedOrder(item)}
                        style={{
                          fontSize: '1.1em',
                          fontWeight: 500,
                        }}
                      >
                        {item.title}
                      </span>
                    </Text>
                  </Col>

                  <Col span={24}>
                    <div style={{ paddingTop: '5px' }}>
                      <div
                        className="list-item-with-label"
                        style={{ display: 'flex' }}
                      >
                        <Text
                          strong
                          className="label"
                          style={{ marginRight: '.5rem' }}
                        >
                          Stage:{' '}
                        </Text>
                        {<StageNameTag text={item.stage?.name} record={item} />}
                      </div>
                    </div>
                  </Col>

                  <Col span={24}>
                    <div style={{ paddingTop: '5px' }}>
                      <div
                        className="list-item-with-label"
                        style={{ display: 'flex' }}
                      >
                        <Text
                          strong
                          className="label"
                          style={{ marginRight: '.5rem' }}
                        >
                          Total Price:{' '}
                        </Text>
                        {getProperty(item, 'TotalPrice')}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Divider
                  orientation="center"
                  style={{ margin: '18px 0px', borderTopColor: '#d6d6d6' }}
                />
              </>
            )}
          />
        </Spin>
      </Layout>
    );
  }
}

const mapState = (state: any) => ({
  recordReducer: state.recordReducer,
  schemaReducer: state.schemaReducer,
  identityReducer: state.identityReducer,
});

const mapDispatch = (dispatch: any) => ({
  searchRecords: (params: ISearchRecords) =>
    dispatch(searchRecordsRequest(params)),
  getSchema: (params: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(OrdersListView);
