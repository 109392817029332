import { Icon } from '@blueprintjs/core';

export const getPastelColorForUsername = (fullName: string) => {
  if (fullName) {
    let hash = 0;
    for (let i = 0; i < fullName.length; i++) {
      hash = fullName.charCodeAt(i) + ((hash << 10) - hash);
    }

    // Make the color lighter by adding an offset to each component
    const lightnessOffset = 1; // You can adjust this value for a lighter or darker effect
    const r = ((hash & 0xff) % 130) + 80 + lightnessOffset;
    const g = (((hash >> 8) & 0xff) % 100) + 100 + lightnessOffset;
    const b = (((hash >> 16) & 0xff) % 120) + 120 + lightnessOffset;

    const color =
      '#' + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1);

    return color;
  } else {
    return '';
  }
};

export const getInitialsFromName = (name: string) => {
  if (name) {
    const nameParts = name.split(' ');
    const initials = nameParts
      .map((part: string) => part[0]?.toUpperCase())
      ?.join('');
    return initials;
  } else {
    return '';
  }
};

export const generatePastelGradientColor = (text: string) => {
  // Convert characters to ASCII values
  const ascii1 = text[0].charCodeAt(0);
  const ascii2 = text[1].charCodeAt(0);

  // Calculate stronger gradient values
  const strongerGradientR = Math.abs((ascii1 * 2 + ascii2 * 1) % 256);
  const strongerGradientG = Math.abs((ascii1 * 3 + ascii2 * 2) % 256);
  const strongerGradientB = Math.abs((ascii1 * 5 - ascii2 * 2) % 256);

  // Convert stronger gradient values to pastel range (128 - 255)
  const strongerPastelR = Math.floor((strongerGradientR + 192) / 2);
  const strongerPastelG = Math.floor((strongerGradientG + 192) / 2);
  const strongerPastelB = Math.floor((strongerGradientB + 192) / 2);

  // Construct the stronger gradient pastel color in CSS format
  const strongerGradientPastelColor = `rgb(${strongerPastelR}, ${strongerPastelG}, ${strongerPastelB})`;

  return strongerGradientPastelColor;
};

// 07-Feb-2024 - On Frank's request, we are showing checkboxes only if value is present
export const renderBooleanValue = (value: string | undefined | null) => {
  if (value && value === 'true') {
    return <Icon icon="tick" color="#32A467" />;
  } else if (value && value === 'false') {
    return <Icon icon="cross" color="#CD4246" />;
  } else {
    return <></>;
  }
};
