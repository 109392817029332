import Resizer from 'react-image-file-resizer';
import dayjs from 'dayjs';
import EXIF from 'exif-js';
import { RcFile } from 'antd/lib/upload';
import { message } from 'antd';
import { isMobile } from 'react-device-detect';
import { SchemaModuleEntityTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.types';
import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/models/dist/schema-manager/helpers/dbRecordHelpers';
import exifr from 'exifr';

const { PROJECT_MODULE } = SchemaModuleTypeEnums;
const { FEATURE } = SchemaModuleEntityTypeEnums;

interface beforeUploadProps {
  file: any;
  record: DbRecordEntityTransform | undefined;
  coordinates?: any[];
}

export const beforeUpload = async (file: any, message?: any) => {
  return new Promise((resolve: any) => {
    const datestamp = dayjs().format('x');
    const isLt25M = file.size / 1024 / 1024 < 25;
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isLt25M) {
      if (message) {
        message.error('File must be smaller than 25MB!');
      }
    }

    // Resize uploaded file to 1200x1200px
    const resizeFile = (file: any) =>
      new Promise((resolve: any) => {
        Resizer.imageFileResizer(
          file,
          1200,
          1200,
          'JPEG',
          70,
          0,
          (uri: any) => resolve(uri),
          'blob',
        );
      });

    try {
      if (isJpgOrPng) {
        EXIF.getData(file, async function () {
          const manufacturer = EXIF.getTag(file, 'Make');
          const model = EXIF.getTag(file, 'Model');

          // This device/model combination will not resize images properly, so skip resize.
          if (manufacturer === 'samsung' && model === 'SM-G991B') {
            const fileName = `${datestamp}_${file.name}`;

            const newFile = new File([file as BlobPart], fileName, {
              type: 'image/jpeg',
            });
            resolve(isLt25M && (newFile as any));
          }
          // Resize file
          else {
            const fileName = `${datestamp}_${file.name.replace(
              /\.[^/.]+$/,
              '',
            )}.jpeg`;
            const blobFile = await resizeFile(file);

            const newFile = new File([blobFile as BlobPart], fileName, {
              type: 'image/jpeg',
            });

            resolve(isLt25M && (newFile as any));
          }
        });
      } else {
        const fileName = `${datestamp}_${file.name}`;
        const newFile = file
          ? new File([file], fileName, {
              type: file.type,
              lastModified: file.lastModified,
            })
          : false;
        resolve(isLt25M && (newFile as any));
      }
    } catch (err) {
      resolve(isLt25M && (file as any));
    }
  });
};

export const getBase64 = async (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const ConvertDDToDMS = (D: any, lng: any): string => {
  const response = {
    dir: D < 0 ? (lng ? 'W' : 'S') : lng ? 'E' : 'N',
    deg: 0 | (D < 0 ? (D = -D) : D),
    min: 0 | (((D += 1e-9) % 1) * 60),
    sec: (0 | (((D * 60) % 1) * 6000)) / 100,
  };
  return `${response.deg}° ${response.min}' ${response.sec.toFixed(2)}"`;
};

/* WITH WATERMARK ******************************************************/
export const beforeUploadWithWatermark = async (args: beforeUploadProps) => {
  const { file, record, coordinates } = args || {
    file: null,
    record: null,
    coordinates: [],
  };

  return new Promise((resolve: any) => {
    const datestamp = dayjs().format('x');
    const isLt25M = file.size / 1024 / 1024 < 25;
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isLt25M) {
      message.error('File must be smaller than 25MB!');
    }

    // Resize uploaded file to 1200x1200px
    const resizeFile = (file: any) =>
      new Promise((resolve: any) => {
        Resizer.imageFileResizer(
          file,
          1200,
          1200,
          'JPEG',
          70,
          0,
          (uri: any) => resolve(uri),
          'blob',
        );
      });

    // Add Watermark to Image
    const addWatermark = async (
      file: any,
      latitude: string,
      longitude: string,
      dateTime: string,
      address: string,
    ) =>
      new Promise(async (resolve: any) => {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx: any = canvas.getContext('2d');

          // Configure Text for Watermark
          const lineHeight: number = 25;
          let currentLine: number = 35;

          const addLine = () => {
            currentLine += lineHeight;
          };

          // Get Nearest address and set to Unknown Location if empty
          let nearestAddress = [];
          if (!address || address === '') {
            nearestAddress = ['Unknown Address'];
          } else {
            // split address into array of strings no longer than 30
            nearestAddress = address.match(/.{1,30}/g) || [];
          }

          if (ctx) {
            ctx.drawImage(img, 0, 0);
            ctx.font = '16px Arial';

            const squareHeight =
              nearestAddress.length * lineHeight + lineHeight * 3 + 20;

            // Draw Rounded Rectangle for Watermark
            ctx.beginPath();
            ctx.roundRect(15, 10, 310, squareHeight, 8);
            ctx.fillStyle = 'rgba(0, 0, 0, 0.4)';
            ctx.fill();

            ctx.fillStyle = 'rgba(255, 255, 255, 1)';
            ctx.textAlign = 'left';

            // Draw Nearest Address for Watermark
            nearestAddress?.forEach((line: string) => {
              ctx.fillText(line, 28, currentLine);
              addLine();
            });

            // Draw Text for Watermark
            ctx.fillText(`Lon: ${longitude || '-'}`, 30, currentLine);
            addLine();
            ctx.fillText(`Lat: ${latitude || '-'}`, 30, currentLine);
            addLine();
            ctx.fillText(dateTime || '-', 30, currentLine);

            canvas.toBlob((blob: any) => {
              resolve(blob);
            }, 'image/jpeg');
          }
        };
      });

    try {
      if (isJpgOrPng) {
        let blobFile, newFile;

        exifr
          .parse(file, ['GPSLatitude', 'GPSLongitude', 'DateTimeOriginal'])
          .then(async (EXIF: any) => {
            // Extract EXIF Data from Image, parse GPS data and create Datestamp
            const GPSLatitude = EXIF?.GPSLatitude;
            const GPSLongitude = EXIF?.GPSLongitude;

            let parsedGPSLatitude = '';
            let parsedGPSLongitude = '';

            if (GPSLatitude && GPSLongitude) {
              parsedGPSLatitude = `${GPSLatitude[0]}° ${GPSLatitude[1]}' ${GPSLatitude[2]}"`;
              parsedGPSLongitude = `${GPSLongitude[0]}° ${GPSLongitude[1]}' ${GPSLongitude[2]}"`;
            }
            const nearestAddress = getProperty(record, 'NearestAddress');
            let dateTime = EXIF?.DateTimeOriginal;

            // Parse EXIF DateTime to readable format or fallback to current time
            if (dateTime) {
              dateTime = dayjs(dateTime).format('dddd, MMMM D, YYYY h:mm A');
            } else {
              dateTime = dayjs().format('dddd, MMMM D, YYYY h:mm A');
            }

            // Create Filename
            const fileName = `${datestamp}_${file.name.replace(
              /\.[^/.]+$/,
              '',
            )}.jpeg`;

            blobFile = await resizeFile(file);
            newFile = new File([blobFile as BlobPart], fileName, {
              type: 'image/jpeg',
            });

            //  Rule Set for Adding Watermark to Feature image uploads
            //
            //  a) MOBILE DEVICES: We try to access the GPS data from the device, if it is not
            //  available, we try to access the GPS data from the EXIF data. If neither is
            //  available, we do not add a watermark.
            //
            //  b) DESKTOP DEVICES: We try to access the GPS data from the EXIF data. If it is
            //  not available, we do not add a watermark.
            //

            // Record is ProjectModule:Feature - try to add watermark
            if (record?.entity === `${PROJECT_MODULE}:${FEATURE}`) {
              // MOBILE DEVICES
              //
              // Browser coordinates available
              if (isMobile && coordinates!.length > 0) {
                console.log('Debug -> isMobile, browser coordinates available');
                const watermarkedBlob = await addWatermark(
                  newFile,
                  ConvertDDToDMS(coordinates![1], true),
                  ConvertDDToDMS(coordinates![0], true),
                  dateTime,
                  nearestAddress,
                );
                const watermarkedFile = new File(
                  [watermarkedBlob as BlobPart],
                  fileName,
                  {
                    type: 'image/jpeg',
                  },
                );
                resolve(isLt25M && (watermarkedFile as any));
              }
              // Browser coordinates unavailable, but EXIF coordinates available
              else if (
                isMobile &&
                coordinates!.length === 0 &&
                (GPSLatitude || GPSLongitude)
              ) {
                console.log('Debug -> isMobile, EXIF coordinates available');
                const watermarkedBlob = await addWatermark(
                  newFile,
                  parsedGPSLatitude,
                  parsedGPSLongitude,
                  dateTime,
                  nearestAddress,
                );
                const watermarkedFile = new File(
                  [watermarkedBlob as BlobPart],
                  fileName,
                  {
                    type: 'image/jpeg',
                  },
                );
                resolve(isLt25M && (watermarkedFile as any));
              }
              // Browser coordinates unavailable, and EXIF coordinates unavailable - skip watermark
              else if (
                isMobile &&
                coordinates!.length === 0 &&
                !GPSLatitude &&
                !GPSLongitude
              ) {
                console.log(
                  'Debug -> isMobile, no EXIF or browser coordinates available',
                );
                resolve(isLt25M && (newFile as any));
              }
              // DESKTOP DEVICES
              //
              // Desktop app - EXIF coordinates available
              else if (!isMobile && GPSLatitude && GPSLongitude) {
                console.log('Debug -> isDesktop, EXIF coordinates available');
                const watermarkedBlob = await addWatermark(
                  newFile,
                  parsedGPSLatitude,
                  parsedGPSLongitude,
                  dateTime,
                  nearestAddress,
                );
                const watermarkedFile = new File(
                  [watermarkedBlob as BlobPart],
                  fileName,
                  {
                    type: 'image/jpeg',
                  },
                );
                resolve(isLt25M && (watermarkedFile as any));
              }
              // We don't add browser coordinates for Desktop uploads, uploader might be in the office.
              else {
                resolve(isLt25M && (newFile as any));
              }
            }
            // Record is not ProjectModule:Feature - skip watermark
            else {
              console.log('debug -> not ProjectModule:Feature');
              resolve(isLt25M && (newFile as any));
            }
          });
      } else {
        const fileName = `${datestamp}_${file.name}`;

        const newFile = file
          ? new File([file], fileName, {
              type: file.type,
              lastModified: file.lastModified,
            })
          : false;
        resolve(isLt25M && (newFile as any));
      }
    } catch (e: any) {
      resolve(isLt25M && (file as any));
    }
  });
};

/* WITHOUT WATERMARK ******************************************************/
export const beforeUploadWithoutWatermark = async (file: any) => {
  return new Promise(async (resolve: any) => {
    const datestamp = dayjs().format('x');
    const isLt25M = file.size / 1024 / 1024 < 25;
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isLt25M) {
      message.error('File must be smaller than 25MB!');
    }

    // Resize uploaded file to 1200x1200px
    const resizeFile = (file: any) =>
      new Promise((resolve: any) => {
        Resizer.imageFileResizer(
          file,
          1200,
          1200,
          'JPEG',
          70,
          0,
          (uri: any) => resolve(uri),
          'blob',
        );
      });

    try {
      if (isJpgOrPng) {
        let blobFile: any;
        let newFile: any;

        const fileName = `${datestamp}_${file.name.replace(
          /\.[^/.]+$/,
          '',
        )}.jpeg`;
        blobFile = await resizeFile(file);
        newFile = new File([blobFile as BlobPart], fileName, {
          type: 'image/jpeg',
        });
        resolve(isLt25M && (newFile as any));
      } else {
        const fileName = `${datestamp}_${file.name}`;

        const newFile = file
          ? new File([file], fileName, {
              type: file.type,
              lastModified: file.lastModified,
            })
          : false;
        resolve(isLt25M && (newFile as any));
      }
    } catch (e: any) {
      resolve(isLt25M && (file as any));
    }
  });
};
