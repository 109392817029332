export const SEARCH_DB_RECORD_REQUEST = 'SEARCH_DB_RECORD_REQUEST';
export const SEARCH_DB_RECORD_SUCCESS = 'SEARCH_DB_RECORD_SUCCESS';
export const SEARCH_DB_RECORD_ERROR = 'SEARCH_DB_RECORD_ERROR';

// ODN-1706 bulk update records
export const BULK_UPDATE_DB_RECORDS_REQUEST = 'BULK_UPDATE_DB_RECORDS_REQUEST';
export const BULK_UPDATE_DB_RECORDS_SUCCESS = 'BULK_UPDATE_DB_RECORDS_SUCCESS';
export const BULK_UPDATE_DB_RECORDS_ERROR = 'BULK_UPDATE_DB_RECORDS_ERROR';

export const CREATE_DB_RECORD_REQUEST = 'CREATE_DB_RECORD_REQUEST';
export const CREATE_DB_RECORD_SUCCESS = 'CREATE_DB_RECORD_SUCCESS';
export const CREATE_DB_RECORD_ERROR = 'CREATE_DB_RECORD_ERROR';

export const MERGE_DB_RECORD_REQUEST = 'MERGE_DB_RECORD_REQUEST';
export const MERGE_DB_RECORD_SUCCESS = 'MERGE_DB_RECORD_SUCCESS';
export const MERGE_DB_RECORD_ERROR = 'MERGE_DB_RECORD_ERROR';

export const GET_DB_RECORD_BY_ID_REQUEST = 'GET_DB_RECORD_BY_ID_REQUEST';
export const GET_DB_RECORD_BY_ID_SUCCESS = 'GET_DB_RECORD_BY_ID_SUCCESS';
export const GET_DB_RECORD_BY_ID_ERROR = 'GET_DB_RECORD_BY_ID_ERROR';

export const LOOKUP_DB_RECORD_REQUEST = 'LOOKUP_DB_RECORD_REQUEST';
export const LOOKUP_DB_RECORD_SUCCESS = 'LOOKUP_DB_RECORD_SUCCESS';
export const LOOKUP_DB_RECORD_ERROR = 'LOOKUP_DB_RECORD_ERROR';

export const UPDATE_DB_RECORD_BY_ID_REQUEST = 'UPDATE_DB_RECORD_BY_ID_REQUEST';
export const UPDATE_DB_RECORD_BY_ID_SUCCESS = 'UPDATE_DB_RECORD_BY_ID_SUCCESS';
export const UPDATE_DB_RECORD_BY_ID_ERROR = 'UPDATE_DB_RECORD_BY_ID_ERROR';

export const DELETE_DB_RECORD_BY_ID_REQUEST = 'DELETE_DB_RECORD_BY_ID_REQUEST';
export const DELETE_DB_RECORD_BY_ID_SUCCESS = 'DELETE_DB_RECORD_BY_ID_SUCCESS';
export const DELETE_DB_RECORD_BY_ID_ERROR = 'DELETE_DB_RECORD_BY_ID_ERROR';

export const SET_DB_RECORD_SEARCH_QUERY = 'SET_DB_RECORD_SEARCH_QUERY';

export const RESET_DB_RECORDS_LIST = 'RESET_DB_RECORDS_LIST';

export const ADD_RECORD_TO_SHORT_LIST = 'ADD_RECORD_TO_SHORT_LIST';

export const SET_DB_RECORD_STATE = 'SET_DB_RECORD_STATE';

export const TOGGLE_SEARCH_VISIBILITY = 'TOGGLE_SEARCH_VISIBILITY';

export const RESET_DB_RECORDS_SEARCH_QUERY = 'RESET_DB_RECORDS_SEARCH_QUERY';
export const ORDER_CHECKOUT_REQUEST = 'ORDER_CHECKOUT_REQUEST';
export const ORDER_CHECKOUT_SUCCESS = 'ORDER_CHECKOUT_SUCCESS';
export const ORDER_CHECKOUT_ERROR = 'ORDER_CHECKOUT_ERROR';

export const UPDATE_ADDRESS_RECORD_WITH_VISIT_REQUEST =
  'UPDATE_ADDRESS_RECORD_WITH_VISIT_REQUEST';

export const UPDATE_IS_BACK_PROPERTY_REQUEST =
  'UPDATE_IS_BACK_PROPERTY_REQUEST';
export const UPDATE_RECORD_IN_SHORT_LIST = 'UPDATE_RECORD_IN_SHORT_LIST';
