import {
  CANCEL_APPOINTMENT_RECORD_REQUEST,
  CANCEL_WORK_ORDER_REQUEST,
  CLOSE_CANCEL_APPOINTMENT_MODAL,
  CREATE_APPOINTMENT_REQUEST,
  CREATE_WORK_ORDER_AND_APPOINTMENT_REQUEST,
  INITIALIZE_CANCEL_APPOINTMENT_MODAL,
  LOAD_AVAILABLE_APPOINTMENTS_REQUEST,
  SET_SCHEDULE_ID,
  UPDATE_APPOINTMENT_REDUCER,
} from './constants';
import { ISetScheduleId, ServiceAppointmentCreateDto } from './reducer';

export interface ICreateServiceAppointment {
  workOrderId: any;
  createUpdate: ServiceAppointmentCreateDto;
}

export interface ILoadAppointments {
  start: string;
  end: string;
  type: string | undefined;
  addressId?: string;
  exPolygonId?: string;
  scheduleId?: string;
  isOverview?: boolean;
}

export function cancelWorkOrder(
  params: { body: any; workOrderId: string },
  cb: any,
) {
  return {
    type: CANCEL_WORK_ORDER_REQUEST,
    params,
    cb,
  };
}

export function loadAppointmentsRequest(
  params: { start: string; end: string },
  cb: any,
) {
  return {
    type: LOAD_AVAILABLE_APPOINTMENTS_REQUEST,
    params,
    cb,
  };
}

export function createAppointmentRequest(
  params: ICreateServiceAppointment,
  cb = () => {},
) {
  return {
    type: CREATE_APPOINTMENT_REQUEST,
    params,
    cb,
  };
}

export function creteWorkOrderAndAppointmentRequest(
  params: any,
  cb = () => {},
) {
  return {
    type: CREATE_WORK_ORDER_AND_APPOINTMENT_REQUEST,
    params,
    cb,
  };
}

export function loadTimeSlotsRequest(params: ILoadAppointments) {
  return {
    type: LOAD_AVAILABLE_APPOINTMENTS_REQUEST,
    params,
  };
}

export function cancelAppointmentRequest(params: any, cb: any) {
  return {
    type: CANCEL_APPOINTMENT_RECORD_REQUEST,
    params,
    cb,
  };
}

export function initailizeCancelAppointmentModal(params: any) {
  return {
    type: INITIALIZE_CANCEL_APPOINTMENT_MODAL,
    params,
  };
}

export function setScheduleId(params: ISetScheduleId) {
  return {
    type: SET_SCHEDULE_ID,
    params,
  };
}

export function closeCancelAppointmentModal() {
  return {
    type: CLOSE_CANCEL_APPOINTMENT_MODAL,
  };
}

export function updateAppointmentReducer(params: any) {
  return {
    type: UPDATE_APPOINTMENT_REDUCER,
    params,
  };
}
