import React, { FC, useEffect, useState } from 'react';
import { Col, Divider, Row } from 'antd';
import { connect } from 'react-redux';
import DeviceListSection from '../shared/DeviceListSection';
import { SchemaModuleTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.types';
import { SchemaModuleEntityTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.entity.types';
import { httpGet } from '../../../../../shared/http/requests';
import ModuleEntityIcon from '../../../../../core/theme/ModuleEntityIcon';
import { displayMessage } from '../../../../../shared/system/messages/store/reducers';
import { initializeRecordForm } from '../../../../../core/records/components/Forms/store/actions';
import {
  deleteRecordAssociationById,
  IDeleteRecordAssociation,
} from '../../../../../core/recordsAssociations/store/actions';
import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';

interface INetworkAddonStaticIpProductProps {
  record: DbRecordEntityTransform; // OrderItem record
  orderRecord: DbRecordEntityTransform; // Order record
  onUpdated?: () => void;
}

const { SERVICE_MODULE } = SchemaModuleTypeEnums;
const { CUSTOMER_DEVICE_ROUTER } = SchemaModuleEntityTypeEnums;

type StaticIpData = {
  subnet?: string;
  defaultGateway?: string;
  dnsServers?: string[];
  ipAddresses?: string[];
  ipv6Address?: string;
  ipv6Prefix?: string;
};

const NetworkAddonStaticIpProduct: FC<INetworkAddonStaticIpProductProps> = (
  props,
) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<StaticIpData>();
  const { record, onUpdated, orderRecord } = props;

  useEffect(() => {
    getStaticIpData();
  }, []);

  const getStaticIpData = () => {
    setLoading(true);

    httpGet(`ServiceModule/v1.0/network/bng/static-ip/${orderRecord.id}`)
      .then((res: any) => {
        if (res.data) {
          setData(res.data?.data);
          console.log(res.data?.data);
          setLoading(false);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const detailsSection = () => {
    const details = [
      {
        label: 'Subnet',
        value: data?.subnet,
      },
      {
        label: 'Default Gateway',
        value: data?.defaultGateway,
      },
      {
        label: 'DNS Servers',
        value: data?.dnsServers ? data.dnsServers.join(', ') : undefined,
      },
      {
        label: 'IP Addresses',
        value: data?.ipAddresses ? data.ipAddresses.join(', ') : undefined,
      },
    ];

    return <DeviceListSection details={details} />;
  };

  return (
    <div
      style={{
        borderRadius: 2,
        background: '#f5f5f5',
        border: '1px solid #e2e2e2',
        padding: '15px 15px',
      }}
    >
      <Row justify="space-between" align="middle">
        <Col>
          <ModuleEntityIcon
            moduleName={SERVICE_MODULE}
            entityName={CUSTOMER_DEVICE_ROUTER}
          />
          <span style={{ fontWeight: 600 }}>Static IP</span>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Divider
            style={{ marginTop: 15, marginBottom: 10, borderColor: '#e2e2e2' }}
          />
        </Col>
        <Col span={24}>{loading ? 'Loading...' : detailsSection()}</Col>
      </Row>
    </div>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any, ownProps: any) => ({
  alertMessage: (params: { body: string; type: string }) =>
    dispatch(displayMessage(params)),
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  deleteAssociation: (params: IDeleteRecordAssociation, cb: () => {}) =>
    dispatch(deleteRecordAssociationById(params, cb)),
});

export default connect(mapState, mapDispatch)(NetworkAddonStaticIpProduct);
