export const getEnvironmentName = (
    window: any,
): 'localhost' | 'sandbox' | 'youfibre' | 'netomnia' | undefined => {
    if (window) {
        // Localhost
        if (window.location.hostname === 'localhost') {
            return 'localhost';
        }
        // Sandbox
        else if (window.location.hostname.indexOf('sandbox') > -1) {
            return 'sandbox';
        }
        // YouFibre
        else if (window.location.hostname.indexOf('youfibre') > -1) {
            return 'youfibre';
        }
        // Netomnia
        else if (window.location.hostname.indexOf('netomnia') > -1) {
            return 'netomnia';
        } else {
            return undefined;
        }
    } else {
        return undefined;
    }
};
