import { Col, Layout, Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import CardWithTabs from '../../../../../shared/components/CardWithTabs';
import PlainRecordDetailView from '../../../../../shared/PlainRecordDetailView';
import { PartitionOutlined } from '@ant-design/icons';
import {
  getAllSchemaAssociationSchemas,
  getRecordFromShortListById,
} from '../../../../../shared/utilities/recordHelpers';
import { IRecordReducer } from '../../../../../core/records/store/reducer';
import { getSchemaFromShortListBySchemaId } from '../../../../../shared/utilities/schemaHelpers';
import { ISchemaReducer } from '../../../../../core/schemas/store/reducer';
import { renderDynamicAssociations } from '../../../../../core/recordsAssociations/helpers/component-helpers';
import OrderItemView from './../../OrderItem';
import { SchemaModuleTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.types';
import { SchemaModuleEntityTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.entity.types';

const { ORDER_MODULE } = SchemaModuleTypeEnums;
const { ORDER_ITEM } = SchemaModuleEntityTypeEnums;

type PathParams = {
  url: string;
  recordId: string;
};

type PropsType = RouteComponentProps<PathParams> & {
  recordReducer: IRecordReducer;
  schemaReducer: ISchemaReducer;
};

class OrderDetailView extends React.Component<PropsType> {
  render() {
    const { match, recordReducer, schemaReducer } = this.props;
    const record = getRecordFromShortListById(
      recordReducer.shortList,
      match.params.recordId,
    );
    const schema = getSchemaFromShortListBySchemaId(
      schemaReducer.shortList,
      record?.schemaId,
    );
    const relatedSchemas = getAllSchemaAssociationSchemas(
      schema?.associations,
      ['Note', 'OrderItem'],
    );

    const backProps = { backUrl: '/OrderModule' };

    return (
      <>
        <Layout className="record-detail-view">
          <Row gutter={12} className="record-main-content-row">
            <Col span={24} style={{ marginBottom: 8 }}>
              <PlainRecordDetailView {...backProps} />
            </Col>
            <Col span={24} style={{ marginBottom: 8 }}>
              <OrderItemView
                record={record}
                moduleName={ORDER_MODULE}
                entityName={ORDER_ITEM}
              />
            </Col>
            {/* Associated Records */}
            <Col span={24} style={{ marginBottom: 8 }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <CardWithTabs
                  title={
                    <span>
                      <PartitionOutlined className="mobileCardIcon" />
                      Associated Records
                    </span>
                  }
                  defaultTabKey="Contact"
                  tabList={[
                    ...relatedSchemas.map((elem) => ({
                      key: elem.entityName,
                      tab: elem.entityName,
                    })),
                  ]}
                  contentList={{
                    ...renderDynamicAssociations(record, relatedSchemas),
                  }}
                />
              </div>
            </Col>
          </Row>
        </Layout>
      </>
    );
  }
}

const mapState = (state: any) => ({
  recordReducer: state.recordReducer,
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({});

export default withRouter(connect(mapState, mapDispatch)(OrderDetailView));
