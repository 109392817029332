import { Button, Col, Descriptions, Modal, Popconfirm, Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

import dayjs from 'dayjs';
import { IRecordAssociationsReducer } from '../../../../../core/recordsAssociations/store/reducer';
import { ISchemaReducer } from '../../../../../core/schemas/store/reducer';
import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/models/dist/schema-manager/helpers/dbRecordHelpers';
import { httpPost } from '../../../../../shared/http/requests';
import {
  IGetRecordAssociations,
  getRecordAssociationsRequest,
} from '../../../../../core/recordsAssociations/store/actions';
import { displayMessage } from '../../../../../shared/system/messages/store/reducers';

interface Props {
  record: DbRecordEntityTransform;
  recordReducer: any;
  schemaReducer: ISchemaReducer;
  recordAssociationReducer: IRecordAssociationsReducer;
  hidden?: string[];
  getAssociations: any;
  alertMessage: any;
  onSuccess?: any;
}

interface State {
  isLoading: boolean;
  data: any;
}

class SpeedTest extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
      data: undefined,
    };
  }

  handleCancel = () => {
    this.setState({
      isLoading: false,
      data: undefined,
    });
  };

  handleOk = async () => {
    const { record, alertMessage, onSuccess } = this.props;
    this.setState({
      isLoading: true,
    });

    await httpPost(
      `ServiceModule/v1.0/network/eero/eeros/${getProperty(
        record,
        'SerialNumber',
      )}/speedtest`,
      {},
    )
      .then((res: any) => {
        alertMessage({ body: 'successful', type: 'success' });
        this.setState({
          isLoading: false,
          data: res.data.data,
        });
        this.props.onSuccess && onSuccess();
      })
      .catch((err: any) => {
        alertMessage({
          body: 'There was an error processing your request',
          type: 'error',
        });
        this.setState({
          isLoading: false,
          data: undefined,
        });
      });
  };

  render() {
    const { data } = this.state;

    // 1. Speed test is not loading and there's no data
    if (!this.state.data && !this.state.isLoading) {
      return (
        <Row>
          <Col span={24}>
            <Popconfirm
              title="Please confirm you would like to run a speed test for this router."
              onCancel={() => this.handleCancel()}
              onConfirm={() => this.handleOk()}
            >
              <Button
                icon={
                  <i
                    className="bi bi-speedometer2"
                    style={{ marginRight: 8 }}
                  />
                }
                type="primary"
                ghost
                style={{ width: '100%' }}
              >
                Speed Test
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      );
    } else if (!this.state.data && this.state.isLoading) {
      return (
        <Button
          disabled={true}
          loading={true}
          type="primary"
          style={{ width: '100%' }}
          ghost
        >
          Running speed test...
        </Button>
      );
    } else if (this.state.data && !this.state.isLoading) {
      return (
        <Descriptions
          title="Speed test results"
          column={1}
          bordered
          size="small"
          style={{ marginTop: 20, marginBottom: 10 }}
        >
          <Descriptions.Item label="Speed Status">
            {data.speed?.status || '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Speed Date">
            {data.speed?.date
              ? dayjs(data.speed?.date).format('DD/MM/YYYY HH:mm:ss')
              : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Upload Speed">
            {data.speed?.up?.value || '-'} {data.speed?.up?.units || '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Download Speed">
            {data.speed?.down?.value || '-'} {data.speed?.down?.units || '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Internet Status">
            {data.health?.internet?.status || '-'}
          </Descriptions.Item>
          <Descriptions.Item label="ISP Connection">
            {data.health?.internet?.isp_up ? 'True' : 'False'}
          </Descriptions.Item>
          <Descriptions.Item label="Eero Network Status">
            {data.health?.eero_network?.status || '-'}
          </Descriptions.Item>
        </Descriptions>
      );
    } else {
      return <></>;
    }
  }
}

const mapState = (state: any) => ({
  recordReducer: state.recordReducer,
  schemaReducer: state.schemaReducer,
  recordAssociationReducer: state.recordAssociationReducer,
});

const mapDispatch = (dispatch: any) => ({
  getAssociations: (params: IGetRecordAssociations) =>
    dispatch(getRecordAssociationsRequest(params)),
  alertMessage: (params: { body: string; type: string }) =>
    dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(SpeedTest);
