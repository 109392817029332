import { Alert, Button, Card, Tag } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { getProperty } from '@d19n/models/dist/schema-manager/helpers/dbRecordHelpers';
import React from 'react';
import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SupportedMethods } from './supported-methods.enum';
import { httpGet, httpPost } from '../../../../shared/http/requests';
import confirm from 'antd/lib/modal/confirm';
import { SchemaModuleTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.types';
import NetworkWebhookResults from './NetworkWebhookResults';

const { SCHEMA_MODULE } = SchemaModuleTypeEnums;

interface Props {
  record: DbRecordEntityTransform;
  type: string;
}

class NetworkPanel extends React.Component<Props> {
  timer: NodeJS.Timeout | undefined;
  state = {
    loading: undefined,
    error: undefined,
    serviceState: {
      color: 'gray',
      text: 'NOT PROVISIONED',
      enabledMethods: [
        SupportedMethods.PROVISION,
        SupportedMethods.DEPROVISION,
        SupportedMethods.CHECK,
        SupportedMethods.RESTART,
      ],
      locked: undefined,
      loading: undefined,
    },
    deviceState: {
      color: 'gray',
      text: 'NOT PROVISIONED',
      enabledMethods: [
        SupportedMethods.PROVISION,
        SupportedMethods.DEPROVISION,
        SupportedMethods.CHECK,
        SupportedMethods.RESTART,
      ],
      locked: undefined,
      loading: undefined,
    },
    mergedState: {
      color: 'gray',
      text: 'NOT PROVISIONED',
      enabledMethods: [
        SupportedMethods.PROVISION,
        SupportedMethods.DEPROVISION,
        SupportedMethods.CHECK,
        SupportedMethods.RESTART,
      ],
      locked: undefined,
      loading: undefined,
    },
    tabAutoSelect: true,
    initialLoad: false,
  };

  componentDidMount() {
    this.timer = setInterval(() => this.getState(), 5000);

    void this.getState();
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = undefined;
  }

  getState = async () => {
    const { record, type } = this.props;

    const response = await httpGet(
      `ServiceModule/v2.0/network/onu/${type}/${record.id}/state`,
    );

    const serviceState = response.data.data as {
      color: string;
      text: string;
      enabledMethods: SupportedMethods[];
      locked: boolean;
    };

    const deviceResponse = await httpGet(
      `ServiceModule/v2.0/network/onu/device/${record.id}/state`,
    );

    const deviceState = deviceResponse.data.data as {
      color: string;
      text: string;
      enabledMethods: SupportedMethods[];
      locked: boolean;
    };

    this.setState({
      ...this.state,
      serviceState,
      deviceState,
      mergedState: {
        ...serviceState,
        enabledMethods: serviceState.enabledMethods.filter((x) =>
          deviceState.enabledMethods.includes(x),
        ),
        locked: serviceState.locked || deviceState.locked,
      },
      initialLoad: true,
    });
  };

  activate = () => {
    confirm({
      title: 'Provision Request',
      content: 'Please confirm you would like to provision the ONT',
      onOk: async () => {
        const { record, type } = this.props;

        this.setState({
          isEditDisabled: true,
          loading: 'ACTIVATE',
          tabAutoSelect: true,
        });

        await httpPost(
          `ServiceModule/v2.0/network/onu/${type}/${record.id}/activate`,
          {},
        )
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            const error = err.response ? err.response.data : undefined;
            this.setState({
              isEditDisabled: false,
              loading: undefined,
              error:
                (error && error.message) || 'error processing your request',
            });
          });
      },
    });
  };

  deactivate = () => {
    confirm({
      title: 'Deprovision Request',
      content: 'Please confirm you would like to deprovision the ONT',
      onOk: async () => {
        const { record, type } = this.props;

        this.setState({
          isEditDisabled: true,
          loading: 'DEACTIVATE',
          tabAutoSelect: true,
        });

        await httpPost(
          `ServiceModule/v2.0/network/onu/${type}/${record.id}/deactivate`,
          {},
        )
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            const error = err.response ? err.response.data : undefined;
            this.setState({
              isEditDisabled: false,
              loading: undefined,
              error:
                (error && error.message) || 'error processing your request',
            });
          });
      },
    });
  };

  checkStatus = () => {
    confirm({
      title: 'Status Check',
      content: 'Please confirm you would like check the status of the ONT',
      onOk: async () => {
        const { record, type } = this.props;

        this.setState({
          isEditDisabled: true,
          loading: 'CHECK',
          tabAutoSelect: true,
        });

        await httpPost(
          `ServiceModule/v2.0/network/onu/${type}/${record.id}/check`,
          {},
        )
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            const error = err.response ? err.response.data : undefined;
            this.setState({
              isEditDisabled: false,
              loading: undefined,
              error:
                (error && error.message) || 'error processing your request',
            });
          });
      },
      onCancel() {
        console.log('Cancel Check Status Request');
      },
    });
  };

  rebootOnt = () => {
    confirm({
      title: 'Reboot ONT',
      content:
        'Rebooting will take several minutes and will disrupt connection, would you like to continue?',
      onOk: async () => {
        const { record, type } = this.props;

        this.setState({
          isEditDisabled: true,
          loading: 'REBOOT',
          tabAutoSelect: true,
        });

        await httpPost(
          `ServiceModule/v2.0/network/onu/${type}/${record.id}/restart`,
          {},
        )
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            const error = err.response ? err.response.data : undefined;
            this.setState({
              isEditDisabled: false,
              loading: undefined,
              error:
                (error && error.message) || 'error processing your request',
            });
          });
      },
      onCancel() {
        console.log('Cancel Check Status Request');
      },
    });
  };

  render() {
    const { record } = this.props;
    const { loading, error } = this.state;

    return (
      <div className="ont-view-wrapper">
        <Card
          size="small"
          title="Network"
          extra={
            <>
              <div>
                <Tag color={this.state.deviceState.color}>
                  ONT {this.state.deviceState.text}
                </Tag>
              </div>
              <div>
                <Tag color={this.state.serviceState.color}>
                  SERVICE {this.state.serviceState.text}
                </Tag>
              </div>
            </>
          }
        >
          <div>
            {error && (
              <>
                <Alert
                  type="error"
                  message={
                    <>
                      <strong>Error: </strong> {error}
                    </>
                  }
                />
                <br />
              </>
            )}

            {this.state.initialLoad ? (
              <div
                className="ont-view-controls"
                style={{ display: 'flex', marginBottom: 5 }}
              >
                {this.state.mergedState.enabledMethods.length === 0 && (
                  <div>
                    No actions are available at the moment, please wait a few
                    minutes.
                  </div>
                )}

                {this.state.serviceState.enabledMethods.includes(
                  SupportedMethods.PROVISION,
                ) && (
                  <div style={{ marginRight: 12 }}>
                    <Button
                      disabled={
                        this.state.serviceState.locked ||
                        this.state.serviceState.loading
                      }
                      type="primary"
                      ghost
                      onClick={this.activate}
                    >
                      Provision{' '}
                      {(loading === 'ACTIVATE' ||
                        this.state.serviceState.loading ===
                          SupportedMethods.PROVISION) && (
                        <LoadingOutlined spin />
                      )}
                    </Button>
                  </div>
                )}

                {this.state.serviceState.enabledMethods.includes(
                  SupportedMethods.DEPROVISION,
                ) && (
                  <div style={{ marginRight: 12 }}>
                    <Button
                      disabled={
                        this.state.serviceState.locked ||
                        this.state.serviceState.loading
                      }
                      type="primary"
                      ghost
                      danger
                      onClick={this.deactivate}
                    >
                      Deprovision{' '}
                      {(loading === 'DEACTIVATE' ||
                        this.state.serviceState.loading ===
                          SupportedMethods.DEPROVISION) && (
                        <LoadingOutlined spin />
                      )}
                    </Button>
                  </div>
                )}

                {this.state.serviceState.enabledMethods.includes(
                  SupportedMethods.CHECK,
                ) && (
                  <div style={{ marginRight: 12 }}>
                    <Button
                      disabled={
                        this.state.serviceState.locked ||
                        this.state.serviceState.loading
                      }
                      onClick={this.checkStatus}
                    >
                      Check Status{' '}
                      {(loading === 'CHECK' ||
                        this.state.serviceState.loading ===
                          SupportedMethods.CHECK) && <LoadingOutlined spin />}
                    </Button>
                  </div>
                )}

                {this.state.serviceState.enabledMethods.includes(
                  SupportedMethods.RESTART,
                ) && (
                  <div style={{ marginRight: 12 }}>
                    <Button
                      disabled={
                        this.state.serviceState.locked ||
                        this.state.serviceState.loading
                      }
                      onClick={this.rebootOnt}
                    >
                      Reboot ONT{' '}
                      {(loading === 'REBOOT' ||
                        this.state.serviceState.loading ===
                          SupportedMethods.RESTART) && <LoadingOutlined spin />}
                    </Button>
                  </div>
                )}
              </div>
            ) : (
              <>Loading...</>
            )}
          </div>

          <NetworkWebhookResults
            title={'Activity'}
            record={record}
            moduleName={SCHEMA_MODULE}
            entityName="Activity"
            onNewWebhookReceived={(record: any) => {
              const data = getProperty(record, 'JSONData');
              const type = data.webhookType;

              if (
                (loading === 'ACTIVATE' && type === 'SERVICE_PROVISION') ||
                (loading === 'DEACTIVATE' && type === 'SERVICE_DEPROVISION') ||
                (loading === 'CHECK' && type === 'SERVICE_CHECK') ||
                (loading === 'REBOOT' && type === 'DEVICE_REBOOT')
              ) {
                this.setState({
                  ...this.state,
                  isEditDisabled: false,
                  loading: undefined,
                });
              }
            }}
            tabAutoSelect={this.state.tabAutoSelect}
            setTabAutoSelect={(tabAutoSelect: boolean) =>
              this.setState({ ...this.state, tabAutoSelect })
            }
          />
        </Card>
      </div>
    );
  }
}

export default NetworkPanel;
