import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/models/dist/schema-manager/schema/schema.entity';
import React from 'react';
import { IRecordReducer } from '../../../../../../core/records/store/reducer';
import { IRecordAssociationsReducer } from '../../../../../../core/recordsAssociations/store/reducer';
import { ISchemaReducer } from '../../../../../../core/schemas/store/reducer';
import { VoiceActivateForm } from '../../VoiceActivateForm';

export interface Props {
  record: DbRecordEntityTransform;
  recordReducer: IRecordReducer;
  schemaReducer: ISchemaReducer;
  parentSchema: SchemaEntity;
  recordAssociationReducer: IRecordAssociationsReducer;
  sendConfirmation: any;
  getSchema: any;
  getAssociations: any;
  type: string;
}

class OrderItemLegacyVoiceView extends React.Component<Props> {
  render() {
    return <VoiceActivateForm targetRecord={this.props.record} />;
  }
}

export default OrderItemLegacyVoiceView;
