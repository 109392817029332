import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaModuleEntityTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.entity.types';
import { GET_ACTIVE_OFFER_SUCCESS, UPDATE_ORDER_WORKFLOW } from './constants';

const { ORDER, OFFER } = SchemaModuleEntityTypeEnums;

export interface WorkflowReducer {
  [ORDER]: {
    addressRecord: DbRecordEntityTransform | undefined;
    cppModalVisible: boolean;
    createCPP: boolean;
    AreaCode: string | undefined;
    CountryCode: string | undefined;
    SubscriberNumber: string | undefined;
    AuthorizedLOA: boolean;
  };
  [OFFER]: {
    activeOffer: DbRecordEntityTransform | undefined;
  };
}

export const initialState: WorkflowReducer = {
  [ORDER]: {
    addressRecord: undefined,
    cppModalVisible: false,
    createCPP: false,
    AreaCode: undefined,
    CountryCode: undefined,
    SubscriberNumber: undefined,
    AuthorizedLOA: false,
  },
  [OFFER]: {
    activeOffer: undefined,
  },
};

function reducer(state = initialState, action: any) {
  switch (action.type) {
    case UPDATE_ORDER_WORKFLOW: {
      return {
        ...state,
        [ORDER]: {
          ...state[ORDER],
          ...action.params,
        },
      };
    }

    case GET_ACTIVE_OFFER_SUCCESS: {
      return {
        ...state,
        [OFFER]: {
          ...state[OFFER],
          activeOffer: action.results.data,
        },
      };
    }

    default:
      return state;
  }
}

export default reducer;
