import { CalendarOutlined, ReloadOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Card, Col, Layout, Row, Tag } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import './index.scss';

interface Props {
  userReducer: any;
}

const ModuleSelector = (props: Props) => {

  const flushApplicationCacheAndRefresh = async () => {
    const names = await caches.keys();
    await Promise.all(names.map((name) => caches.delete(name))).then(() => {
      window.location.reload();
    });
  };

  const [refreshingCache, setRefreshingCache] = useState<boolean>(false)

  return (
    <Layout style={{ padding: '10px' }}>
      <Row>
        {/* WORK ORDERS - Install/Service */}
        <Col span={24} style={{ textAlign: 'center' }}>
          <Link to="/FieldServiceModule/WorkOrder">
            <Card
              className="moduleSelectorCard"
              title={
                <Row>
                  <Col span={24}>
                    <CalendarOutlined className="moduleSelectorIcon" />
                  </Col>
                  <Col span={24}>
                    <span>
                      Work Orders
                    </span>
                  </Col>
                </Row>
              }
              bordered
            >
              See a list of install and service work orders.
            </Card>
          </Link>
        </Col>

        <Col span={24} style={{ textAlign: 'center', marginBottom: 30 }}>
          <Card
            className="moduleSelectorCard"
            title={
              <Row>
                <Col span={24}>
                  <SettingOutlined className="moduleSelectorIcon" />
                </Col>
                <Col span={24}>
                  <span>Settings</span>
                </Col>
              </Row>
            }
            bordered
          >
            <Row>
              <Col span={12} style={{ textAlign: 'left' }}>
                {process.env.REACT_APP_VERSION ? (
                  <Tag>v{process.env.REACT_APP_VERSION}</Tag>
                ) : (
                  <></>
                )}
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                <Button
                  size="small"
                  type="primary"
                  icon={<ReloadOutlined />}
                  loading={refreshingCache}
                  disabled={refreshingCache}
                  ghost
                  onClick={() => {
                    setRefreshingCache(true)
                    setTimeout(
                      flushApplicationCacheAndRefresh
                      , 3000
                    )
                  }}
                >
                  Refresh
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
};

const renderOrdersVisable = (props: Props) => {
  const { userReducer } = props;
  if (
    ['SalesHead', 'SalesManager', 'system.admin'].some((el) =>
      userReducer.roles.includes(el),
    )
  ) {
    return 'block';
  } else {
    return 'none';
  }
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

export default withRouter(connect(mapState)(ModuleSelector));
