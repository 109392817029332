export const GET_SCHEMA_ASSOCIATIONS_REQUEST =
  'GET_SCHEMA_ASSOCIATIONS_REQUEST';
export const GET_SCHEMA_ASSOCIATIONS_SUCCESS =
  'GET_SCHEMA_ASSOCIATIONS_SUCCESS';
export const GET_SCHEMA_ASSOCIATIONS_ERROR = 'GET_SCHEMA_ASSOCIATIONS_ERROR';

export const CREATE_SCHEMA_ASSOCIATION_REQUEST =
  'CREATE_SCHEMA_ASSOCIATION_REQUEST';
export const CREATE_SCHEMA_ASSOCIATION_SUCCESS =
  'CREATE_SCHEMA_ASSOCIATION_SUCCESS';
export const CREATE_SCHEMA_ASSOCIATION_ERROR =
  'CREATE_SCHEMA_ASSOCIATION_ERROR';

export const UPDATE_SCHEMA_ASSOCIATION_REQUEST =
  'UPDATE_SCHEMA_ASSOCIATION_REQUEST';
export const UPDATE_SCHEMA_ASSOCIATION_SUCCESS =
  'UPDATE_SCHEMA_ASSOCIATION_SUCCESS';
export const UPDATE_SCHEMA_ASSOCIATION_ERROR =
  'UPDATE_SCHEMA_ASSOCIATION_ERROR';

export const DELETE_SCHEMA_ASSOCIATION_REQUEST =
  'DELETE_SCHEMA_ASSOCIATIONS_REQUEST';
export const DELETE_SCHEMA_ASSOCIATION_SUCCESS =
  'DELETE_SCHEMA_ASSOCIATIONS_SUCCESS';
export const DELETE_SCHEMA_ASSOCIATION_ERROR =
  'DELETE_SCHEMA_ASSOCIATIONS_ERROR';
