export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export const USER_LOGIN_CANCEL_REQUESTS = 'USER_LOGIN_CANCEL_REQUESTS';
export const USER_LOGIN_REQUIRE_OTP = 'USER_LOGIN_REQUIRE_OTP';

export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_ERROR = 'USER_LOGOUT_ERROR';

export const UPDATE_USER_ROLES_AND_PERMISSIONS_REQUEST =
  'UPDATE_USER_ROLES_AND_PERMISSIONS_REQUEST';
export const UPDATE_USER_ROLES_AND_PERMISSIONS_SUCCESS =
  'UPDATE_USER_ROLES_AND_PERMISSIONS_SUCCESS';
export const UPDATE_USER_ROLES_AND_PERMISSIONS_ERROR =
  'UPDATE_USER_ROLES_AND_PERMISSIONS_ERROR';

export const GET_USER_LIST_REQUEST = 'GET_USER_LIST_REQUEST';
export const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS';
export const GET_USER_LIST_ERROR = 'GET_USER_LIST_ERROR';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
