import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Col, Layout, Row, Tabs, Typography } from 'antd';
import React from 'react';
import { connect, ConnectedComponent } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { sendConfirmationEmail } from '../../../../../core/notifications/email/store/actions';
import { closeRecordForm } from '../../../../../core/records/components/Forms/store/actions';
import { IRecordReducer } from '../../../../../core/records/store/reducer';
import { ISchemaReducer } from '../../../../../core/schemas/store/reducer';
import { getRecordFromShortListById } from '../../../../../shared/utilities/recordHelpers';
import OrderItemLegacyOntView from './ont/LegacyOntView';
import OrderItemOntView from './ont/OntView';
import AddRouterDevice from './AddRouterDevice';
import {
  showCustomerDeviceOnt,
  showCustomerDeviceRouter,
  showVoiceTab,
} from './component-rendering-conditions';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { getSchemaFromShortListBySchemaId } from '../../../../../shared/utilities/schemaHelpers';
import { getProperty } from '@d19n/models/dist/schema-manager/helpers/dbRecordHelpers';
import OrderItemLegacyVoiceView from './ont/LegacyVoiceView';

interface Props {
  recordReducer: IRecordReducer;
  schemaReducer: ISchemaReducer;
  sendConfirmation: any;
  match: any;
  closeForm: any;
}

interface State {
  selectedTab?: string;
}

const providerDataOverride: { [key: string]: ConnectedComponent<any, any> } = {
  NETOMNIA_LEGACY: OrderItemLegacyOntView,
};
const providerVoiceOverride: {
  [key: string]: any;
} = {
  NETOMNIA_LEGACY: OrderItemLegacyVoiceView,
};

class WorkOrderOrderItemView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedTab: undefined,
    };
  }

  componentDidMount() {
    this.setDefaultTab();
  }

  private handleOntView() {
    const { recordReducer, schemaReducer, match } = this.props;

    const sourceRecord = getRecordFromShortListById(
      recordReducer.shortList,
      match.params.parentRecordId,
    );

    const targetRecord = getRecordFromShortListById(
      recordReducer.shortList,
      match.params.recordId,
    );

    const schema = getSchemaFromShortListBySchemaId(
      schemaReducer.shortList,
      targetRecord?.schemaId,
    );

    const provider = getProperty(sourceRecord, 'Provider');

    return React.createElement(
      provider
        ? (providerDataOverride[provider] as any) || (OrderItemOntView as any)
        : OrderItemLegacyOntView,
      { parentSchema: schema!, record: targetRecord, type: 'data' },
    );
  }

  private handleVoiceView() {
    const { recordReducer, schemaReducer, match } = this.props;

    const sourceRecord = getRecordFromShortListById(
      recordReducer.shortList,
      match.params.parentRecordId,
    );

    const targetRecord = getRecordFromShortListById(
      recordReducer.shortList,
      match.params.recordId,
    );

    const schema = getSchemaFromShortListBySchemaId(
      schemaReducer.shortList,
      targetRecord?.schemaId,
    );

    const provider = getProperty(sourceRecord, 'Provider');

    return React.createElement(
      provider
        ? providerVoiceOverride[provider] || OrderItemOntView
        : OrderItemLegacyVoiceView,
      { parentSchema: schema!, record: targetRecord, type: 'data' },
    );
  }

  setDefaultTab(targetRecord?: DbRecordEntityTransform) {
    if (targetRecord) {
      if (showCustomerDeviceOnt(targetRecord)) {
        if (!this.state.selectedTab) {
          this.setState({
            selectedTab: '#tab1_ONT',
          });
        }
        return '#tab1_ONT';
      } else if (showVoiceTab(targetRecord)) {
        if (!this.state.selectedTab) {
          this.setState({
            selectedTab: '#tab3_Devices',
          });
        }
        return '#tab3_Devices';
      } else {
        if (!this.state.selectedTab) {
          this.setState({
            selectedTab: '#tab1_Router',
          });
        }
        return '#tab1_Router';
      }
    }
  }

  render() {
    const { recordReducer, schemaReducer, match, closeForm } = this.props;
    const sourceRecord = getRecordFromShortListById(
      recordReducer.shortList,
      match.params.parentRecordId,
    );
    const targetRecord = getRecordFromShortListById(
      recordReducer.shortList,
      match.params.recordId,
    );

    const schema = getSchemaFromShortListBySchemaId(
      schemaReducer.shortList,
      targetRecord?.schemaId,
    );

    console.log('sourceRecord', sourceRecord);
    console.log('targetRecord', targetRecord);

    return (
      <Layout className="record-detail-view">
        <Row gutter={12} className="record-main-content-row">
          <Col span={24} style={{ marginBottom: 15 }}>
            <Link
              to={`/FieldServiceModule/WorkOrder/${match.params.parentRecordId}`}
              component={Typography.Link}
              style={{ fontSize: 14 }}
            >
              <ArrowLeftOutlined style={{ marginRight: 10 }} />
              Back to work order
            </Link>
          </Col>
          <Col span={24}>
            <Typography.Title level={4}>{targetRecord?.title}</Typography.Title>
            <Tabs
              style={{ background: '#fff', margin: 0, padding: 12 }}
              defaultActiveKey={this.setDefaultTab(targetRecord)}
              activeKey={this.state.selectedTab}
              destroyInactiveTabPane={true}
              onChange={(key) => {
                this.setState({ selectedTab: key });
                closeForm();
              }}
            >
              {showVoiceTab(targetRecord) && (
                <Tabs.TabPane
                  forceRender={true}
                  tab="Devices"
                  key={`#tab3_Devices`}
                >
                  {this.handleVoiceView()}
                </Tabs.TabPane>
              )}
              {showCustomerDeviceOnt(targetRecord) && (
                <Tabs.TabPane forceRender={true} tab="ONT" key={`#tab1_ONT`}>
                  {this.state.selectedTab === '#tab1_ONT' &&
                    this.handleOntView()}
                </Tabs.TabPane>
              )}
              {showCustomerDeviceRouter(targetRecord) && (
                <Tabs.TabPane
                  forceRender={true}
                  tab="Router"
                  key={`#tab1_Router`}
                >
                  {this.state.selectedTab === '#tab1_Router' && (
                    <AddRouterDevice
                      parentSchema={schema!}
                      record={targetRecord}
                    />
                  )}
                </Tabs.TabPane>
              )}
            </Tabs>
          </Col>
        </Row>
      </Layout>
    );
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  recordReducer: state.recordReducer,
});

const mapDispatch = (dispatch: any) => ({
  closeForm: () => dispatch(closeRecordForm()),
  sendConfirmation: (payload: any) => dispatch(sendConfirmationEmail(payload)),
});

export default withRouter(
  connect(mapState, mapDispatch)(WorkOrderOrderItemView),
);
