import React from 'react';
import { connect } from 'react-redux';
import { SchemaModuleEntityTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.types';
import {
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Layout,
  PageHeader,
  Row,
  Spin,
  Tag,
  Typography,
} from 'antd';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRecordReducer } from '../../../../../core/records/store/reducer';
import { IRecordAssociationsReducer } from '../../../../../core/recordsAssociations/store/reducer';
import history from '../../../../../shared/utilities/browserHisory';
import AssociationDataTable from '../../../../../core/recordsAssociations/AssociationDataTable/DataTable';
import CardWithTabs from '../../../../../shared/components/CardWithTabs';
import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/models/dist/schema-manager/helpers/dbRecordHelpers';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../../shared/utilities/schemaHelpers';
import {
  createRecordsRequest,
  getRecordByIdRequest,
  IGetRecordById,
  updateAddressRecordWithVisitRequest,
  updateIsBackPropertyRequest,
} from '../../../../../core/records/store/actions';
import { initializeRecordForm } from '../../../../../core/records/components/Forms/store/actions';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '../../../../../core/schemas/store/actions';
import { ISchemaReducer } from '../../../../../core/schemas/store/reducer';
import {
  getPremiseByUdprnAndUmprnRequest,
  premiseListCancelRequests,
} from '../../Premise/store/actions';
import { parseDateToLocalFormat } from '../../../../../shared/utilities/dateHelpers';
import {
  HomeOutlined,
  PartitionOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import './styles.scss';
import {
  getRecordAssociationsRequest,
  IGetRecordAssociations,
} from '../../../../../core/recordsAssociations/store/actions';
import { updateOrderWorkflow } from '../../../../../core/workflow/store/actions';
import { displayMessage } from '../../../../../shared/system/messages/store/reducers';
import CoreLegacyEmbeddedForm from '../../../../../core/records/components/Forms/CoreLegacyEmbeddedForm';
import CoreForm from '../../../../../core/records/components/Forms/CoreForm';

const uuid = uuidv4();

const {
  ORDER_MODULE,
  CRM_MODULE,
  BILLING_MODULE,
  FIELD_SERVICE_MODULE,
  SERVICE_MODULE,
} = SchemaModuleTypeEnums;
const {
  ADDRESS,
  LEAD,
  ACCOUNT,
  CONTACT,
  INVOICE,
  ORDER,
  WORK_ORDER,
  CUSTOMER_DEVICE_ONT,
  CUSTOMER_DEVICE_ROUTER,
  VISIT,
} = SchemaModuleEntityTypeEnums;

type PathParams = { url: string; recordId: string };

type PropsType = RouteComponentProps<PathParams> & {
  recordReducer: IRecordReducer;
  recordAssociationReducer: IRecordAssociationsReducer;
  match: any;
  hasColumnMappings?: boolean;
  visibleProperties?: string[];
  schemaReducer: ISchemaReducer;
  identityReducer: any;
  cancelRequests: any;
  initializeForm: any;
  getSchema: any;
  getPremiseByUdprnAndUmprn: any;
  createRecord: any;
  recordFormReducer: any;
  navigationReducer: any;
  getAssociations: (params: IGetRecordAssociations, cb: any) => any;
  getRecordById: (payload: IGetRecordById, cb: any) => DbRecordEntityTransform;
  updateOrderWorkflowAction: Function;
  alertMessage: any;
  updateAddressRecordWithVisit: (params: {
    visitRecord: DbRecordEntityTransform;
    addressRecord: DbRecordEntityTransform;
  }) => void;
  updateIsBackProperty: () => void;
};

interface State {
  coordinates: number[][];
  visit: any;
  addressType: 'RESIDENTIAL' | 'BUSINESS';
  visitRecord: DbRecordEntityTransform | undefined;
  addressRecord: DbRecordEntityTransform | undefined;
}

class AddressDetailView extends React.Component<PropsType, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      coordinates: [],
      visit: null,
      addressType: 'RESIDENTIAL',
      visitRecord: undefined,
      addressRecord: undefined,
    };
  }

  componentDidMount() {
    this.loadSchema();
    this.getAddressRecord();
    this.initializeContactForm();
    this.getGeolocation();
  }

  componentDidUpdate(prevProps: Readonly<PropsType>) {
    // When the visit form clears schema from the form reducer, we want to reinitialize the contact form.
    if (
      prevProps.recordFormReducer.schema &&
      !this.props.recordFormReducer.schema
    ) {
      this.initializeContactForm();
    }
  }

  async initializeFormForNewVisit() {
    const { coordinates, addressRecord } = this.state;
    const { schemaReducer, initializeForm } = this.props;

    const visitSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      CRM_MODULE,
      'Visit',
    );

    if (visitSchema && addressRecord) {
      return initializeForm({
        formUUID: uuid,
        title: 'Record New Visit',
        showFormModal: true,
        isCreateReq: true,
        schema: visitSchema,
        modified: [
          {
            associations: [
              {
                recordId: addressRecord.id,
                entity: addressRecord.entity,
              },
            ],
            schemaId: visitSchema.id,
            properties: {
              UDPRN: getProperty(addressRecord, 'UDPRN') || '',
              UMPRN: getProperty(addressRecord, 'UMPRN') || '',
              Coordinates: coordinates,
            },
          },
        ],
        sections: [{ name: visitSchema.name, schema: visitSchema }],
        payload: [],
      });
    }
  }

  /* Get schema by module and entity and save it to the local state */
  loadSchema() {
    const { getSchema } = this.props;
    getSchema({ moduleName: CRM_MODULE, entityName: ADDRESS });
    getSchema({ moduleName: CRM_MODULE, entityName: CONTACT });
    getSchema({ moduleName: CRM_MODULE, entityName: LEAD });
    getSchema({ moduleName: CRM_MODULE, entityName: VISIT });
  }

  /* Get location that we need for saving the Visit */
  getGeolocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (res) => {
          this.setState({
            coordinates: [[res.coords.longitude, res.coords.latitude]],
          });
        },
        (err) => {
          console.error(err);
        },
      );
    }
  }

  // After visit is created, update the address record with the visit.
  onVisitCreate = (createdVisit: any) => {
    const {
      updateAddressRecordWithVisit,
      schemaReducer,
      updateIsBackProperty,
      getRecordById,
    } = this.props;

    const visitSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      CRM_MODULE,
      VISIT,
    );

    if (createdVisit?.results && visitSchema) {
      getRecordById(
        { schema: visitSchema, recordId: createdVisit?.results?.id },
        (visitRecord: DbRecordEntityTransform) => {
          if (visitRecord) {
            this.setState({ visitRecord: visitRecord });
            updateAddressRecordWithVisit({
              visitRecord: visitRecord,
              addressRecord: this.state.addressRecord!,
            });
            updateIsBackProperty();
          }
        },
      );
    }
  };

  // // Fetch Premise by UDPRN and set addressType
  // fetchPremiseByUDPRN = (addressRecord: DbRecordEntityTransform) => {
  //
  //   const { getPremiseByUdprnAndUmprn } = this.props
  //
  //   getPremiseByUdprnAndUmprn({
  //     udprn: getProperty(addressRecord, 'UDPRN'),
  //     umprn: getProperty(addressRecord, 'UMPRN'),
  //   },
  //     (res: any) => {
  //       this.setState({
  //         addressType: res.data?.ab_plus_class_1 === 'B' ? 'BUSINESS' : 'RESIDENTIAL',
  //       })
  //     },
  //   )
  //
  // }

  // Fetch Visit record by id and set it to state
  fetchVisitByRecordId = (recordId: string) => {
    const { getRecordById, schemaReducer } = this.props;
    const visitSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      CRM_MODULE,
      VISIT,
    );

    if (visitSchema && recordId) {
      getRecordById(
        { schema: visitSchema, recordId: recordId },
        (visitRecord: any) => {
          if (visitRecord) {
            this.setState({
              visitRecord: visitRecord,
            });
          }
        },
      );
    }
  };

  // Get address record by URL param id, check if there are associated visits, get the newest Visit.
  getAddressRecord = async () => {
    const { getRecordById, schemaReducer, match } = this.props;

    const addressSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      CRM_MODULE,
      ADDRESS,
    );

    if (addressSchema && match.params?.recordId) {
      getRecordById(
        { schema: addressSchema, recordId: match.params?.recordId },
        (addressRecord: any) => {
          if (addressRecord) {
            let visits = addressRecord?.links.filter(
              (link: any) => link.entity === `${CRM_MODULE}:${VISIT}`,
            );

            // this.fetchPremiseByUDPRN(addressRecord)

            // If there are associated visits, get the newest one.
            if (visits?.length > 0) {
              const latest = visits.reduce(function (r: any, a: any) {
                return r.createdAt > a.createdAt ? r : a;
              });
              this.fetchVisitByRecordId(latest.id);
            }

            this.setState({ addressRecord: addressRecord });
          }
        },
      );
    }
  };

  /* Save Contact form with the related Address record */
  handleContactFormSave = () => {
    const { addressRecord } = this.state;

    const { schemaReducer, recordFormReducer, createRecord, identityReducer } =
      this.props;

    const contactSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      CRM_MODULE,
      CONTACT,
    );
    const leadSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      CRM_MODULE,
      LEAD,
    );

    type User = {
      id: string;
      email: string;
      firstname: string;
      lastname: string;
    };

    let modified = recordFormReducer.modified;

    /* Get User Identity */
    const userIdentity: User = {
      id: identityReducer?.user?.id,
      email: identityReducer?.user?.email,
      firstname: identityReducer?.user?.firstname,
      lastname: identityReducer?.user?.lastname,
    };

    if (contactSchema && leadSchema && addressRecord) {
      if (modified[0]) {
        modified[0].associations = [
          { recordId: addressRecord.id, entity: addressRecord.entity },
        ];
        modified[0].title = `${getProperty(
          modified[0],
          'FirstName',
        )} ${getProperty(modified[0], 'LastName')}`;

        /* Create Contact */
        createRecord(
          {
            schema: contactSchema,
            upsert: recordFormReducer.upsert,
            createUpdate: modified,
          },
          (res: any) => {
            if (res) {
              /* Then Create Lead */
              createRecord({
                schema: leadSchema,

                upsert: recordFormReducer.upsert,
                createUpdate: [
                  {
                    title: getProperty(addressRecord, 'FullAddress'),
                    schemaId: leadSchema.id,
                    ownerId: userIdentity.id,
                    entity: 'CrmModule:Lead',
                    properties: {
                      Source: 'FIELDSALES',
                      Type: this.state.addressType || 'RESIDENTIAL',
                      EmailAddress: getProperty(modified[0], 'EmailAddress'),
                    },
                    associations: [
                      {
                        recordId: addressRecord.id,
                        entity: addressRecord.entity,
                      },
                      { recordId: res.id, entity: addressRecord.entity },
                    ],
                  },
                ],
              });
            }
          },
        );
      }
    }
  };

  initializeContactForm() {
    const { initializeForm, schemaReducer } = this.props;

    const contactSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      CRM_MODULE,
      CONTACT,
    );

    if (contactSchema) {
      initializeForm({
        formUUID: uuid,
        showFormModal: false,
        showInitializing: false,
        isCreateReq: true,
        schema: contactSchema,
        modified: [
          {
            schemaId: contactSchema.id,
            properties: { Phone: '+44' },
          },
        ],
        sections: [{ name: contactSchema.name, schema: contactSchema }],
      });
    }
  }

  render() {
    const { Text } = Typography;
    const { schemaReducer, recordReducer } = this.props;
    const { addressRecord } = this.state;

    const contactSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      CRM_MODULE,
      CONTACT,
    );

    return (
      <>
        {/* Page Header */}
        <PageHeader
          className="page-header"
          ghost={false}
          title={
            <>
              <span onClick={() => history.push('/CrmModule/Address')}>
                Back
              </span>
            </>
          }
          onBack={() => history.push('/CrmModule/Address')}
        >
          {/* Create Visit Modal Form */}
          <CoreForm
            type="MODAL"
            formUUID={uuid}
            onSubmitEvent={(record: any) => this.onVisitCreate(record)}
          />

          <Row>
            <Col span={24}>
              <Divider style={{ marginTop: 0, marginBottom: '15px' }} />
            </Col>
          </Row>

          {/* Address Title */}
          <Row
            style={{
              backgroundColor: '#e4f2ff',
              borderRadius: '5px',
              padding: '10px 10px 10px 15px',
            }}
          >
            <Col span={12}>
              <Text style={{ fontSize: '1.1em', fontWeight: 500 }}>
                Address
              </Text>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Tag color="blue">{this.state.addressType}</Tag>
            </Col>
            <Divider style={{ margin: '10px 0 8px 0' }} />
            <Col span={24} style={{ paddingTop: '5px' }}>
              <span style={{ fontSize: '1em' }}>
                {addressRecord?.title || <Spin size="small" />}
              </span>
            </Col>
          </Row>
        </PageHeader>

        <Layout className="record-detail-view">
          <Row gutter={12} className="record-main-content-row">
            {/* Address Details Card */}
            <Col span={24} style={{ marginBottom: '15px' }}>
              <Card
                className="contactUpdateCard"
                title={
                  <span>
                    <HomeOutlined className="mobileCardIcon" />
                    Visit Details
                  </span>
                }
                extra={
                  <Button
                    type="primary"
                    onClick={() => this.initializeFormForNewVisit()}
                  >
                    Update Visit
                  </Button>
                }
              >
                <Descriptions bordered size="small" column={1}>
                  <Descriptions.Item label="Sales status">
                    <span className="descriptionText">
                      {getProperty(addressRecord, 'SalesStatus') || '-'}
                    </span>
                  </Descriptions.Item>

                  <Descriptions.Item label="Follow up date">
                    <span className="descriptionText">
                      {parseDateToLocalFormat(
                        getProperty(this.state.visitRecord, 'FollowUpDate'),
                      ) || '-'}
                    </span>
                  </Descriptions.Item>

                  <Descriptions.Item label="Interested Reason">
                    <span className="descriptionText">
                      {getProperty(
                        this.state.visitRecord,
                        'InterestedReason',
                      ) || '-'}
                    </span>
                  </Descriptions.Item>

                  <Descriptions.Item label="Not Interested Reason">
                    <span className="descriptionText">
                      {getProperty(
                        this.state.visitRecord,
                        'NotInterestedReason',
                      ) || '-'}
                    </span>
                  </Descriptions.Item>

                  <Descriptions.Item label="Outcome">
                    <span className="descriptionText">
                      {getProperty(this.state.visitRecord, 'Outcome') || '-'}
                    </span>
                  </Descriptions.Item>

                  <Descriptions.Item label="Last visited by">
                    <span className="descriptionText">
                      {this.state.visitRecord?.createdBy?.fullName || '-'}
                    </span>
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>

            {/* Contact Details Card */}
            <Col span={24} style={{ marginBottom: '15px' }}>
              <Card
                className="contactUpdateCard"
                title={
                  <span>
                    <UserOutlined className="mobileCardIcon" />
                    Contact Details
                  </span>
                }
                extra={
                  <Button
                    type="primary"
                    loading={recordReducer.isCreating}
                    onClick={() => this.handleContactFormSave()}
                  >
                    Update Details
                  </Button>
                }
              >
                <CoreLegacyEmbeddedForm
                  formUUID={uuid}
                  formDirection="vertical"
                  showFormActions={false}
                  isCreateRecord
                  hideStageFormField
                />
              </Card>
            </Col>

            {/* Associated Records */}
            <Col span={24} style={{ marginBottom: 8 }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <CardWithTabs
                  title={
                    <span>
                      <PartitionOutlined className="mobileCardIcon" />
                      Associated Records
                    </span>
                  }
                  defaultTabKey="Order"
                  tabList={[
                    { key: 'Order', tab: 'Orders' },
                    { key: 'WorkOrder', tab: 'WorkOrders' },
                    { key: 'Billing', tab: 'Billing' },
                    { key: 'CustomerDevices', tab: 'CustomerDevices' },
                    { key: 'Contact', tab: 'Contacts' },
                    { key: 'Lead', tab: 'Leads' },
                    { key: 'Account', tab: 'Accounts' },
                  ]}
                  contentList={{
                    Billing: (
                      <AssociationDataTable
                        title={INVOICE}
                        record={addressRecord!}
                        moduleName={BILLING_MODULE}
                        entityName={INVOICE}
                      />
                    ),
                    Order: (
                      <AssociationDataTable
                        title={ORDER}
                        record={addressRecord!}
                        moduleName={ORDER_MODULE}
                        entityName={ORDER}
                      />
                    ),
                    WorkOrder: (
                      <AssociationDataTable
                        title={WORK_ORDER}
                        record={addressRecord!}
                        moduleName={FIELD_SERVICE_MODULE}
                        entityName={WORK_ORDER}
                      />
                    ),
                    Contact: (
                      <AssociationDataTable
                        title={CONTACT}
                        record={addressRecord!}
                        moduleName={CRM_MODULE}
                        entityName={CONTACT}
                      />
                    ),
                    CustomerDevices: (
                      <div>
                        <AssociationDataTable
                          title={CUSTOMER_DEVICE_ONT}
                          record={addressRecord!}
                          moduleName={SERVICE_MODULE}
                          entityName={CUSTOMER_DEVICE_ONT}
                        />
                        <AssociationDataTable
                          title={CUSTOMER_DEVICE_ROUTER}
                          record={addressRecord!}
                          moduleName={SERVICE_MODULE}
                          entityName={CUSTOMER_DEVICE_ROUTER}
                        />
                      </div>
                    ),
                    Lead: (
                      <AssociationDataTable
                        title={LEAD}
                        record={addressRecord!}
                        moduleName={CRM_MODULE}
                        entityName={LEAD}
                      />
                    ),
                    Account: (
                      <AssociationDataTable
                        title={ACCOUNT}
                        record={addressRecord!}
                        moduleName={CRM_MODULE}
                        entityName={ACCOUNT}
                      />
                    ),
                  }}
                />
              </div>
            </Col>
          </Row>
        </Layout>
      </>
    );
  }
}

const mapState = (state: any) => ({
  recordReducer: state.recordReducer,
  recordAssociationReducer: state.recordAssociationReducer,
  schemaReducer: state.schemaReducer,
  identityReducer: state.identityReducer,
  recordFormReducer: state.recordFormReducer,
  navigationReducer: state.navigationReducer,
});

const mapDispatch = (dispatch: any) => ({
  createRecord: (params: any, cb: any) =>
    dispatch(createRecordsRequest(params, cb)),
  cancelRequests: () => dispatch(premiseListCancelRequests()),
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  getSchema: (params: ISchemaByModuleAndEntity) =>
    dispatch(getSchemaByModuleAndEntityRequest(params)),
  getPremiseByUdprnAndUmprn: (params: any, cb: () => {}) =>
    dispatch(getPremiseByUdprnAndUmprnRequest(params, cb)),
  getAssociations: (params: IGetRecordAssociations, cb: any) =>
    dispatch(getRecordAssociationsRequest(params, cb)),
  getRecordById: (payload: IGetRecordById, cb: any) =>
    dispatch(getRecordByIdRequest(payload, cb)),
  updateOrderWorkflowAction: (payload: any) =>
    dispatch(updateOrderWorkflow(payload)),
  alertMessage: (params: { body: string; type: string }) =>
    dispatch(displayMessage(params)),
  updateAddressRecordWithVisit: (params: {
    visitRecord: DbRecordEntityTransform;
    addressRecord: DbRecordEntityTransform;
  }) => dispatch(updateAddressRecordWithVisitRequest(params)),
  updateIsBackProperty: () => dispatch(updateIsBackPropertyRequest()),
});

export default withRouter(connect(mapState, mapDispatch)(AddressDetailView));
