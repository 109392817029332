import { ArrowLeftOutlined } from '@ant-design/icons';
import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Button, Col, Divider, PageHeader, Row, Typography } from 'antd';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  IGetRecordById,
  getRecordByIdRequest,
} from '../../../../core/records/store/actions';
import {
  ISchemaByModuleAndEntity,
  getSchemaByModuleAndEntityRequest,
} from '../../../../core/schemas/store/actions';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { SchemaEntity } from '@d19n/models/dist/schema-manager/schema/schema.entity';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../shared/utilities/schemaHelpers';
import { OutcomeFormBody } from '../OutcomeFormBody';
import history from '../../../../shared/utilities/browserHisory';
import { v4 as uuidv4 } from 'uuid';
import { initializeRecordForm } from '../../../../core/records/components/Forms/store/actions';
import { SchemaModuleTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.types';
import CoreForm from '../../../../core/records/components/Forms/CoreForm';
const { Text } = Typography;

const uuid = uuidv4();

type PathParams = {
  url: string;
  recordId: string;
};

const { FIELD_SERVICE_MODULE } = SchemaModuleTypeEnums;

type PropsType = RouteComponentProps<PathParams> & {
  getRecordById: (payload: IGetRecordById, cb: any) => DbRecordEntityTransform;
  getSchema: Function;
  schemaReducer: any;
  recordReducer: any;
  match: any;
  initializeForm: Function;
};

const OutcomeFormDetailView: FC<PropsType> = (props: PropsType) => {
  const { schemaReducer, recordReducer, match, initializeForm } = props;
  const [record, setRecord] = useState<DbRecordEntityTransform | undefined>(
    undefined,
  );
  const [isEditingForm, setIsEditingForm] = useState<boolean>(false);

  const [schema, setSchema] = useState<SchemaEntity | undefined>(undefined);

  // Set record and schema to local state on component mount
  useEffect(() => {
    const { getRecordById } = props;

    if (schema && match.params.recordId) {
      getRecordById(
        { schema: schema, recordId: match.params.recordId },
        (res: DbRecordEntityTransform) => {
          setRecord(res);
        },
      );
    }
  }, [schema]);

  const afterFormUpdate = () => {
    const { getRecordById } = props;
    if (schema && match.params.recordId) {
      getRecordById(
        { schema: schema, recordId: match.params.recordId },
        (res: DbRecordEntityTransform) => {
          setRecord(res);
        },
      );
    }
  };

  //   When record is set, fetch the schema and set to local state
  useEffect(() => {
    const { schemaReducer, getSchema } = props;
    if (!schema) {
      const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
        schemaReducer.shortList,
        FIELD_SERVICE_MODULE,
        match.params.entityName,
      );

      if (shortlistSchema) {
        setSchema(shortlistSchema);
      } else {
        getSchema(
          {
            moduleName: FIELD_SERVICE_MODULE,
            entityName: match.params.entityName,
          },
          (res: SchemaEntity) => {
            setSchema(res);
          },
        );
      }
    }
  }, []);

  const onFormSubmit = (results: any) => {
    if (results) {
      setRecord(results);
    }
    setIsEditingForm(false);
  };

  const initializeEditForm = () => {
    initializeForm({
      title: `Edit ${schema?.entityName}`,
      formUUID: uuid,
      selected: record,
      recordType: record?.type,
      showFormModal: true,
      showInitializing: false,
      isCreateReq: false,
      isUpdateReq: true,
      schema: schema,
      sections: [
        {
          name: schema?.name,
          schema: schema,
          associations: [],
        },
      ],
    });
  };

  return (
    <PageHeader
      className="page-header"
      ghost={false}
      title={
        isEditingForm ? (
          <></>
        ) : (
          <Button
            type="primary"
            style={{ display: 'flex', marginLeft: 'auto' }}
            onClick={() => initializeEditForm()}
          >
            Edit Form
          </Button>
        )
      }
      backIcon={
        <div style={{ display: 'flex' }}>
          <ArrowLeftOutlined style={{ marginRight: 10 }} />
          Back
        </div>
      }
      onBack={() => history.goBack()}
    >
      <Row>
        <Col span={24}>
          <Divider style={{ marginTop: 0, marginBottom: '15px' }} />
        </Col>
      </Row>

      <Row
        style={{
          textAlign: 'center',
          backgroundColor: '#e4f2ff',
          borderRadius: '5px',
          padding: '12px',
        }}
      >
        <Col span={24}>
          <Text>
            {record ? record?.entity?.split(':')[1] : 'Unknown Entity'}
          </Text>{' '}
          -
          <Text>
            <strong> {record?.recordNumber}</strong>
          </Text>
        </Col>
        <Col span={24} style={{ paddingTop: '5px' }}>
          <span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>
            {record?.title || ''}
          </span>
        </Col>
      </Row>
      <Row>
        {/* Form Viewer */}
        {record && schema ? (
          <OutcomeFormBody record={record} schema={schema} />
        ) : (
          <></>
        )}
      </Row>

      <CoreForm
        type="MODAL"
        onSubmitEvent={() => afterFormUpdate()}
        showFormActions={true}
        formUUID={uuid}
      />
    </PageHeader>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  recordReducer: state.recordReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  getSchema: (params: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(params, cb)),
  getRecordById: (payload: IGetRecordById, cb: any) =>
    dispatch(getRecordByIdRequest(payload, cb)),
});

export default withRouter(
  connect(mapState, mapDispatch)(OutcomeFormDetailView),
);
