import { DbRecordEntityTransform } from '@d19n/models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  getFirstRelation,
  getProperty,
} from '@d19n/models/dist/schema-manager/helpers/dbRecordHelpers';
import { Badge, Col, Divider, Input, Layout, List, Row, Spin } from 'antd';
import Text from 'antd/es/typography/Text';
import React, { createRef } from 'react';
import { connect } from 'react-redux';
import {
  ISearchRecords,
  searchRecordsRequest,
  updateIsBackPropertyRequest,
} from '../../../../../core/records/store/actions';
import { IRecordReducer } from '../../../../../core/records/store/reducer';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '../../../../../core/schemas/store/actions';
import { ISchemaReducer } from '../../../../../core/schemas/store/reducer';
import { parseDateToLocalFormat } from '../../../../../shared/utilities/dateHelpers';
import { getRecordListFromShortListById } from '../../../../../shared/utilities/recordHelpers';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../../shared/utilities/schemaHelpers';
import {
  getDefaultFields,
  setSearchQuery,
  setSortQuery,
} from '../../../../../shared/utilities/searchHelpers';
import history from '../../../../../shared/utilities/browserHisory';
import { SchemaModuleTypeEnums } from '@d19n/models/dist/schema-manager/schema/types/schema.module.types';

const { CRM_MODULE } = SchemaModuleTypeEnums;

const { Search } = Input;

interface Props {
  moduleName: string;
  entityName: string;
  schemaReducer: ISchemaReducer;
  recordReducer: IRecordReducer;
  identityReducer: any;
  searchRecords: any;
  getSchema: any;
  updateIsBackProperty: () => void;
}

class AddressListView extends React.Component<Props> {
  // @ts-ignore
  private searchInputRef = createRef<Input>();

  componentDidMount(): void {
    this.loadSchema();

    /* If search box contains search query when the component is mounted -> search for it */
    if (
      this.searchInputRef.current &&
      this.searchInputRef?.current?.props?.value
    ) {
      const searchQuery: string = String(
        this.searchInputRef?.current?.props?.value,
      ).trim();

      if (searchQuery.length > 0) {
        this.searchRecordOnChange(searchQuery);
      } else {
        this.searchRecordOnChange('');
      }
    } else {
      this.searchRecordOnChange('');
    }
  }

  loadSchema() {
    const { getSchema } = this.props;
    // get schema by module and entity and save it to redux
    getSchema({ moduleName: 'CrmModule', entityName: 'Address' });
    getSchema({ moduleName: 'CrmModule', entityName: 'Premise' });
    getSchema({ moduleName: 'CrmModule', entityName: 'Contact' });
    getSchema({ moduleName: 'CrmModule', entityName: 'Visit' });
  }

  searchRecordOnChange(keyword: string) {
    const {
      schemaReducer,
      recordReducer,
      moduleName,
      entityName,
      searchRecords,
      updateIsBackProperty,
    } = this.props;
    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      moduleName,
      entityName,
    );
    if (recordReducer.isBackFromAddress) {
      updateIsBackProperty();
      return;
    }
    if (schema) {
      searchRecords({
        schema: schema,
        searchQuery: {
          terms: keyword,
          fields: getDefaultFields(moduleName, entityName),
          schemas: schema.id,
          sort: setSortQuery(
            schemaReducer,
            recordReducer,
            moduleName,
            entityName,
          ),
          pageable: {
            size: 200,
          },
        },
      });
    }
  }

  renderVisitBadge(item: DbRecordEntityTransform) {
    const lastVisit = getFirstRelation(item, 'Visit');

    const visitOutcome = lastVisit
      ? getProperty(lastVisit, 'Outcome')
      : undefined;

    if (visitOutcome) {
      return (
        <div className="list-item-with-label">
          <Text strong className="label">
            Last Visit:{' '}
          </Text>
          <Text>{visitOutcome}</Text>
        </div>
      );
    } else {
      return (
        <div className="list-item-with-label">
          <Text strong className="label">
            Last Visit:{' '}
          </Text>
          <Text>-</Text>
        </div>
      );
    }
  }

  renderFollowUpTime(item: DbRecordEntityTransform) {
    const lastVisit = getFirstRelation(item, 'Visit');

    const visitFollowUpDate = lastVisit
      ? getProperty(lastVisit, 'FollowUpDate')
      : undefined;

    if (visitFollowUpDate) {
      if (parseDateToLocalFormat(visitFollowUpDate)) {
        return (
          <div className="list-item-with-label">
            <Text strong className="label">
              Follow up:{' '}
            </Text>
            <Text>{parseDateToLocalFormat(visitFollowUpDate)}</Text>
          </div>
        );
      } else {
        return (
          <div className="list-item-with-label">
            <Text strong className="label">
              Follow up:{' '}
            </Text>
            <Text>-</Text>
          </div>
        );
      }
    } else {
      return (
        <div className="list-item-with-label">
          <Text strong className="label">
            Follow up:{' '}
          </Text>
          <Text>-</Text>
        </div>
      );
    }
  }

  renderOrderBadge(item: any) {
    if (item.CustomerDeviceOnt?.dbRecords) {
      return <Badge.Ribbon color="#009900" text="Active Order" />;
    } else {
      return undefined;
    }
  }

  handleSelectedAddress(item: any) {
    history.push(`/${CRM_MODULE}/Address/${item.id}`);
  }

  render() {
    const { recordReducer, schemaReducer, moduleName, entityName } = this.props;

    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      moduleName,
      entityName,
    );

    return (
      <Layout className="premise-list-view" style={{ padding: '10px' }}>
        <Search
          className="search-input"
          placeholder="search records"
          ref={this.searchInputRef}
          value={setSearchQuery(
            schemaReducer,
            recordReducer,
            moduleName,
            entityName,
          )}
          onChange={(e) => this.searchRecordOnChange(e.target.value)}
        />
        <Spin spinning={recordReducer.isCreating}>
          <List
            bordered
            className="list"
            loading={recordReducer.isSearching}
            style={{ padding: '15px' }}
            dataSource={
              schema
                ? getRecordListFromShortListById(recordReducer.list, schema.id)
                : []
            }
            renderItem={(item: DbRecordEntityTransform) => (
              <>
                <Row>
                  {/* Address / Title */}
                  <Col
                    style={{
                      textAlign: 'left',
                      paddingTop: '2px',
                      cursor: 'pointer',
                    }}
                  >
                    <Text style={{ color: '#1890ff' }}>
                      <span
                        onClick={() => this.handleSelectedAddress(item)}
                        style={{
                          fontSize: '1.1em',
                          fontWeight: 500,
                        }}
                      >
                        {item.title}
                      </span>
                    </Text>
                  </Col>

                  {this.renderVisitBadge(item) ? (
                    <Col span={24}>
                      <div style={{ paddingTop: '5px' }}>
                        {this.renderVisitBadge(item)}
                      </div>
                    </Col>
                  ) : (
                    <></>
                  )}

                  {this.renderFollowUpTime(item) ? (
                    <Col span={24}>
                      <div style={{ paddingTop: '5px' }}>
                        {this.renderFollowUpTime(item)}
                      </div>
                    </Col>
                  ) : (
                    <></>
                  )}

                  <Col span={24}>
                    <div style={{ paddingTop: '5px' }}>
                      <Text strong className="label">
                        Sales Status:{' '}
                      </Text>
                      <Text>{getProperty(item, 'SalesStatus')}</Text>
                    </div>
                  </Col>

                  {this.renderOrderBadge(item) ? (
                    <Col span={24}>
                      <div style={{ paddingTop: '5px' }}>
                        {this.renderOrderBadge(item)}
                      </div>
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>
                <Divider
                  orientation="center"
                  style={{ margin: '18px 0px', borderTopColor: '#d6d6d6' }}
                />
              </>
            )}
          />
        </Spin>
      </Layout>
    );
  }
}

const mapState = (state: any) => ({
  recordReducer: state.recordReducer,
  schemaReducer: state.schemaReducer,
  identityReducer: state.identityReducer,
});

const mapDispatch = (dispatch: any) => ({
  searchRecords: (params: ISearchRecords) =>
    dispatch(searchRecordsRequest(params)),
  getSchema: (params: ISchemaByModuleAndEntity) =>
    dispatch(getSchemaByModuleAndEntityRequest(params)),
  updateIsBackProperty: () => dispatch(updateIsBackPropertyRequest()),
});

export default connect(mapState, mapDispatch)(AddressListView);
