export const ADD_PATH_TO_HISTORY = 'ADD_PATH_TO_HISTORY';

export const ADD_TAB_TO_HISTORY = 'ADD_TAB_TO_HISTORY';

export const CLOSE_TAB = 'CLOSE_TAB';

export const ADD_SELECTED_MODULE = 'ADD_SELECTED_MODULE';

export const ADD_SELECTED_ENTITY = 'ADD_SELECTED_ENTITY';

export const ADD_NAVIGATION_STRUCTURE = 'ADD_NAVIGATION_STRUCTURE';

export const ADD_ROUTING_STRUCTURE = 'ADD_ROUTING_STRUCTURE';
